import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMobileMediaQuery} from '../../../hooks/useMediaQueries';

const OrderTable = ({order}) => {
	const [rowSpan, setRowSpan] = useState(3);
	const {t} = useTranslation();
	const isMobile = useMobileMediaQuery();
	useEffect(() => {
		if (!order) return;
		let c = 3;
		if (parseInt(order.Subtotals.shipping.value_int)) c += 1;
		if (parseInt(order.Subtotals.discount.value_int)) c += 1;

		setRowSpan(c);

	}, [order]);

	return (
		<div className="overflow-x-auto">
			<table className="table-auto w-full border-separate order-table-details">
				<thead>
					<tr>
						<th className="text-sm md:text-base font-bold pxl-display-font order-table-background">{isMobile ? t('order_detail_description_short') : t('order_detail_description')}</th>
						<th className="text-right text-sm md:text-base font-bold pxl-display-font order-table-background">{isMobile ? t('order_detail_quantity_short') : t('order_detail_quantity')}</th>
						<th className="text-right text-sm md:text-base font-bold pxl-display-font order-table-background">{t('order_detail_unit')}</th>
						<th className="text-right text-sm md:text-base font-bold pxl-display-font order-table-background">{t('order_detail_sum')}</th>
						<th className="text-right text-sm md:text-base font-bold pxl-display-font order-table-background">{t('order_detail_tax')}</th>
						<th className="text-right text-sm md:text-base font-bold pxl-display-font order-table-background">{t('order_detail_sum_tax')}</th>
					</tr>
				</thead>
				<tbody>
					{order && order.Items && order.Items.map(row => {
						return (<tr key={row.Order_Item__}>
							<td className="order-table-background">{row.Catalog_Product['Basic.Name']}</td>
							<td className="text-right order-table-background">{row.Quantity}</td>
							<td className="text-right order-table-background">{row.Price.display}</td>
							<td className="text-right order-table-background">{row.Full_Price.display}</td>
							<td className="text-right order-table-background">{row.Full_Vat_Amount.display} ({row.Vat_Rate + '%'})</td>
							<td className="text-right order-table-background">{row.Full_Price_Vat.display}</td>
						</tr>);
					}
					)}
				</tbody>
			</table>
			{/* <WavyLine className="mt-8"/> */}
			<table className="mt-6 ml-auto table-auto border-separate order-table-total">
				<tbody>
					<tr>
						<td rowSpan={rowSpan}/>
						<td className="order-table-background" colSpan={2}>{t('order_detail_subtotal')}</td>
						<td className="text-right order-table-background">{order.Total.display}</td>
					</tr>
					{
						(order && parseInt(order.Subtotals.shipping.value_int) !== 0) &&

						<tr>
							<td className="order-table-background" colSpan={2}>{t('order_detail_shipping')}</td>
							<td className="text-right order-table-background">{order.Subtotals.shipping.display}</td>
						</tr>
					}

					<tr>
						<td className="order-table-background" colSpan={2}>{t('order_detail_tax')}</td>
						<td className="text-right order-table-background">{order.Vat_Amount.display}</td>
					</tr>

					{
						(order && parseInt(order.Subtotals.discount.value_int) !== 0) &&

						<tr>
							<td className="order-table-background" colSpan={2}>{t('order_detail_discount')}</td>
							<td className="text-right order-table-background">{order.Subtotals.discount.display}</td>
						</tr>
					}

					<tr>
						<td className="order-table-background" colSpan={2}>{t('order_detail_total')}</td>
						<td className="text-right order-table-background">{order.Total_Vat.display}</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default OrderTable;

OrderTable.propTypes = {
	order: PropTypes.object.isRequired
};
