import React     from 'react';
import PropTypes from 'prop-types';
import moment    from 'moment';

const DateUtil = ({ klbDateObj, format = 'YYYY-MM-DD HH:mm:ss' }) => {
	let unixTime;

	if (!klbDateObj || typeof klbDateObj !== 'object') return 'N/A';
	if (klbDateObj && klbDateObj.unixms) unixTime = parseInt(klbDateObj.unixms);
	else if (klbDateObj && klbDateObj.unix)
		unixTime = parseInt(klbDateObj.unix) * 1000;

	return <>{unixTime ? moment(unixTime).format(format) : 'N/A'}</>;
};

DateUtil.propTypes = {
	klbDateObj: PropTypes.shape({
		unix  : PropTypes.string,
		unixms: PropTypes.string
	}),
	format: PropTypes.string
};

export default DateUtil;
