import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import TextInput from '../../core/inputs/TextInput/TextInput';
import Select from '../../core/inputs/Select/Select';

export default function EventQuestions({questions, answers, onAnswerChange}) {
	const {t} = useTranslation();

	if (questions.length === 0) {
		return <></>;
	}

	const buildQuestion = (question, i) => {
		const onChange = (event) => {
			const cpy = {...answers};
			cpy[question.Code] = event.target.value;

			onAnswerChange(cpy);
		};

		let answerElement;
		if (question.Type === 'select') {
			const options = question.Options.map((value) => {
				return {
					name: value,
					value: value,
				};
			});
			answerElement = <Select
				label={question.Question}
				onChange={onChange}
				value={answers[question.Code]}
				options={options}
			/>;
		} else {
			answerElement =
				<TextInput
					label={question.Question}
					value={answers[question.Code]}
					onChange={onChange}
					type={'text'}
					required
				/>;
		}

		return <div key={i} className={'py-6'}>
			{answerElement}
		</div>;
	};

	return <div className={'flex flex-col'}>
		<div className={'mb-8 font-bold pxl-display-font'}>{t('event_questions')}</div>
		{questions.map(buildQuestion)}
	</div>;
}

EventQuestions.propTypes = {
	questions: PropTypes.array,
	answers: PropTypes.object,
	onAnswerChange: PropTypes.func,
};