import React              from 'react';
import { useTranslation } from 'react-i18next';

import EmailLayout from '../../../../components/email/EmailLayout';

const Html = () => {
	const { t } = useTranslation();

	return (
		<EmailLayout
			intro={t('billing_method_changed_title')}
			paragraphs={[
				t('billing_method_changed_description'),
				t('billing_method_contact_us')
			]}
			showContactBtn={true}
		/>
	);
};

export default Html;
