import React     from 'react';
import PropTypes from 'prop-types';

import './TextInput.css';

const TextInput = ({
	className = '',
	disabled = false,
	id = `field-${Math.round(Math.random() * 10000)}`,
	label = '',
	name = '',
	onChange = () => {},
	required = false,
	type = 'text',
	placeholder = ' ',
	error = undefined,
	...etcetera
}) => {
	return (
		<>
			<label className={
				`pxl-txt-input${
					placeholder.trim().split('').length > 0 ? ' with-placeholder' : ''
				}
				${error ? ' error' : ''}
				${disabled ? ' disabled' : ''}
				${className ? ' ' + className : ''}`}
			>
				{type !== 'textarea' ? (

					<input
						id={id}
						type={type}
						name={name}
						required={required}
						placeholder={placeholder}
						onChange={onChange}
						disabled={disabled}
						tabIndex="0"
						{...etcetera}
					/>
				) : (
					<textarea
						id={id}
						required={required}
						placeholder={placeholder}
						onChange={onChange}
						disabled={disabled}
						{...etcetera}
					/>
				)}
				<span>{label}</span>
			</label>
			{error && <span className="pxl-txt-input-error">{error}</span>}
		</>
	);
};

TextInput.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	id: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	error: PropTypes.string,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	type: PropTypes.oneOf([
		'email',
		'number',
		'password',
		'text',
		'textarea'
	])
};

export default TextInput;