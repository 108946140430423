import React, { useEffect, useState } from 'react';
import PropTypes                      from 'prop-types';
import { useHistory, useLocation }    from 'react-router-dom';
import queryString                    from 'query-string';

let tagProp = 'tag';

export const buildArgsFromUrlSearchParams = (location, tagPropName = 'tag') => {
	const query = queryString.parse(location.search);
	const res   = {};

	tagProp = tagPropName;

	if (query.filters) 
		res.query = { [tagPropName]: query.filters.split(',') };

	if (query.page)
		res.page_no = query.page;

	return res;
};

const ArgsBuilderFromUrl = ({ args, filters = [], setArgs = () => {}}) => {
	const history  = useHistory();
	const location = useLocation();
	const [prevFilters, setPrevFilters] = useState([...filters]);

	useEffect(() => {
		if (filters.length > 0) {
			let sameFilters = true; 
			for (let i = 0; i < filters.length; i++) {
				if (!prevFilters[i] || !prevFilters[i].Classify_Tag__ || (prevFilters[i].Classify_Tag__ !== filters[i].Classify_Tag__)) {
					sameFilters = false;
					break;
				}
			}
			if (!sameFilters) {
				setArgs(a => ({ ...a, page_no: 1, query: { [tagProp]: filters.map(f => f.Classify_Tag__) }}));
				setPrevFilters([...filters]);
			}
		} else {
			setArgs(a => {
				const tmpArgs = {...a };
				delete tmpArgs.query;
				return tmpArgs;
			});
		}
	}, [filters]);

	useEffect(() => {
		let params = [];
		if (args.page_no && args.page_no > 1)
			params.push(`page=${encodeURIComponent(args.page_no)}`);

		if (filters?.length > 0)
			params.push(`filters=${encodeURIComponent(filters.map(f => f.Classify_Tag__).join(','))}`);
		
		history.push(`${location.pathname}?${params.map((p, i) => `${i > 0 ? '&' : ''}${p}`).join('')}`);
	}, [args, filters]);

	return <></>;
};

ArgsBuilderFromUrl.propTypes = {
	args: PropTypes.shape({
		page_no: PropTypes.any
	}).isRequired,
	filters: PropTypes.arrayOf(
		PropTypes.shape({
			Classify_Tag__: PropTypes.string
		})
	),
	setArgs: PropTypes.func
};

export default ArgsBuilderFromUrl;
