import React, {useState}  from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes          from 'prop-types';

import Btn                  from '../../core/inputs/Btn/Btn';
import BtnWithArrow         from '../../layout/BtnWithArrow/BtnWithArrow';
import ImgLoader            from '../../layout/ImgLoader/ImgLoader';
import { Link, useHistory } from 'react-router-dom';

import { dateFormat }         from '../../../utils/date';
import {clickOnEnterKeyPress} from '../../../utils/accessibility';

const ScheduleItem = ({ imageVariation, item }) => {
	const { t }   = useTranslation();
	const history = useHistory();
	const [tags] = useState((item.Tags ?? []).filter(t => !('hidden' in (t.Flags ?? {}))));

	return (
		<Link 
			className="no-underline flex flex-col justify-center items-center py-4 my-4"
			to={`/events/${item.Content_Cms_Entry_Data.Slug}`}
			tabIndex={-1}
			aria-label={item?.Content_Cms_Entry_Data?.Title || item.Label}
		>
			<div className="w-full flex justify-center pb-4 pr-2 md:pr-0">
				<div className="mx-2 md:mx-0 flex flex-col items-stretch w-1/3 sm:w-1/6 text-center">
					<div className="text-5xl">
						{dateFormat(item.Date, 'D')}
					</div>
					<div>{dateFormat(item.Date, 'dddd')}</div>
					<div className="my-2 flex mb-auto md:hidden flex-col justify-center items-center">
						{(tags && tags.length > 0) && (
							<div>
								{tags.map(t => (
									<div
										key={t.Classify_Tag__}
										className="w-full mt-2 px-2 py-1 text-xs border border-white"
									>
										{t.Full_Name}
									</div>
								))}
							</div>
						)}
					</div>
				</div>
				<div className="w-1/2 hidden md:block mx-4">
					<div
						className="relative w-full bg-pxl-darkGray"
						style={{ paddingTop: '56.25%' }}
					>
						{(item.Content_Cms_Entry_Data && item.Content_Cms_Entry_Data.Drive_Item) && (
							<div className="absolute top-0 left-0 w-full h-full">
								<ImgLoader
									src={
										item.Content_Cms_Entry_Data.Drive_Item.Media_Image[imageVariation] ||
										item.Content_Cms_Entry_Data.Drive_Item.View_Url
									}
									width="100%"
									height="100%"
									className="object-cover"
								/>
							</div>
						)}
					</div>
				</div>
				<div className="w-2/3 sm:w1/3 flex flex-col md:justify-between">
					<div className="text-2xl mb-4 pxl-display-font">
						{item?.Content_Cms_Entry_Data?.Title || item.Label}
					</div>
					<div
						className="block md:hidden relative w-full bg-pxl-darkGray"
						style={{ paddingTop: '56.25%' }}
					>
						{(item.Content_Cms_Entry_Data && item.Content_Cms_Entry_Data.Drive_Item) && (
							<img 
								src={
									item.Content_Cms_Entry_Data.Drive_Item.Media_Image[imageVariation] ||
									item.Content_Cms_Entry_Data.Drive_Item.View_Url
								}
								alt=""
								className="absolute top-0 left-0 w-full h-full object-cover"
							/>
						)}
					</div>
					<div className="mb-2 hidden md:block">
						{(tags && tags.length > 0) && (
							<div>
								{tags.map(t => (
									<Btn
										key={t.Classify_Tag__}
										size="xs"
										className="mr-2 mb-2 pointer-events-none"
										tabIndex={-1}
										accessibility={t.Full_Name}
									>
										{t.Full_Name}
									</Btn>
								))}
							</div>
						)}
					</div>
					<div className="hidden md:block">
						{item?.Content_Cms_Entry_Data?.Short_Contents || item.Description || t('no_description_for_this_event')}
					</div>
				</div>
			</div>
			<div className="block md:hidden mx-2 mb-4">
				{item?.Content_Cms_Entry_Data?.Short_Contents || item.Description || t('no_description_for_this_event')}
			</div>
			<BtnWithArrow
				variant="primary"
				className="w-full"
				accessibility={'Read more'}
				onKeyPress={(e) => clickOnEnterKeyPress(e, () => history.push(`/events/${item.Content_Cms_Entry_Data.Slug}`))}
			>
				Read more
			</BtnWithArrow>
		</Link>
	);
};

ScheduleItem.propTypes = {
	imageVariation: PropTypes.string,
	item          : PropTypes.shape({
		Content_Cms_Entry_Data: PropTypes.shape({
			Drive_Item: PropTypes.shape({
				Media_Image: PropTypes.object,
				View_Url   : PropTypes.string
			}),
			Slug: PropTypes.string,
			Short_Contents: PropTypes.string,
			Title: PropTypes.string,
		}),
		Date: PropTypes.shape({
			iso   : PropTypes.string,
			unix  : PropTypes.number,
			unixms: PropTypes.string
		}),
		Description       : PropTypes.string,
		Label             : PropTypes.string,
		Planner_Schedule__: PropTypes.string,
		Tags: PropTypes.arrayOf(
			PropTypes.shape({
				Classify_Tag__: PropTypes.string,
				Full_Name     : PropTypes.string
			})
		)
	}),
};

export default ScheduleItem;
