import React     from 'react';
import PropTypes from 'prop-types';

const EmailInfoBox = ({ children, ...etc }) => {
	return (
		<table
			border="0"
			cellSpacing="0"
			cellPadding="12"
			width="100%"
			align="center"
			style={{ width: '100%' }}
			{...etc}
		>
			<tbody>
				<td align="center">
					<table
						border="0"
						cellPadding="12"
						align="center"
					>
						<tbody>
							<tr>
								<td>{children}</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tbody>
		</table>
	);
};

EmailInfoBox.propTypes = {
	children: PropTypes.any
};

export default EmailInfoBox;
