import React, { useEffect } from 'react';
import PropTypes            from 'prop-types';
import { useTranslation }   from 'react-i18next';
import { rest }             from '@karpeleslab/klbfw';

import { FiLoader, FiX } from 'react-icons/fi';

const SelectedFilters = ({ filters, setFilters = () => {} }) => {
	const { t } = useTranslation();

	useEffect(() => {
		filters.forEach((f, i) => {
			if (!f.Full_Name) {
				rest(`Classify/@/Tag/${f.Classify_Tag__}`)
					.then(({ data }) => {
						const tmp = [...filters];
						tmp[i] = data;
						setFilters(tmp);
					})
					.catch(() => {});
			}
		});
	}, [filters]);

	return (
		<div className="flex flex-wrap items-center">
			{filters.length > 0 && (
				<div
					className="md:hidden mr-2 text-sm"
				>
					{t('selected_category')}
				</div>
			)}
			{filters.map(f => (
				<div
					key={f.Classify_Tag__}
					className="flex items-center m-1 p-1 border border-white hover:bg-pxl-darkGray"
				>
					<div className="flex-1 text-sm">
						{f.Full_Name || <FiLoader className="animate-spin" />}
					</div>
					<button
						type="button"
						className="flex-none pl-1 bg-transparent border-0"
						onClick={() => setFilters([])}
					>
						<FiX />
					</button>
				</div>
			))}
			{filters.length < 1 && (
				<div className="hidden md:block text-sm">{t('no_category_selected')}</div>
			)}
		</div>
	);
};

SelectedFilters.propTypes = {
	filters: PropTypes.arrayOf(
		PropTypes.shape({
			Classify_Tag__: PropTypes.string,
			Full_Name     : PropTypes.string,
			Name          : PropTypes.string
		})
	),
	setFilters: PropTypes.func
};

export default SelectedFilters;