import React, { useEffect, useState } from 'react';
import { useTranslation  }            from 'react-i18next';
import PropTypes                      from 'prop-types';
import { useRest }                    from '@karpeleslab/react-klbfw-hooks';

import AddressCard  from '../AddressCard/AddressCard';
import Btn          from '../../core/inputs/Btn/Btn';
import { FiLoader } from 'react-icons/fi';
import Loader       from '../../layout/Loader/Loader';

import { useUserSetDefaultLocation } from '@karpeleslab/klb-react-services';

const LocationList = ({ onAddressChanged = () => {}, onClose = () => {}}) => {
	const { t }                   = useTranslation();
	const [data]                  = useRest('User/Location');
	const [list, setList]         = useState([]);
	const [selected, setSelected] = useState(null);

	const [setDefaultLocation, settingDefaultLocation] = useUserSetDefaultLocation('@');

	const toggleSelected = address => {
		if (selected && selected.User_Location__ === address.User_Location__)
			setSelected(null);
		else
			setSelected(address);
	};

	const validateSelection = () => {
		setDefaultLocation(
			selected.User_Location__,
			'Billing',
			{ snackMessageToken: null }
		).then(() => onAddressChanged(selected));
	};

	useEffect(() => {
		if (data?.data) {
			setList(data.data);
			if (data.data.length > 0) 
				setSelected(data.data[0]);
		}
	}, [data]);

	return (
		<div className="w-full">
			<h2 className="text-2xl text-center">
				{t('choose_another_address')}
			</h2>
			<div className="flex flex-wrap flex-col md:flex-row">
				{list ? (
					list.map(address => (
						<div
							key={address.User_Location__}
							className={
								`flex m-2 bg-pxl-darkGray border border-transparent hover:border-white cursor-pointer${
									selected && address.User_Location__ === selected.User_Location__ ? ' border-green-500' : ''
								}`
							}
							onClick={() => toggleSelected(address)}
						>
							<div className="flex-none my-4 mx-2">
								<input
									type="radio"
									className="cursor-pointer"
									checked={selected && address.User_Location__ === selected.User_Location__}
								/>
							</div>
							<div className="flex-1">
								<AddressCard
									address={address}
									onClick={toggleSelected}
								/>
							</div>
						</div>
					))
				) : <Loader />}
			</div>
			<div className="w-full flex justify-between items-center pt-4 px-2">
				<div className="flex-none">
					<Btn onClick={onClose} accessibility={t('cancel')}>
						{t('cancel')}
					</Btn>
				</div>
				<div className="flex-none">
					<Btn
						type="submit"
						variant="primary"
						disabled={!selected || settingDefaultLocation}
						onClick={validateSelection}
						accessibility={t('validate')}
					>
						{settingDefaultLocation ? <FiLoader className="animate-spin" /> : t('validate')}
					</Btn>
				</div>
			</div>
		</div>
	);
};

LocationList.propTypes = {
	onAddressChanged: PropTypes.func,
	onClose         : PropTypes.func
};

export default LocationList;
