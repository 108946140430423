import React, { useState }     from 'react';
import PropTypes               from 'prop-types';
// import NoSsr                   from '../../core/utils/NoSsr/NoSsr';
// import ShareButtons            from '../../core/inputs/ShareButtons/ShareButtons';
// import EventInformation        from '../EventInformation/EventInformation';
import { BiLinkExternal }      from 'react-icons/bi';
import ImgLoader               from '../../layout/ImgLoader/ImgLoader';
import { MdNotListedLocation } from 'react-icons/md';
import PopIn                   from '../../layout/PopIn/PopIn';
import Tags                    from '../Tags/Tags';

// import { dateFormat }          from '../../../utils/date';
import { EVENT_TOP_VARIATION } from '../../../pages/Event/Event';

import './EventHeader.css';
import Like                    from '../Like/Like';
import NoSsr                   from '../../core/utils/NoSsr/NoSsr';
import ShareButtons            from '../../core/inputs/ShareButtons/ShareButtons';

const EventHeader = ({ event, cms }) => {
	const [openImage, setOpenImage] = useState(false);
	const img = cms.Top_Drive_Item ? cms.Top_Drive_Item.Media_Image[EVENT_TOP_VARIATION] : '';
	const isEvent = !!event.Planner_Event;
	const [tags] = useState((event.Tags ?? []).filter(t => !('hidden' in (t.Flags ?? {}))));

	return (
		<>
			<div className="flex flex-wrap flex-col sm:flex-row items-center mx-6 mt-0 event-header">
				<div className="w-full">
					<div className='flex py-4'>
						<NoSsr>
							<ShareButtons/>
						</NoSsr>
						<Like
							entryId={cms.Content_Cms_Entry__}
							initialLikeCount={cms.Likes.Like_Count ?? 0}
							initialLiked={cms.Likes.Liked ?? false}
						/>
					</div>
				</div>
				{!!img && (
					<div className="my-3 sm:pr-3 flex-none flex justify-center overflow-hidden">
						<ImgLoader
							src={img}
							width="200px"
							height="280px"
							className="cursor-pointer"
							onClick={() => setOpenImage(true)}
							rootClasses={'flex items-center'}
							style={{
								width: 'auto',
								height: 'auto',
							}}
						/>
						<PopIn open={openImage} setOpen={setOpenImage} maxWidth="md:max-w-screen-md">
							<img
								src={cms.Top_Drive_Item.Media_Image.Url}
								className="event-details-img-focus"
								alt="image"
							/>
						</PopIn>
					</div>
				)}
				<div className="flex-none sm:flex-1 flex flex-col items-center sm:items-start my-3 px-2">
					<Tags tags={tags || []}/>
					<div className="my-4">
						{cms.Short_Contents}
					</div>
					{isEvent && (
						<div className="flex items-center">
							<div className="flex-none pr-2 text-4xl">
								<MdNotListedLocation />
							</div>
							<div className="flex-1">
								{event.Planner_Event.Location && (
									<p className="text-xl mb-1">
										{event.Planner_Event.Location}
									</p>
								)}
								{event.Planner_Event.Location_Postal && (
									<p className="mb-1">
										{event.Planner_Event.Location_Postal}
									</p>
								)}
								{(event.Planner_Event.Location_Url && event.Planner_Event.Location_Url !== '') && (
									<a
										className="flex-none flex items-center underline text-sm"
										href={event.Planner_Event.Location_Url}
										target="_blank"
										rel="noopener noreferrer"
										aria-label={'go to website'}
									>
										go to website <span className="inline-block pl-1"><BiLinkExternal /></span>
									</a>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default EventHeader;

EventHeader.propTypes = {
	cms: PropTypes.shape({
		Short_Contents: PropTypes.string,
		Title         : PropTypes.string,
		Content_Cms_Entry__         : PropTypes.string,
		Likes: PropTypes.shape({
			Liked: PropTypes.bool,
			Like_Count: PropTypes.number,
		}),
		Top_Drive_Item: PropTypes.shape({
			Media_Image: PropTypes.shape({
				Url: PropTypes.string
			})
		})
	}).isRequired,
	event: PropTypes.shape({
		Planner_Event: PropTypes.shape({
			Event_Date     : PropTypes.object,
			Location       : PropTypes.string,
			Location_Postal: PropTypes.string,
			Location_Url   : PropTypes.string
		}),
		Tags: PropTypes.array
	}).isRequired,
};
