import React             from 'react';
import { Route, Switch } from 'react-router-dom';

import LayoutContexts   from './components/layout/LayoutContexts/LayoutContexts';
import LayoutHelmet     from './components/layout/LayoutHelmet/LayoutHelmet';
import LayoutSkeleton   from './components/layout/LayoutSkeleton/LayoutSkeleton';
import NoSsr            from './components/core/utils/NoSsr/NoSsr';
import ReactTooltip     from 'react-tooltip';
import RouterController from './components/routing/RouterController';

import mailRouteDefinitions from './components/routing/mailRouteDefinitions';
import {getMode}            from '@karpeleslab/klbfw';

function App() {
	const mode = getMode();
	const addLayout = mode !== 'ssr-open' &&
		mode !== 'client-open' &&
		mode !== 'ssr-mail';

	if (!addLayout) {
		return (
			<>
				<Switch>
					{mailRouteDefinitions.map(route => (
						<Route key={route.path} exact path={route.path}>
							{route.component}
						</Route>
					))}
				</Switch>
			</>
		);
	}

	return (
		<>
			<LayoutHelmet />
			<Switch>
				<Route>
					<LayoutContexts>
						<LayoutSkeleton>
							<RouterController/>
						</LayoutSkeleton>
					</LayoutContexts>
				</Route>
			</Switch>
			<NoSsr>
				<ReactTooltip
					backgroundColor='#fc0046'
					textColor="#ffffff"
					effect='solid'
				/>
			</NoSsr>
		</>
	);
}

export default App;
