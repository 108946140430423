import React, {useContext, useState} from 'react';
import {AiFillHeart, AiOutlineHeart} from 'react-icons/ai';
import {GoComment}                   from 'react-icons/go';
import Comments                      from './Comments';
import {UserContext}                 from '../../../context/UserContext';
import PropTypes                     from 'prop-types';
import {rest}                        from '@karpeleslab/klbfw';

const Comment = ({comment}) => {
	const [{loggedIn}] = useContext(UserContext);

	const [showReplies, setShowReplies] = useState(false);
	const [like, setLike] = useState(comment.Likes.Like_Count);
	const [liked, setLiked] = useState(comment.Likes.Liked);
	const [loadingLike, setLoadingLike] = useState(false);

	const toggleLike = () => {
		if (loadingLike || !loggedIn) return;

		setLoadingLike(true);
		const method = liked ? 'unlike' : 'like';
		rest(`Social/Comment/${comment.Social_Comment__}:${method}`, 'POST')
			.then((c) => {
				setLike(c.data.Like_Count);
				setLiked(c.data.Liked);
			})
			.finally(() => {
				setLoadingLike(false);
			});
	};

	return (
		<div className="py-1 w-full">
			<div className="flex flex-wrap justify-between overflow-hidden">
				<div className="overflow-hidden pb-1">
					<h5>{comment.Profile.Display_Name}</h5>
				</div>
			</div>
			<p>{comment.Message}</p>
			<div className="flex flex-wrap justify-end overflow-hidden">
				<div className="overflow-hidden flex items-center">
					<span className={'mr-2 cursor-pointer'} onClick={() => toggleLike()}>
						{!liked && <AiOutlineHeart/>}
						{liked && <AiFillHeart/>}
					</span>
					<span className={'pr-1 pb-1'}>{like}</span>
				</div>
				{comment.Comments && <div className="overflow-hidden flex items-center">
					<span className={'mx-2 cursor-pointer'}>
						<GoComment onClick={() => setShowReplies(!showReplies)}/>
					</span>
					<span className={'pr-1 pb-1'}>{comment.Comments.Comment_Count}</span>
				</div>}
			</div>
			{showReplies && <div className={'pl-6'}>
				<Comments
					id={comment.Comments.Key}
					path={`Social/Comment/${comment.Social_Comment__}:comment`}
					isReply
				/>
			</div>}
		</div>
	);
};

export default Comment;

Comment.propTypes = {
	comment: PropTypes.object.isRequired,
};