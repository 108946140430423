import React, { useContext} from 'react';

import { TemplateContext } from '../../../context/TemplateContext';

const Biography = () => {
	const { artist } = useContext(TemplateContext);
	if (!artist || !artist.Bio)	return <></>;

	return (
		<section 
			className="w-full max-w-3xl mx-auto h-auto pt-6 pb-12 flex flex-col justify-center items-center text-justify"
		>
			<div className="w-full max-w-7xl my-0 mx-auto text-md md:text-lg lg:text-xl">
				{artist.Bio}
			</div>
		</section>
	);
};

export default Biography;
