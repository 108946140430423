import React     from 'react';
import PropTypes from 'prop-types';

import Btn from '../../core/inputs/Btn/Btn';
import { 
	FiFacebook,
	FiGlobe, 
	FiInstagram,
	FiTwitter,
	FiYoutube } from 'react-icons/fi';

import './ArtistCard.css';

const iconsObj = {
	default  : {
		classes  : 'from-emerald-400 to-emerald-500 hover:from-emerald-500 hover:to-emerald-600',
		component: <FiGlobe />
	},
	facebook : {
		classes  : 'from-blue-700 to-blue-800 hover:from-blue-800 hover:to-blue-900',
		component: <FiFacebook />
	},
	instagram:  {
		classes  : 'from-fuchsia-600 via-pink-500 to-orange-400 hover:from-fuchsia-700 hover:via-pink-600 hover:to-orange-500',
		component: <FiInstagram />
	},
	twitter  :  {
		classes  : 'from-sky-400 to-sky-500 hover:from-sky-500 hover:to-sky-600',
		component: <FiTwitter />
	},
	youtube  :  {
		classes  : 'from-red-500 to-red-600 hover:from-red-600 hover:to-red-700',
		component: <FiYoutube />
	}
};
const ArtistCard = ({ artist, imageVariation }) => {
	return (
		<article className="pxl-artist-card w-full flex flex-col flex-wrap md:flex-row items-center md:items-stretch mx-0 md:mx-4 my-10 md:my-8 overflow-x-hidden md:overflow-x-visible">
			{artist.Image_Drive_Item && (
				<div className="pxl-artist-image mt-10 md:mt-0 relative flex-none">
					<img
						src={artist.Image_Drive_Item.Media_Image[imageVariation] || artist.Image_Drive_Item.View_Url}
						className="object-contain max-w-xs"
						width="100%"
						alt=""
					/>
				</div>
			)}
			<div className="flex-1 flex flex-col justify-center px-4">
				<div className="pxl-artist-title py-4">
					<h3 className="text-3xl">{artist.Name}</h3>
					{artist.Birthdate && <p>{artist.Birthdate}</p>}
				</div>
				<div style={{whiteSpace:'pre-line'}} dangerouslySetInnerHTML={{ __html: artist.Bio }} />
				<div className="py-4">
					{artist.Links.map(sn => (
						<Btn
							key={sn.Type}
							size="small"
							variant="discrete"
							href={sn.Link}
							className="mr-4"
							accessibility={sn.Type}
						>
							{iconsObj[sn.Type]
								? iconsObj[sn.Type].component
								: iconsObj.default.component
							}
						</Btn>
					))}
				</div>
			</div>
		</article>
	);
};

ArtistCard.propTypes = {
	artist: PropTypes.shape({ 
		Bio      : PropTypes.string,
		Birthdate: PropTypes.string,
		Links    : PropTypes.arrayOf(PropTypes.shape({
			Link: PropTypes.string,
			Type: PropTypes.string
		})), 
		Image_Drive_Item: PropTypes.shape({ 
			Media_Image: PropTypes.object,
			View_Url   : PropTypes.string
		}),
		Music_Label_Artist_Member__: PropTypes.string, 
		Name                       : PropTypes.string,
		Order                      : PropTypes.string
	}),
	imageVariation: PropTypes.string
};

export default ArtistCard;
