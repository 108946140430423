import React, { useContext } from 'react';
import BigTitle              from '../../../components/layout/BigTitle/BigTitle';
import MemberList            from '../../../components/pxl/ArtistList/MemberList';
import { useTranslation }    from 'react-i18next';
import { TemplateContext }   from '../../../context/TemplateContext';

const MembersSection = () => {
	const { t } = useTranslation();
	const { artist } = useContext(TemplateContext);
	const members = artist.Members || [];
	if (members.length < 1) return <></>;

	return (
		<section className='my-8'>
			<BigTitle direction={artist.Bio ? 'right' : 'left'}>{t('the_crew')}</BigTitle>
			<MemberList members={members}/>
		</section>
	);
};

export default MembersSection;
