import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useOrderProcess} from '@karpeleslab/klb-react-services';
import Loader from '../../../layout/Loader/Loader';
import PaymentMethodTab from './PaymentMethodTab';
import PaymentMethodContent from './PaymentMethodContent';


const OrderPayments = ({order, refresh}) => {
	const {t} = useTranslation();
	const [processOrder, processingOrder] = useOrderProcess(order.Order__);
	const [processResult, setProcessResult] = useState(null);

	const [paying, setPaying] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState();

	const handleTabChange = (newValue) => {
		if (processingOrder || paying || newValue === paymentMethod) return;

		setPaymentMethod(newValue);
	};

	useEffect(() => {
		processOrder().then(setProcessResult);
	}, [processOrder, setProcessResult]);

	useEffect(() => {
		if (paymentMethod || !processResult) return;

		// Select default payment method
		if (processResult.methods_order.includes('OnFile')) {
			setPaymentMethod('OnFile');
		} else {
			setPaymentMethod(processResult.methods_order[0]);
		}
	}, [processResult, setPaymentMethod]);

	if (processingOrder || !processResult || !paymentMethod) {
		return (
			<div className="flex flex-wrap overflow-hidden sm:-mx-3">
				<div className="w-full overflow-hidden sm:my-3 sm:px-3">
					<Loader/>
				</div>
			</div>
		);
	}

	return (
		<div className="flex flex-wrap overflow-hidden sm:-mx-3 px-3 md:px-0">

			<div className="w-full overflow-hidden sm:my-3 sm:px-3">
				<h1 className="text-2xl my-4 font-bold pxl-display-font">
					{t('order_payment_methods')}
				</h1>
			</div>

			<div className="w-full overflow-hidden sm:my-3 sm:px-3">
				{processResult.methods_order.length === 1 ?
					<div
						className={'font-bold pxl-display-font'}
					>
						{t(`payment_method_${processResult.methods_order[0].toLowerCase()}`)}
					</div> :
					<ul className="flex items-stretch md:items-center my-4 flex-col md:flex-row flex-wrap">
						{processResult.methods_order.map(method => (
							<PaymentMethodTab
								key={`${method}_tab`}
								onChange={handleTabChange}
								method={method}
								selected={paymentMethod}
								disabled={processingOrder || paying}
							/>
						))}
					</ul>
				}
			</div>

			<div className="w-full overflow-hidden sm:my-3 sm:px-3">
				{processResult.methods_order.map(method =>
					<PaymentMethodContent
						method={method}
						methodData={processResult.methods[method]}
						key={`${method}_content_${order.Status}`}
						selectedPaymentMethod={paymentMethod}
						setPaying={setPaying}
						order={order}
						refreshOrder={refresh}
						refreshProcessResult={setProcessResult}
					/>
				)}
			</div>

		</div>
	);
};

export default OrderPayments;

OrderPayments.propTypes = {
	order: PropTypes.object,
	refresh: PropTypes.func.isRequired
};
