import React     from 'react';
import PropTypes from 'prop-types';

import './Check.css';

const Check = ({ className, children, disabled, ...etcetera }) => {
	return (
		<label className={`pxl-check${disabled ? ' disabled' : ''} ${className}`}>
			<input
				type="checkbox" 
				tabIndex="0" 
				disabled={disabled}
				{...etcetera}
			/>
			<div>{children}</div>
		</label>
	);
};

Check.propTypes = {
	className: PropTypes.string,
	children : PropTypes.node, 
	disabled : PropTypes.bool
};

export default Check;
