import PropTypes          from 'prop-types';
import React              from 'react';
import Alert              from '../../layout/Alert/Alert';
import { useTranslation } from 'react-i18next';
import { getLocale }      from '@karpeleslab/klbfw';
import Redirect           from '../../core/utils/Redirect/Redirect';

const EventLanguageWarning = ({ entry, alternatives = [] }) => {
	const { t } = useTranslation();

	const lang = entry.Language__;

	const validAlternative = alternatives.filter(a => a.Language__ === getLocale());

	if (validAlternative.length > 0) {
		return (<Redirect target={`/events/${validAlternative[0].Key}`}/>);
	}

	return (
		<div className='my-4 w-full'>
			<Alert type={'warning'} className='text-center'>{t('event_lang_unavailable', { lang: lang })}</Alert>
		</div>
	);
};

export default EventLanguageWarning;

EventLanguageWarning.propTypes = {
	entry: PropTypes.object.isRequired,
	alternatives: PropTypes.array
};
