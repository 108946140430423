import React, { useContext } from 'react';
import PropTypes             from 'prop-types';

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Link }                            from 'react-router-dom';

import { MenuContext } from './../../../context/MenuContext';

import './MobileMenu.css';
import {allowPageScroll, noPageScroll} from '../../../utils/misc';

const MobileMenu = ({ pages }) => {
	const [menu, setMenu] = useContext(MenuContext);

	const onClick = () => {
		if (menu) {
			noPageScroll();
		} else {
			allowPageScroll();
		}
		setMenu(!menu);
	};

	return (
		<SwitchTransition>
			<CSSTransition
				key={!menu ? 'mobile-menu-show' : 'mobile-menu-hide' }
				addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
				classNames='u-transition--zoom'
			>
				{!menu ? (
					<div
						className="fixed top-0 left-0 w-screen h-full bg-pxl-darkerGray z-50"
						onClick={onClick}
					>
						<div className="w-full h-full box-border py-6 flex flex-col justify-evenly items-center">
							{pages.map((page, index) => (
								<Link
									key={page.route}
									to={page.route}
									className="mobile-menu-link text-3xl text-current no-underline cursor-pointer hover:underline"
									style={{ transitionDelay: `${0.075 * index}s` }}
									aria-label={page.name}
								>
									{page.name}
								</Link>
							))}
						</div>
					</div>
				) : <></>}
			</CSSTransition>
		</SwitchTransition>
	);
};

MobileMenu.propTypes = {
	pages: PropTypes.arrayOf(
		PropTypes.shape({
			name : PropTypes.string,
			route: PropTypes.string
		})
	)
};

export default MobileMenu;