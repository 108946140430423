import React     from 'react';
import PropTypes from 'prop-types';

import Footer         from '../Footer/Footer';
import LayoutHelmet   from '../LayoutHelmet/LayoutHelmet';
import LayoutPlayer   from '../LayoutPlayer/LayoutPlayer';
import MainNavigation from '../MainNavigation/MainNavigation';
import ScrollToTop    from '../../core/utils/ScrollToTop/ScrollToTop';
import Snackbar       from '../Snackbar/Snackbar';

const LayoutSkeleton = ({ children }) => {
	return (
		<>
			<LayoutHelmet/>
			<div className='h-auto min-h-screen flex-grow flex flex-col justify-between bg-pxl-darkerGray text-white'>
				<MainNavigation/>
				<div/>
				{children}
				<Footer/>
			</div>
			<ScrollToTop/>
			<Snackbar/>
			<LayoutPlayer/>
		</>
	);
};

LayoutSkeleton.propTypes = {
	children: PropTypes.node
};

export default LayoutSkeleton;
