import React              from 'react';
import { useTranslation } from 'react-i18next';

import ContactForm  from '../../components/pxl/ContactForm/ContactForm';
import Hero         from '../../components/layout/Hero/Hero';
import LayoutHelmet from '../../components/layout/LayoutHelmet/LayoutHelmet';
import WavyLine     from '../../components/layout/WavyLine/WavyLine';

export default function Contact() {
	const { t }  = useTranslation();

	return (
		<>
			<LayoutHelmet title={t('contact_us')} />
			<Hero title={t('contact_us')} />
			<main className="w-full max-w-3xl mb-4 mx-auto p-6">
				<p className="pb-12 text-center text-base leading-8">
					{t('contact_us_desc')}
				</p>
				<WavyLine className="py-6" />
				<ContactForm />
			</main>
		</>
	);
}
