import React     from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import './Btn.css';

const Btn = ({
	activeVariant = null,
	children = null,
	className = '',
	disabled = false,
	forceComponent = null,
	hoverVariant = null,
	href = null,
	onClick = () => {},
	size = 'normal',
	to = null,
	type = 'button', 
	variant = '',
	accessibility = '',
	...etcetera
}) => {
	// must do it this way for Tailwind
	let variantClasses = variant;
	if (hoverVariant) variantClasses += ` hover-${hoverVariant}`;
	if (activeVariant) variantClasses += ` active-${activeVariant}`;

	const classes = `pxl-btn pxl-display-font ${className} ${variantClasses} ${size}`;
	
	if (to && !disabled) {
		if (forceComponent) {
			return (
				<Link component={forceComponent} className={classes} onClick={onClick} to={to} aria-label={accessibility} {...etcetera}>
					<div>{children}</div>
				</Link>
			);
		} else {
			return (
				<Link className={classes} to={to} aria-label={accessibility} {...etcetera}>
					<div>{children}</div>
				</Link>
			);
		}
	} else if (href && !disabled) {
		return (
			<a className={classes} href={href} target="_blank" aria-label={accessibility} rel="noopener noreferrer" {...etcetera}>
				<div>{children}</div>
			</a>
		); 
	} else {
		return (
			<button type={type} disabled={disabled} className={classes} aria-label={accessibility} onClick={onClick} {...etcetera}>
				<div>{children}</div>
			</button>
		);
	}
};

Btn.propTypes = {
	activeVariant : PropTypes.oneOf(['accent', 'primary']),
	className     : PropTypes.string,
	children      : PropTypes.node,
	forceComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	hoverVariant  : PropTypes.oneOf(['accent', 'danger', 'primary']),
	href          : PropTypes.string,
	inverted      : PropTypes.bool,
	disabled      : PropTypes.bool,
	onClick       : PropTypes.func,
	size          : PropTypes.oneOf(['special', 'xs', 'small', 'normal', 'large']),
	to            : PropTypes.string,
	accessibility : PropTypes.string.isRequired,
	type          : PropTypes.oneOf(['button', 'submit']),
	variant       : PropTypes.oneOf(['danger', 'discrete', 'inverted', 'primary']),
};

export default Btn;