import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {CSSTransition, SwitchTransition} from 'react-transition-group';
import {FiLoader, FiPause, FiPlay} from 'react-icons/fi';

import {MusicContext} from '../../../context/MusicContext';

import './LayoutPlayer.css';
import {useSetSnackbar} from '../../../hooks/useSnackbar';
import {MdSkipNext, MdSkipPrevious, MdVolumeMute, MdVolumeUp} from 'react-icons/all';

const onTimeUpdate = (audio, canvas, progress) => {
	if (!audio || !canvas || isNaN(audio.duration)) return;

	if (progress.max !== Math.floor(audio.duration)) {
		progress.max = Math.floor(audio.duration);
	}
	const completion = audio.currentTime / audio.duration;
	const endAngle = (2 * Math.PI) * completion;
	progress.value = Math.floor(audio.currentTime);

	const ctx = canvas.getContext('2d');
	ctx.clearRect(0, 0, canvas.width, canvas.height);
	const gradient = ctx.createLinearGradient(100, 50, 0, 50);
	gradient.addColorStop(0, '#d59000');
	gradient.addColorStop(1, '#d32d49');

	ctx.strokeStyle = gradient;
	ctx.lineWidth = 7;
	ctx.beginPath();
	ctx.arc(50, 50, 46, 0, endAngle);
	ctx.stroke();
};

// https://developer.mozilla.org/en-US/docs/Web/API/MediaError
const MEDIA_ERR_ABORTED = 1;
const MEDIA_ERR_NETWORK = 2;

const LayoutPlayer = () => {
	const {t} = useTranslation();
	const audioRef = useRef(null);
	const playerRef = useRef(null);
	const progressBarRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const playCanvas = useRef(null);
	const setSnackbar = useSetSnackbar();
	const [muted, setMuted] = useState(false);

	const {
		currentIdx,
		playing,
		queue,
		togglePlay,
		nextSong,
		previousSong,
	} = useContext(MusicContext);

	const onMouseEnter = () => {
		if (!playerRef || !playerRef.current) return;
		playerRef.current.classList.add('w-96', 'bg-trueGray-800');
		playerRef.current.classList.remove('minimized');
	};

	const onMouseLeave = () => {
		if (!playerRef || !playerRef.current) return;
		playerRef.current.classList.add('minimized');
		playerRef.current.classList.remove('w-96', 'bg-trueGray-800');
	};

	useEffect(() => {
		if (!playerRef || !playerRef.current) return;

		playerRef.current.addEventListener('mouseenter', onMouseEnter);
		playerRef.current.addEventListener('mouseleave', onMouseLeave);
	}, [playerRef.current]);

	useEffect(() => {
		if (currentIdx >= 0 && playing && audioRef && audioRef.current) {
			setLoading(true);
			audioRef.current.oncanplay = () => {
				setLoading(false);
				audioRef.current.play()
					.catch((error) => {
						if (playing) {
							togglePlay();
						}
						audioRef.current.pause();
						setLoading(false);
						console.error(`Audio API error ${error}`);
					});
			};

			audioRef.current.ontimeupdate = () => {
				if (!playCanvas || !playCanvas.current || !audioRef || !audioRef.current || !progressBarRef || !progressBarRef.current) return;

				onTimeUpdate(audioRef.current, playCanvas.current, progressBarRef.current);
				if (audioRef.current.currentTime === audioRef.current.duration) {
					if (currentIdx + 1 === queue.length) {
						if (playing) 
							togglePlay();
					} else 
						nextSong();
				}
			};

			audioRef.current.src = queue[currentIdx].track.Music_Label_Record.Preview.mp3;
		} else if (audioRef && audioRef.current)
			audioRef.current.pause();

	}, [currentIdx, queue, audioRef]);

	useEffect(() => {
		if (!audioRef || !audioRef.current) return;

		if (audioRef) {
			audioRef.current.onerror = (error) => {
				if (playing) {
					togglePlay();
				}
				if (error.currentTarget.error.code === MEDIA_ERR_ABORTED) {
					return;
				} else if (error.currentTarget.error.code === MEDIA_ERR_NETWORK) {
					setSnackbar(t('player_error_network'), 'error');
				} else {
					setSnackbar(t('player_error_media'), 'error');
				}
				audioRef.current.pause();
				setLoading(false);
				console.error(`Audio API error code ${error.currentTarget.error.code} with message "${error.currentTarget.error.message}"`);
			};
		}

		if (playing) {
			audioRef.current.play().catch((error) => {
				if (playing) {
					togglePlay();
				}
				audioRef.current.pause();
				setLoading(false);
				console.error(`Audio API error ${error}`);
			});
		} else audioRef.current.pause();
	}, [playing, audioRef]);

	const updateTime = (value) => {
		if (!playing) {
			togglePlay();
		}

		if (!audioRef) {
			return;
		}
		audioRef.current.currentTime = value;
	};

	return (
		<>
			<SwitchTransition mode="out-in">
				<CSSTransition
					key={queue.length > 0 ? 'active' : 'inactive'}
					classNames="u-transition--vertical"
					addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
				>
					{queue.length > 0 ? (
						<div
							key="player-active"
							className="fixed bottom-4 left-1/2 w-0 h-20 overflow-visible z-10 pointer-events-none"
						>
							<div
								ref={playerRef}
								className="c-layout-player absolute bottom-0 left-1/2 w-96 h-full flex items-center bg-trueGray-800 text-white border border-white pointer-events-auto"
							>
								<div className="relative flex-none my-2 mr-2 ml-1">
									<button
										className="c-layout-player__btn m-2 p-4 rounded-full border-none bg-trueGray-800 hover:bg-trueGray-700"
										onClick={() => togglePlay()}
										title={t(loading ? 'buffering' : playing ? 'pause' : 'play')}
									>
										{loading
											? <FiLoader className="animate-spin"/>
											: (
												playing ? <FiPause/> : <FiPlay style={{transform: 'translateX(2px)'}}/>
											)
										}
									</button>
									<canvas
										ref={playCanvas}
										className="c-layout-player__time-canvas absolute z-20 pointer-events-none"
										width="100"
										height="100"
									/>
								</div>
								<div className="c-layout-player__inner w-full h-auto max-h-full transition-opacity flex flex-col">
									<div
										className="w-full h-1/3 overflow-hidden text-sm text-center overflow-ellipsis whitespace-nowrap"
									>
										{(currentIdx >= 0 && queue.length > 0) && (
											queue[currentIdx].track.Music_Label_Record.Name
										)}
									</div>
									<div className="w-full h-1/3">
										<input
											type={'range'}
											min={0}
											max={100}
											ref={progressBarRef}
											className={'progress w-full'}
											onChange={(e) => updateTime(e.target.value)}
										/>
									</div>
									<div className="w-full h-1/3 flex justify-between">
										<div className={'flex flex-row'}>
											<MdSkipPrevious
												onClick={previousSong}
												size={'24px'}
												className={'cursor-pointer'}
											/>
											<MdSkipNext onClick={nextSong} size={'24px'} className={'cursor-pointer'}/>
										</div>
										<div>
											{muted ?
												<MdVolumeMute
													size={'24px'}
													className={'cursor-pointer'}
													onClick={() => setMuted(false)}
												/> :
												<MdVolumeUp
													size={'24px'}
													className={'cursor-pointer'}
													onClick={() => setMuted(true)}
												/>
											}

										</div>
									</div>
								</div>
								{(currentIdx >= 0 && queue.length > 0) && (
									<img
										src={queue[currentIdx]?.album?.Drive_Item?.View_Url}
										alt=""
										className="flex-none w-16 h-full ml-2 object-cover bg-pxl-darkerGray"
									/>
								)}
							</div>
						</div>
					) : (
						<div key="player-inactive"/>
					)}
				</CSSTransition>
			</SwitchTransition>
			<audio ref={audioRef} className="hidden" muted={muted}/>
		</>
	);
};

export default LayoutPlayer;
