import React from 'react';
import PropTypes from 'prop-types';

export default function ShopPrice({item, textAlign}) {
	if (!textAlign) {
		textAlign = 'text-center';
	}
	const buildPrice = () => {
		if (item['Price.Discount'] === 'Y') {
			if (item['Public_Price'] !== undefined) {
				return (
					<div className={`${textAlign} my-2`}>
						<span
							className={'mr-2'}
							style={{textDecoration: 'line-through'}}
						>
							{item.Public_Price.tax.display}
						</span>
						{item.Price.tax.display}
					</div>
				);
			}
		}
		return <div className={`${textAlign} my-2`}>{item.Price.tax.display}</div>;
	};

	return <>
		{buildPrice()}
	</>;
}

ShopPrice.propTypes = {
	item: PropTypes.object,
	textAlign: PropTypes.string
};
