import { useCallback, useEffect, useRef, useState } from 'react';

const MIN_REFRESH_INTERVAL = 30 * 1000; // minimum 30 seconds between refresh;
const BACKGROUND_INTERVAL_REFRESH = 60 * 1000 * 30; // Background refresh every 30 minutes;

export const useAutoRefresher = (refresh, backGroundRefresh = BACKGROUND_INTERVAL_REFRESH) => {
	const timeoutId = useRef(null);
	const lastRefresh = useRef(0);
	const [backGroundRefreshInterval] = useState(backGroundRefresh < MIN_REFRESH_INTERVAL ? MIN_REFRESH_INTERVAL : backGroundRefresh);

	const clear = useCallback(() => {
		clearTimeout(timeoutId.current);
	}, []);

	const executeRefresh = useCallback(() => {
		if (Date.now() - lastRefresh.current < MIN_REFRESH_INTERVAL) return false;
		lastRefresh.current = Date.now();
		refresh();
		return true;
	}, [refresh]);


	const restart = useCallback(() => {
		if (timeoutId.current) clear();
		timeoutId.current = setTimeout(() => {
			executeRefresh();
			restart(); // Timer as reached the end, restart it.
		}, backGroundRefreshInterval);
	}, [executeRefresh]);

	const onFocus = useCallback(() => {
		if (document.visibilityState === 'hidden') return;
		// Only restart the timer if the refresh has been done to reset it to BACKGROUND_INTERVAL_REFRESH
		if (executeRefresh())
			restart();
	}, [executeRefresh, restart]);

	useEffect(() => {
		restart();

		return () => {
			if (timeoutId.current) clear();
		};
	}, [restart, clear]);

	useEffect(() => {
		if (typeof document === 'undefined') return;

		document.removeEventListener('visibilitychange', onFocus);
		document.removeEventListener('focus', onFocus);

		document.addEventListener('visibilitychange', onFocus);
		document.addEventListener('focus', onFocus);
		return () => {
			document.removeEventListener('visibilitychange', onFocus);
			document.removeEventListener('focus', onFocus);
		};
	}, [onFocus]);
};
