import React, { useContext, useEffect, useState } from 'react';
import { useTranslation  } from 'react-i18next';
import { rest }            from '@karpeleslab/klbfw';
import { useRest }         from '@karpeleslab/react-klbfw-hooks';

import AddBillingAddress from '../AddBillingAddress/AddBillingAddress';
import AddressForm       from '../../core/utils/AddressForm/AddressForm';
import Btn               from '../../core/inputs/Btn/Btn';
import { FiLoader }      from 'react-icons/fi';
import Loader            from '../../layout/Loader/Loader';
import PopIn             from '../../layout/PopIn/PopIn';

import { UserContext } from '../../../context/UserContext';

import { useUserSetDefaultLocation } from '@karpeleslab/klb-react-services';
import { useSetSnackbar }            from '../../../hooks/useSnackbar';
import { Confirm } from '../../layout/Confirm/Confirm';

const fieldsWhitelist   = ['First_Name', 'Last_Name', 'Middle_Name', 'Address', 'Address2', 'Zip', 'Province', 'City', 'Contact_Phone', 'Country__'];
const additionnalFields = { Contact_Phone: '' };

const EditLocations = () => {
	const { t }           = useTranslation();
	const [data, refresh] = useRest('User/Location');
	const [list, setList] = useState([]);
	const setSnackbar     = useSetSnackbar();
	const [user]          = useContext(UserContext);

	const [addAddressOpen, setAddAddressOpen]   = useState(false);
	const [addressToEdit, setAddressToEdit]     = useState(null); 
	const [updatingAddress, setUpdatingAddress] = useState(null);
	const [countryCode, setCountryCode] = useState(null);
	const [zip, setZip] = useState(null);
	const [provinces, setProvinces] = useState([]);
	const [isValid, setIsValid]                 = useState(false);
	const [setDefaultLocation]                  = useUserSetDefaultLocation('@');
	const [delModalOpen, setDelModalOpen] = useState(false);
	const [focusId, setFocusId] = useState(null);

	const onAddressAdded = () => {
		refresh();
		setAddAddressOpen(false);
	};

	const deleteAddress = User_Location__ => {
		rest(`User/Location/${User_Location__}`, 'DELETE')
			.then(() => {
				refresh();
				setSnackbar(t('user_location_deleted'), 'success');
				resetOnDelete();
			})
			.catch(err => setSnackbar(err.error, 'error'));
	};

	const editAddress = address => {
		setFieldsAndOrder(address);
	};

	useEffect(() => {
		if (addressToEdit && addressToEdit.Country__ && addressToEdit.Country__ !== countryCode)
			setCountryCode(addressToEdit.Country__);
	}, [addressToEdit]);

	useEffect(() => {
		if (countryCode) {
			setFieldsAndOrder(countryCode);
			fetchProvinces(countryCode);
		}
	}, [countryCode]);

	const fetchProvinces = (country) => {
		rest(`Country/${country}:getProvinces`)
			.then(({data}) => setProvinces(data));
	};

	const setDefaultBillingAddress = User_Location__ => {
		setDefaultLocation(User_Location__, 'Billing')
			.then(() => {
				refresh();
				setSnackbar(t('default_user_location_changed'), 'success');
			});
	};

	const setFieldsAndOrder = address => {
		rest(`Country/${address.Country__}`)
			.then(({ data }) => {
				const fields         = [];
				const newAddressFieldsList = {};
				data.Display_Format.forEach(ds => {
					ds.forEach(field => {
						if (fieldsWhitelist.includes(field)) {
							if (field !== 'Country__' && field !== 'Zip') fields.push(field);
							let val = '';
							if (field === 'Country__') val = address.Country__;
							else if (address && address[field]) val = address[field];
							newAddressFieldsList[field] = val;
						}
					});
				});

				if (!fields.Contact_Phone)
					fields.push(...Object.keys(additionnalFields));

				newAddressFieldsList.Contact_Phone = address.Contact_Phone || '';

				const _fielsdOrder = ['Country__', 'Zip', ...fields];
				setAddressToEdit({ ...newAddressFieldsList, User_Location__: address.User_Location__, _fielsdOrder });
			});
	};

	const updateAddress = () => {
		setUpdatingAddress(true);
		rest(`User/Location/${addressToEdit.User_Location__}`, 'PATCH', addressToEdit)
			.then(() => {
				setAddressToEdit(null);
				refresh();
				setSnackbar(t('address_updated'), 'success');
			})
			.catch(err => {
				setSnackbar(err.error, 'error');
			})
			.finally(() => setUpdatingAddress(false));
	};

	const zipBlurred = () => {
		if (zip === addressToEdit['Zip'] || addressToEdit['Zip'] === '') {
			return;
		}

		rest(`Country/${addressToEdit['Country__']}:zip`, 'GET', { zip: addressToEdit['Zip'] })
			.then(({ data }) => {
				const newAddressValues = { ...addressToEdit };
				Object.keys(data).forEach((field) => {
					let addrField = field;
					if(field === 'Province' && provinces.length < 1) return ;
					if(field === 'Province_Name' && provinces.length > 0) addrField = 'Province';
					if (!newAddressValues[addrField] || newAddressValues[addrField] === '') {
						newAddressValues[addrField] = data[field];
					}
				});
				setAddressToEdit(newAddressValues);
			})
			.catch((error) => {
				if (error.token === 'error_zip_not_found') {
					return;
				}
				throw(error);
			})
			.finally(() => {
				setZip(addressToEdit['Zip']);
			});
	};

	const onDelete = (id) => {
		setDelModalOpen(true);
		setFocusId(id);
	};

	const resetOnDelete = () => {
		setDelModalOpen(false);
		setFocusId(null);
	};

	useEffect(() => {
		if (data && data.data)
			setList(data.data);
	}, [data]);

	return (
		<div className="w-full">
			<div className="flex flex-wrap mt-4">
				{list ? (
					list.map(address => (
						<div key={address.User_Location__} className="w-full sm:w-1/2">
							<div className="m-2 p-4 border">
								{address.Display.map((d, idx) =>
									<p key={`${address.User_Location__}-${idx}`} className="text-md">{d}</p>
								)}
								<div className="flex justify-between items-center mt-2 text-sm">
									<span
										className="text-red-600 hover:underline cursor-pointer"
										onClick={() => onDelete(address.User_Location__)}
									>{t('delete')}</span>
									<span
										className="hover:underline cursor-pointer"
										onClick={() => editAddress(address)}
									>{t('edit')}</span>
									{(!user || !user.user || !user.user.data || !user.user.data.Default_Billing_User_Location__ || user.user.data.Default_Billing_User_Location__ !== address.User_Location__) &&
										<span
											className="hover:underline cursor-pointer"
											onClick={() => setDefaultBillingAddress(address.User_Location__)}
										>{t('set_default_billing')}</span>
									}
								</div>
							</div>
						</div>
					))
				) : <Loader />}
			</div>
			<div className="w-full flex justify-center mt-4">
				<Btn onClick={() => setAddAddressOpen(true)} accessibility={t('add_an_address')}>
					{t('add_an_address')}
				</Btn>
			</div>
			<Confirm
				open={delModalOpen}
				onCancel={resetOnDelete}
				title={t('user_delete_location')}
				content={t('user_delete_location_question')}
				onValidate={() => deleteAddress(focusId)}
			/>
			<PopIn
				open={!!addressToEdit}
				setOpen={() => setAddressToEdit(null)}
			>
				<h2 className="text-2xl text-center">
					{t('edit_your_address')}
				</h2>
				<AddressForm
					address={addressToEdit}
					setAddress={setAddressToEdit}
					setIsValid={setIsValid}
					zipBlurred={zipBlurred}
					isGeoLookup={false}
					provinces={provinces}
				/>
				<div className="flex justify-between items-center mt-4">
					<Btn
						onClick={() => setAddressToEdit(null)}
						tabIndex={4}
						accessibility={t('cancel')}
					>
						{t('cancel')}
					</Btn>
					<Btn
						variant="primary"
						disabled={!isValid || updatingAddress}
						onClick={updateAddress}
						tabIndex={4}
						accessibility={t('update')}
					>
						{updatingAddress ? <FiLoader className="animate-spin" /> : t('update')}
					</Btn>
				</div>
			</PopIn>
			<PopIn open={addAddressOpen} setOpen={setAddAddressOpen}>
				<AddBillingAddress
					onComplete={onAddressAdded}
					onCancel={() => setAddAddressOpen(false)}
				/>
			</PopIn>
		</div>
	);
};

export default EditLocations;
