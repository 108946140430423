import React, {useEffect, useState} from 'react';
import PropTypes                    from 'prop-types';
import { useTranslation }           from 'react-i18next';
import { rest }                     from '@karpeleslab/klbfw';

import SelectedFilters from './SelectedFilters';
import ShopTags        from './ShopTags';

const findTagPath = (tag, tags, callback = () => {}) => {
	for (let i = 0; i < tags.length; i++) {
		if (tags[i].Classify_Tag__ === tag.Classify_Tag__) {
			callback(tags[i]);
			return tags[i];
		}
		
		if (tags[i].Children) {
			const resInChildren = findTagPath(tag, tags[i].Children, callback);
			if (resInChildren)
				return resInChildren;
		}
	}
	return false;
};

const ShopMenu = ({ baseTags = [], filters, setFilters = () => {} }) => {
	const { t }           = useTranslation();
	const [tags, setTags] = useState(
		baseTags?.data?.length > 0 ?
			[...baseTags.data.filter(t => !('hidden' in (t.Flags ?? {}))).map(t => ({ ...t, childLoaded: false, loading: false }))] :
			[]
	);

	const handleClick = (tag) => {
		if (!tag.childLoaded) {
			const tmp     = [...tags];
			const thisTag = findTagPath(tag, tmp);

			if (!thisTag)
				return;

			findTagPath(tag, tmp, t => t.loading = true);
			setTags(tmp);
			rest('Classify/@:getTag', 'GET', { tag: tag.Classify_Tag__ })
				.then(({ data }) => {
					findTagPath(tag, tmp, t => t.childLoaded = true);
					if (data?.Children?.length > 0)
						findTagPath(tag, tmp, t => t.Children = [...data.Children.map(c => ({ ...c, childLoaded: false, loading: false }))]);
				})
				.catch(() => {})
				.finally(() => {
					findTagPath(tag, tmp, t => t.loading = false);
					setTags([...tmp]);
				});
		} 
		
		setFilters(filters.find(f => f.Classify_Tag__ === tag.Classify_Tag__) ? [] : [tag]);
	};

	useEffect(() => {
		if (baseTags?.data?.length > 0) {
			setTags(() => [...baseTags.data.filter(t => !('hidden' in (t.Flags ?? {}))).map(t => ({ ...t, childLoaded: false, loading: false }))]);
		}
	}, [baseTags]);

	return (
		<>
			<div className="py-2">
				<div className="text-2xl font-bold pb-1">{t('selected_category')}</div>
				<SelectedFilters
					filters={filters}
					setFilters={setFilters}
				/>
			</div>
			<div className="py-2">
				<div className="text-2xl font-bold pb-1">{t('categories')}</div>
				<ShopTags
					filters={filters}
					tags={tags}
					onTagClicked={handleClick}
				/>
			</div>
		</>
	);
};

ShopMenu.propTypes = {
	baseTags: PropTypes.arrayOf(
		PropTypes.shape({
			Classify_Tag__: PropTypes.string
		})
	),
	filters : PropTypes.arrayOf(
		PropTypes.shape({
			Classify_Tag__: PropTypes.string
		})
	),
	setFilters: PropTypes.func
};

export default ShopMenu;