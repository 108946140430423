import PropTypes                 from 'prop-types';
import React, { useState }       from 'react';
import { useCatalogCartProcess } from '@karpeleslab/klb-react-services';
import { useTranslation }        from 'react-i18next';
import Btn                       from '../../core/inputs/Btn/Btn';
import TextInput                 from '../../core/inputs/TextInput/TextInput';
import { FiLoader }              from 'react-icons/fi';

const CartAddCoupon = ({ refreshCart }) => {
	const { t } = useTranslation();
	const [processQuery, processingCart] = useCatalogCartProcess();

	const [coupon, setCoupon] = useState('');

	const addCoupon = () => {
		if (coupon.trim().length === 0) {
			return;
		}

		const query = `coupon,coupon_code=${coupon.trim()}`;
		processQuery(query, { snackMessageToken: 'toast_coupon_added' })
			.then(cart => {
				setCoupon('');
				refreshCart(cart);
			});

	};

	return (
		<div className={'flex justify-end items-center w-full mt-8 px-2 md:px-0'}>
			<div className=''>
				<TextInput
					label={t('cart_coupon_placeholder')}
					value={coupon}
					onChange={e => setCoupon(e.target.value)}
				/>
			</div>
			<div className={'my-4 mx-2'}>
				<Btn onClick={addCoupon} disabled={processingCart} accessibility={t('add_basket')}>
					{processingCart ? <FiLoader className='animate-spin'/> : t('add_basket')}
				</Btn>
			</div>
		</div>
	);
};

export default CartAddCoupon;

CartAddCoupon.propTypes = {
	refreshCart: PropTypes.func.isRequired
};
