import React              from 'react';
import { Get }            from '@karpeleslab/klbfw';
import { useTranslation } from 'react-i18next';
import { getHostname }    from '@karpeleslab/klbfw';

import EmailLayout from '../../../../components/email/EmailLayout';
import Text        from '../../../../components/email/EmailText';

const Html = () => {
	const { t } = useTranslation();

	return (
		<EmailLayout
			intro={t('mail_payment_invalidate_subject', { website: getHostname() })}
			showContactBtn={true}
		>
			<table
				border="0"
				cellSpacing="0"
				cellPadding="0"
				width="100%"
				style={{ width: '100%' }}
			>
				<tbody>
					<tr>
						<td>
							<Text size="medium">{Get('_user').Profile.Display_Name}</Text>
							<Text size="medium">{t('mail_payment_invalidate_desc', { website: getHostname() })}</Text>
						</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td align="center">
							<Text size="medium">{t('contact_us_desc')}</Text>
						</td>
					</tr>
				</tbody>
			</table>
		</EmailLayout>
	);
};

export default Html;
