import PropTypes           from 'prop-types';
import React               from 'react';
import { useTranslation }  from 'react-i18next';
import { BsCloudDownload } from 'react-icons/bs';

const OrderFileDeliverables = ({ files }) => {
	const { t } = useTranslation();

	return (
		<div className='flex flex-wrap overflow-hidden sm:-mx-3 px-3 md:px-0'>

			<div className='w-full overflow-hidden sm:my-3 sm:px-3'>
				<h1 className='text-2xl my-4 font-bold pxl-display-font'>
					{t('order_file_deliverables')}
				</h1>
			</div>

			<div className='overflow-x-auto w-full'>
				<table className='table-auto w-full border-separate order-table-details'>
					<thead>
						<tr>
							<th className='text-sm md:text-base font-bold pxl-display-font order-table-background'>{t('order_file_name')}</th>
							<th className='text-sm md:text-base font-bold pxl-display-font order-table-background'>{t('order_file_download')}</th>
						</tr>
					</thead>
					{files.map(file =>
						(
							<tr key={file.Drive_Item.Drive_Item__}>
								<td className='order-table-background'>{file.Name}</td>
								<td className='order-table-background'>
									<a href={file.Drive_Item.Download_Url} target='_blank' rel='noreferrer noopener'>
										<BsCloudDownload
											size={'24px'}
											style={{ marginLeft: 'auto', marginRight: 'auto' }}
										/>
									</a>
								</td>
							</tr>
						)
					)}
				</table>
			</div>
		</div>
	);
};

export default OrderFileDeliverables;

OrderFileDeliverables.propTypes = {
	files: PropTypes.array.isRequired
};
