import React, { useContext } from 'react';
import { useTranslation }    from 'react-i18next';

import { LoginContext } from '@karpeleslab/klb-react-services';

import Btn from '../../core/inputs/Btn/Btn';

const ButtonsBuilder = () => {
	const { goBack, initialStep } = useContext(LoginContext);
	const { t } = useTranslation();

	return (
		<div className="flex justify-between items-center mt-6">
			<div className="flex-none mr-2">
				{!initialStep && (
					<Btn
						type="button"
						onClick={goBack}
						accessibility={t('back_btn')}
					>
						{t('back_btn')}
					</Btn>
				)}
			</div>
			<div className="flex-none ml-2">
				<Btn
					type="submit"
					variant="primary"
					accessibility={t('next_btn')}
				>
					{t('next_btn')}
				</Btn>
			</div>
		</div>
	);
};

export default ButtonsBuilder;
