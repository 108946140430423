import PropTypes          from 'prop-types';
import React              from 'react';
import { useTranslation } from 'react-i18next';

const CartTotals = ({ cart }) => {
	const { t } = useTranslation();

	return (
		<table className={'md:mr-2 table-auto border-separate order-table-total w-3/5 md:w-full'}>
			<thead>
				<tr>
					<th align='center' colSpan={2}>
						<strong>
							{t('cart_totals')}
						</strong>
					</th>
				</tr>
			</thead>
			<tbody>
				{/*<tr className={'row'}>
					<td>{t('cart_total_before_discount')}</td>
					<td align='right'>{cart.total_no_coupon_no_vat.display}</td>
				</tr>
				*/}
				<tr className={'row'}>
					<td>{t('cart_total_vat_ht')}</td>
					<td align='right'>{cart.total.display}</td>
				</tr>

				<tr className={'row'}>
					<td>{t('cart_total_vat_only')}</td>
					<td align='right'>{cart.total_vat_only.display}</td>
				</tr>

				<tr className={'row'}>
					<td><strong>{t('cart_total_vat')}</strong></td>
					<td align='right'><strong>{cart.total_vat.display}</strong></td>
				</tr>
			</tbody>
		</table>
	);
};

export default CartTotals;

CartTotals.propTypes = {
	cart: PropTypes.object.isRequired
};
