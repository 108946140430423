import React, { useContext, useEffect, useState } from 'react';
import PropTypes                                  from 'prop-types';
import { Helmet }                                 from 'react-helmet';
import { useLocation }                            from 'react-router-dom';
import { getHostname, getPrefix }                 from '@karpeleslab/klbfw';

import { TemplateContext } from '../../../context/TemplateContext';

/*
 * Notice: if you specify a title, it will prefix the artist's name in the title
 *         for example if you specify "my title" it will show as "my title - artist name"
 *         whereas for the description and image, it will override the defaults
 */
const LayoutHelmet = ({ description = null, image = null, title = null }) => {
	const location                = useLocation();
	const { artist, favicon }     = useContext(TemplateContext);
	const [defaults, setDefaults] = useState({
		description: artist ? artist.Bio : '',
		image      : !artist ? null : (artist.Image_Drive_Item && artist.Image_Drive_Item.Icon ? artist.Image_Drive_Item.Icon : null),
		title      : artist ? artist.Name : '',
	});

	useEffect(() => {
		if (!artist) return;

		setDefaults({
			title      : artist.Name,
			description: artist.Bio,
			image      :
				artist.Image_Drive_Item && artist.Image_Drive_Item.Icon
					? artist.Image_Drive_Item.Icon
					: null
		});
	}, [artist, setDefaults]);

	const img = image || defaults.image;

	return (
		<Helmet>
			<meta charset="utf-8" />
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1, user-scalable=no"
			/>
			<meta name="theme-color" content="#1e1f20" />
			<link rel="icon" href={favicon?.Icon || '/favico.ico'} />
			<title>{(title ? `${title} - ` : '') + defaults.title}</title>
			<meta property="og:title" content={(title ? `${title} - ` : '') + defaults.title} />
			<meta name="twitter:title" content={(title ? `${title} - ` : '') + defaults.title} />

			<link rel="canonical" href={`https://${getHostname()}${getPrefix()}${location.pathname}`} />
			<meta property="og:url" content={`https://${getHostname()}${getPrefix()}${location.pathname}`} />
			<meta name="twitter:url" content={`https://${getHostname()}${getPrefix()}${location.pathname}`} />

			<meta name="description" content={description || defaults.description} />
			<meta property="og:description" content={description || defaults.description} />
			<meta Bio="twitter:description" content={description || defaults.description} />

			{/* can't group the 2 following meta tags into a fragment because react-helmet is stupid af */}
			{img && <meta property="og:image" content={img} />}
			{img && <meta name="twitter:image" content={img} />}
		</Helmet>
	);
};

LayoutHelmet.propTypes = {
	description: PropTypes.string,
	image      : PropTypes.string,
	title      : PropTypes.string
};

export default LayoutHelmet;
