import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRest }        from '@karpeleslab/react-klbfw-hooks';

import { FiX } from 'react-icons/fi';

// import './NewsSelectedFilters.scss';

export default function NewsSelectedFilters ({
	classify,
	selectedFilters = [], 
	onRemoveFilter = () => {}
}) {
	const { t }  = useTranslation();
	const [data] = useRest(`Classify/${classify}/Tag`); 

	return (
		<div>
			{selectedFilters.length > 0 && (
				<h3 className="text-2xl text-center pb-4">{t('selected_filters')}</h3>
			)}
			{selectedFilters && (
				selectedFilters.map(t => (
					<button
						key={t.Classify_Tag__}
						className="inline-block whitespace-nowrap text-white m-2 p-1 rounded-lg text-xs bg-black bg-gradient-to-b from-blueGray-700 to-blueGray-800"
						onClick={() => onRemoveFilter([t])}
					>
						{t.Full_Name || data?.data?.find(tag => tag.Classify_Tag__ === t.Classify_Tag__)?.Full_Name}
						<FiX className="inline pl-1" />
					</button>
				))
			)}
		</div>
	);
}

NewsSelectedFilters.propTypes = {
	classify       : PropTypes.string.isRequired,
	onRemoveFilter : PropTypes.func,
	selectedFilters: PropTypes.arrayOf(PropTypes.shape({
		Classify_Tag__   : PropTypes.string,
		Color            : PropTypes.string,
		Full_Name        : PropTypes.string,
		Full_Name_Compact: PropTypes.string,
		Name             : PropTypes.string,
		Priority         : PropTypes.string
	}))
};
