import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getHostname }    from '@karpeleslab/klbfw';

const Text = ({ date, recoverUrl }) => {
	const { t } = useTranslation();

	return (
		<textarea
			id="mail-text"
			defaultValue={
				t('user_password_mail_notice') +
				'\n\r' +
				recoverUrl + 
				'\n\r' +
				t('user_password_mail_date_notice', { date }) + 
				'\n\r' +
				t('user_password_mail_regards') +
				'\n\r' +
				t('user_password_email_footer', { website: getHostname() }) +
				'\n\r'
			}
		/>
	);
};

Text.propTypes = {
	date      : PropTypes.string,
	recoverUrl: PropTypes.string
};

export default Text;
