import PropTypes                    from 'prop-types';
import React                        from 'react';
import { useCatalogCartItemRemove } from '@karpeleslab/klb-react-services';
import { CgRemove }                 from 'react-icons/all';
import { FiLoader }                 from 'react-icons/fi';

const CartItem = ({ cartItem, refreshCart, readonly = false }) => {
	const [remove, removing] = useCatalogCartItemRemove(cartItem.key);

	const handleDelete = () => {
		remove().then((newCart) => {
			refreshCart(newCart);
		});
	};

	return (
		<tr className={'row'}>
			<td>{cartItem.data['Basic.Name']}</td>
			<td>{cartItem.quantity}</td>
			<td>{cartItem.price.raw.display}</td>
			<td>{`${cartItem.price.tax_rate}%`}</td>
			<td>{cartItem.price.tax.display}</td>
			{!readonly &&
			<td align='center'>
				<button className={'btn'} onClick={() => handleDelete()} disabled={removing}>
					{removing ? <FiLoader className='animate-spin'/> : <CgRemove/>}
				</button>
			</td>
			}

		</tr>
	);
};

export default CartItem;

CartItem.propTypes = {
	cartItem: PropTypes.object.isRequired,
	readonly: PropTypes.bool,
	refreshCart: PropTypes.func.isRequired
};
