import React            from 'react';
import {useTranslation} from 'react-i18next';
import {getHostname}    from '@karpeleslab/klbfw';

import Helmet            from 'react-helmet';
import Html              from './Html';
import Text              from './Text';
import {dateFormat}                            from '../../../utils/date';
import { getFrom, getFromName, getSubjectTag } from '../../utils';

const Mail = () => {
	const {t} = useTranslation();

	const dateTimeNow = dateFormat('now', 'L LT');

	return (
		<div>
			<Helmet>
				<title>{t('mail_pwd_recovered_subject', {website: getSubjectTag()})}</title>
				<meta name="mail-from-name" content={getFromName()}/>
				<meta name="mail-from" content={getFrom()}/>
			</Helmet>
			<Html formattedDateTime={dateTimeNow} website={getHostname()}/>
			<Text formattedDateTime={dateTimeNow} website={getHostname()}/>
		</div>
	);
};

export default Mail;
