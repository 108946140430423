import React        from 'react';
import {useRest}    from '@karpeleslab/react-klbfw-hooks';
import FanClubPlans from './FanClubPlans';


const FanClubPlansLogged = () => {
	const [sub] = useRest('Membership/@fanclub/User/@', {}, true, 1000);

	return (
		<FanClubPlans userSubscription={sub}/>
	);
};

export default FanClubPlansLogged;
