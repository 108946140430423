import React, { useContext, useState } from 'react';
import { useTranslation }              from 'react-i18next';
import { getPrefix }         from '@karpeleslab/klbfw';

import LayoutHelmet from '../../components/layout/LayoutHelmet/LayoutHelmet';
import Login        from '../../components/user/Login/Login';
import Redirect     from '../../components/core/utils/Redirect/Redirect';

import { LoginContextContainer } from '@karpeleslab/klb-react-services';
import { UserContext }           from '../../context/UserContext';

export default function LoginPage () {
	const { t }	          = useTranslation();
	const [user, setUser] = useContext(UserContext);
	const [redirectTo, setRedirectTo] = useState('/');


	const onValidated = data => {
		setRedirectTo(data.Redirect.replace(getPrefix(), ''));

		setUser({
			loggedIn: true,
			user    : { data: data.user }
		});
	};

	if (user.loggedIn) {
		return <Redirect target={redirectTo}/>;
	}

	return (
		<LoginContextContainer onValidated={onValidated}>
			<LayoutHelmet
				title={t('login_or_signup')}
				description={t('login_or_signup_description')}
			/>
			<main className="w-full max-w-md my-0 mx-auto pt-28 px-6 pb-4">
				<Login />
			</main>
		</LoginContextContainer>
	);
}
