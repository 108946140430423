import React              from 'react'; 
import { useTranslation } from 'react-i18next';

import SetStatus from './../SetStatus/SetStatus';

const NotFound = () => {
	const { t } = useTranslation();

	return (
		<SetStatus statusCode={404}>
			<div className="flex flex-col justify-center items-center max-w-lg mx-auto text-center">
				<h1 className="text-3xl pb-12">{t('error_content_not_found_title')}</h1>
				<p>{t('error_content_not_found_description')}</p>
			</div>
		</SetStatus>
	);
};

export default NotFound;
