import React           from 'react';
import PropTypes       from 'prop-types';
import { useLocation } from 'react-router-dom';
import queryString     from 'query-string';

import Btn from '../../core/inputs/Btn/Btn';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const Paging = ({ onClick = () => {}, page_max, page_no }) => {
	const location = useLocation();

	const genLink = (pageNb) => {
		const query = queryString.parse(location.search);
		if (pageNb === 1 && typeof query.page !== 'undefined')
			delete query.page;
		else
			query.page = pageNb;
		

		let link = location.pathname, idx = 0;
		for (let prop in query) {
			link += `${(idx < 1 ? '?' : '&')}${prop}=${encodeURIComponent(query[prop])}`;
			idx++;
		}
		
		return link;
	};

	const handleClick = (event, page) => {
		event.preventDefault();
		onClick(page);
	};

	if (page_max < 1) return <></>;

	return (
		<div className="w-full flex justify-center items-center mt-4">
			<Btn
				variant="discrete" 
				size="xs"
				className="mr-1"
				disabled={page_no === 1}
				onClick={() => onClick(page_no - 1)}
			>
				<FiChevronLeft />
			</Btn>
			{[...Array(page_max)].map((_, i) => (
				<Btn
					key={`go-to-page-${i + 1}`}
					variant="discrete" 
					size="xs"
					forceComponent="a"
					className={`mx-1${i + 1 === page_no ? ' discrete-bordered' : ''}`}
					disabled={i + 1 === page_no}
					to={genLink(i + 1)}
					onClick={e => handleClick(e, i + 1)}
				>
					{i + 1}
				</Btn>
			))}
			<Btn
				variant="discrete" 
				size="xs"
				className="ml-1"
				disabled={page_no === page_max}
				onClick={() => onClick(page_no + 1)}
			>
				<FiChevronRight />
			</Btn>
		</div>
	);
};

Paging.propTypes = {
	onClick : PropTypes.func,
	page_max: PropTypes.number,
	page_no : PropTypes.number
};

export default Paging;
