import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';

import EmailLayout from '../../../components/email/EmailLayout';

const Html = ({ artist, date, recoverUrl }) => {
	const { t } = useTranslation();

	return (
		<EmailLayout
			intro={t('mail_forgotten_pwd_subject', { artist })}
			paragraphs={[
				t('user_password_mail_notice'),
				`<a href="${recoverUrl}">${recoverUrl}</a>`,
				t('user_password_mail_date_notice', { date }),
				'&nbsp;',
				t('user_password_mail_regards')
			]}
		/>
	);
};

Html.propTypes = {
	artist    : PropTypes.string,
	date      : PropTypes.string,
	recoverUrl: PropTypes.string
};

export default Html;
