import React, {useEffect, useState} from 'react';
import {useMassMailGetLists}        from '../../hooks/useMassMail';
import Loader                       from '../layout/Loader/Loader';
import Segment                      from './Segment';
import {useTranslation}             from 'react-i18next';

const MassMailLists = () => {
	const [fetch] = useMassMailGetLists();
	const [lists, setLists] = useState(null);
	const {t} = useTranslation();
	useEffect(() => {
		fetch().then((d) => setLists(Object.values(d)));
	}, [fetch, setLists]);

	if (!lists) return <Loader/>;

	return (
		<div>
			{lists.length > 0 && <div className='flex items-center justify-center mb-3'>
				{t('notification_info')}
			</div>}
			{lists.map(list => {
				return <Segment key={list.list} id={list.list} name={list.name} subscribed={list.subscribed}/>;
			})}
			{lists.length < 1 && <div className='flex items-center justify-center'>
				{t('notification_empty')}
			</div>}
		</div>
	);
};

export default MassMailLists;