import React                    from 'react';
import Html                     from './Html';
import Text                     from './Text';
import Helmet                   from 'react-helmet';
import { useTranslation }       from 'react-i18next';
import { getFrom, getFromName } from '../../../utils';
import { getHostname }          from '@karpeleslab/klbfw';

const Mail = () => {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>{t('mail_payment_failed_subject', { website: getHostname() })}</title>
				<meta name="mail-from-name" content={getFromName()} />
				<meta name="mail-from" content={getFrom()} />
			</Helmet>
			<Html />
			<Text />
		</>
	);
};

export default Mail;
