import React, {useEffect, useState} from 'react';
import {useTranslation}             from 'react-i18next';
import PropTypes                    from 'prop-types';

import Select from '../Select/Select';

const ProvinceSelect = ({onChange, value, provinces, ...etcetera}) => {
	const {t} = useTranslation();
	const [options, setOptions] = useState([]);
	useEffect(() => {
		let opt = [{name: '', value: ''}, ...provinces.map(c => ({name: c.name, value: c.key}))];
		setOptions(opt);
	}, [setOptions, provinces]);

	return (
		<div className="w-full my-4 pt-6">
			<Select
				label={t('province')}
				options={options}
				value={value}
				onChange={onChange}
				{...etcetera}
			/>
		</div>
	);
};

ProvinceSelect.propTypes = {
	provinces: PropTypes.array,
	onChange: PropTypes.func,
	value: PropTypes.string
};

export default ProvinceSelect;
