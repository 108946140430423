import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes                                  from 'prop-types';
import { useRest }                                from '@karpeleslab/react-klbfw-hooks';

import AlbumThirdPartyServicesLinks        from '../AlbumThirdPartyServicesLinks/AlbumThirdPartyServicesLinks';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import TrackList                           from '../TrackList/TrackList';

const AlbumDetails = forwardRef(({ albumId }, ref) => { // eslint-disable-line
	const [details] = useRest(`Music/Label/Album/${albumId}`);

	useImperativeHandle(ref, () => ({
		getDetails () {
			return details ? details.data : null;
		}
	}));

	return (
		<SwitchTransition mode="out-in">
			<CSSTransition
				key={!details || !details.data ? 'loading' : 'loaded'}
				classNames="u-transition--vertical"
				addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
			>
				{details && details.data ? (
					<div key="loaded" className="w-full flex flex-wrap justify-between">
						<div className="flex-none w-full md:w-1/2 p-2">
							{details.data.Tracks && (
								<TrackList album={details.data} />
							)}
						</div>
						<div className="flex-none w-full md:w-1/2 p-2">
							{details.data.Links && (
								<AlbumThirdPartyServicesLinks album={details.data} />
							)}
						</div>
					</div>
				): (
					<div key="loading" />
				)}
			</CSSTransition>
		</SwitchTransition>
	);
});

AlbumDetails.propTypes = {
	albumId: PropTypes.string.isRequired
};

export default AlbumDetails;
