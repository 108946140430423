import React, { useState }         from 'react';
import { useRest }                 from '@karpeleslab/react-klbfw-hooks';
import LayoutHelmet                from '../../components/layout/LayoutHelmet/LayoutHelmet';
import { useTranslation }          from 'react-i18next';
import CartElement                 from '../../components/pxl/CartElement/CartElement';
import Btn                         from '../../components/core/inputs/Btn/Btn';
import { useHistory, useLocation } from 'react-router-dom';
import Hero                        from '../../components/layout/Hero/Hero';

export default function Cart() {
	const { t } = useTranslation();
	const history = useHistory();
	const query = new URLSearchParams(useLocation().search);
	const [request] = useState(query.get('product'));
	const [cart] = useRest('Catalog/Cart/@');

	return <>
		<LayoutHelmet title={t('cart_title')}/>
		<Hero title={t('cart_title')}/>
		<main className='w-full max-w-7xl mb-auto mx-auto pt-4'>
			<CartElement readonly={false} request={request}/>
			<div className={'flex justify-between items-center w-full mt-8 px-3 md:px-0'}>
				<Btn onClick={() => history.goBack()} accessibility={t('back_btn')}>{t('back_btn')}</Btn>
				{cart && cart.data.products.length > 0 && (
					<Btn
						onClick={() => history.push('/checkout')}
						accessibility={t('cart_checkout')}
					>
						{t('cart_checkout')}
					</Btn>)
				}
			</div>
		</main>
	</>;
}
