import {useAction} from '@karpeleslab/klb-react-services';
import {useCallback} from 'react';

export const getMassMailGetLists = () => 'Network/MassMail:getLists';
export const getMassMailSubscribe = () => 'Network/MassMail:subscribe';
export const getMassMailUnsubscribe = () => 'Network/MassMail:unsubscribe';

export const useMassMailGetLists = () => useAction(getMassMailGetLists(), 'POST',);
export const useMassMailSubscribe = () => {
	const [_doAction, loading] = useAction(getMassMailSubscribe(), 'POST');

	const doAction = useCallback((list, email = '', displayName = '', settingsOverride = {}) => _doAction({
		list: list,
		email: email,
		displayName: displayName
	}, settingsOverride), []); //eslint-disable-line

	return [doAction, loading];
};
export const useMassMailUnsubscribe = () => {
	const [_doAction, loading] = useAction(getMassMailUnsubscribe(), 'POST');

	const doAction = useCallback((list, settingsOverride = {}) => _doAction({list: list}, settingsOverride), []); //eslint-disable-line

	return [doAction, loading];
};