import React, {useContext} from 'react';
import {useTranslation}    from 'react-i18next';

import FanClubPlans       from '../../components/pxl/FanClubPlans/FanClubPlans';
import Hero               from '../../components/layout/Hero/Hero';
import LayoutHelmet       from '../../components/layout/LayoutHelmet/LayoutHelmet';
import {UserContext}      from '../../context/UserContext';
import FanClubPlansLogged from '../../components/pxl/FanClubPlans/FanClubPlansLogged';

export default function JoinFanClub() {
	const {t} = useTranslation();
	const [{loggedIn}] = useContext(UserContext);

	return (
		<>
			<LayoutHelmet title={t('join_fanclub')}/>
			<Hero title={t('join_fanclub')}/>
			<main className="w-full max-w-7xl my-0 mx-auto">
				{!loggedIn && <FanClubPlans/>}
				{loggedIn && <FanClubPlansLogged/>}
			</main>
		</>
	);
}
