import React              from 'react';
import BigTitle           from '../../../components/layout/BigTitle/BigTitle';
import NewsList           from '../../../components/pxl/NewsList/NewsList';
import { useTranslation } from 'react-i18next';

const NewsSection = () => {
	const { t } = useTranslation();

	return (
		<section className='my-8 pt-6'>
			<BigTitle>{t('news')}</BigTitle>
			<NewsList showFilters={false} />
		</section>
	);
};

export default NewsSection;
