import React from 'react';
import LayoutHelmet from '../../components/layout/LayoutHelmet/LayoutHelmet';
import {useTranslation} from 'react-i18next';

export default function ThankYou() {
	const {t} = useTranslation();

	return <main className="w-full max-w-7xl my-0 mx-auto md:pt-24 mb-auto">
		<LayoutHelmet title={t('thank_you_title')}/>
		<div className={'flex justify-center items-center border border-black rounded-2xl'}>
			<div className={'text-2xl py-20'}>{t('thank_you_purchase')}</div>
		</div>
	</main>;
}