import React, { createContext, useContext, useEffect, useState } from 'react';
import { rest }                            from '@karpeleslab/klbfw';
import { useRest }                         from '@karpeleslab/react-klbfw-hooks';
import PropTypes                           from 'prop-types';
import { getUserGetEndpoint, RestContext } from '@karpeleslab/klb-react-services';

export const defaultUserContext = {
	loggedIn    : false,
	loading     : true,
	subLoaded   : false,
	subscription: null,
	user        : {
		data: {
			Display_Name: null,
			Email       : null,
			Profile     : {
				Media_Image: {
					Url: null
				}
			}
		}
	}
};

export const UserContext = createContext(defaultUserContext);

export const UserContextContainer = ({ children }) => {
	const [loggedUser]    = useRest(getUserGetEndpoint());
	const [user, setUser] = useState({
		loggedIn: !!(loggedUser && loggedUser.data),
		user    : loggedUser,
		loading : loggedUser === null
	});
	
	const { setLogoutUserCallback } = useContext(RestContext);

	const fetchSubscription = () => {
		rest('Membership/@fanclub/User/@', 'GET')
			.then(({ data }) => 
				setUser(u => ({ ...u, subLoaded: true, subscription: data }))
			)
			.catch(() => 
				setUser(u => ({ ...u, subLoaded: true }))
			);
	};

	useEffect(() => {
		setLogoutUserCallback(() => {
			setUser(prev => ({ ...prev }));
		});
	}, [setUser, setLogoutUserCallback]);

	useEffect(() => {
		if (!loggedUser)
			return;

		let userCtx = {...user};
		if (loggedUser.data)
			userCtx = { loggedIn: true, user: loggedUser };

		setUser({ ...userCtx, loading: false });
	}, [loggedUser]);

	useEffect(() => {
		if (user?.loggedIn)
			fetchSubscription();
	}, [user.loggedIn]);

	return (
		<UserContext.Provider value={[user, setUser, fetchSubscription]}>
			{children}
		</UserContext.Provider>
	);
};

UserContextContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired
};
