import React, { useContext, useEffect, useState } from 'react';
import PropTypes   from 'prop-types';
import { useRest } from '@karpeleslab/react-klbfw-hooks';

import { Player } from '../Player/Player';

import { UserContext } from '../../../context/UserContext';

import { getPlannerEventSeatReserveEndpoint } from '../../../hooks/usePlannerEventSeatReserve';
import { getPlannerEventStreamEndpoint }      from '../../../hooks/usePlannerEvent';

import './EventStream.css';

const EventStream = ({ event, thumbnail }) => { // eslint-disable-line
	const [streamInfo, refreshStreamInfo] = useRest(getPlannerEventStreamEndpoint(event.Planner_Event__), 'GET', false, 1000);
	const [{ loggedIn }]                  = useContext(UserContext);
	const [open, setOpen]                 = useState(false);
	const [afterOpen, setAfterOpen]       = useState(false);
	const [onlineSeats, setOnlineSeats]   = useState(null);

	const params     = { Status: { '$in': ['reserve_paid', 'reserve_conf'] }, results_per_page: 100 };
	const [reserves] = useRest(getPlannerEventSeatReserveEndpoint(event.Planner_Event__), params, false, 3000);

	useEffect(() => {
		refreshStreamInfo();
	}, [loggedIn]);

	useEffect(() => {
		setOnlineSeats(() => event.Seats.find(({ Type }) => Type === 'stream'));
	}, [event]);

	useEffect(() => {
		if (!!onlineSeats && reserves?.data?.length > 0)
			setOpen(true);
	}, [onlineSeats, reserves]);

	useEffect(() => {
		if (open) 
			setTimeout(() => { setAfterOpen(true); }, 500);
	}, [open]);

	return (
		<div className="pxl-stream-wrapper w-full relative">
			<div className={`pxl-stream-placeholder ${open ? ' pxl-stream-open' : ''} w-full bg-black`}></div>
			<div className="pxl-stream-player w-full bg-black">
				{(event && streamInfo?.data && afterOpen) && (
					<Player
						eventId={event.Planner_Event__}
						thumbnail={thumbnail}
					/>
				)}
			</div>
		</div>
	);
};

export default EventStream;

EventStream.propTypes = {
	event    : PropTypes.object.isRequired,
	thumbnail: PropTypes.string
};
