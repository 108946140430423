import React              from 'react';
import { useTranslation } from 'react-i18next';
import { getHostname }    from '@karpeleslab/klbfw';

import Helmet                   from 'react-helmet';
import Html                     from './Html';
import Text                     from './Text';
import { getFrom, getFromName } from '../../utils';

const Mail = () => {
	const { t } = useTranslation();

	return (
		<div>
			<Helmet>
				<title>
					{`[${getHostname()}] ${t('reserve_seat_allocated')}`}
				</title>
				<meta name="mail-from-name" content={getFromName()} />
				<meta name="mail-from" content={getFrom()} />
			</Helmet>
			<Html />
			<Text />
		</div>
	);
};

export default Mail;
