import React            from 'react';
import PropTypes        from 'prop-types';
import {dateFormat}     from '../../../utils/date';
import {useTranslation} from 'react-i18next';

const Author = ({date, author}) => {
	const {t} = useTranslation();

	return (
		<p className='text-sm italic'>
			{author && t('published_on_by', {
				date: dateFormat(date, 'L').replaceAll('/', '.'),
				author: author
			})}
			{!author && dateFormat(date, 'L').replaceAll('/', '.')}
		</p>
	);
};

export default Author;

Author.propTypes = {
	date: PropTypes.shape({unixms: PropTypes.string}),
	author: PropTypes.string,
};
