import PropTypes                      from 'prop-types';
import { useOrderProcess }            from '@karpeleslab/klb-react-services';
import { useTranslation }             from 'react-i18next';
import React, { useEffect, useState } from 'react';
import Btn                            from '../../../../core/inputs/Btn/Btn';
import StripeForm                     from '../../../../core/inputs/StripeForm/StripeForm';
import { FiLoader }                   from 'react-icons/fi';
import Check                          from '../../../../core/inputs/Check/Check';

const Stripe = ({ order, settings, setPaying, refreshOrder, refreshProcessResult }) => {
	const { t } = useTranslation();

	const [processingOrder, setProcessingOrder] = useState(false);
	const [processOrder] = useOrderProcess(order.Order__);

	const [stripe, setStripe] = useState(null);
	const [stripeElements, setStripeElements] = useState(null);
	const [stripeError, setStripeError] = useState(null);
	const [saveEnabled, setSaveEnabled] = useState(false);

	const [ccRemember, setCcRemember] = useState(true);


	useEffect(() => {
		setPaying(processingOrder);
	}, [processingOrder]);

	const onStripeChange = e => {
		setSaveEnabled(e.complete === true);
	};

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
			switch (paymentIntent.status) {
				case 'succeeded':
					processOrder(settings.session, settings.method, {stripe_intent: 1}, { snackMessageToken: 'order_paid' })
						.then(d => {
							setProcessingOrder(false);
							refreshOrder(d.order);
							refreshProcessResult(d);
						});
					break;
				case 'processing':
					setStripeError(t('payment_processing'));
					break;
				case 'requires_payment_method':
					setStripeError(t('requires_payment_method'));
					break;
				default:
					setStripeError(t('unexpected_error'));
					break;
			}
		});
		// eslint-disable-next-line
	}, [stripe, setStripeError, processOrder, setProcessingOrder]);


	const handleProcess = async () => {
		if (!stripe || !stripeElements)
			return; // stripe not loaded yet

		setProcessingOrder(true);

		const result = await stripe.confirmPayment({
			elements: stripeElements,
			redirect: 'if_required',
			confirmParams: {
				//setup_future_usage: ccRemember ? 'off_session' : undefined,
				payment_method_data: {
					billing_details: {
						name: `${order.Billing_User_Location.First_Name} ${order.Billing_User_Location.Last_Name}`,
						email: order.User.Email,
						address: {
							country: order.Billing_User_Location.Country__,
							postal_code: order.Billing_User_Location.Zip,
							state: order.Billing_User_Location.Province ?? '',
							city: order.Billing_User_Location.City ?? '',
							line1: order.Billing_User_Location.Address ?? '',
							line2: order.Billing_User_Location.Address2 ?? '',
						}
					}
				}, // Make sure to change this to your payment completion page
				return_url: `https://${window.location.host}/order/${order.Order__}`,
			},
		});

		if (result.error) {
			if (result.error.type === 'card_error' || result.error.type === 'validation_error') {
				setStripeError(result.error.message);
			} else {
				setStripeError(t('unexpected_error'));
			}

			setProcessingOrder(false);
			return;
		}

		processOrder(settings.session, settings.method, {stripe_intent: 1}, { snackMessageToken: 'order_paid' })
			.then(d => {
				setProcessingOrder(false);
				refreshOrder(d.order);
				refreshProcessResult(d);
			});
	};

	useEffect(() => {
		setProcessingOrder(true);
		processOrder(settings.session, settings.method, {cc_remember: ccRemember ? 1 : 0, stripe_intent: 1})
			.then(d => {
				setProcessingOrder(false);
				refreshOrder(d.order);
				refreshProcessResult(d);
			});
		// eslint-disable-next-line
	}, [ccRemember])

	return (
		<div className='flex flex-wrap overflow-hidden sm:-mx-3'>

			{stripeError && <div className='w-full overflow-hidden sm:my-3 sm:px-3'>
				<div className='bg-red-600 p-4 text-white' role='alert'>
					<p>
						{stripeError}
					</p>
				</div>
			</div>
			}
			<div className='w-full overflow-hidden sm:my-3 sm:px-3'>
				<StripeForm
					settings={settings.fields}
					setStripeElements={setStripeElements}
					setStripe={setStripe}
					onChange={onStripeChange}
					disabled={processingOrder}
				/>
			</div>

			<div className='w-full overflow-hidden sm:my-3 sm:px-3'>
				<Check
					className="my-6"
					name='cc_remember'
					checked={ccRemember}
					disabled={processingOrder}
					value={t('stripe_cc_remember')}
					onChange={(e) => setCcRemember(e.target.checked)}
				>
					{t('stripe_cc_remember')}
				</Check>
			</div>

			<div className='w-full overflow-hidden sm:my-3 sm:px-3'>
				<Btn
					className={'ml-auto block'}
					variant='primary'
					disabled={!stripe || !stripeElements || processingOrder || !saveEnabled}
					onClick={handleProcess}
					accessibility={t('purchase_btn')}
				>
					{processingOrder ? <FiLoader className="animate-spin" /> : t('purchase_btn')}
				</Btn>
			</div>

		</div>
	);
};

export default Stripe;

Stripe.propTypes = {
	refreshOrder: PropTypes.func.isRequired,
	refreshProcessResult: PropTypes.func.isRequired,
	setPaying: PropTypes.func.isRequired,
	settings: PropTypes.object.isRequired,
	order: PropTypes.object.isRequired,
};
