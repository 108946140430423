import React from 'react';
import {useRest} from '@karpeleslab/react-klbfw-hooks';
import {Helmet} from 'react-helmet';
import {useLocation, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Loader from '../../components/layout/Loader/Loader';
import ModuleNotLoaded from '../../components/core/utils/ModuleNotLoaded/ModuleNotLoaded';
import {Player} from '../../components/pxl/Player/Player';
import NoSsr from '../../components/core/utils/NoSsr/NoSsr';
import ShareButtons from '../../components/core/inputs/ShareButtons/ShareButtons';
import NotFound from '../../components/core/utils/NotFound/NotFound';
import Btn from '../../components/core/inputs/Btn/Btn';

const useQuery = () => new URLSearchParams(useLocation().search);

export default function Live() {
	const {t} = useTranslation();
	const {slug} = useParams();
	const query = useQuery();

	const args = {slug};
	if (query.get('_preview')) {
		args._preview = query.get('_preview');
	}
	const [data] = useRest('Planner/@planner/Schedule:loadBySlug', args);

	if (!data) {
		return <Loader/>;
	} else if (data.result !== 'success') {
		return <ModuleNotLoaded/>;
	} else if (data.data && data.data.content_cms_entry_data) {
		return (
			<main className="w-full max-w-7xl my-0 mx-auto md:pt-24 mb-auto">
				<Helmet>
					{ /* https://devhints.io/html-meta */}
					<title>{data.data.content_cms_entry_data.Title}</title>
					<meta property="og:title" content={data.data.content_cms_entry_data.Title}/>
					<meta name="twitter:title" content={data.data.content_cms_entry_data.Title}/>
				</Helmet>
				<h2 className={'text-4xl font-bold ml-4 md:m-0'}>{data.data.content_cms_entry_data.Title}</h2>
				<Player eventId={data.data.planner_schedule.Planner_Event__}/>
				<div className="p-6 text-justify">
					<NoSsr>
						<ShareButtons/>
					</NoSsr>
				</div>

				<div className="p-6">
					<Btn onClick={() => history.goBack()} accessibility={t('go_back')}>
						{t('go_back')}
					</Btn>
				</div>
			</main>
		);
	} else {
		return (
			<main className="p-6">
				<Helmet>
					<title>{t('error_content_not_found_title')}</title>
				</Helmet>
				<NotFound/>
			</main>
		);
	}
}