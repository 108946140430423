import React     from 'react';
import PropTypes from 'prop-types';

import { FiLoader } from 'react-icons/fi';

const ShopTags = ({ filters, tags, onTagClicked = () => {}}) => {
	const onClick = (event, tag) => {
		event.preventDefault();
		onTagClicked(tag);
	};

	return (
		<ul className="ml-4" style={{ listStyle: 'square'}}>
			{tags.map(t => (
				<li key={t.Classify_Tag__}>
					<a
						className={`flex justify-between flex-nowrap items-center py-1 cursor-pointer${
							filters.find(f => f.Classify_Tag__ === t.Classify_Tag__) ? ' bg-pxl-darkGray' : ''
						}`}
						href={`?filter=${t.Classify_Tag__}`}
						onClick={e => onClick(e, t)}
						disabled={t.loading}
					>
						<div className="flex items-center hover:underline">
							<span className="inline-block mr-1">{t.Name}</span>
							{t.loading && <FiLoader className="animate-spin" />}
						</div>
						<div
							className="flex-none w-2 text-center opacity-50 hover:opacity-100"
						>
							{filters.find(f => f.Classify_Tag__ === t.Classify_Tag__) ? '-' : '+'}
						</div>
					</a>
					{t.Children && (
						<ShopTags
							filters={filters}
							tags={t.Children}
							onTagClicked={tc => onTagClicked(tc)}
						/>
					)}
				</li>
			))}
		</ul>
	);
};

const tagPropType = PropTypes.shape({
	Children      : PropTypes.arrayOf(PropTypes.object),
	childLoaded   : PropTypes.bool,
	Classify_Tag__: PropTypes.string,
	loading       : PropTypes.bool,
	Name          : PropTypes.string
});

ShopTags.propTypes = {
	filters     : PropTypes.arrayOf(tagPropType),
	onTagClicked: PropTypes.func,
	tags        : PropTypes.arrayOf(tagPropType)
};

export default ShopTags;