import PopIn from '../PopIn/PopIn';
import Btn from '../../core/inputs/Btn/Btn';
import {FiLoader} from 'react-icons/fi';
import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

export const Confirm = ({open, title, content, onCancel, onValidate, loading = false}) => {
	const {t} = useTranslation();
	return <PopIn open={open} setOpen={onCancel}>
		<h2 className="text-2xl text-center">
			{title}
		</h2>
		<div className={'my-8'}>
			{content}
		</div>
		<div className="flex justify-between items-center mt-4">
			<Btn
				onClick={() => onCancel()}
				tabIndex={4}
			>
				{t('cancel')}
			</Btn>
			<Btn
				variant="primary"
				disabled={loading}
				onClick={onValidate}
				tabIndex={4}
			>
				{loading ? <FiLoader className="animate-spin" /> : t('validate')}
			</Btn>
		</div>
	</PopIn>;
};

Confirm.propTypes = {
	open: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	onCancel: PropTypes.func.isRequired,
	onValidate: PropTypes.func.isRequired,
	loading: PropTypes.bool,
};