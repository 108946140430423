import React, {useEffect, useState} from 'react';
import PropTypes                    from 'prop-types';
import { useTranslation }  from 'react-i18next';

import Btn       from '../../inputs/Btn/Btn';
import PopIn     from '../../../layout/PopIn/PopIn';
import TextInput from '../../inputs/TextInput/TextInput';

const RequestPasswordPopin = ({ closeCallback, open, setOpen }) => {
	const { t }                   = useTranslation();
	const [password, setPassword] = useState('');

	const onSubmit = e => {
		e.preventDefault();
		closeCallback(password);
	};

	useEffect(() => {
		// Be sure to not keep the pwd when the popin is displayed or hidden
		setPassword('');
	}, [open]);

	return (
		<PopIn
			open={open}
			setOpen={setOpen}
		>
			<form onSubmit={onSubmit}>
				<h2 className="text-2xl text-center pb-4">
					{t('security_password_required_title')}
				</h2>
				<p>{t('security_password_required_description')}</p>
				<div className="w-full flex justify-between items-center pt-4 px-2">
					<div className="flex-1">
						<TextInput
							label={t('input_field_password')}
							type="password"
							value={password}
							onChange={e => setPassword(e.target.value)}
							required={true}
						/>
					</div>
					<div className="flex-none mt-7 ml-2">
						<Btn
							type="submit"
							variant="primary"
							disabled={password.trim().split('').length <= 0}
							accessibility={t('unlock_btn')}
						>
							{t('unlock_btn')}
						</Btn>
					</div>
				</div>
			</form>
		</PopIn>
	);
};

RequestPasswordPopin.propTypes = {
	closeCallback: PropTypes.func,
	open         : PropTypes.bool,
	setOpen      : PropTypes.func
};

export default RequestPasswordPopin;