import React                  from 'react';
import PropTypes              from 'prop-types';

import { getLocale }          from '@karpeleslab/klbfw';
import EventLanguageWarning   from './EventLanguageWarning';

import './EventDetails.css';

export const EventDetails = ({ cms, alternatives = [] }) => {
	return <>
		{(getLocale() !== cms.Content_Cms_Entry.Language__) && (
			<EventLanguageWarning
				entry={cms.Content_Cms_Entry}
				alternatives={alternatives ?? []}
			/>
		)}

		<div
			className="my-4 mx-3"
			dangerouslySetInnerHTML={{ __html: cms.Content_Cms_Entry.Contents }}
		/>

	</>;
};

EventDetails.propTypes = {
	cms         : PropTypes.object,
	alternatives: PropTypes.array
};
