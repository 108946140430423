import React, { useEffect } from 'react';
import PropTypes            from 'prop-types';
import { useTranslation }   from 'react-i18next';

import CreditCardInfo from './CreditCardInfo';

import { useUserBillings } from '@karpeleslab/klb-react-services';

const EditBilling = ({ onLoad = () => {}}) => {
	const { t }                   = useTranslation();
	const [billings, getBillings] = useUserBillings('@');

	useEffect(() => {
		getBillings();
	}, []);

	useEffect(() => {
		if (billings && billings.data)
			onLoad(billings.data);

	}, [billings]);

	return (
		<div className="w-full">
			{(billings && billings.data && billings.data.length > 0 && billings.data[0].Methods) ? (
				<CreditCardInfo
					locationId={billings.data[0].User_Location__}
					method={billings.data[0].Methods[0]}
					onCardUpdated={() => getBillings()}
				/>
			) : t('no_mean_of_payment_registered')}
		</div>
	);
};

EditBilling.propTypes = {
	onLoad: PropTypes.func
};

export default EditBilling;
