import React, { useContext } from 'react';
import BigTitle              from '../../../components/layout/BigTitle/BigTitle';
import Biography             from '../../../components/pxl/Biography/Biography';
import { useTranslation }    from 'react-i18next';
import { TemplateContext }   from '../../../context/TemplateContext';

const BiographySection = () => {
	const {t} = useTranslation();
	const { artist } = useContext(TemplateContext);

	if (!artist.Bio) return <></>;

	return (
		<div className="my-8">
			<BigTitle>{t('about')}</BigTitle>
			<Biography />
		</div>
	);
};

export default BiographySection;
