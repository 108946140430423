import React, { useCallback, useEffect } from 'react';
import PropTypes                         from 'prop-types';

import './cart-element.css';
import WavyLine                                       from '../../layout/WavyLine/WavyLine';
import CartHeader                                     from './CartHeader';
import CartItems                                      from './CartItems';
import { useCatalogCartProcess, useCatalogCartReset } from '@karpeleslab/klb-react-services';
import Loader                                         from '../../layout/Loader/Loader';
import CartPrices                                     from './CartPrices';
import { useTranslation }                             from 'react-i18next';
import CartAddCoupon                                  from './CartAddCoupon';
import { useRest }                                    from '@karpeleslab/react-klbfw-hooks';
import {useMobileMediaQuery} from '../../../hooks/useMediaQueries';
import {CartMobile} from './CartMobile';

export default function CartElement({ request = null, isCheckout = false }) {
	const { t } = useTranslation();
	const [cart, cartRefresh] = useRest('Catalog/Cart/@');
	const isMobile = useMobileMediaQuery();

	const refresh = useCallback((newCart) => {
		cartRefresh({ ...cart, data: newCart });
	}, [cart, cartRefresh]);


	const [processQuery, processingCart] = useCatalogCartProcess();
	const [resetCart, resettingCart] = useCatalogCartReset();

	useEffect(() => {
		if (!request) return;

		resetCart()
			.then(() => processQuery(request, { snackMessageToken: null }))
			.then((newCart) => refresh(newCart));

	}, [request, refresh, resetCart]);

	if (!cart || processingCart || resettingCart) {
		return <Loader/>;
	}

	if (cart.data.products.length < 1) {
		return (
			<div className='w-full overflow-hidden sm:my-3 sm:px-3'>
				<h1 className='text-center text-2xl my-4'>
					{t('cart_empty')}
				</h1>
			</div>
		);
	}

	return (
		<div className={'cart px-3 md:px-0'}>
			{isMobile ?
				<CartMobile
					items={cart.data.products}
					refreshCart={refresh}
					readonly={isCheckout}
				/> :
				<table className={'table table-auto w-full border-separate order-table-details'}>
					<CartHeader readonly={isCheckout}/>
					<CartItems
						items={cart.data.products}
						refreshCart={refresh}
						readonly={isCheckout}
					/>
				</table>
			}
			{!isCheckout && <CartAddCoupon refreshCart={refresh}/>}
			<WavyLine className={'mt-8 ml-2'}/>
			<CartPrices cart={cart.data}/>
		</div>
	);
}

CartElement.propTypes = {
	isCheckout: PropTypes.bool,
	request: PropTypes.string
};
