import React, { useContext, useEffect, useState } from 'react';
import PropTypes                                  from 'prop-types';
import { useTranslation }                         from 'react-i18next';
import { UserContext }                            from '../../../context/UserContext';
import EventStreamAlertLogged                     from './EventStreamAlertLogged';

const EventStreamAlert = ({ event }) => {
	const { t } = useTranslation();
	const [onlineSeats, setOnlineSeats] = useState((event && event.Seats) ? event.Seats.find(({ Type }) => Type === 'stream') : false);
	const [{ loggedIn }] = useContext(UserContext);

	useEffect(() => {
		setOnlineSeats(() => event.Seats.find(({ Type }) => Type === 'stream'));
	}, [event]);

	if (!onlineSeats) return <></>;
	if (loggedIn) return <EventStreamAlertLogged event={event}/>;

	return (
		<div className={'bg-pxl-darkGray p-5 mb-5 text-center'}>
			{t('event_live_will_be_here')}
		</div>
	);
};

export default EventStreamAlert;

EventStreamAlert.propTypes = {
	event: PropTypes.object.isRequired
};
