import Billing                                  from '../../user/Billing/Billing';
import EventQuestions                from '../EventQuestions/EventQuestions';
import Btn                           from '../../core/inputs/Btn/Btn';
import {rest}                        from '@karpeleslab/klbfw';
import React, { useEffect, useState} from 'react';
import PropTypes                     from 'prop-types';
import {useSetSnackbar}              from '../../../hooks/useSnackbar';
import {useHistory, useParams}       from 'react-router-dom';
import {useTranslation}              from 'react-i18next';
import TextInput                     from '../../core/inputs/TextInput/TextInput';
import WavyLine                      from '../../layout/WavyLine/WavyLine';
import {FiLoader}                    from 'react-icons/fi';
import EventTicketDetails            from '../EventTicketDetails/EventTicketDetails';
import Alert from '../../layout/Alert/Alert';

export default function ReserveDetails({event, tickets}) {
	const {t} = useTranslation();
	const [location, setLocation] = useState(null);
	const [loading, setLoading] = useState(false);
	const [amount, setAmount] = useState(1);
	const [maxAmount, setMaxAmount] = useState(0);
	const [answers, setAnswers] = useState({});
	const setSnackbar = useSetSnackbar();
	const {seatId} = useParams();
	const history = useHistory();

	const seat = event.Seats.find((seat) => seat.Planner_Event_Seat__ === seatId);

	useEffect(() => {
		if (event.Questions.length === 0) {
			return;
		}

		const value = event.Questions.reduce((old, {Code, Type, Options}) => {
			if (Type !== 'select') {
				return old;
			}
			if (!answers[Code] && answers[Code] !== 0) {
				old[Code] = Options[0];
			}
			return old;
		}, {});
		if (Object.keys(value).length !== 0) {
			setAnswers({
				...answers,
				...value
			});
		}
	}, [event]);

	useEffect(() => {
		if (event.Max_Seat_Per_User) {
			const max = parseInt(event.Max_Seat_Per_User);
			if (max - tickets.length > 0) {
				setMaxAmount(max - tickets.length);
			}
		}
	}, [tickets, event]);

	const processOrder = () => {
		if (event.Questions.length > 0) {
			const missingAnswer = event.Questions.find(({Code}) => answers[Code] === undefined || answers[Code] === '');
			if (missingAnswer) {
				setSnackbar(t('reserve_missing_question_value', {q: missingAnswer.Question}), 'error');
				return;
			}
		}
		let quantity = 1;
		if (event.Max_Seat_Per_User) {
			quantity = amount;
		}


		const params = {
			Billing: {User_Location__: location.User_Location__},
			seat: seat.Seat_Name_Display ?? seat.Seat_Name,
			answers,
			quantity,
		}; //https://www.atonline.com/l/en-US/doc/rest/Planner/Event:reserveOrder

		setLoading(true);
		return rest(
			`Planner/Event/${event.Planner_Event__}:reserveOrder`,
			'POST',
			params
		)
			.then(res => {
				history.push(`/order/${res.data.Order.Order__}`);
			})
			.catch(err => {
				setSnackbar(err.message, 'error');
				setLoading(false);
			});
	};

	const canSelectAmount = maxAmount > 0;
	const hasNoTicketLimit = !event.Max_Seat_Per_User;

	const setNewAmount = (valueStr) => {
		const value = parseInt(valueStr);
		if (isNaN(value)) {
			setAmount(1);
			return;
		}
		if (value < 1 && amount !== maxAmount) {
			setAmount(1);
			return;
		}
		if (value > maxAmount && amount !== maxAmount) {
			setAmount(maxAmount);
			return;
		}
		setAmount(value);
	};

	return <div className={'p-3'}>
		<div className={'pb-6 md:pb-12'}>
			<EventTicketDetails ticket={seat} event={event} enableOrder={false}/>

			{canSelectAmount ?
				<div className={'mt-8'}>
					<TextInput
						label={t('reserve_select_amount', {max: maxAmount})}
						value={amount}
						onChange={(e) => setNewAmount(e.target.value)}
						disabled={loading}
						type={'number'}
						required
					/>
				</div> :
				<></>
			}
		</div>
		<WavyLine className={'my-6 md:my-12'}/>

		{(!canSelectAmount && tickets.length > 0 && !hasNoTicketLimit) && <>
			<div className={'py-6 md:py-12'}>
				<Alert type={'warning'}>{t('event_reserve_max_reached_warn')}</Alert>
			</div>
		</>
		}

		{(canSelectAmount || hasNoTicketLimit) && <>
			<div className={'py-6 md:py-12'}>
				<div className={'pxl-display-font font-bold mb-4'}>{t('billing_address')}</div>
				<Billing onLocationSelected={setLocation} fit={true}/>
			</div>
			<div className={'py-6 md:py-12'}>
				<EventQuestions questions={event.Questions} answers={answers} onAnswerChange={setAnswers}/>
			</div>
			<div className={'flex justify-between content-center pt-12'}>
				<Btn
					onClick={() => history.goBack()}
					disabled={loading}
					accessibility={t('go_back')}
				>
					{t('go_back')}
				</Btn>
				<Btn
					onClick={processOrder}
					disabled={!location || loading}
					accessibility={t('purchase')}
				>
					{loading ? <FiLoader className="animate-spin"/> : t('purchase')}
				</Btn>
			</div>
		</>
		}
	</div>;
}

ReserveDetails.propTypes = {
	event: PropTypes.object,
	tickets: PropTypes.array,
};