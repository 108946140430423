import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import { useRest }                    from '@karpeleslab/react-klbfw-hooks';

import Btn                       from '../../core/inputs/Btn/Btn';
import { HiOutlineShoppingCart } from 'react-icons/hi';
import PopIn                     from '../../layout/PopIn/PopIn';
import {useHistory}              from 'react-router-dom';

export default function CartButton() {
	const {t} = useTranslation();
	const [cart] = useRest('Catalog/Cart/@');
	const [cartAmount, setCartAmount] = useState(-1);
	const [prevAmount, setPrevAmount] = useState(-1);
	const [open, setOpen]             = useState(false);
	const history                     = useHistory();

	useEffect(() => {
		if (cart && cart.data) {
			const amount = cart.data.products.reduce((nb, product) => {
				return nb + product.quantity;
			}, 0);
			setPrevAmount(cartAmount);
			setCartAmount(amount);
		}
	}, [cart]);

	useEffect(() => {
		if (prevAmount > -1 && cartAmount > prevAmount)
			setOpen(true);
	}, [cartAmount]);

	return cartAmount < 1 || !cartAmount ? (
		<></> 
	) : (
		<>
			<PopIn open={open} setOpen={setOpen}>
				{cart?.data?.products?.length > 0 && (
					<p className="text-xl text-center">{t('item_added_to_cart', { item: cart.data.products[cart.data.products.length - 1].data['Basic.Name'] })}</p>
				)}
				<div className="flex w-full justify-between items-center mt-4">
					<Btn variant="primary" onClick={() => {
						setOpen(false);
						history.push('/cart');
					}}>
						{t('go_to_cart')}
					</Btn>
					<Btn onClick={() => setOpen(false)}>
						{t('continue_shopping')}
					</Btn>
				</div>
			</PopIn>
			<Btn to="/cart" variant="discrete" className="ml-2 cart-wrapper" accessibility={t('acc_cart')}>
				<HiOutlineShoppingCart/>
				<div className="cart-number">{cartAmount}</div>
			</Btn>
		</>
	);
}