import React                from 'react';
import { useTranslation }   from 'react-i18next';
import { Get, getHostname } from '@karpeleslab/klbfw';
import moment               from 'moment';

const Text = () => {
	const { t } = useTranslation();

	return (
		<textarea
			id="mail-text"
			defaultValue={
				t('login_notification_text') +
				'\n\r' +
				t('login_notification_title') +
				'\n\r' +
				t('login_notification_name', { username: Get('_usr').Email }) +
				'\n\r' +
				t('login_notification_date', {
					year : moment().format('YYYY'),
					month: moment().format('MM'),
					day  : moment().format('DD'),
					time : `${moment().format('HH:mm:ss')} UTC`
				}) +
				'\n\r' +
				t('user_password_mail_regards') +
				'\n\r' +
				t('user_password_email_footer', { website: getHostname() }) +
				'\n\r'
			}
		/>
	);
};

export default Text;
