import React                from 'react';
import { useTranslation }   from 'react-i18next';
import { Get, getHostname } from '@karpeleslab/klbfw';

import Helmet            from 'react-helmet';
import Html              from './Html';
import Text                                    from './Text';
import { getFrom, getFromName, getSubjectTag } from '../../utils';


const Mail = () => {
	const { t } = useTranslation();

	return (
		<div>
			<Helmet>
				<title>{t('account_confirmed_title', { website: getSubjectTag() })}</title>
				<meta name="mail-from-name" content={getFromName()} />
				<meta name="mail-from" content={getFrom()} />
			</Helmet>
			<Html username={Get('_USER')?.Display_Name || Get('_USER')?.Email} website={getHostname()} />
			<Text username={Get('_USER')?.Display_Name || Get('_USER')?.Email} website={getHostname()} />
		</div>
	);
};

export default Mail;
