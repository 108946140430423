import React, { useContext, useState } from 'react';
import { useTranslation }              from 'react-i18next';
import { rest }                        from '@karpeleslab/klbfw';

import { AiFillSave }       from 'react-icons/ai';
import Btn                  from '../../../core/inputs/Btn/Btn';
import { FiLoader }         from 'react-icons/fi';
import RequestPasswordPopin from '../../../core/utils/RequestPasswordPopin/RequestPasswordPopin';
import TextInput            from '../../../core/inputs/TextInput/TextInput';

import { UserContext }    from '../../../../context/UserContext';
import { useSetSnackbar } from '../../../../hooks/useSnackbar';

const ChangeEmail = () => {
	const { t }           = useTranslation();
	const [user, setUser] = useContext(UserContext);
	const setSnackbar     = useSetSnackbar();

	const [loading, setLoading]               = useState(false);
	const [requestPwdOpen, setRequestPwdOpen] = useState(false);
	const [email, setEmail]                   = useState(user.user.data.Email);

	const onSubmit = e => {
		e.preventDefault();
		setRequestPwdOpen(true);
	};

	const submitNewEmail = current_password => {
		setLoading(true);
		setRequestPwdOpen(false);
		rest('User/@:setEmail', 'POST', {
			current_password,
			email
		})
			.then(data => {
				setSnackbar(t('email_change_success'), 'success');
				setUser({...user, user: data});
			})
			.catch(err => setSnackbar(err.message, 'error'))
			.finally(() => setLoading(false));
	};

	return (
		<>
			<form
				className="w-full flex justify-between items-center pt-4 px-2"
				onSubmit={onSubmit}
			>
				<div className="flex-1">
					<TextInput
						label={t('profile_email')}
						type="email"
						value={email}
						onChange={e => setEmail(e.target.value)}
						required={true}
					/>
				</div>
				<div className="flex-none ml-6">
					<Btn
						type="submit"
						variant="primary"
						disabled={email.trim().split('').length <= 0 || email.trim() === user.user.data.Email || loading}
						accessibility={t('save_btn')}
					>
						<AiFillSave />
						{loading && <FiLoader size={'1rem'} className="animate-spin btn-loader" />}					</Btn>
				</div>
			</form>
			<RequestPasswordPopin
				closeCallback={submitNewEmail}
				open={requestPwdOpen}
				setOpen={setRequestPwdOpen}
			/>
		</>
	);
};

export default ChangeEmail;
