import React, {useCallback, useEffect} from 'react';
import {useTranslation}                          from 'react-i18next';
import PropTypes                                 from 'prop-types';
import {phoneValidator}                          from '../../../../utils/validator';

import CountrySelect       from '../../inputs/CountrySelect/CountrySelect';
import TextInput           from '../../inputs/TextInput/TextInput';
import ProvinceSelect      from '../../inputs/ProvinceSelect/ProvinceSelect';
import {isFullWidthString} from '../../../../utils/misc';

const notWantedFields = ['-sama'];
const nonRequiredFields = ['Address1', 'Address2', 'Address3', 'Middle_Name', ...notWantedFields];
const halfSizeFields = ['First_Name', 'Last_Name', 'Zip', 'City', 'Province', 'Country'];

const AddressForm = ({address, setAddress, setIsValid, zipBlurred, provinces = [], isGeoLookup = false}) => {
	const {t} = useTranslation();

	const createInputChangeHandler = key => e => {
		const newAddr = {...address, [key]: e.target.value};
		setAddress(newAddr);
	};

	const isFilled = useCallback(k => k in address && !!address[k].trim(), [address]);

	useEffect(() => {
		const phoneValid = isFilled('Contact_Phone') && phoneValidator(address['Contact_Phone']);
		let res = true;

		if(address['Country__'] === 'JP') {
			if(!isFullWidthString(address['City']) ||
				!isFullWidthString(address['Address']) ||
				!isFullWidthString(address['Address2']) ||
				!isFullWidthString(address['Address3'])) {
				setIsValid(false);
				return;
			}
		}

		address._fielsdOrder
			.filter(fieldName => !nonRequiredFields.includes(fieldName))
			.filter(fieldName => !notWantedFields.includes(fieldName))
			.forEach(fieldName => {
				if (res) res = isFilled(fieldName);
			});
		setIsValid(res && phoneValid);
	}, [address, setIsValid, isFilled]);
	const fields = address._fielsdOrder.filter((fieldName) => !notWantedFields.includes(fieldName));

	return (
		<fieldset className="w-full flex flex-row flex-wrap">
			{fields.length > 0 && fields.map((fieldName, i) => {
				let maxLength = fieldName === 'Address' ? 30 : undefined;
				if (fieldName === 'Country__') {
					return <div key={fieldName} className="md:flex-none w-full">
						<CountrySelect
							onChange={createInputChangeHandler('Country__')}
							value={address.Country__}
							tabIndex={4}
							className={'w-full'}
							isGeoLookup={isGeoLookup}
						/>
					</div>;
				} else if (fieldName === 'Zip') {
					return <div
						key={fieldName}
						className={'w-full box-border p-4 md:flex-none'}
					>
						<TextInput
							label={t(fieldName.toLowerCase())}
							value={address[fieldName] ?? ''}
							onChange={createInputChangeHandler(fieldName)}
							onBlur={zipBlurred}
							required={true}
							tabIndex={4}
						/>
					</div>;
				} else if (fieldName === 'Province' && provinces.length > 0) {
					return <div key={fieldName} className="md:flex-none w-full">
						<ProvinceSelect
							provinces={provinces}
							onChange={createInputChangeHandler('Province')}
							value={address.Province}
							tabIndex={4}
							autoFocus={i === 0}
							className={'w-full'}
							required={!nonRequiredFields.includes(fieldName)}
						/>
					</div>;
				} else {
					let addressHelperText = undefined;
					let error = undefined;
					if (address.Country__ === 'JP' && ['City', 'Address', 'Address2', 'Address3'].includes(fieldName)) {
						addressHelperText = t('jp_address_helperText');
						maxLength = 15;
						error=!isFullWidthString(address[fieldName] ?? '');
					}

					return <div
						key={fieldName}
						className={`w-full box-border p-4${halfSizeFields.includes(fieldName) ? ' md:flex-none md:w-1/2' : ''}`}
					>
						<TextInput
							label={t(fieldName.toLowerCase())}
							value={address[fieldName] ?? ''}
							onChange={createInputChangeHandler(fieldName)}
							required={!nonRequiredFields.includes(fieldName)}
							error={error ? addressHelperText : undefined}
							tabIndex={4}
							maxLength={maxLength}
							autoFocus={i === 0}
						/>
					</div>;
				}
			})
			}
		</fieldset>
	);
};

AddressForm.propTypes = {
	address: PropTypes.shape({
		Address: PropTypes.string,
		Address2: PropTypes.string,
		Address3: PropTypes.string,
		City: PropTypes.string,
		Contact_Phone: PropTypes.string,
		Country__: PropTypes.string,
		First_Name: PropTypes.string,
		Last_Name: PropTypes.string,
		Province: PropTypes.string,
		Zip: PropTypes.string,
		_fielsdOrder: PropTypes.arrayOf(PropTypes.string)
	}).isRequired,
	setAddress: PropTypes.func.isRequired,
	zipBlurred: PropTypes.func.isRequired,
	provinces: PropTypes.array,
	setIsValid: PropTypes.func,
	isGeoLookup: PropTypes.bool,
};

export default AddressForm;
