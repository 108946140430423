import React, { useState } from 'react'; 
import PropTypes           from 'prop-types';
import { useLocation }     from 'react-router-dom';
import { useTranslation }  from 'react-i18next';
import { useRest }         from '@karpeleslab/react-klbfw-hooks';

import ArgsBuilderFromUrl, { 
	buildArgsFromUrlSearchParams 
} from '../../core/utils/ArgsBuilderFromUrl/ArgsBuilderFromUrl';

import Loader              from '../../layout/Loader/Loader';
import ModuleNotLoaded     from '../../core/utils/ModuleNotLoaded/ModuleNotLoaded';
import NewsCard            from '../NewsCard/NewsCard';
import NewsSelectedFilters from '../NewsSelectedFilters/NewsSelectedFilters';
import Paging              from '../../layout/Paging/Paging';

const NewsList = ({ showFilters = true, showPaging = false }) => {
	const { t }                 = useTranslation();
	const imageVariation        = 'strip&scale_crop=305x171';
	const location              = useLocation();
	const [args, setArgs]       = useState({
		image_variation : imageVariation,
		sort            : 'weight:desc,published:desc',
		audience        : 'web',
		...buildArgsFromUrlSearchParams(location),
	});
	const [filters, setFilters] = useState(args?.query?.tag.map(f => ({ Classify_Tag__: f })) || []);

	const [data] = useRest('Content/Cms/@news:search', args);

	const addFilters = filtersArr => {
		filtersArr.forEach(filter => {
			const idx = filters.findIndex(f => f.Classify_Tag__ === filter.Classify_Tag__);
			if (idx < 0)
				setFilters(filters.concat([filter]));
		});
	};

	const goToPage = page_no => setArgs(a => ({ ...a, page_no }));

	const removeFilters = filtersArr => { 
		const tmp = JSON.parse(JSON.stringify(filters));
		filtersArr.forEach(filter => {
			const idx = tmp.findIndex(f => f.Classify_Tag__ === filter.Classify_Tag__);
			if (idx >= 0) 
				tmp.splice(idx, 1);
		});
		setFilters(tmp);
	};

	if (!data) // loading
		return <Loader />;
	else if (data?.result !== 'success') // error 
		return <ModuleNotLoaded />;
	else // OK
		return (
			<div className="w-full">
				<ArgsBuilderFromUrl args={args} filters={filters} setArgs={setArgs} />
				<div className="flex flex-col-reverse md:flex-row justify-center items-between">
					<div className={`flex flex-wrap justify-center md:justify-start w-full${showFilters ? ' md:w-3/4' : ''}`}>
						{data?.data?.data?.length > 0
							? data.data.data.map(item => (
								<NewsCard
									key={item.Content_Cms_Entry__}
									addFilters={addFilters}
									filters={filters}
									imageVariation={imageVariation}
									item={item}
								/>))
							: <p className="w-full text-xl text-center">{t('no_articles_yet')}</p>
						}
					</div>
					{(showFilters && data?.data?.content_cms?.Classify && filters.length > 0) && (
						<div className="w-full md:w-1/4">
							<NewsSelectedFilters 
								classify={data.data.content_cms.Classify.Classify__}
								selectedFilters={filters} 
								onRemoveFilter={filtersArr => removeFilters(filtersArr)}
							/>
						</div>
					)}
				</div>
				{(showPaging && data.paging) && (
					<Paging
						page_max={data.paging.page_max}
						page_no={data.paging.page_no}
						onClick={goToPage}
					/>
				)}
			</div>
		);
};

NewsList.propTypes = {
	showFilters: PropTypes.bool,
	showPaging : PropTypes.bool
};

export default NewsList;
