import React, { useContext } from 'react';
import { useTranslation }    from 'react-i18next';

import BigTitle            from '../../components/layout/BigTitle/BigTitle';
import CurrentSubscription from '../../components/user/CurrentSubscription/CurrentSubscription';
import EditBilling         from '../../components/user/EditBilling/EditBilling';
import EditLocations       from '../../components/user/EditLocations/EditLocations';
import LayoutHelmet        from '../../components/layout/LayoutHelmet/LayoutHelmet';
import OrderHistory        from '../../components/order/OrderHistory';
import UserInformation     from '../../components/user/UserInformation/UserInformation';

import { TemplateContext } from '../../context/TemplateContext';
import MassMailLists       from '../../components/massmail/MassMailLists';

const UserAccount = () => {
	const { t }               = useTranslation();
	const { fanclub_visible } = useContext(TemplateContext);

	return (
		<main className="w-full max-w-3xl mb-auto mx-auto pt-20">
			<LayoutHelmet title={t('user_profile')} />
			<div className="pxl-sections w-full">
				<a id="profile" aria-hidden></a>
				<BigTitle className="mt-8">{t('user_profile')}</BigTitle>
				<UserInformation />
				<a id="location" aria-hidden></a>
				<BigTitle className="mt-8" direction="right">{t('your_addresses')}</BigTitle>
				<EditLocations />
				<a id="payment" aria-hidden></a>
				<BigTitle className="mt-8">{t('your_mean_of_payment')}</BigTitle>
				<EditBilling />
				<a id="orders" aria-hidden></a>
				<BigTitle className="mt-8" direction="right">{t('order_history')}</BigTitle>
				<OrderHistory />
				<a id="notification" aria-hidden></a>
				<BigTitle className="mt-8">{t('notifications')}</BigTitle>
				<MassMailLists/>
				<a id="subscription" aria-hidden></a>
				{fanclub_visible === 'Y' && (
					<>
						<BigTitle className="mt-8" direction="right">{t('your_current_subscription')}</BigTitle>
						<CurrentSubscription />
					</>
				)}
			</div>
		</main>
	);
};

export default UserAccount;
