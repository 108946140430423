import React              from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes          from 'prop-types';

import {
	CSSTransition,
	SwitchTransition } from 'react-transition-group';
import Loader          from '../../layout/Loader/Loader';
import MonthsFrieze    from '../MonthsFrieze/MonthsFrieze';
import ScheduleItem    from '../ScheduleItem/ScheduleItem';

const Schedule = ({ imageVariation, loading, pushHistory, selectedMonth, schedule }) => {
	const { t } = useTranslation();

	return (
		<div className="w-full flex flex-col items-center">
			<MonthsFrieze 
				selectedMonth={selectedMonth}
				onChange={pushHistory}
			/>
			<div className="w-full max-w-3xl mt-6">
				<SwitchTransition mode="out-in">
					<CSSTransition
						key={loading ? 'loading' : `loaded-${selectedMonth.month}-${selectedMonth.year}`}
						classNames="u-transition--lateral"
						addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
					>
						{loading ? (
							<div className="mt-6 p-6">
								<Loader />
							</div>
						) : (
							schedule && schedule.length > 0 ? (
								<div>
									{schedule.map(item => (
										<ScheduleItem
											key={item.Planner_Schedule__}
											imageVariation={imageVariation}
											item={item} 
										/>
									))}
								</div>
							) : (
								<div className="mt-6 p-6 text-center">
									{t('no_event_for_this_month')}
								</div>
							)
						)}
					</CSSTransition>
				</SwitchTransition>
			</div>
		</div>
	);
};

Schedule.propTypes = {
	imageVariation: PropTypes.string,
	loading       : PropTypes.bool,
	pushHistory   : PropTypes.func,
	schedule      : PropTypes.arrayOf(
		PropTypes.shape({
			Planner_Schedule__: PropTypes.string
		})
	),
	selectedMonth: PropTypes.shape({
		month: PropTypes.number,
		year : PropTypes.number
	})
};

export default Schedule;
