import React from 'react';
import {trimPrefix} from '@karpeleslab/klbfw';
import {useRest} from '@karpeleslab/react-klbfw-hooks';
import './LangSwitch.css';

const getCountryFlag = (code) => {
	return typeof String.fromCodePoint !== 'undefined'
		? code.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
		: '';
};

export default function LangSwitch() {
	const [langData] = useRest('Language:local');

	if (!langData) {
		return <></>;
	}

	const reloadWithSelectedLocale = (fullCode) => {
		if (!window)
			return;

		const [prefix, url] = trimPrefix(window.location.pathname);
		let newPrefix = '';
		prefix['l'] = fullCode;
		Object.keys(prefix).forEach(k => newPrefix += `/${k}/${prefix[k]}`);

		window.location.href = newPrefix + url;
	};

	const elements = langData.data.map((lang, i) => {
		const id = lang.Language__.split('-')[1];
		const flag = getCountryFlag(id);
		return <button
			title={lang.Name_Long}
			className={'c-lang-switch-flag'}
			onClick={() => reloadWithSelectedLocale(lang.Language__)}
			key={i}
		>
			{flag}
		</button>;
	});

	return <>{elements}</>;
}
