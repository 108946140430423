import React, { useEffect, useState } from 'react';	
import { useTranslation }             from 'react-i18next';
import { rest }                       from '@karpeleslab/klbfw';

import BtnWithArrow from '../../layout/BtnWithArrow/BtnWithArrow';
import TextInput    from './../../core/inputs/TextInput/TextInput';

import { useSetSnackbar } from './../../../hooks/useSnackbar';

// import './ContactForm.scss';

const ContactForm = () => {
	const { t } = useTranslation();
	
	const [email, setEmail]     = useState('');
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const [name, setName]       = useState('');
	const [subject, setSubject] = useState('');

	const [canSubmit, setCanSubmit] = useState(false);
	
	useEffect(() => {
		setCanSubmit(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email) && message.length > 0 && name.length > 0 && subject.length > 0);
	}, [email, message, name, subject, setCanSubmit]);

	const setSnackbar = useSetSnackbar();

	const submitForm = () => {
		setLoading(true);
		rest('Support/Ticket', 'POST', {
			Email  : email,
			Name   : name,
			To     : '@support',
			Subject: subject,
			Message: message
		})
			.then(() => {
				setName('');
				setEmail('');
				setSubject('');
				setMessage('');
				setSnackbar(t('contact_form_success'), 'success');
			})
			.catch(() => {
				setSnackbar(t('contact_form_error'), 'error');
			})
			.finally(() => setLoading(false));
	};

	return (
		<form 
			className="flex justify-center items-center js-hero"
			action="#" 
			method="POST"
		>
			<fieldset className="border-none w-full my-0 mx-auto p-6">
				<div className="flex flex-wrap">
					<div className="w-full py-6">
						<TextInput 
							label={`${t('your_name')}`}
							value={name}
							onChange={e => setName(e.target.value)}
							disabled={loading}
							required
						/>
					</div>
					<div className="w-full py-6">
						<TextInput 
							label={`${t('your_email_address')}`}
							type="email"
							value={email}
							disabled={loading}
							onChange={e => setEmail(e.target.value)}
							required
						/>
					</div>
					<div className="w-full py-6">
						<TextInput 
							label={`${t('object_of_the_message')}`}
							value={subject}
							disabled={loading}
							onChange={e => setSubject(e.target.value)}
							required
						/>
					</div>
					<div className="w-full py-6">
						<TextInput 
							type="textarea"
							label={`${t('your_message')}`}
							value={message}
							disabled={loading}
							rows="10"
							onChange={e => setMessage(e.target.value)}
							required
						/>
					</div>
				</div> 
				<div className="flex justify-center pt-4">
					<BtnWithArrow
						variant="inverted"
						size="large"
						className="w-64"
						disabled={!canSubmit || loading}
						loading={loading}
						accessibility={t('send')}
						onClick={() => submitForm()}
					>
						{t('send')}
					</BtnWithArrow>
				</div>
			</fieldset>
		</form>
	);
};

export default ContactForm;