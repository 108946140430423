import PropTypes            from 'prop-types';
import { useTranslation }   from 'react-i18next';
import { useOrderProcess }  from '@karpeleslab/klb-react-services';
import React, { useEffect } from 'react';
import Btn                  from '../../../../core/inputs/Btn/Btn';
import { FiLoader }         from 'react-icons/fi';

const Free = ({ order, settings, setPaying, refreshOrder, refreshProcessResult }) => {
	const { t } = useTranslation();

	const [processOrder, processingOrder] = useOrderProcess(order.Order__);

	useEffect(() => {
		setPaying(processingOrder);
	}, [processingOrder]);

	const handleProcess = () => {
		processOrder(settings.session, settings.method, {}, { snackMessageToken: 'order_paid' })
			.then(d => {
				refreshOrder(d.order);
				refreshProcessResult(d);
			});
	};

	return (
		<div className='flex flex-wrap overflow-hidden sm:-mx-3'>

			<div className='w-full overflow-hidden sm:my-3 sm:px-3'>
				<h1 className='text-center text-2xl my-4'>
					{t('free_order_text')}
				</h1>
			</div>

			<div className='w-full overflow-hidden sm:my-3 sm:px-3 flex items-center justify-center'>
				<Btn
					onClick={handleProcess}
					disabled={processingOrder}
					accessibility={t('free_order_btn')}
				>
					{processingOrder ? <FiLoader className="animate-spin" /> : t('free_order_btn')}
				</Btn>
			</div>
		</div>
	);
};

export default Free;

Free.propTypes = {
	order: PropTypes.object.isRequired,
	refreshOrder: PropTypes.func.isRequired,
	refreshProcessResult: PropTypes.func.isRequired,
	setPaying: PropTypes.func.isRequired,
	settings: PropTypes.object.isRequired
};
