import React              from 'react';
import { useTranslation } from 'react-i18next';

import Helmet          from 'react-helmet';
import Html            from './Html';
import Text                     from './Text';
import { getFrom, getFromName } from '../../utils';

const Mail = () => {
	const { t } = useTranslation();

	return (
		<div>
			<Helmet>
				<title>{t('login_notification_title')}</title>
				<meta name="mail-from-name" content={getFromName()} />
				<meta name="mail-from" content={getFrom()} />
			</Helmet>
			<Html />
			<Text />
		</div>
	);
};

export default Mail;
