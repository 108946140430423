import React, { useEffect, useRef, useState } from 'react';
import PropTypes                              from 'prop-types';
import { useTranslation }                     from 'react-i18next';

import AvatarImage        from './AvatarImage';
import Btn                from '../../core/inputs/Btn/Btn';
import { HiOutlineTrash } from 'react-icons/hi';

import defaultAvatar from '../../../assets/img/default-avatar.svg';

const AvatarEditor = ({ currentImg = null, field, fieldId, required = false, setAvatarImage }) => {
	const [state, setState] = useState({
		imgPath: null,
		width  : 170,
		height : 170,
		scale  : 1,
		preview: { img: null }
	});
	const editor           = useRef(null);
	const croppieFileInput = useRef(null);
	const { t }            = useTranslation();

	useEffect(() => {
		if (editor && editor.current)
			handleSetImage();
	}, [editor]); // eslint-disable-line

	const handleNewImage = e => setState({ ...state, imgPath: e.target.files[0] });

	const handleRemove = () => {
		setState({
			...state,
			imgPath: null,
			scale  : 1
		});
		croppieFileInput.current.value = '';
		setAvatarImage(field.name, currentImg || defaultAvatar, field.param, field.target);
	};

	const handleScale = e => {
		setState({ ...state, scale: parseFloat(e.target.value) });
		handleSetImage();
	};

	const handleSetImage = () => {
		editor.current.getImageScaledToCanvas().toBlob((blob) => {
			blob['name'] = 'avatar.png';
			setAvatarImage(field.name, blob, field.param, field.target);
		});
	};

	return (
		<div className="mb-5 flex flex-col items-center text-center">
			<AvatarImage
				editor={editor}
				handleSetImage={handleSetImage}
				handleScale={handleScale}
				state={state}
				defaultAvatar={currentImg || defaultAvatar}
			/>
			<div className="flex justify-between items-center mt-4">
				<div className="mx-2">
					<label className="pxl-btn normal pxl-display-font">
						<input
							ref={croppieFileInput}
							id={fieldId}
							type="file"
							className="hidden"
							name={field.name}
							accept="image/png, image/jpeg, image/jpg, image/svg"
							onChange={handleNewImage}
							required={required}
						/>
						<div>
							{t('profile_upload_image')}
						</div>
					</label>
				</div>
				{(state && state.imgPath) && (
					<Btn
						variant="danger"
						className="mx-2"
						onClick={handleRemove}
						accessibility={t('acc_remove_avatar')}
					>
						<HiOutlineTrash />
					</Btn>
				)}
			</div>
		</div>
	);
};

export default AvatarEditor;

AvatarEditor.propTypes = {
	currentImg    : PropTypes.string,
	field         : PropTypes.object.isRequired,
	fieldId       : PropTypes.string.isRequired,
	required      : PropTypes.bool,
	setAvatarImage: PropTypes.func.isRequired,
};
