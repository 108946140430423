import React              from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes          from 'prop-types';

import EmailLayout from '../../../components/email/EmailLayout';

const Html = ({ username, website }) => {
	const { t }      = useTranslation();
	const paragraphs = t('account_confirmed_description', { username, website }).split('\n');

	return (
		<EmailLayout
			intro={t('account_confirmed_title', { website })}
			paragraphs={paragraphs}
		/>
	);
};

Html.propTypes = {
	username: PropTypes.string,
	website : PropTypes.string
};

export default Html;
