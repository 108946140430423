import React     from 'react';
import PropTypes from 'prop-types';

import WavyLine from '../WavyLine/WavyLine';

import './BigTitle.css';

const BigTitle = ({ children, className = '', direction = '' }) => {
	return (
		<div className={
			`pxl-section-title w-full px-4 md:px-0 py-8 flex items-center ${
				['left', 'right'].includes(direction) ? direction : ''
			} ${className}`}
		>
			<div className="flex-none w-full sm:w-auto">
				<h1 className="text-4xl md:text-6xl">{children}</h1>
			</div>
			<div className="pxl-section-title__wave hidden sm:block flex-1 pl-36">
				<WavyLine className="py-6" />
			</div>
		</div>
	);
};

BigTitle.propTypes = {
	children : PropTypes.node,
	className: PropTypes.string,
	direction: PropTypes.oneOf(['', 'left', 'right'])
};

export default BigTitle;
