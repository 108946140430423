import React, { useContext } from 'react';

import Article              from '../../pages/Article/Article';
import Cart                 from '../../pages/Cart/Cart';
import CheckoutConfirmed    from '../../pages/CheckoutConfirmed/CheckoutConfirmed';
import CheckoutShop         from '../../pages/CheckoutShop/CheckoutShop';
import CheckoutSubscription from '../../pages/CheckoutSubscription/CheckoutSubscription';
import Contact              from '../../pages/Contact/Contact';
import Discography          from '../../pages/Discography/Discography';
import Event                from '../../pages/Event/Event';
import FanclubArticles      from '../../pages/FanclubArticles/FanclubArticles';
import Home                 from '../../pages/Home/Home';
import JoinFanClub          from '../../pages/JoinFanClub/JoinFanClub';
import Live                 from '../../pages/Live/Live';
import Login                from '../../pages/Login/Login';
import News                 from '../../pages/News/News';
import Reserve              from '../../pages/Reserve/Reserve';
import Schedule             from '../../pages/Schedule/Schedule';
import Shop                 from '../../pages/Shop/Shop';
import ShopEntry            from '../../pages/ShopEntry/ShopEntry';
import ThankYou             from '../../pages/ThankYou/ThankYou';
import UserAccount          from '../../pages/UserAccount/UserAccount';
import LegalInformation     from '../../pages/LegalInformation/LegalInformation';
import Order                from '../../pages/Order/Order';
import PasswordForgotten    from '../../pages/PasswordForgotten/PasswordForgotten';
import ReinitPassword       from '../../pages/ReinitPassword/ReinitPassword';

import { TemplateContext } from '../../context/TemplateContext';

const routeDefinitions = ({ fanclub_visible = 'N', releases_visible = 'N', shop_visible = 'N', news_visible = 'N', schedule_visible = 'N' }) => {
	const { location } = useContext(TemplateContext);

	const routes = [{
		path: '/contact',
		component: <Contact />
	}, {
		path     : '/forget-password/',
		component: <PasswordForgotten />
	}, {
		path     : '/recover-password/:userId',
		component: <ReinitPassword />
	}, {
		path     : '/live/:slug',
		component: <Live />
	}, {
		path     : '/reserve/:eventId/:seatId',
		component: <Reserve />
	}, {
		path: '/login',
		component: <Login />
	}, {
		path: '/pages/:slug',
		component: <Article cmsId='ctcm-ofk2r3-dvyj-cwlh-o7wf-3w6rc7qy' enableShare={false} backLink="/" author={false}/>
	}, {
		path: '/checkout-completed/:type',
		component: <CheckoutConfirmed />,
		loginRequired: true
	}, {
		path: '/checkout-completed/:type/:orderId',
		component: <CheckoutConfirmed />,
		loginRequired: true
	}, {
		path: '/user',
		component: <UserAccount />,
		loginRequired: true
	}, {
		path     : '/thankyou',
		component: <ThankYou />
	}, {
		path     : '/',
		component: <Home />
	}];

	if (fanclub_visible === 'Y') {
		routes.push(
			{ path: '/fanclub', component: <JoinFanClub /> },
			{ path: '/fanclub/join/:subscriptionId', component: <CheckoutSubscription /> },
			{
				path         : '/articles/:slug', // routes is fixed with pxl admin that force it like that
				component    : <Article fanclub backLink="/fanclub/articles" enableComments={true}/>,
				loginRequired: true
			},
			{
				path         : '/fanclub/articles',
				component    : <FanclubArticles />,
				loginRequired: true
			}
		);
	}

	if (news_visible === 'Y') {
		routes.push(
			{ path: '/news', component: <News />},
			{ path: '/news/:slug', component: <Article enableComments={true}/>},
		);
	}

	if (schedule_visible === 'Y') {
		routes.push(
			{ path: '/events/:slug', component: <Event />},
			{ path: '/events/:year?/:month?', component: <Schedule />},
		);
	}

	if (releases_visible === 'Y') {
		routes.push(
			{ path: '/discography', component: <Discography /> },
			{ path: '/discography/release/:id', component: <Discography /> }
		);
	}

	if (shop_visible === 'Y') {
		routes.push(
			{ path: '/shop', component: <Shop /> },
			{ path: '/shop/:id', component: <ShopEntry /> },
			{ path: '/cart', component: <Cart /> },
			{ path: '/checkout', component: <CheckoutShop /> },
		);
	}

	routes.push(	{ path: '/order/:orderId', component: <Order /> });

	if (location) 
		routes.push({ path: '/legal-information', component: <LegalInformation /> });

	return routes;
};

export default routeDefinitions;
