import React                from 'react';
import { useTranslation }   from 'react-i18next';
import { Get, getHostname } from '@karpeleslab/klbfw';

import Helmet            from 'react-helmet';
import Html              from './Html';
import Text                                    from './Text';
import { getFrom, getFromName, getSubjectTag } from '../../utils';

const Mail = () => {
	const { t } = useTranslation();

	return (
		<div>
			<Helmet>
				<title>{t('user_registration_confirm_title', { website: getSubjectTag() })}</title>
				<meta name='mail-from-name' content={getFromName()}/>
				<meta name='mail-from' content={getFrom()}/>
			</Helmet>
			<Html code={Get('_code')} username={Get('_name')} website={getHostname()}/>
			<Text code={Get('_code')} username={Get('_name')} website={getHostname()}/>
		</div>
	);
};

export default Mail;
