import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { rest }           from '@karpeleslab/klbfw';

import { AiOutlineFileDone, AiOutlinePoweroff, AiOutlineStar, AiOutlineUser } from 'react-icons/ai';
import Btn          from '../../core/inputs/Btn/Btn';
import { FiLoader } from 'react-icons/fi';

import { useSetSnackbar } from './../../../hooks/useSnackbar';

const UserDropDown = forwardRef(({}, ref) => { // eslint-disable-line
	const { t }                       = useTranslation();
	const [loggingOut, setLoggingOut] = useState(false);
	const [open, setOpen]             = useState(false);
	const setSnackbar                 = useSetSnackbar();

	const logOut = () => {
		setLoggingOut(true);
		rest('User/@:logout', 'POST')
			.then(() => window.location.href = '/')
			.catch(err => setSnackbar(err.error, 'error'));
	};

	useImperativeHandle(ref, () => ({
		open: () => setOpen(v => !v)
	}));

	useEffect(() => {
		if (typeof window === 'undefined') return;
		document.addEventListener('click', () => setOpen(false));
	}, []);

	return (
		<div
			className={`topbar-dropdown ${
				open ? 'open' : ''
			} absolute py-6 pr-6 top-full mt-4 right-4 bg-pxl-darkerGray border border-white`}
		>
			<Btn
				variant="discrete"
				to="/user#profile"
				className="w-full mx-4 my-2 text-right"
				onFocus={() => setOpen(true)}
				onBlur={() => setOpen(false)}
				accessibility={t('user_profile')}
			>
				<div className="w-full flex justify-end items-center">
					<span>{t('user_profile')}</span>
					<span className="pl-4">
						<AiOutlineUser />
					</span>
				</div>
			</Btn>
			<Btn
				variant="discrete"
				to="/user#subscription"
				className="w-full mx-4 my-2 text-right whitespace-nowrap"
				onFocus={() => setOpen(true)}
				accessibility={t('subscription')}
			>
				<div className="flex w-full justify-end items-center">
					<span>{t('subscription')}</span>
					<span className="pl-4">
						<AiOutlineStar />
					</span>
				</div>
			</Btn>
			<Btn
				variant="discrete"
				to="/user#orders"
				className="w-full mx-4 my-2 text-right whitespace-nowrap"
				onFocus={() => setOpen(true)}
				accessibility={t('orders')}
			>
				<div className="w-full flex justify-end items-center">
					<span>{t('orders')}</span>
					<span className="pl-4">
						<AiOutlineFileDone />
					</span>
				</div>
			</Btn>
			<Btn
				variant="discrete"
				hoverVariant="danger"
				className="w-full mx-4 my-2 text-right whitespace-nowrap"
				onClick={logOut}
				onFocus={() => setOpen(true)}
				onBlur={() => setOpen(false)}
				accessibility={t('logout')}
			>
				<div className="w-full flex justify-end items-center">
					<span>
						{loggingOut ? <FiLoader className="animate-spin" /> : t('logout')}
					</span>
					<span className="pl-4">
						<AiOutlinePoweroff />
					</span>
				</div>
			</Btn>
		</div>
	);
});

export default UserDropDown;
