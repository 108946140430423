import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';

import EmailLayout from '../../../components/email/EmailLayout';

const Html = ({ code, username, website }) => {
	const { t } = useTranslation();

	return (
		<EmailLayout
			intro={t('user_registration_confirm_title', { website })}
			paragraphs={[
				...t('user_registration_confirm_description', {
					username,
					website
				}).split('\n'),
				code,
				t('user_registration_confirm_contact_us')
			]}
			showContactBtn={true}
		/>
	);
};

Html.propTypes = {
	code    : PropTypes.string,
	username: PropTypes.string,
	website : PropTypes.string
};

export default Html;
