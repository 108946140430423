import React, { useContext } from 'react';
import PropTypes             from 'prop-types';

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import ImgLoader                           from '../../layout/ImgLoader/ImgLoader';
import { FiPause, FiPlay }                 from 'react-icons/fi';
import { Swiper, SwiperSlide }             from 'swiper/react';
import SwiperCore, {
	EffectCoverflow,
	History,
	Pagination,
	A11y,
	Keyboard,
} from 'swiper/core';

import { MusicContext }     from '../../../context/MusicContext';

import 'swiper/swiper.min.css';
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
import 'swiper/components/pagination/pagination.min.css';
import './AlbumCarousel.css';
import {BsFillPinAngleFill} from 'react-icons/all';

SwiperCore.use([EffectCoverflow, History, Pagination, A11y, Keyboard]);

const AlbumCarousel = ({ discography, imageVariation, onAlbumClicked, onAlbumSelected }) => {
	const { currentIdx, playing, queue } = useContext(MusicContext);

	const isAlbumPlaying = albumId => (
		playing &&
		queue.length > 0 &&
		currentIdx >= 0 &&
		queue[currentIdx].album.Music_Label_Album__ === albumId
	);

	const onKeyPress = (e) => {
		if (e.nativeEvent.keyCode === 13 || e.nativeEvent.keyCode === 32) {
			onAlbumClicked();
		}
	};

	// https://swiperjs.com/react
	return (
		<SwitchTransition mode="out-in">
			<CSSTransition
				key={!discography || discography.length < 1 ? 'loading' : 'loaded-albums'}
				classNames="u-transition--vertical"
				addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
			>
				{discography && discography.length > 0 ? (
					<div key="carousel" className="relative w-full h-80 mx-auto">
						<Swiper
							centeredSlides
							coverflowEffect={{
								rotate: 33,
								stretch: 0,
								depth: 200,
								modifier: 1,
								slideShadows: false
							}}
							effect={'coverflow'}
							grabCursor
							keyboard={{
								enabled: true,
								onlyInViewport: false,
							}}
							history={{ key: 'discography/release' }}
							loop
							a11y={{enabled: true}}
							onClick={swiper => swiper.slideTo(swiper.clickedIndex)}
							onSlideChange={e => onAlbumSelected(discography[e.realIndex])}
							slidesPerView={'auto'}
						>
							{discography.map(album => (
								<SwiperSlide
									key={album.Music_Label_Album__}
									data-history={album.Music_Label_Album__}
								>
									{({ isActive }) => (
										<div className="relative w-full h-full bg-pxl-darkGray">
											{album?.Music_Label_Album?.Drive_Item && (
												<ImgLoader
													src={
														album.Music_Label_Album.Drive_Item.Media_Image[imageVariation] ||
														album.Music_Label_Album.Drive_Item.View_Url
													}
													alt=""
													width="100%"
													height="100%"
												/>
											)}
											{(parseInt(album.Weight) ?? 0) > 0 && <BsFillPinAngleFill className={'pinned-album-icon'} size={'24px'} />}
											<SwitchTransition mode="out-in">
												<CSSTransition
													key={isActive ? 'active' : 'inactive'}
													classNames="u-transition--zoom"
													addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
												>
													{isActive ? (
														<div
															key="active"
															className="absolute top-0 left-0 w-full h-full flex justify-center items-center text-white cursor-pointer transition-all opacity-50 hover:opacity-100 focus:border-white focus:border-8"
															style={{ fontSize: '8rem' }}
															onClick={onAlbumClicked}
															tabIndex={0}
															onKeyPress={onKeyPress}
														>
															{isAlbumPlaying(album.Music_Label_Album__) ?
																<FiPause/> :
																<FiPlay/>
															}
														</div>
													) : <div key="inactive"/>}
												</CSSTransition>
											</SwitchTransition>

										</div>
									)}
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				) : <div key="no-carousel"/>}
			</CSSTransition>
		</SwitchTransition>
	);
};

AlbumCarousel.propTypes = {
	discography: PropTypes.arrayOf(
		PropTypes.shape({
			Music_Label_Album: PropTypes.shape({
				Media_Image: PropTypes.object,
				View_Url   : PropTypes.string
			}),
			Music_Label_Album__: PropTypes.string
		})
	),
	imageVariation : PropTypes.string.isRequired,
	onAlbumClicked : PropTypes.func,
	onAlbumSelected: PropTypes.func
};

export default AlbumCarousel;
