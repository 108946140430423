import React, { useContext } from 'react';
import PropTypes             from 'prop-types';

import AvatarEditor from './AvatarEditor';
import Check        from '../../core/inputs/Check/Check';
import TextInput    from '../../core/inputs/TextInput/TextInput';
import {Trans, useTranslation} from 'react-i18next';

import { LoginContext }        from '@karpeleslab/klb-react-services';
import { useMobileMediaQuery } from '../../../hooks/useMediaQueries';

const FormBuilder = ({ profilePicture = true }) => {
	const {t} = useTranslation();
	const isMobile = useMobileMediaQuery();
	const { onInputChange, setAvatarImage, fields } = useContext(LoginContext);

	if (!fields)
		return <></>;

	let inputFound = false; // We use this to only focus the first input of the form (we ignore labels and file chooser)

	return fields.map((field, idx) => {
		const fieldId = (field.name ?? 0) + idx;
		const autoFocus = !inputFound;
		switch (field.type) {
			case 'text':
			case 'password':
			case 'email' :
				inputFound = true;
				return (
					<TextInput
						autoFocus={autoFocus}
						key={`field-${fieldId}`}
						label={field.label}
						name={field.name}
						required={field.required}
						type={field.type}
						className="my-6"
						onChange={onInputChange}
					/>
				);
			case 'label':
				if (field.link) {
					return (
						<a
							key={`field-${fieldId}`}
							href={field.link}
							className="inline-block my-4"
							target="_blank"
							rel="noopener noreferrer"
							tabIndex="0"
							aria-label={field.label}
						>
							{field.label}
						</a>
					);
				}
	
				if (field.style === 'error' && isMobile)
					return <React.Fragment key={`field-${fieldId}`}></React.Fragment>;

				return (
					<p
						key={`field-${fieldId}`}
						className={`block w-full my-6 ${
							field.style === 'error' ? 'text-red-500' : ''
						}`}
					>
						{field.label}
					</p>
				);
			case 'checkbox': {
				inputFound = true;
				return (
					<Check
						autoFocus={autoFocus}
						key={`field-${fieldId}`} 
						className="my-6"
						name={field.name}
						value="1"
						required={field.required}
						onChange={onInputChange}
					>
						{field.link ? (
							<Trans
								i18nKey={field.name}
								components={{
									'external-link': (
										<a
											href={field.link}
											className="text-pxl-accent1 hover:underline"
											target="_blank"
											rel="noopener noreferrer"
											aria-label={t(field.name)}
										/>
									)
								}}
							/>
						) :
							field.label
						}
					</Check>
				);
			}
			case 'image':
				if (!profilePicture) return <React.Fragment key={`field-${fieldId}`} />;
				return (
					<AvatarEditor
						key={fieldId}
						id={fieldId}
						fieldId={fieldId}
						field={field}
						required={field.required}
						setAvatarImage={setAvatarImage}
					/>
				);
			default:
				return <React.Fragment key={`field-${fieldId}`} />;
		}
	});
};

export default FormBuilder;

FormBuilder.propTypes = {
	profilePicture: PropTypes.bool,
};
