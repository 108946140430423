import React              from 'react';
import PropTypes          from 'prop-types';
import EventDates    from './EventDates';
import EventLocation from './EventLocation';

import './EventInformation.css';

export default function EventInformation({ event }) {
	return (
		<div className='flex flex-col md:flex-row flex-wrap overflow-hidden w-full'>
			<div className={'my-3 w-full md:w-1/2 overflow-hidden'}>
				<EventDates event={event}/>
			</div>

			<div className={'my-3 w-full md:w-1/2 overflow-hidden'}>
				<EventLocation event={event}/>
			</div>
		</div>
	);
}

EventInformation.propTypes = {
	event: PropTypes.object,
};
