import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import {FiPause, FiPlay} from 'react-icons/fi';

import {MusicContext} from '../../../context/MusicContext';

import './TrackList.css';
import {clickOnEnterKeyPress} from '../../../utils/accessibility';

const TrackList = ({album}) => {
	const {t} = useTranslation();
	const {
		currentIdx,
		playing,
		queue,
		togglePlay,
		setPlaylist,
		playIdxInQueue
	} = useContext(MusicContext);

	const changeTrack = track => {
		if (queue.length !== 0) {
			const trackIndexInQueue = queue.findIndex((t) => t.track.Music_Label_Record__ === track.Music_Label_Record__ );
			if (trackIndexInQueue > -1) {
				playIdxInQueue(trackIndexInQueue);
				return;
			}
		}
		const trackIndex = album.Tracks.findIndex(t => t.Music_Label_Record__ === track.Music_Label_Record__);
		const items = album.Tracks.map((t) => ({
			track: t, album
		}));
		setPlaylist(items, trackIndex);
	};

	const isTrackCurrentlyPlaying = t => (
		playing &&
		queue.length > 0 &&
		currentIdx >= 0 &&
		queue[currentIdx].track.Music_Label_Record__ === t.Music_Label_Record__
	);

	return (
		<div className="flex flex-col items-stretch py-6 border border-white">
			<div className="flex px-6 p-4 font-bold">
				<div className="w-1/12"/>
				<div className="w-8/12 px-2">{t('title')}</div>
				<div className="w-1/4 text-right">{t('duration')}</div>
			</div>
			{album.Tracks.map((t, i) => {
				const hasPreview = !('no_preview' in t.Music_Label_Record.Flags);

				return (
					<div
						key={t.Music_Label_Record__}
						className={`flex items-center px-6 py-4 transition-colors track-list-line ${hasPreview && 'cursor-pointer hover:bg-pxl-darkGray '}`}
						onClick={() => {
							if(!hasPreview) return;
							isTrackCurrentlyPlaying(t) ? togglePlay() : changeTrack(t);
						}}
						onKeyPress={(e) => {
							if(!hasPreview) return;
							clickOnEnterKeyPress(e, () => isTrackCurrentlyPlaying(t) ? togglePlay() : changeTrack(t));
						}}
						tabIndex="0"
					>
						<div className="w-1/12 flex justify-center">
							{hasPreview ? (isTrackCurrentlyPlaying(t)
								? <FiPause/>
								: <FiPlay/>)
								: <></>
							}
						</div>
						<div className="w-8/12 px-2">{i + 1}. {t.Music_Label_Record.Name}</div>
						<div
							className="w-1/4 text-right">{new Date(t.Music_Label_Record.Duration * 1000).toISOString().substr(14, 5)}</div>
					</div>
				);
			})}
		</div>
	);
};

TrackList.propTypes = {
	album: PropTypes.shape({
		Music_Label_Album__: PropTypes.string,
		Tracks: PropTypes.arrayOf(
			PropTypes.shape({
				Music_Label_Record__: PropTypes.string.isRequired,
				Music_Label_Record: PropTypes.shape({
					Duration: PropTypes.string.isRequired,
					Name: PropTypes.string.isRequired
				}).isRequired
			})
		)
	})
};

export default TrackList;
