import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getHostname }    from '@karpeleslab/klbfw';

import EmailHead   from './EmailHead';
import EmailFooter from './EmailFooter';

import TemplateContextContainer from '../../context/TemplateContext';

const css = `
/* -------------------------------------
    RESPONSIVE AND MOBILE FRIENDLY STYLES
------------------------------------- */
@media only screen and (max-width: 620px) {
  table[class=body] h1 {
    font-size: 28px !important;
    margin-bottom: 10px !important;
  }
  table[class=body] p,
        table[class=body] ul,
        table[class=body] ol,
        table[class=body] td,
        table[class=body] span,
        table[class=body] a {
    font-size: 16px !important;
  }
  table[class=body] .wrapper,
        table[class=body] .article {
    padding: 10px !important;
  }
  table[class=body] .content {
    padding: 0 !important;
  }
  table[class=body] .container {
    padding: 0 !important;
    width: 100% !important;
  }
  table[class=body] .main {
    border-left-width: 0 !important;
    border-radius: 0 !important;
    border-right-width: 0 !important;
  }
  table[class=body] .btn table {
    width: 100% !important;
  }
  table[class=body] .btn a {
    width: 100% !important;
  }
  table[class=body] .img-responsive {
    height: auto !important;
    max-width: 100% !important;
    width: auto !important;
  }
}

/* -------------------------------------
    PRESERVE THESE STYLES IN THE HEAD
------------------------------------- */
@media all {
  html, body, #mail-html {
	  background-color: #1e1f20;
  }
  .ExternalClass {
    width: 100%;
  }
  .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
    line-height: 100%;
  }
  .apple-link a {
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    text-decoration: none !important;
  }
  #MessageViewBody a {
    color: inherit;
    text-decoration: none;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
  .btn-primary table td,
  .btn-primary a {
	  color: #fff !important;
  }
  .btn-primary table td:hover {
    background-color: #fc0046 !important;
  }
  .btn-primary a:hover {
    background-color: #fc0046 !important;
    border-color: #fc0046 !important;
  }
}`;

const EmailLayout = ({ children = null, intro = '', paragraphs = [], showContactBtn = false }) => {
	const { t } = useTranslation();

	return (
		<TemplateContextContainer>
			<div id="mail-html">
				<style>{css}</style>
				<span
					className="preheader"
					style={{
						color: 'transparent',
						display: 'none',
						height: '0',
						maxHeight: '0',
						maxWidth: '0',
						opacity: '0',
						overflow: 'hidden',
						msoHide: 'all',
						visibility: 'hidden',
						width: '0'
					}}
				>
					{intro}
				</span>
				<table
					border="0"
					cellPadding="0"
					cellSpacing="0"
					className="body"
					style={{
						borderCollapse: 'separate',
						msoTableLspace: '0pt',
						msoTableRspace: '0pt',
						width: '100%',
						backgroundColor: '#1e1f20'
					}}
				>
					<tbody>
						<tr>
							<td
								style={{
									fontFamily: 'sans-serif',
									fontSize: '14px',
									verticalAlign: 'top'
								}}
							>&nbsp;</td>
							<td
								className="container"
								style={{
									fontFamily: 'sans-serif',
									fontSize: '14px',
									verticalAlign: 'top',
									display: 'block',
									margin: '0 auto',
									maxWidth: '580px',
									padding: '10px',
									width: '580px'
								}}
							>
								<div
									className="content"
									style={{
										boxSizing: 'border-box',
										display: 'block',
										margin: '0 auto',
										maxWidth: '580px',
										padding: '10px'
									}}
								>
									<table
										className="main"
										style={{
											borderCollapse: 'separate',
											msoTableLspace: '0pt',
											msoTableRspace: '0pt',
											width: '100%',
											background: '#1e1f20',
											borderRadius: '3px'
										}}
									>
										<tbody>
											<EmailHead />
											<tr>
												<td
													className="wrapper"
													colSpan="3"
													style={{
														fontFamily: 'sans-serif',
														fontSize: '14px',
														verticalAlign: 'top',
														boxSizing: 'border-box',
														padding: '20px'
													}}
												>
													<table
														border="0"
														cellPadding="0"
														cellSpacing="0"
														style={{
															borderCollapse: 'separate',
															msoTableLspace: '0pt',
															msoTableRspace: '0pt',
															width: '100%'
														}}
													>
														<tbody>
															<tr>
																<td
																	style={{
																		fontFamily: 'sans-serif',
																		fontSize: '14px',
																		verticalAlign: 'top'
																	}}
																>
																	{paragraphs.map((p, i) => (
																		<p
																			key={`paragraph-${i + 1}`}
																			style={{
																				fontFamily: 'sans-serif',
																				fontSize: '14px',
																				fontWeight: 'normal',
																				margin: '0',
																				marginBottom: '15px',
																				color: '#ffffff'
																			}}
																			dangerouslySetInnerHTML={{ __html: p }}
																		></p>
																	))}
																	{children || ''}
																	{showContactBtn && (
																		<>
																			<p
																				style={{
																					fontFamily: 'sans-serif',
																					fontSize: '14px',
																					fontWeight: 'normal',
																					margin: '0',
																					marginBottom: '15px',
																					color: '#ffffff'
																				}}
																			>&nbsp;</p>
																			<table
																				border="0"
																				cellPadding="0"
																				cellSpacing="0"
																				className="btn btn-primary"
																				style={{
																					borderCollapse: 'separate',
																					msoTableLspace: '0pt',
																					msoTableRspace: '0pt',
																					width: '100%',
																					boxSizing: 'border-box'
																				}}
																			>
																				<tbody>
																					<tr>
																						<td
																							align="left"
																							style={{
																								fontFamily: 'sans-serif',
																								fontSize: '14px',
																								verticalAlign: 'top',
																								paddingBottom: '15px'
																							}}
																						>
																							<table
																								border="0"
																								cellPadding="0"
																								cellSpacing="0"
																								style={{
																									borderCollapse: 'separate',
																									msoTableLspace: '0pt',
																									msoTableRspace: '0pt',
																									width: 'auto'
																								}}
																							>
																								<tbody>
																									<tr>
																										<td
																											style={{
																												fontFamily: 'sans-serif',
																												fontSize: '14px',
																												verticalAlign: 'top',
																												backgroundColor: '#fc0046',
																												textAlign: 'center'
																											}}
																										> 
																											<a
																												href={`https://${getHostname()}/contact`}
																												target="_blank"
																												rel="noopener noreferrer"
																												style={{
																													display: 'inline-block',
																													color: '#ffffff',
																													backgroundColor: '#fc0046',
																													border: 'solid 1px #fc0046',
																													boxSizing: 'border-box',
																													cursor: 'pointer',
																													textDecoration: 'none',
																													fontSize: '14px',
																													fontWeight: 'bold',
																													margin: '0',
																													padding: '12px 25px',
																													textTransform: 'capitalize',
																													borderColor: '#fc0046'
																												}}
																												aria-label={t('contact_us')}
																											>
																												{t('contact_us')}
																											</a> 
																										</td>
																									</tr>
																								</tbody>
																							</table>
																						</td>
																					</tr>
																				</tbody>
																			</table>
																		</>
																	)}
																</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
									<EmailFooter />
								</div>
							</td>
							<td
								style={{
									fontFamily: 'sans-serif',
									fontSize: '14px',
									verticalAlign: 'top'
								}}
							>
								&nbsp;
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</TemplateContextContainer>
	);
};

EmailLayout.propTypes = {
	children      : PropTypes.node,
	intro         : PropTypes.string,
	paragraphs    : PropTypes.arrayOf(PropTypes.string),
	showContactBtn: PropTypes.bool
};

export default EmailLayout;
