import React, {useContext, useEffect, useState} from 'react';
import {useRest}                                from '@karpeleslab/react-klbfw-hooks';
import {useTranslation}                         from 'react-i18next';

import {CSSTransition, SwitchTransition} from 'react-transition-group';
import Loader                            from '../../layout/Loader/Loader';
import Plan                              from './Plan';

import {UserContext} from '../../../context/UserContext';
import PropTypes     from 'prop-types';

export default function FanClubPlans({userSubscription = null}) {
	const {t} = useTranslation();

	const getPlanClass = () => {
		let nbPlans = plans.data.Membership_Plan.length;
		if (plans.data.Membership_Plan_Free)
			nbPlans += 1;

		if ([3, 6, 9].includes(nbPlans))
			return 'sm:w-1/3';
		else if (nbPlans === 4)
			return 'sm:w-1/2 md:w-1/4';
	};

	const [plans] = useRest('Membership/@fanclub');
	const [loading, setLoading] = useState(!plans);
	const [planClass, setPlanClass] = useState((!plans || !plans.data) ? 'sm:w-1/2' : getPlanClass());
	const [{loggedIn}] = useContext(UserContext);

	useEffect(() => {
		setLoading(!plans);
		if (plans && plans.data)
			setPlanClass(getPlanClass());
	}, [plans, setLoading]);

	return (
		<SwitchTransition mode="out-in">
			<CSSTransition
				key={loading ? 'loading' : 'loaded-plans'}
				classNames="u-transition--lateral"
				addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
			>
				{loading ? (
					<div className="mt-6 p-6">
						<Loader/>
					</div>
				) : (
					<div className="flex flex-wrap items-stretch">
						{plans?.data?.Membership_Plan_Free?.length > 0 && (
							<Plan
								loggedIn={loggedIn}
								plan={Object.values(plans.data.Membership_Plan_Free)[0]}
								planClass={planClass}
								userSubscription={userSubscription ? userSubscription.data : null}
							/>
						)}
						{plans?.data?.Membership_Plan?.length > 0
							? (plans.data.Membership_Plan.map(p => (
								<Plan
									key={p.Membership_Plan__}
									plan={p}
									planClass={planClass}
									userSubscription={userSubscription ? userSubscription.data : null}
								/>)))
							: (
								<p className="w-full text-center text-2xl">{t('no_plans_yet')}</p>
							)
						}
					</div>
				)}
			</CSSTransition>
		</SwitchTransition>
	);
}

FanClubPlans.propTypes = {
	userSubscription: PropTypes.object,
};
