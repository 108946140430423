import React                                        from 'react';
import {BiTimer, CgScreen, ImManWoman, MdEventSeat} from 'react-icons/all';
import {useTranslation}                             from 'react-i18next';
import PropTypes                                    from 'prop-types';

import ReactTooltip from 'react-tooltip';

const TicketType = ({type, ...rest}) => {
	const {t} = useTranslation();

	React.useEffect(() => {
		ReactTooltip.rebuild();
	}, []);

	const getIcon = () => {
		switch (type) {
			case 'slot':
				return <BiTimer data-tip={t('event_type_timeslot')} {...rest}/>;
			case 'standing':
				return <ImManWoman data-tip={t('event_type_standing')} {...rest}/>;
			case 'stream':
				return <CgScreen data-tip={t('event_type_online')} {...rest}/>;
			case 'seat': 
			default:
				return <MdEventSeat data-tip={t('event_type_seating')} {...rest}/>;
		}
	};

	return getIcon();
};

export default TicketType;

TicketType.propTypes = {
	type: PropTypes.string.isRequired,
};
