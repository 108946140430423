import React     from 'react';
import PropTypes from 'prop-types';

import './WavyLine.css';

const WavyLine = ({ className = '' }) => {
	return <hr className={`pxl-hr ${className} overflow-hidden md:overflow-visible`} />;
};

WavyLine.propTypes = {
	className: PropTypes.string,
};

export default WavyLine;
