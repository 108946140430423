import PropTypes from 'prop-types';
import React     from 'react';
import CartItem  from './CartItem';

const CartItems = ({ items, refreshCart, readonly = false }) => {
	return (
		<tbody>
			{items.map(product => <CartItem
				key={product.key}
				cartItem={product}
				refreshCart={refreshCart}
				readonly={readonly}
			/>)}
		</tbody>
	);
};

export default CartItems;

CartItems.propTypes = {
	items: PropTypes.array.isRequired,
	readonly: PropTypes.bool,
	refreshCart: PropTypes.func.isRequired
};
