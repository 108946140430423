import React     from 'react';
import PropTypes from 'prop-types';

import Login from './Login';
import PopIn from '../../layout/PopIn/PopIn';

import { LoginContextContainer } from '@karpeleslab/klb-react-services';
export default function LoginModal({ loginCallback, open, setOpen = () => {}}) {
	return (
		<PopIn open={open} setOpen={setOpen}>
			<LoginContextContainer onValidated={loginCallback}>
				<Login/>
			</LoginContextContainer>
		</PopIn>
	);
}

LoginModal.propTypes = {
	loginCallback: PropTypes.func.isRequired,
	open         : PropTypes.bool,
	setOpen      : PropTypes.func
};