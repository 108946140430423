import moment from 'moment';

export const dateFormat = (platformDate, format) => {
	try {
		if (platformDate === 'now')
			return moment().format(format);

		const unixms = Number.isInteger(platformDate) ? platformDate : platformDate?.unixms;
		if (!unixms) return '';
		const parsed = parseInt(unixms);

		if (isNaN(parsed)) return '';
		return moment(parsed).format(format);
	} catch (e) {
		return '';
	}
};
