import React, { useContext }     from 'react';
import { useTranslation }        from 'react-i18next';
import { useParams }             from 'react-router-dom';
import { useRest }               from '@karpeleslab/react-klbfw-hooks';
import ModuleNotLoaded           from '../../components/core/utils/ModuleNotLoaded/ModuleNotLoaded';
import { Helmet }                from 'react-helmet';
import { UserContext }           from '../../context/UserContext';
import Loader                    from '../../components/layout/Loader/Loader';
import ReserveDetails            from '../../components/pxl/ReserveDetails/ReserveDetails';
import Hero                      from '../../components/layout/Hero/Hero';
import EventInformation          from '../../components/pxl/EventInformation/EventInformation';
import LoginModal from '../../components/user/Login/LoginModal';

export default function Reserve() {
	const { t } = useTranslation();
	const [{ loggedIn }, setUser] = useContext(UserContext);
	const { eventId } = useParams();

	const onValidated = data => setUser({ loggedIn: true, user: { data: data.user } });

	const [eventRes] = useRest(`Planner/Event/${eventId}`);
	const [tickets] = useRest(`Planner/Event/${eventId}/Seat/Reserve`, null, true);

	if (!eventRes || !tickets) {
		return <Loader/>;
	} else if (eventRes.result !== 'success') {
		return <ModuleNotLoaded/>;
	}

	const event = eventRes.data;
	let validTickets = [];
	if (tickets && tickets.data) {
		validTickets = tickets.data.filter((ticket) => ['reserve_paid', 'reserve_conf'].includes(ticket.Status));
	}
	return <>
		<Helmet>
			{ /* https://devhints.io/html-meta */}
			<title>{event.Name}</title>
			<meta property='og:title' content={event.Name}/>
			<meta name='twitter:title' content={event.Name}/>
		</Helmet>
		<Hero title={t('reserve_title')}/>
		<main className='w-full max-w-4xl my-0 mx-auto mb-auto'>
			<p className={'my-5 mx-3 md:m-5 text-2xl pxl-display-font'}>{event.Schedule.Content_Cms_Entry_Data.Title}</p>
			<p className={'my-5 mx-3 md:m-5'}>{event.Schedule.Content_Cms_Entry_Data.Short_Contents}</p>
			<div className={'my-3 mx-5 md:m-5'}>
				<EventInformation event={event}/>
			</div>
			{loggedIn ? (
				<ReserveDetails event={event} tickets={validTickets}/>
			) : (
				<p>{t('login_to_continue')}</p>
			)}
			{!loggedIn && <LoginModal loginCallback={onValidated}/>}
		</main>
	</>;
}
