import React, {useState}                              from 'react';
import PropTypes                                      from 'prop-types';
import './segment.css';
import {useMassMailSubscribe, useMassMailUnsubscribe} from '../../hooks/useMassMail';

const Segment = ({id, name, subscribed}) => {
	const [sub, setSub] = useState(subscribed);
	const [subscribe] = useMassMailSubscribe();
	const [unsubscribe] = useMassMailUnsubscribe();

	const handleSubChange = (isSub) => {
		setSub(isSub);
		if (isSub) {
			subscribe(id);
		} else {
			unsubscribe(id);
		}
	};

	return (
		<div className="flex w-full mb-6">

			<label htmlFor={id} className="flex items-center cursor-pointer">
				<div className="relative">
					<input
						type="checkbox"
						id={id}
						className="sr-only segment"
						checked={sub}
						onChange={e => handleSubChange(e.target.checked)}
					/>
					<div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
					<div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
				</div>
				<div className="ml-3 font-medium">
					{name}
				</div>
			</label>

		</div>
	);
};

export default Segment;

Segment.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	subscribed: PropTypes.bool.isRequired,
};