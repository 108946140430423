import React               from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation }  from 'react-i18next';

import Btn          from '../../components/core/inputs/Btn/Btn';
import LayoutHelmet from '../../components/layout/LayoutHelmet/LayoutHelmet';

const CheckoutConfirmed = () => {
	const { type, orderId } = useParams();
	const { t }             = useTranslation();

	return (
		<main className="flex flex-col items-center w-full max-w-sm my-0 mx-auto p-6 bg-white rounded-3xl shadow-2xl">
			<LayoutHelmet title={t('checkout_confirmed_title')} />
			<h1 className="pb-8 text-3xl text-center">{t('checkout_confirmed_title')}</h1>
			{type === 'order' && orderId && (
				<h2 className="pb-4 text-xl text-center">{t('checkout_order_id', { id: orderId })}</h2>
			)}
			<p className="pb-4">{t('checkout_confirmed_description')}</p>
			<Link
				component={Btn}
				variant="primary"
				to="/user"
				aria-label={t('go_to_profile')}
			>
				{t('go_to_profile')}
			</Link>
		</main>
	);
};

export default CheckoutConfirmed;