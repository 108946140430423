import PropTypes                                   from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import OrderProcessingAlert                        from './OrderProcessingAlert';
import OrderTable                                  from './OrderTable';
import OrderPayments                               from './Payments/OrderPayments';
import './order.css';
import Alert                                       from '../../layout/Alert/Alert';
import { Trans }                                   from 'react-i18next';
import OrderFileDeliverables                       from './OrderFileDeliverables';

const OrderWithPayment = ({ order, refreshOrder, onPaymentComplete = undefined }) => {
	const [files, setFiles] = useState((order.Items ?? []).map(i => i.Deliverable?.Files ?? []).flat(1));

	const detectComplete = useCallback(newOrder => {
		if (order.Status !== newOrder.Status &&
			newOrder.Status === 'completed' &&
			onPaymentComplete) {
			onPaymentComplete(newOrder);
		}

		refreshOrder(newOrder);
	}, [order]);

	useEffect(() => {
		setFiles((order.Items ?? []).map(i => i.Deliverable?.Files ?? []).flat(1));
	}, [order]);

	return (
		<div className='flex flex-wrap overflow-hidden sm:-mx-3'>
			{(order.Paid && order.Status !== 'completed') &&
			<div className='w-full overflow-hidden sm:my-3 sm:px-3 border-2 border-transparent'>
				<OrderProcessingAlert refresh={refreshOrder}/>
			</div>
			}

			{(order.Status === 'completed') &&
			<div className='w-full overflow-hidden sm:my-3 sm:px-3 border-2 border-transparent'>
				<Alert type='success'>
					<p>
						<Trans i18nKey='order_completed'>
							sample<br/>
							<strong>sample</strong>
						</Trans>
					</p>
				</Alert>
			</div>
			}

			<div className='w-full overflow-hidden sm:my-3 sm:px-3'>
				<OrderTable order={order}/>
			</div>

			{(order.Status === 'completed' && files.length > 0) &&
			<div className='w-full overflow-hidden sm:my-3 sm:px-3'>
				<OrderFileDeliverables files={files}/>
			</div>
			}

			{!order.Paid &&
			<div className='w-full sm:my-3 sm:px-3'>
				<OrderPayments refresh={detectComplete} order={order}/>
			</div>
			}
		</div>
	);
};

export default OrderWithPayment;

OrderWithPayment.propTypes = {
	onPaymentComplete: PropTypes.func,
	order: PropTypes.object.isRequired,
	refreshOrder: PropTypes.func.isRequired
};
