import React              from 'react';
import { useTranslation } from 'react-i18next';
import { Get }            from '@karpeleslab/klbfw';
import moment             from 'moment';

import EmailLayout from '../../../components/email/EmailLayout';

const Html = () => {
	const { t } = useTranslation();

	return (
		<EmailLayout
			intro={t('login_notification_title')}
			paragraphs={[
				t('login_notification_text'),
				t('login_notification_name', { username: Get('_usr').Email }),
				t('login_notification_date', {
					year : moment().format('YYYY'),
					month: moment().format('MM'),
					day  : moment().format('DD'),
					time : `${moment().format('HH:mm:ss')} UTC`
				}),
				'&nbsp;'
			]}
			showContactBtn={true}
		/>
	);
};

export default Html;
