import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getHostname }    from '@karpeleslab/klbfw';

const Text = ({ code, username, website }) => {
	const { t } = useTranslation();

	return (
		<textarea
			id="mail-text"
			defaultValue={
				t('user_registration_confirm_description', {
					username,
					website
				}) +
				'\n\r' +
				code +
				'\n\r' +
				t('user_registration_confirm_contact_us') +
				'\n\r' +
				`https://${website}/contact` +
				'\n\r' +
				t('user_password_email_footer', { website: getHostname() })
			}
		>
		</textarea>
	);
};

Text.propTypes = {
	code    : PropTypes.string,
	username: PropTypes.string,
	website : PropTypes.string
};

export default Text;
