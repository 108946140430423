import React, { createContext, useState } from 'react';
import PropTypes                         from 'prop-types';

const defaultMenuContext = {
	main          : false,
	music         : false,
	socialNetworks: false
};

export const MenuContext = createContext(defaultMenuContext);

const MenuContextContainer = ({ children }) => {
	const [menuState, setMenuState] = useState(defaultMenuContext);

	return (
		<MenuContext.Provider value={[menuState, setMenuState]}>
			{children}
		</MenuContext.Provider>
	);
};

MenuContextContainer.propTypes = {
	children: PropTypes.node
};

export default MenuContextContainer;
