import React, { useContext, useEffect, useState } from 'react';
import { useTranslation }                         from 'react-i18next';
import PropTypes                                  from 'prop-types';
import { rest }                                   from '@karpeleslab/klbfw';

import AddBillingAddress from '../AddBillingAddress/AddBillingAddress';
import AddressCard       from '../AddressCard/AddressCard';
import Btn               from '../../core/inputs/Btn/Btn';
import LocationList      from '../LocationList/LocationList';
import PopIn             from '../../layout/PopIn/PopIn';

import { UserContext } from '../../../context/UserContext';
import Alert from '../../layout/Alert/Alert';

const Billing = ({ onLocationSelected, fit = false }) => {
	const { t }           = useTranslation();
	const [user, setUser] = useContext(UserContext); 

	const [addAddressOpen, setAddAddressOpen]       = useState(false);
	const [changeAddressOpen, setChangeAddressOpen] = useState(false);
	const [currentLocation, setCurrentLocation]     = useState(null);

	const onAddressAdded = data => {
		setAddAddressOpen(false);
		setChangeAddressOpen(false);

		if (data && data.User_Location__) {
			const tmp = { ...user };
			tmp.user.data.Default_Billing_User_Location__ = data.User_Location__;
			setUser(tmp);
			setCurrentLocation(data);
		}
	};

	const setLocation = async User_Location__ => {
		const { data } = await rest(`User/Location/${User_Location__}`);
		setCurrentLocation(data);
	};

	useEffect(() => {
		if (user && user.user && user.user.data && user.user.data.Default_Billing_User_Location__) 
			setLocation(user.user.data.Default_Billing_User_Location__);
	}, [user]);

	useEffect(() => {
		onLocationSelected(currentLocation);
	}, [currentLocation]);

	const wrapperFitClass = fit ? 'w-full' : '';
	const childFitClass = fit ? 'flex-1' : '';

	return (
		<div className="flex flex-col items-center md:items-start">
			<div className={`flex flex-col px-2 ${wrapperFitClass}`}>
				{currentLocation ? (
					<AddressCard className={childFitClass} address={currentLocation} />
				) : (
					<Alert type={'warning'} className={`my-8 ${childFitClass}`}>
						{t('provide_address')}
					</Alert>
				)}
				<div className="flex flex-wrap justify-between py-4 md:py-0">
					<Btn
						variant="primary"
						className="flex-none my-2 sm:my-0 sm:mr-2"
						size="xs"
						onClick={() => setAddAddressOpen(true)}
						accessibility={t('add_an_address')}
					>
						{t('add_an_address')}
					</Btn>
					{user && user.user && user.user.data && user.user.data.Default_Billing_User_Location__ && <Btn
						className="flex-none my-2 sm:my-0 sm:ml-2"
						size="xs"
						onClick={() => setChangeAddressOpen(true)}
						accessibility={t('choose_another_address')}
					>
						{t('choose_another_address')}
					</Btn>}
				</div>
			</div>
			<PopIn open={addAddressOpen} setOpen={setAddAddressOpen}>
				<AddBillingAddress
					onComplete={onAddressAdded}
					onCancel={() => setAddAddressOpen(false)}
				/>
			</PopIn>
			<PopIn open={changeAddressOpen} setOpen={setChangeAddressOpen}>
				<LocationList
					onAddressChanged={onAddressAdded}
					onClose={() => setChangeAddressOpen(false)}
				/>
			</PopIn>
		</div>
	);
};

Billing.propTypes = {
	onLocationSelected: PropTypes.func,
	fit: PropTypes.bool,
};

export default Billing;
