import React from 'react';

import {
	EmailShareButton,
	FacebookShareButton,
	LineShareButton,
	RedditShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	EmailIcon,
	FacebookIcon,
	LineIcon,
	RedditIcon,
	TwitterIcon,
	WhatsappIcon } from 'react-share';

export default function ShareButtons () {
	const url = window ? window.location.href : '';

	return (
		<div className="u-py-md">
			<FacebookShareButton 
				className="u-mr-sm mx-1"
				url={ url }
			>
				<FacebookIcon size={32} round={true} />
			</FacebookShareButton>
			<TwitterShareButton 
				className="u-mx-sm mx-1"
				url={ url }
			>
				<TwitterIcon size={32} round={true} />
			</TwitterShareButton>
			<RedditShareButton 
				className="u-mx-sm mx-1"
				url={ url }
			>
				<RedditIcon size={32} round={true} />
			</RedditShareButton>
			<WhatsappShareButton 
				className="u-mx-sm mx-1"
				url={ url }
			>
				<WhatsappIcon size={32} round={true} />
			</WhatsappShareButton>		
			<LineShareButton 
				className="u-mx-sm mx-1"
				url={ url }
			>
				<LineIcon size={32} round={true} />
			</LineShareButton>   
			<EmailShareButton 
				className="u-ml-sm mx-1"
				url={ url }
			>
				<EmailIcon size={32} round={true} />
			</EmailShareButton>		 
		</div>
	);
}
