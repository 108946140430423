import React     from 'react';
import PropTypes from 'prop-types';

const AddressCard = ({ address, className = '', onClick = () => {} }) => {
	return (
		<div
			className={`${className} px-2 py-4`}
			onClick={() => onClick(address)}
		>
			{address.Display.map((d, idx) =>
				<p key={`${address.User_Location__}-${idx}`} className="text-md">{d}</p>
			)}
			
		</div>
	);
};

AddressCard.propTypes = {
	address: PropTypes.shape({
		User_Location__      : PropTypes.string,
		Display      : PropTypes.array,
		Address      : PropTypes.string,
		City         : PropTypes.string,
		Contact_Phone: PropTypes.string,
		Country__    : PropTypes.string,
		First_Name   : PropTypes.string,
		Last_Name    : PropTypes.string,
		Province     : PropTypes.string,
		Zip          : PropTypes.string,
	}).isRequired,
	className: PropTypes.string,
	onClick  : PropTypes.func
};

export default AddressCard;
