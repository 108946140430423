import React, { useContext } from 'react';

import Btn from '../../core/inputs/Btn/Btn';

import { LoginContext } from '@karpeleslab/klb-react-services';

import './OauthBtnsBuilder.css';

const OauthBtnsBuilder = () => {
	const { oauthFields, onOauthClicked } = useContext(LoginContext);
	if (!oauthFields)
		return <></>;

	return (
		<div className="flex justify-center items-center">
			{oauthFields.map(field => (
				<Btn
					key={field.id}
					type="button"
					size="special"
					onClick={() => onOauthClicked(field.id)}
					alt={field.info.Name}
					className={`oauth-btn overflow-hidden cursor-pointer ${field.info.Token_Name}`}
					style={{
						backgroundColor: field.button['background-color']
					}}
				>
					<div
						className="w-10 h-10 m-2 bg-scroll bg-center bg-no-repeat"
						style={{
							backgroundImage: `url(${field.button['logo']})`,
							backgroundSize : 'contain'
						}}
					></div>
				</Btn>
			))}
		</div>
	);
};

export default OauthBtnsBuilder;
