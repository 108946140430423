import React, { useContext, useEffect } from 'react';
import { Switch, useLocation }          from 'react-router-dom';

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Loader                              from '../layout/Loader/Loader';
import NotFound                            from '../core/utils/NotFound/NotFound';
import RouteTransition                     from './RouteTransition';

import routeDefinitions    from './routeDefinitions';
import { TemplateContext } from '../../context/TemplateContext';

const RouterController = () => {
	const location = useLocation();
	const settings = useContext(TemplateContext);

	useEffect(() => {
		if (location.hash === '') 
			window.scrollTo(0, 0);
		else {
			setTimeout(() => {
				const id = location.hash.replace('#', '');
				const element = document.getElementById(id);
				if (element)
					element.scrollIntoView();
			}, 150);
		}
	}, [location.pathname, location.hash]);

	return (
		<SwitchTransition>
			<CSSTransition
				key={settings && settings.artist ? 'loaded' : 'loading'}
				addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
				classNames='u-transition--vertical'
			>
				{settings && settings.artist ? (
					<Switch location={location}>
						{routeDefinitions(settings).map((route, index) => (
							<RouteTransition
								key={`route-${index}`}
								path={route.path}
								requiresLogin={route.loginRequired}
								exact
							>
								{route.component}
							</RouteTransition>
						))}
						<RouteTransition path='*'>
							<NotFound/>
						</RouteTransition>
					</Switch>
				) : (
					<div className='c-loader__fullpage-wrapper'>
						<Loader/>
					</div>
				)}
			</CSSTransition>
		</SwitchTransition>
	);
};

export default RouterController;
