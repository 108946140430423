import React, { useEffect, useState } from 'react';
import PropTypes                      from 'prop-types';
import { useTranslation }             from 'react-i18next';

const SubscriptionCheckoutSummary = ({ plan }) => {
	const { t }                   = useTranslation();
	const [lifetime, setLifetime] = useState('');

	useEffect(() => {
		if (!plan || !plan['Basic.ServiceLifetime']) return;

		switch (plan['Basic.ServiceLifetime']) {
			case '1m':
				setLifetime(t('monthly'));
				break;
			case '1y':
				setLifetime(t('yearly'));
				break;
			default:
				break;
		}
	}, [plan]);

	return (
		<table className="w-full table-auto">
			<thead>
				<tr>
					<th className="px-8 py-4 border text-center" colSpan="2">
						{plan['Basic.Name']}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="px-8 py-4 border">{t('billed')}</td>
					<td className="px-8 py-4 border text-right">{lifetime}</td>
				</tr>
				<tr>
					<td className="px-8 py-4 border">{t('price')}</td>
					<td className="px-8 py-4 border text-right">{plan.Price.raw.display}</td>
				</tr>
				<tr>
					<td className="px-8 py-4 border">{t('vat')}</td>
					<td className="px-8 py-4 border text-right">{plan.Price.tax_only.display}</td>
				</tr>
				<tr className="uppercase font-bold">
					<td className="px-8 py-4 border">{t('total')}</td>
					<td className="px-8 py-4 border text-right">{plan.Price.display}</td>
				</tr>
			</tbody>
		</table>
	);
};

SubscriptionCheckoutSummary.propTypes = {
	plan: PropTypes.shape({
		['Basic.Name']           : PropTypes.string.isRequired,
		['Basic.ServiceLifetime']: PropTypes.string.isRequired,
		Price                    : PropTypes.shape({
			currency: PropTypes.string.isRequired,
			display : PropTypes.string.isRequired,
			raw     : PropTypes.shape({
				display   : PropTypes.string.isRequired,
				value_disp: PropTypes.string.isRequired
			}).isRequired,
			tax_only: PropTypes.shape({
				display   : PropTypes.string.isRequired,
				value_disp: PropTypes.string.isRequired
			}).isRequired,
			value_disp: PropTypes.string.isRequired
		}).isRequired,
	})
};

export default SubscriptionCheckoutSummary;