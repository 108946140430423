import React              from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes          from 'prop-types';

const Text = ({ formattedDateTime, website }) => {
	const { t } = useTranslation();

	return (
		<textarea
			id="mail-text"
			defaultValue={
				t('user_password_mail_updated_notice', {
					datetime: formattedDateTime,
					website
				}) +
				'\n\r' +
				t('user_password_mail_updated_warning') + 
				'\n\r' + 
				`https://${website}/contact`
			}
		>
		</textarea>
	);
};

Text.propTypes = {
	formattedDateTime: PropTypes.string, 
	website          : PropTypes.string
};

export default Text;
