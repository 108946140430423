import PropTypes          from 'prop-types';
import React              from 'react';
import { useTranslation } from 'react-i18next';
import Alert              from '../../layout/Alert/Alert';
import { getLocale }      from '@karpeleslab/klbfw';
import Redirect           from '../../core/utils/Redirect/Redirect';

const ArticleLangWarning = ({ entry, alternatives = [], target = 'news' }) => {
	const { t } = useTranslation();

	const lang = entry.Language__;

	const validAlternative = alternatives.filter(a => a.Language__ === getLocale());

	if (validAlternative.length > 0) {
		return (<Redirect target={`/${target}/${validAlternative[0].Key}`}/>);
	}

	return (
		<div className='my-4 w-full'>
			<Alert type={'warning'} className='text-center'>{t('article_lang_unavailable', { lang: lang })}</Alert>
		</div>
	);
};

export default ArticleLangWarning;

ArticleLangWarning.propTypes = {
	alternatives: PropTypes.array.isRequired,
	entry: PropTypes.object.isRequired,
	target: PropTypes.string,
};
