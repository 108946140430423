import React              from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes          from 'prop-types';

import EmailLayout from '../../../components/email/EmailLayout';

const Html = ({ formattedDateTime, website }) => {
	const { t } = useTranslation();

	return (
		<EmailLayout
			intro={t('mail_pwd_recovered_subject', { website })}
			paragraphs={[
				t('user_password_mail_updated_notice', {
					datetime: formattedDateTime,
					website
				}),
				t('user_password_mail_updated_warning')
			]}
			showContactBtn={true}
		/>
	);
};

Html.propTypes = {
	formattedDateTime: PropTypes.string, 
	website          : PropTypes.string
};

export default Html;
