import React, {useContext, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRest} from '@karpeleslab/react-klbfw-hooks';

import AlbumCarousel from '../../components/pxl/AlbumCarousel/AlbumCarousel';
import AlbumDetails from '../../components/pxl/AlbumDetails/AlbumDetails';
import AlbumInList from '../../components/pxl/AlbumInList/AlbumInList';
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import LayoutHelmet from '../../components/layout/LayoutHelmet/LayoutHelmet';

import {MusicContext} from '../../context/MusicContext';
import {useParams} from 'react-router-dom';

const imageVariation = 'strip&scale_crop=300x300';

export default function Discography() {
	const {t} = useTranslation();
	const [discography] = useRest('Music/Label/Release', {
		image_variation: imageVariation,
		Music_Label_Artist__: '@artist',
		Role: 'artist'
	});

	const {id} = useParams();

	const {setPlaylist, playing, togglePlay} = useContext(MusicContext);


	// This complexity is important for SSR only
	// We first check the discography list is loaded
	// The if ze have an ID (/discography/release/:id) we try to select the right album in the discography if not found we select null
	// If we don t have an ID we select the first album in the disco
	// If the discography data are not loaded we select null
	const [currentAlbum, setCurrentAlbum] = useState(
		(discography && discography.data) ?
			(id ? (discography.data.find(a => a.Music_Label_Album__ === id) ?? null) : discography.data[0])
			: null
	);
	const detailsRef = useRef(null);

	const playPauseAlbum = () => {
		if (playing) {
			togglePlay();
			return;
		}
		if (!detailsRef?.current) return;
		const details = detailsRef.current.getDetails();
		if (!details || !details.Tracks || details.Tracks.length <= 0) return;
		const items = [];
		details.Tracks.forEach((t) => {
			const hasPreview = !('no_preview' in t.Music_Label_Record.Flags);
			if(!hasPreview) return;
			items.push({
				track: t, album: details
			});
		});

		setPlaylist(items, 0);
	};

	const title = currentAlbum && currentAlbum.Music_Label_Album ? currentAlbum.Music_Label_Album.Name : t('discography');
	return (
		<>
			<LayoutHelmet
				title={title}
				description={(currentAlbum && currentAlbum.Music_Label_Album) && currentAlbum.Music_Label_Album.Description}
				image={
					currentAlbum?.Music_Label_Album?.Drive_Item && (
						currentAlbum.Music_Label_Album.Drive_Item.Media_Image[imageVariation] ||
						currentAlbum.Music_Label_Album.Drive_Item.View_Url
					)
				}
			/>
			<main className="w-full max-w-7xl mt-24 mx-auto">
				<div className="w-full max-w-7xl my-0 mx-auto flex flex-col justify-center">
					<div className="mt-6 h-80">
						<AlbumCarousel
							discography={discography ? discography.data : null}
							imageVariation={imageVariation}
							onAlbumClicked={playPauseAlbum}
							onAlbumSelected={setCurrentAlbum}
						/>
					</div>
					<div className="mt-6 w-full flex justify-center items-center">
						{currentAlbum && (
							<SwitchTransition mode="out-in">
								<CSSTransition
									key={currentAlbum.Music_Label_Album__}
									classNames="u-transition--lateral"
									addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
								>
									<AlbumInList
										key={currentAlbum.Music_Label_Album__}
										album={currentAlbum}
										imageVariation={imageVariation}
									/>
								</CSSTransition>
							</SwitchTransition>
						)}
					</div>
					<SwitchTransition mode="out-in">
						<CSSTransition
							key={currentAlbum ? 'loaded' : 'loading'}
							classNames="u-transition--vertical"
							addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
						>
							{currentAlbum ? (
								<AlbumDetails
									ref={detailsRef}
									albumId={currentAlbum.Music_Label_Album__}
								/>
							) : <div/>}
						</CSSTransition>
					</SwitchTransition>
				</div>
			</main>
		</>
	);
}
