
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation }                         from 'react-i18next';

import TopBar     from '../TopBar/TopBar';
import MobileMenu from '../MobileMenu/MobileMenu';

import { MenuContext }                 from '../../../context/MenuContext';
import { TemplateContext }             from '../../../context/TemplateContext';
import { UserContext }                 from '../../../context/UserContext';
import { useTabletAndBelowMediaQuery } from '../../../hooks/useMediaQueries';

export default function MainNavigation () {
	const settings           = useContext(TemplateContext);
	const [menu, setMenu]    = useContext(MenuContext);
	const { t }              = useTranslation();
	const isMobile           = useTabletAndBelowMediaQuery();
	const [{ subscription }] = useContext(UserContext);

	const [anyMenuOpen, setAnyMenuOpen] = useState(menu.main || menu.music || menu.socialNetworks);

	const closeAnyMenu = () => setMenu({
		main          : false,
		music         : false, 
		socialNetworks: false
	});

	const buildPages = () => {
		const p = [];

		if (!settings) return p;

		if (settings.releases_visible === 'Y')
			p.push({ name: t('discography'), route: '/discography' });

		if (settings.news_visible === 'Y')
			p.push({ name: t('news'), route: '/news' });

		if (settings.schedule_visible === 'Y')
			p.push({ name: t('schedule'), route: '/events' });

		if (settings.fanclub_visible === 'Y') {
			if (subscription)
				p.push({ name: t('fanclub'), route: '/fanclub/articles' });
			else
				p.push({ name: t('fanclub'), route: '/fanclub' });

		}

		if (settings.shop_visible === 'Y')
			p.push({ name: t('shop_title'), route: '/shop' });

		p.push({ name: t('contact'), route: '/contact' });

		return p;
	};

	// Put it here because we need the func to be initialized first
	const [pages, setPages]  = useState(buildPages());

	useEffect(() => {
		if (!window || !document)
			return; 

		document.body.classList.toggle('overflow-hidden', anyMenuOpen);
	}, []);

	useEffect(() => {
		setAnyMenuOpen(menu.main || menu.music || menu.socialNetworks);
	}, [menu]);

	useEffect(() => {
		setPages(buildPages());
	}, [settings, setPages]);

	useEffect(() => {
		if (!subscription) return;

		const fanclubIdx = pages.findIndex(p => p.name === t('fanclub'));
		const tmp        = [...pages];

		if (fanclubIdx >= 0) 
			tmp[fanclubIdx].route = '/fanclub/articles';

		setPages(tmp);
	}, [subscription]);

	return (
		<>
			<TopBar pages={pages} sticky={true} />
			{(isMobile && !anyMenuOpen) && (
				<MobileMenu pages={pages} onClose={closeAnyMenu} />
			)}
		</>
	);
}
