	
import React, {createContext, useState, memo, useContext, useEffect} from 'react';
import PropTypes                                                     from 'prop-types';
import {RestContext}                                                 from '@karpeleslab/klb-react-services';
import {useTranslation}                                              from 'react-i18next';
 
export const SnackbarValueContext = createContext({});
export const SnackbarSetContext	  = createContext(() => {});
 
function SnackbarProviderMemo ({ setSnackbar, children }) {
	const handleSnackbarSet = (message, type = 'default') => {
		setSnackbar({ message, type });
	};
	
	return (
		<SnackbarSetContext.Provider value={ handleSnackbarSet }>
			{ children }
		</SnackbarSetContext.Provider>
	);
}
SnackbarProviderMemo.propTypes = {
	children   : PropTypes.node,
	setSnackbar: PropTypes.func
};

const SnackbarProvider = memo(SnackbarProviderMemo);
 
export const SnackbarContextContainer = ({ children }) => {
	const [snackbar, setSnackbar] = useState({
		message : '',
		type	: 'default'
	});

	const {t} = useTranslation();
	const { setSnackMessageCallback } = useContext(RestContext);

	useEffect(() => {
		setSnackMessageCallback((message, type, i18n) => {
			setSnackbar({ message: i18n ? t(message) : message, type });
		});
	}, [setSnackbar, setSnackMessageCallback]);


	return (
		<SnackbarValueContext.Provider value={ snackbar }>
			<SnackbarProvider setSnackbar={ setSnackbar }>
				{ children }
			</SnackbarProvider>
		</SnackbarValueContext.Provider>
	);
};

SnackbarContextContainer.propTypes = {
	children: PropTypes.node
};
