import React              from 'react';
import { useTranslation } from 'react-i18next';
import { getHostname }    from '@karpeleslab/klbfw';

const EmailFooter = () => {
	const { t } = useTranslation();

	return (
		<div
			className="footer"
			style={{
				clear: 'both',
				marginTop: '10px',
				textAlign: 'center',
				width: '100%'
			}}
		>
			<table
				border="0"
				cellPadding="0"
				cellSpacing="0"
				style={{
					borderCollapse: 'separate',
					msoTableLspace: '0pt',
					msoTableRspace: '0pt',
					width: '100%'
				}}
			>
				<tbody>
					<tr>
						<td
							className="content-block"
							style={{
								fontFamily: 'sans-serif',
								verticalAlign: 'top',
								paddingBottom: '10px',
								paddingTop: '10px',
								fontSize: '12px',
								color: '#cccccc',
								textAlign: 'center'
							}}
						>
							<span
								className="apple-link"
								style={{
									color: '#cccccc',
									fontSize: '12px',
									textAlign: 'center'
								}}
							>
								{t('automatic_message')}
							</span>
							<br />
							{(new Date()).getFullYear()} © &nbsp;
							<a
								href={`https://${getHostname()}/`}
								target="_blank"
								rel="noopener noreferrer"
								style={{
									textDecoration: 'underline',
									color: '#f7a043',
									fontSize: '12px',
									textAlign: 'center'
								}}
								aria-label={'PXL.jp'}
							>
								{getHostname()}
							</a>
							.
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default EmailFooter;
