import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation }    from 'react-router-dom';

import Btn from '../../core/inputs/Btn/Btn';

import './Plan.css';

const Plan = ({ loggedIn, plan, planClass = 'sm:w-1/2', userSubscription }) => {
	const { t }    = useTranslation();
	const location = useLocation();

	const generateBtn = () => {
		if (plan?.Free === 'Y') {
			if (loggedIn)
				return <p className="py-3 italic">{t('already_signed_in')}</p>;
			else {
				return (
					<Btn
						to={`/login?return_to=${location.pathname}`}
						variant="primary"
						accessibility={t('sign_up_to_join')}
					>
						{t('sign_up_to_join')}
					</Btn>
				);
			}
		} else {
			if (userSubscription &&
				userSubscription.Membership_Plan__ === plan?.Membership_Plan__ &&
				userSubscription.Status === 'paid'
			)
				return (
					<Btn
						disabled
						accessibility={t('current_plan')}
					>
						{t('current_plan')}
					</Btn>
				);
			else {
				return (
					<Btn
						to={`/fanclub/join/${plan?.Membership_Plan__}`}
						variant="primary"
						accessibility={t(
							userSubscription &&
							userSubscription.Membership_Plan__ === plan?.Membership_Plan__ &&
							userSubscription.Status === 'inactive'
								? 'renew_now'
								: 'subscribe_now'
						)}
					>
						{t(
							userSubscription &&
							userSubscription.Membership_Plan__ === plan?.Membership_Plan__ &&
							userSubscription.Status === 'inactive'
								? 'renew_now'
								: 'subscribe_now'
						)}
					</Btn>
				);
			}
		}
	};

	const generatePrice = () => {
		if (plan?.Free === 'Y')
			return t('free');
		else
			return plan?.Catalog_Product?.Price?.display + t(`period_${plan?.Period}_short`);
	};

	return (
		<div
			className={`w-full ${planClass} flex items-stretch my-6 md:mt-0`}
			style={{ justifyContent: 'stretch' }}
		>
			<div className="pxl-fanclub-plan relative w-full h-full flex flex-col justify-between items-center m-6 p-4 border border-white">
				<p
					className={`text-3xl font-bold mt-4 pxl-display-font ${plan?.Color ? 'border-b-2' : ''}`}
					style={plan?.Color ? {borderColor:`#${plan?.Color}`}: {}}
				>
					{plan?.Catalog_Product?.['Basic.Name']}
				</p>
				<p className="text-sm">
					{plan?.Catalog_Product?.['Description.Short']}
				</p>
				<div>
					<p className="text-2xl text-center pb-6">
						{generatePrice()}
					</p>
					{generateBtn()}
				</div>
			</div>
		</div>
	);
};

Plan.propTypes = {
	loggedIn: PropTypes.bool,
	plan    : PropTypes.shape({
		Catalog_Product: PropTypes.shape({
			'Basic.Name'       : PropTypes.string,
			'Description.Short': PropTypes.string,
			Price              : PropTypes.shape({
				display: PropTypes.string
			})
		}),
		Free             : PropTypes.oneOf(['Y', 'N']),
		Membership_Plan__: PropTypes.string,
		Color            : PropTypes.string,
		Period           : PropTypes.string
	}).isRequired,
	planClass       : PropTypes.string,
	userSubscription: PropTypes.shape({
		Membership_Plan__: PropTypes.string,
		Status           : PropTypes.string
	})
};

export default Plan;
