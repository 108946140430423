import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getPrefix }      from '@karpeleslab/klbfw';

import Btn                               from '../../core/inputs/Btn/Btn';
import CartButton                        from '../../pxl/CartButton/CartButton';
import { FiMenu, FiUser, FiX }           from 'react-icons/fi';
import { Link, useLocation, useHistory } from 'react-router-dom';
import NoSsr                             from '../../core/utils/NoSsr/NoSsr';
import UserDropDown                      from './UserDropDown';

import { MenuContext }     from '../../../context/MenuContext';
import { TemplateContext } from '../../../context/TemplateContext';
import { UserContext }     from '../../../context/UserContext';

import { useTabletAndBelowMediaQuery } from '../../../hooks/useMediaQueries';

import './TopBar.css';
import {allowPageScroll, noPageScroll} from '../../../utils/misc';

const LOGO_VAR = 'background_color=transparent&format=png&scale=0x80';

const TopBar = ({pages, sticky = false}) => {
	const { t }           = useTranslation();
	const isMobile        = useTabletAndBelowMediaQuery();
	const currentLocation = useLocation();
	const history         = useHistory();

	const [menu, setMenu]      = useContext(MenuContext);
	const {artist, cover, shop_visible = 'N' }     = useContext(TemplateContext);
	const [{ loggedIn, user }] = useContext(UserContext);

	const [logo, setLogo]                 = useState((artist && artist.Image_Drive_Item) && (artist.Image_Drive_Item?.Media_Image?.[LOGO_VAR] ?? artist.Image_Drive_Item.Icon));
	const [currentRoute, setCurrentRoute] = useState('');

	const userDropdownRef = useRef(null);

	const initStickiness = () => {
		const elem = document.querySelector('#js-top-bar');
		if (!elem) return;

		if (window.scrollY > 0) {
			elem.classList.add('fixed');
			elem.classList.remove('absolute', 'pt-12');
		} else {
			elem.classList.add('absolute', 'pt-12');
			elem.classList.remove('fixed');
		}
	};

	const refreshIfLoginPage = (e, link) => {
		e.preventDefault();
		if (currentLocation.pathname === '/login')
			window.location.reload();
		else
			history.push(link);
	};


	useEffect(() => {
		if (!artist) return;
		else if (artist.Image_Drive_Item)
			setLogo((artist.Image_Drive_Item?.Media_Image?.[LOGO_VAR] ?? artist.Image_Drive_Item.Icon));
	}, [artist]);

	useEffect(() => {
		const route = currentLocation.pathname.replace(getPrefix(), '');
		const elem  = document.querySelector('#js-top-bar');

		if (route === '/' && cover?.Url && elem) {
			elem.classList.add('gradient-fixed');
		} else if (elem)
			elem.classList.remove('gradient-fixed');
		
		setCurrentRoute(route);
	}, [currentLocation.pathname]);

	useEffect(() => {
		if (typeof window === 'undefined') return;

		if (sticky) 
			document.addEventListener('scroll', initStickiness);
	}, []);

	const getMenuItem = ({ name, route }) => {
		if (!isMobile) {
			return (
				<Btn
					to={route}
					variant="discrete"
					className={
						`mx-2${
							currentRoute !== '/' && currentRoute.startsWith(route)
								? ' discrete-bordered'
								: ''
						}`
					}
					accessibility={name}
				>
					{name}
				</Btn>
			);
		}

		return <></>;
	};

	const onOpenUserDropdown = e => {
		e.stopPropagation();
		userDropdownRef.current.open();
	};

	const openMobileMenu = () => {
		if (menu) {
			noPageScroll();
		} else {
			allowPageScroll();
		}
		setMenu(v => !v);
	};

	return (
		<>
			<nav
				id={isMobile ? 'js-top-bar' : ''}
				className="absolute w-full h-16 py-2 pt-12 flex justify-between items-center bg-transparent transition-all z-30"
			>
				<div className="relative h-full p-2 flex items-center transition-all text-lg">
					<Link to="/" aria-label={t('acc_home_page')}>
						<img src={logo} alt="logo" style={{ height: '44px' }} />
					</Link>
					{pages.map((page, index) => (
						<React.Fragment key={page.route}>
							{getMenuItem(page, index)}
						</React.Fragment>
					))}
				</div>
				<div className="top-bar-right h-full relative p-2 flex items-center transition-all text-lg">
					{shop_visible === 'Y' && <NoSsr>
						<CartButton />
					</NoSsr>}
					{!loggedIn || !user || !user.data ? (
						!isMobile ? (
							<>
								<Btn
									forceComponent="a"
									to={`/login?return_to=${encodeURIComponent(currentLocation.pathname)}`}
									variant="discrete"
									className="mx-2"
									onClick={e => refreshIfLoginPage(e, `/login?return_to=${encodeURIComponent(currentLocation.pathname)}`)}
									accessibility={t('sign_in')}
								>
									{t('sign_in')}
								</Btn>
								<Btn
									forceComponent="a"
									to={`/login?return_to=${encodeURIComponent(currentLocation.pathname)}`}
									hoverVariant="primary"
									className="mx-2"
									onClick={e => refreshIfLoginPage(e, `/login?return_to=${encodeURIComponent(currentLocation.pathname)}`)}
									accessibility={t('sign_up_free')}
								>
									{t('sign_up_free')}
								</Btn>
							</>
						) : menu && (
							<Btn
								to={`/login?return_to=${encodeURIComponent(currentLocation.pathname)}`}
								className="mr-4"
								style={{ width: '50px', height: '50px' }}
								accessibility={t('acc_login_screen')}
							>
								<div
									className="flex justify-center items-center"
									style={{ width: '50px', marginLeft: '-25px', marginTop: '2px' }}
								>
									<FiUser />
								</div>
							</Btn>
						)
					) : menu && (
						<>
							{isMobile ? (
								<Btn
									hoverVariant="primary"
									className="mr-4"
									style={{ width: '50px', height: '50px' }}
									onClick={onOpenUserDropdown}
									accessibility={t('acc_login_screen')}
								>
									<div
										className="flex justify-center items-center"
										style={{ width: '50px', marginLeft: '-25px', marginTop: '2px' }}
									>
										<FiUser />
									</div>
								</Btn>
							) : (
								<Btn
									size="small"
									hoverVariant="primary"
									className="mx-2"
									onClick={onOpenUserDropdown}
									accessibility={t('acc_account_menu')}
								>
									<div className="flex justify-between items-center text-base">
										<span className="topbar-username">
											{user.data.Display_Name || user.data.Email}
										</span>
										{(user.data.Profile.Media_Image && user.data.Profile.Media_Image.Url) && (
											<img
												src={user.data.Profile.Media_Image.Url}
												className="w-8 h-8 ml-4 rounded-full"
											/>
										)}
									</div>
								</Btn>
							)}
							<UserDropDown ref={userDropdownRef} />
						</>
					)}
					{isMobile && (
						<Btn
							className="mr-2"
							style={{ width: '50px', height: '50px' }}
							onClick={openMobileMenu}
							accessibility={menu? t('acc_account_menu') : t('acc_close_menu')}
						>
							<div
								className="flex justify-center items-center"
								style={{ width: '50px', marginLeft: '-25px', marginTop: '2px' }}
							>
								{menu ? <FiMenu/> : <FiX/>}
							</div>
						</Btn>
					)}
				</div>
			</nav>
		</>
	);
};

TopBar.propTypes = {
	pages: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			route: PropTypes.string
		})
	),
	sticky: PropTypes.bool
};

export default TopBar;
