import PropTypes from 'prop-types';
import React     from 'react';

import ArtistCard from './../ArtistCard/ArtistCard';

import './MemberList.css';

const MemberList = ({ members }) => {
	return (
		<div className="pxl-memberlist w-full">
			{members.map(member => (
				<ArtistCard
					key={member.Music_Label_Artist_Member__}
					artist={member}
					imageVariation={MEMBER_IMG_VARIATION}
				/>
			))}
		</div>
	);
};

export const MEMBER_IMG_VARIATION = 'background_color=transparent&strip&scale_crop=320x320&format=png';
export default MemberList;

MemberList.propTypes = {
	members: PropTypes.array.isRequired
};
