import React, {useContext} from 'react';
import {useTranslation}    from 'react-i18next';

import Hero         from '../../components/layout/Hero/Hero';
import LayoutHelmet from '../../components/layout/LayoutHelmet/LayoutHelmet';

import {TemplateContext} from '../../context/TemplateContext';
import {Link}            from 'react-router-dom';

const LegalInformation = () => {
	const {t} = useTranslation();
	const {location} = useContext(TemplateContext);

	return (
		<>
			<LayoutHelmet title={t('legal_information')}/>
			<Hero title={t('legal_information')}/>
			<main className="w-full max-w-3xl flex flex-col items-stretch mb-4 mx-auto p-2 md:p-6">
				<div className="flex flex-col md:flex-row">
					<div className="flex-none w-full md:w-1/3 font-bold p-2 md:p-6">
						{t(location.Company_Name ? 'company_name' : 'representative')}
					</div>
					<div className="flex-1 p-2 md:p-6">{location.Company_Name || location.Display_Name}</div>
				</div>
				<div className="flex flex-col md:flex-row">
					<div className="flex-none w-full md:w-1/3 font-bold p-2 md:p-6">
						{t('address')}
					</div>
					<div className="flex-1 p-2 md:p-6">{location.Display[0]}</div>
				</div>
				{location.Contact_Phone && (
					<div className="flex flex-col md:flex-row">
						<div className="flex-none w-full md:w-1/3 font-bold p-2 md:p-6">
							{t('phone_number')}
						</div>
						<div className="flex-1 p-2 md:p-6">
							{location.Contact_Phone}
						</div>
					</div>
				)}
				<div className="flex flex-col md:flex-row">
					<div className="flex-none w-full md:w-1/3 font-bold p-2 md:p-6">
						{t('email_address')}
					</div>
					<div className="flex-1 p-2 md:p-6">
						<Link
							className="hover:underline cursor-pointer mb-1"
							component="a"
							to={'/contact'}
							aria-label={t('contact_us_link')}
						>
							{t('contact_us_link')}
						</Link>
					</div>
				</div>

				{location.Company_Name && (
					<div className="flex flex-col md:flex-row">
						<div className="flex-none w-full md:w-1/3 font-bold p-2 md:p-6">
							{t('representative')}
						</div>
						<div className="flex-1 p-2 md:p-6">
							{location.Display_Name}
						</div>
					</div>
				)}
				<div className="flex flex-col md:flex-row">
					<div className="flex-none w-full md:w-1/3 font-bold p-2 md:p-6">
						{t('additional_fee')}
					</div>
					<div className="flex-1 p-2 md:p-6">
						{t('none')}
					</div>
				</div>
				<div className="flex flex-col md:flex-row">
					<div className="flex-none w-full md:w-1/3 font-bold p-2 md:p-6">
						{t('exchange_or_cancellation_label')}
					</div>
					<div className="flex-1 p-2 md:p-6">
						{t('exchange_or_cancellation_description')}
					</div>
				</div>
				<div className="flex flex-col md:flex-row">
					<div className="flex-none w-full md:w-1/3 font-bold p-2 md:p-6">
						{t('shipping_time_label')}
					</div>
					<div className="flex-1 p-2 md:p-6">
						{t('shipping_time_description')}
					</div>
				</div>
				<div className="flex flex-col md:flex-row">
					<div className="flex-none w-full md:w-1/3 font-bold p-2 md:p-6">
						{t('payment_method_label')}
					</div>
					<div className="flex-1 p-2 md:p-6">
						{t('payment_method_description')}
					</div>
				</div>
				<div className="flex flex-col md:flex-row">
					<div className="flex-none w-full md:w-1/3 font-bold p-2 md:p-6">
						{t('payment_period_label')}
					</div>
					<div className="flex-1 p-2 md:p-6">
						{t('payment_period_description')}
					</div>
				</div>
				<div className="flex flex-col md:flex-row">
					<div className="flex-none w-full md:w-1/3 font-bold p-2 md:p-6">
						{t('price_label')}
					</div>
					<div className="flex-1 p-2 md:p-6">
						{t('price_description')}
					</div>
				</div>
			</main>
		</>
	);
};

export default LegalInformation;
