import { getHostname, getSettings } from '@karpeleslab/klbfw';

export const getFrom = () => {

	return `noreply@${getHostname()}`;
};

export const getSubjectTag = () => {
	const settings = getSettings();
	return settings.artist.Name;
};

export const getFromName = () => {
	return getSubjectTag();
};
