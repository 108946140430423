import './utils/polyfills';
import React from 'react';
import App   from './App';

import i18n                 from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLocale, rest }  from '@karpeleslab/klbfw';
import { Backend }          from '@karpeleslab/i18next-klb-backend';
import { run }              from '@karpeleslab/react-klbfw-hooks';
import moment               from 'moment';
import './assets/styles/transitions.css';
import './assets/styles/tailwind.css';

let i18nOpt = {
	lng: getLocale(),
	initImmediate: false,
	load: 'currentOnly',
	interpolation: { escapeValue: false },
	react: { useSuspense: false }
};

const locale = getLocale().split('-')[0];
try {
	if (locale !== 'en')
		require('moment/locale/' + locale);
	moment.locale(locale);
} catch (err) {
	console.log(`moment locale ${locale} not found`);
}

global.rest = rest;

run(
	<App/>,
	[i18n.use(Backend).use(initReactI18next).init(i18nOpt)]
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (typeof window !== 'undefined') {
	const serviceWorker = require('./serviceWorker');
	serviceWorker.unregister();

	// prevent tap to zoom and pinch to zoom on Safari iOS 12+ because it doesn't take the meta into account
	document.addEventListener('touchmove', event => {
		if (typeof event.scale !== 'undefined' && event.scale !== 1)
			event.preventDefault();
	}, { capture: false, passive: false });

	let lastTouchEnd = 0;
	document.addEventListener('touchend', event => {
		const now = (new Date()).getTime();
		if (now - lastTouchEnd <= 300)
			event.preventDefault();
		lastTouchEnd = now;
	}, { capture: false, passive: false });
}
