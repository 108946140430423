import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';

import EmailLayout from '../../../components/email/EmailLayout';

const Html = ({ artist, plan }) => {
	const { t } = useTranslation();

	return (
		<EmailLayout
			intro={t('email_welcome_fanclub_title')}
			paragraphs={[
				t('email_welcome_fanclub_description', { artist, plan })
			]}
		/>
	);
};

Html.propTypes = {
	artist: PropTypes.string,
	plan  : PropTypes.string
};

export default Html;
