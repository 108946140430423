import React, { useEffect } from 'react';
import { 
	CSSTransition,
	SwitchTransition } from 'react-transition-group'; 

import { useSnackbar, useSetSnackbar } from './../../../hooks/useSnackbar';

import { FiX } from 'react-icons/fi';

import './Snackbar.css';

export default function Snackbar () {
	const { snackbar }      = useSnackbar();
	const { message, type } = snackbar;

	const setSnackbar = useSetSnackbar();
	const handleClose = () => {
		setSnackbar(null);
	};

	useEffect(() => {
		if (message) {
			setTimeout(() => {
				if (message) 
					setSnackbar(null);
			}, 5000);
		}
	}, [message, setSnackbar]);

	return (
		<div className="fixed top-6 left-6 z-50 w-screen text-center flex justify-center pointer-events-none">
			<SwitchTransition>
				<CSSTransition
					key={ message ? 'show-snack' : 'hide-snack' }
					addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
					classNames='u-transition--vertical'
				>
					{message ? (
						<div className={`pxl-snack relative px-8 py-4 text-white border border-white ${type} pointer-events-none`}>
							{message}
							<button 
								className="absolute top-2 right-2 border-none bg-transparent leading-loose pointer-events-auto"
								onClick={ () => handleClose() }
							>
								<FiX />
							</button>
						</div>
					) : (
						<div />
					)}
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
}
