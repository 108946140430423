import React from 'react';
import {useTranslation} from 'react-i18next';
import LayoutHelmet from '../../components/layout/LayoutHelmet/LayoutHelmet';
import ShopItems from '../../components/pxl/ShopItems/ShopItems';
import './shop.css';

export default function Shop() {
	const {t} = useTranslation();

	return <>
		<LayoutHelmet title={t('shop_title')}/>
		<main className="w-full shop-wrapper mb-auto mx-auto pt-28">
			<ShopItems/>
		</main>
	</>;
}