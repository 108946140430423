import React, { useState } from 'react';
import { useTranslation }  from 'react-i18next';
import { rest }            from '@karpeleslab/klbfw';

import { CSSTransition, SwitchTransition } from 'react-transition-group';

import Btn          from '../../components/core/inputs/Btn/Btn';
import Hero         from '../../components/layout/Hero/Hero';
import LayoutHelmet from '../../components/layout/LayoutHelmet/LayoutHelmet';
import Loader       from '../../components/layout/Loader/Loader';
import TextInput    from '../../components/core/inputs/TextInput/TextInput';

import { useSetSnackbar } from '../../hooks/useSnackbar';

const PasswordForgotten = () => {
	const { t }                 = useTranslation();
	const [email, setEmail]     = useState(''); 
	const [loading, setLoading] = useState(false);
	const [sent, setSent]       = useState(false);
	const setSnackbar           = useSetSnackbar();

	const onSubmit = e => {
		e.preventDefault();
		setLoading(true);
		rest('User:forgot_password', 'POST', {login: email })
			.then(() => setSent(true))
			.catch(err => setSnackbar(err.error, 'error'))
			.finally(() => setLoading(false));
	};

	return (
		<>
			<LayoutHelmet title={t('password_forgotten')}/>
			<Hero title={t('password_forgotten')}/>
			<main className="w-full max-w-3xl flex flex-col items-stretch mb-4 mx-auto p-2 md:p-6">
				<SwitchTransition mode="out-in">
					<CSSTransition
						key={!loading ? 'loaded' : 'loading'}
						classNames="u-transition--vertical"
						addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
					>
						{!loading ? (
							sent ? (
								<div key="request-sent" className="w-full">
									<p>{t('password_request_sent')}</p>
								</div>
							) : (
								<div key="request-form" className="w-full">
									<p className="my-4">{t('password_forgotten_description')}</p>
									<form
										action=""
										className="flex flex-col items-center"
										onSubmit={onSubmit}
									>
										<TextInput
											label={t('email_address')}
											type="email"
											className="mt-4"
											value={email}
											required={true}
											onChange={e => setEmail(e.target.value)}
										/>
										<Btn variant="primary" type="submit" className="mt-4" accessibility={t('send')}>
											{t('send')}
										</Btn>
									</form>
								</div>
							)
						) : (
							<div key="loading" className="w-full">
								<Loader />
							</div>
						)}
					</CSSTransition>
				</SwitchTransition>
			</main>
		</>
	);
};

export default PasswordForgotten;
