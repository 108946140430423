import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';
import { dateFormat }     from '../../../utils/date';

const EventDates = ({ event }) => {
	const { t } = useTranslation();

	const reservationStartDate = event.Reservation_Start;
	const reservationEndDate = event.Reservation_End;

	const now = Date.now();

	const reservationStarted = reservationStartDate.unix <= (now / 1000);
	const reservationEnded = reservationEndDate.unix <= (now / 1000);

	return (
		<table className={'w-full'}>
			<tbody>
				<tr>
					<td className='pr-2'>{t('event_date')}</td>
					<td className={'whitespace-nowrap'}>{dateFormat(event.Event_Date, 'L LT')}</td>
				</tr>
				{!reservationStarted &&
				<tr>
					<td className='pr-2'>{t('event_reservation_not_started')}</td>
					<td className={'whitespace-nowrap'}>{dateFormat(reservationStartDate, 'L LT')}</td>
				</tr>
				}
				{reservationEnded &&
				<tr>
					<td className='pr-2'>{t('event_reservation_over')}</td>
					<td className={'whitespace-nowrap'}>{dateFormat(reservationEndDate, 'L LT')}</td>
				</tr>}
				{(!reservationEnded && reservationStarted) &&
				<>
					<tr>
						<td className='pr-2'>{t('event_reservation_start')}</td>
						<td className={'whitespace-nowrap'}>{dateFormat(reservationStartDate, 'L LT')}</td>
					</tr>
					<tr>
						<td className='pr-2'>{t('event_reservation_end')}</td>
						<td className={'whitespace-nowrap'}>{dateFormat(reservationEndDate, 'L LT')}</td>
					</tr>
				</>
				}
			</tbody>
		</table>
	);
};

export default EventDates;

EventDates.propTypes = {
	event: PropTypes.object.isRequired,
};
