import React     from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const SetStatus = ({ statusCode, children }) => {
	return (
		<Route
			render={({ staticContext }) => {
				if (staticContext) 
					staticContext.statusCode = statusCode;
				return children;
			}}
		/>
	);
};

SetStatus.propTypes = {
	statusCode: PropTypes.number,
	children  : PropTypes.any
};

export default SetStatus;
