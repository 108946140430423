import eaw from 'eastasianwidth';

export const scrollToId = (elementId) => {
	if (typeof window === 'undefined') return;
	const ele = document.getElementById(elementId);
	if (!ele) return;
	window.scrollTo({
		top: ele.offsetTop,
		left: 0,
		behavior: 'smooth'
	});
};

export const noPageScroll = () => {
	document.body.style.overflow = 'hidden';
};

export const allowPageScroll = () => {
	document.body.style.overflow = 'visible';
};

export const isFullWidthString = (string) => {
	if(!string) return true;
	for (let i = 0; i < string.length; i++) {
		if (eaw.characterLength(string[i]) !== 2) return false;
	}
	return true;
};