import React              from 'react';
import { Get }            from '@karpeleslab/klbfw';
import { useTranslation } from 'react-i18next';

import EmailLayout from '../../../components/email/EmailLayout';

const Html = () => {
	const { t } = useTranslation();

	const text = [t('mail_shipped_title', { number: Get('_order').Invoice_Number })];
	if (Get('_tracking')) 
		text.push(t('mail_shipped_tracking_number'), `<b>${Get('_tracking')}</b>`);

	return (
		<EmailLayout
			intro={t('mail_shipped_title', { number: Get('_order').Invoice_Number })}
			paragraphs={text}
			showContactBtn={true}
		>
			<table
				border="0"
				cellSpacing="0"
				cellPadding="0"
				width="100%"
				style={{ width: '100%' }}
			>
				<tbody>
					<tr>
						<td>
							<hr size="2" color="#ffffff" />
						</td>
					</tr>
					<tr>
						<td>
							<p
								style={{
									fontFamily: 'sans-serif',
									fontSize: '14px',
									fontWeight: 'normal',
									margin: '0',
									marginBottom: '15px',
									color: '#ffffff'
								}}
							>
								{t('contact_us_desc')}
							</p>
						</td>
					</tr>
				</tbody>
			</table>
		</EmailLayout>
	);
};

export default Html;
