import React, { useContext } from 'react';
import PropTypes             from 'prop-types';
import { useHistory }        from 'react-router';

import Btn        from '../../core/inputs/Btn/Btn';
import TicketType from './TicketType';

import { UserContext } from '../../../context/UserContext';

import './EventTicketDetails.css';

const EventTicketDetails = ({
	displayLogin = () => {},
	enableOrder = true,
	event,
	ticket
}) => {
	const history                      = useHistory();
	const [{ loggedIn, subscription }] = useContext(UserContext);

	const handleClick = () => {
		if (!enableOrder) // maybe display a message?
			return;
	
		const url = `/reserve/${event.Planner_Event__}/${ticket.Planner_Event_Seat__}`;
		if (!loggedIn) {
			displayLogin(
				ticket.Membership_Required === 'Y',
				url
			);
		} else if (ticket.Membership_Required === 'Y' && !subscription)
			history.push('/fanclub');
		else
			history.push(url);
	};

	return (
		<Btn
			size="special"
			className="w-full mb-2"
			disabled={ticket.Total_Remaining < 1}
			onClick={handleClick}
			accessibility={ticket.Seat_Name_Display ?? ticket.Seat_Name}
		>
			<div className="w-full flex items-center">
				<div className="ticket-price-and-type flex-none flex flex-col items-stretch text-center bg-pxl-darkGray">
					<div className="px-4 pt-2 pb-1 text-2xl font-bold">
						{ticket.Price.display}
					</div>
					<div className="px-4 pt-1 pb-2 text-4xl">
						<TicketType type={ticket.Type} className="inline-block" />
					</div>
				</div>
				<div className="pl-4 text-left">
					<h3 className="text-xl">
						{ticket.Seat_Name_Display ?? ticket.Seat_Name}
					</h3>
					<p className="font-sans font-normal">{ticket.Description}</p>
				</div>
			</div>
		</Btn>
	);
};

export default EventTicketDetails;

EventTicketDetails.propTypes = {
	displayLogin   : PropTypes.func,
	enableOrder    : PropTypes.bool,
	event          : PropTypes.object.isRequired,
	ticket         : PropTypes.object.isRequired
};
