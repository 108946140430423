import React, { useState } from 'react';
import PropTypes           from 'prop-types';
import { useTranslation }        from 'react-i18next';
import {useRest} from '@karpeleslab/react-klbfw-hooks';
import { rest }                  from '@karpeleslab/klbfw';

import { BsFillCartPlusFill } from 'react-icons/bs';
import Btn                    from '../../core/inputs/Btn/Btn';
import Select                 from '../../core/inputs/Select/Select';

import { useSetSnackbar } from '../../../hooks/useSnackbar';

export const AddToCard = ({id, maxAmount}) => {
	if (!maxAmount || maxAmount > 6)
		maxAmount = 6;

	const [quantity, setQuantity] = useState('1');
	const {t}         = useTranslation();
	const [cart, cartRefresh] = useRest('Catalog/Cart/@');
	const setSnackbar = useSetSnackbar();

	const addToCart = (productId) => {
		if (quantity === '')
			return;

		let found = null;
		if (cart && cart.data)
			found = cart.data.products.find(p => p.id === productId);

		let endpoint = 'Catalog/Cart/@:process';
		let query = {request: `${productId}=${quantity}`};

		if (found) {
			endpoint = 'Catalog/Cart/@:setQuantity';
			query = {
				key: found.key,
				quantity: (parseInt(found.quantity) || 0) + (parseInt(quantity) || 0)
			};
		}

		rest(endpoint, 'POST', query)
			.then(() => setSnackbar(t('toast_item_added'), 'success'))
			.catch(() => setSnackbar(t('toast_item_added_error'), 'error'))
			.finally(() => cartRefresh());
	};

	const options = [...Array(maxAmount).keys()].map((nb) => {
		const str = `${nb + 1}`;
		return {name: str, value: str};
	});

	return (
		<div className="flex w-full justify-between items-end">
			<Select
				onChange={(e) => setQuantity(e.target.value)}
				value={quantity}
				options={options}
				className="flex-none w-1/2"
			/>
			<Btn
				variant="primary"
				title={t('shop_add_to_card')}
				onClick={() => addToCart(id)}
			>
				<BsFillCartPlusFill />
			</Btn>
		</div>
	);
};

AddToCard.propTypes = {
	id: PropTypes.string,
	maxAmount: PropTypes.number,
};
