import React, { useContext } from 'react';
import { Get }               from '@karpeleslab/klbfw';
import { useTranslation }    from 'react-i18next';

import DateUtil    from '../../../components/core/utils/DateUtil';
import EmailLayout from '../../../components/email/EmailLayout';
import InfoBox     from '../../../components/email/EmailInfoBox';

import { TemplateContext } from '../../../context/TemplateContext';

const Html = () => {
	const { t }        = useTranslation();
	const { location } = useContext(TemplateContext);

	const Payment_Class = () => {
		if (Get('_order').Payment_Class !== '') {
			return (
				<tr>
					<td width="20%" nowrap>
						<p
							style={{
								fontFamily: 'sans-serif',
								fontSize: '14px',
								fontWeight: 'normal',
								margin: '0',
								marginBottom: '15px',
								color: '#ffffff'
							}}
						>
							{t('payment_method')}
						</p>
					</td>
					<td>
						<p
							style={{
								fontFamily: 'sans-serif',
								fontSize: '14px',
								fontWeight: 'normal',
								margin: '0',
								marginBottom: '15px',
								color: '#ffffff'
							}}
						>
							: {Get('_order').Payment_Method}
						</p>
					</td>
				</tr>
			);
		}
	};

	const Shipping_User_Location = () => {
		if (Get('_order').Shipping_User_Location) {
			return (
				<td valign="top">
					<p
						style={{
							fontFamily: 'sans-serif',
							fontSize: '16px',
							fontWeight: 'bold',
							margin: '0',
							marginBottom: '15px',
							color: '#ffffff'
						}}
					>
						{t('shipping_address')}
					</p>
					<p
						style={{
							fontFamily: 'sans-serif',
							fontSize: '14px',
							fontWeight: 'normal',
							margin: '0',
							marginBottom: '15px',
							color: '#ffffff'
						}}
					>
						{Get('_order').Shipping_User_Location.Display[0]}{' '}
						{Get('_order').Shipping_User_Location.Display[1]}
					</p>
				</td>
			);
		} else {
			return (
				<td valign="top">
					<p
						style={{
							fontFamily: 'sans-serif',
							fontSize: '16px',
							fontWeight: 'bold',
							margin: '0',
							marginBottom: '15px',
							color: '#ffffff'
						}}
					>
						{t('shipping_address')}
					</p>
					<p
						style={{
							fontFamily: 'sans-serif',
							fontSize: '14px',
							fontWeight: 'normal',
							margin: '0',
							marginBottom: '15px',
							color: '#ffffff'
						}}
					>
						{Get('_order').Billing_User_Location.Display[0]}{' '}
						{Get('_order').Billing_User_Location.Display[1]}
					</p>
				</td>
			);
		}
	};

	const quantity = line => {
		if (line.Quantity) {
			return line.Quantity;
		} else {
			return '1';
		}
	};

	const items = () => {
		return Get('_order').Items.map((line, idx) => {
			return (
				<tr key={`order_item_${idx}`}>
					<td>
						<p
							style={{
								fontFamily: 'sans-serif',
								fontSize: '14px',
								fontWeight: 'normal',
								margin: '0',
								marginBottom: '15px',
								color: '#ffffff'
							}}
						>
							{line.Catalog_Product['Basic.Name']}
						</p>
					</td>
					<td align="right">
						<p
							style={{
								fontFamily: 'sans-serif',
								fontSize: '14px',
								fontWeight: 'normal',
								margin: '0',
								marginBottom: '15px',
								color: '#ffffff'
							}}
						>
							{line.Price.display}
						</p>
					</td>
					<td align="center">
						<p
							style={{
								fontFamily: 'sans-serif',
								fontSize: '14px',
								fontWeight: 'normal',
								margin: '0',
								marginBottom: '15px',
								color: '#ffffff'
							}}
						>
							{quantity(line)}
						</p>
					</td>
					<td align="right">
						<p
							style={{
								fontFamily: 'sans-serif',
								fontSize: '14px',
								fontWeight: 'normal',
								margin: '0',
								marginBottom: '15px',
								color: '#ffffff'
							}}
						>
							{line.Full_Price.display}
						</p>
					</td>
				</tr>
			);
		});
	};

	return (
		<EmailLayout
			intro={t('mail_bill_title', { number: Get('_order').Invoice_Number })}
			paragraphs={[
				`${Get('_order').Billing_User_Location.Last_Name} ${Get('_order').Billing_User_Location.First_Name},`,
				t('order_thanks')
			]}
			showContactBtn={true}
		>
			<table
				border="0"
				cellSpacing="0"
				cellPadding="0"
				width="100%"
				style={{ width: '100%' }}
			>
				<tbody>
					<tr>
						<td>
							<InfoBox>
								<table
									cellSpacing="0"
									cellPadding="0"
									border="0"
									align="center"
									width="100%"
								>
									<tr>
										<td style={{ fontWeight: 700 }}>
											<p
												style={{
													fontFamily: 'sans-serif',
													fontSize: '18px',
													fontWeight: 'bold',
													margin: '0',
													marginBottom: '15px',
													color: '#ffffff'
												}}
											>
												<b>
													{t('order_number')}{' '}
													#{Get('_order').Invoice_Number}
												</b>
											</p>
										</td>
									</tr>
								</table>
							</InfoBox>
						</td>
					</tr>
					<tr>
						<td>&nbsp;</td>
					</tr>
					<tr>
						<td>
							<table
								cellSpacing="0"
								cellPadding="0"
								border="0"
								align="center"
								width="100%"
								style={{ lineHeight: '1.4rem' }}
							>
								<tr>
									<td width="20%" nowrap>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{t('order_date')}
										</p>
									</td>
									<td>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											: <DateUtil klbDateObj={Get('_order').Created} />
										</p>
									</td>
								</tr>
								{Get('_order').Invoice_Date && (
									<tr>
										<td width="20%" nowrap>
											<p
												style={{
													fontFamily: 'sans-serif',
													fontSize: '14px',
													fontWeight: 'normal',
													margin: '0',
													marginBottom: '15px',
													color: '#ffffff'
												}}
											>
												{t('payment_date')}
											</p>
										</td>
										<td>
											<p
												style={{
													fontFamily: 'sans-serif',
													fontSize: '14px',
													fontWeight: 'normal',
													margin: '0',
													marginBottom: '15px',
													color: '#ffffff'
												}}
											>
												: <DateUtil klbDateObj={Get('_order').Invoice_Date} />
											</p>
										</td>
									</tr>
								)}
								{Payment_Class()}
							</table>
						</td>
					</tr>
					<tr>
						<td>
							<hr size="2" color="#ffffff" />
						</td>
					</tr>
					<tr>
						<td>
							<table
								cellSpacing="0"
								cellPadding="5"
								border="0"
								align="center"
								width="100%"
							>
								<tr>
									<td valign="top">
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '16px',
												fontWeight: 'bold',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											<b>{t('invoice')}</b>
										</p>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{Get('_order').Billing_User_Location.Display[0]}{' '}
											{Get('_order').Billing_User_Location.Display[1]}
										</p>
									</td>
									{Shipping_User_Location()}
								</tr>
							</table>
						</td>
					</tr>
					<tr>
						<td>
							<hr size="2" color="#ffffff" />
						</td>
					</tr>
					<tr>
						<td>
							<p
								style={{
									fontFamily: 'sans-serif',
									fontSize: '16px',
									fontWeight: 'bold',
									margin: '0',
									marginBottom: '15px',
									color: '#ffffff'
								}}
							>
								{t('order_details')}
							</p>
							<table
								cellSpacing="0"
								cellPadding="5"
								border="0"
								align="center"
								width="100%"
								style={{ borderCollapse: 'collapse' }}
							>
								<tr>
									<th>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'bold',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{t('product_name')}
										</p>
									</th>
									<th>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'bold',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{t('price')}
										</p>
									</th>
									<th>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'bold',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{t('quantity')}
										</p>
									</th>
									<th>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'bold',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{t('sub_total')}
										</p>
									</th>
								</tr>
								{items()}
								<tr>
									<td
										className="subtotal_h"
										colSpan="3"
										align="right"
									>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{t('sub_total')}
										</p>
									</td>
									<td className="subtotal" align="right">
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{Get('_order').Total.display}
										</p>
									</td>
								</tr>
								<tr>
									<td
										className="shippingfee_h"
										colSpan="3"
										align="right"
									>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{t('discount')}
										</p>
									</td>
									<td className="shippingfee" align="right">
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{Get('_order').Subtotals.discount.display}
										</p>
									</td>
								</tr>
								<tr>
									<td
										className="shippingfee_h"
										colSpan="3"
										align="right"
									>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{t('shipping_fee')}
										</p>
									</td>
									<td className="shippingfee" align="right">
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{Get('_order').Subtotals.shipping.display}
										</p>
									</td>
								</tr>
								<tr>
									<td
										className="tax_h"
										colSpan="3"
										align="right"
									>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{t('vat')}
										</p>
									</td>
									<td className="total" align="right">
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{Get('_order').Vat_Amount.display}
										</p>
									</td>
								</tr>
								<tr className="noborder">
									<td
										className="total_h"
										colSpan="3"
										align="right"
									>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'bold',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											<b>{t('total')}</b>
										</p>
									</td>
									<td className="total" align="right">
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'bold',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											<b>{Get('_order').Total_Vat.display}</b>
										</p>
									</td>
								</tr>
							</table>
						</td>
					</tr>
					{location?.Company_European_Vat_Number && (
						<>
							<tr>
								<td>
									<hr size="2" color="#ffffff" />
								</td>
							</tr>
							<tr>
								<td>
									<p
										style={{
											fontFamily: 'sans-serif',
											fontSize: '14px',
											fontWeight: 'normal',
											margin: '0',
											marginBottom: '15px',
											color: '#ffffff'
										}}
									>
										{t('vat_legal_number_eu', { value: location.Company_European_Vat_Number })}
									</p>
								</td>
							</tr>
						</>
					)}
					<tr>
						<td>
							<hr size="2" color="#ffffff" />
						</td>
					</tr>
					<tr>
						<td>
							<p
								style={{
									fontFamily: 'sans-serif',
									fontSize: '14px',
									fontWeight: 'normal',
									margin: '0',
									marginBottom: '15px',
									color: '#ffffff'
								}}
							>
								{t('contact_us_desc')}
							</p>
						</td>
					</tr>
				</tbody>
			</table>
		</EmailLayout>
	);
};

export default Html;
