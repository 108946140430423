import React, { useContext, useState } from 'react';
import { useTranslation }              from 'react-i18next';
import { rest }                        from '@karpeleslab/klbfw';

import { AiFillSave }       from 'react-icons/ai';
import Btn                  from '../../../core/inputs/Btn/Btn';
import { FiLoader }         from 'react-icons/fi';
import RequestPasswordPopin from '../../../core/utils/RequestPasswordPopin/RequestPasswordPopin';
import TextInput            from '../../../core/inputs/TextInput/TextInput';

import { UserContext }    from '../../../../context/UserContext';
import { useSetSnackbar } from '../../../../hooks/useSnackbar';

const ChangePassword = () => {
	const { t }           = useTranslation();
	const [user, setUser] = useContext(UserContext);
	const setSnackbar     = useSetSnackbar();

	const [loading, setLoading]               = useState(false);
	const [requestPwdOpen, setRequestPwdOpen] = useState(false);
	const [password, setPassword]             = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const onSubmit = e => {
		e.preventDefault();
		if (password !== confirmPassword) {
			setSnackbar(t('confirm_password_different'), 'error');
			return;
		}
		setRequestPwdOpen(true);
	};

	const submitNewPassword = old_password => {
		setLoading(true);
		setRequestPwdOpen(false);
		rest('User/@:setPassword', 'POST', {
			old_password,
			password
		})
			.then(data => {
				setSnackbar(t('password_change_success'), 'success');
				setUser({...user, user: data});
			})
			.catch(err => setSnackbar(err.message, 'error'))
			.finally(() => {
				setPassword('');
				setConfirmPassword('');
				setLoading(false);
			});
	};

	return (
		<>
			<form
				className="w-full flex justify-between items-start pt-4 px-2"
				onSubmit={onSubmit}
			>
				<div className="flex-1 flex flex-col">
					<TextInput
						label={t('new_password')}
						type="password"
						value={password}
						onChange={e => setPassword(e.target.value)}
						required={true}
					/>
					<TextInput
						className={'mt-6'}
						label={t('confirm_new_password')}
						type="password"
						value={confirmPassword}
						onChange={e => setConfirmPassword(e.target.value)}
						required={true}
					/>
				</div>
				<div className="flex-none ml-6">
					<Btn
						className={'mt-2'}
						type="submit"
						variant="primary"
						disabled={password.trim().split('').length <= 0 || loading}
						accessibility={t('save_btn')}
					>
						<AiFillSave />
						{loading && <FiLoader size={'1rem'} className="animate-spin btn-loader" />}
					</Btn>
				</div>
			</form>
			<RequestPasswordPopin
				closeCallback={submitNewPassword}
				open={requestPwdOpen}
				setOpen={setRequestPwdOpen}
			/>
		</>
	);
};

export default ChangePassword;
