import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useOrderProcess} from '@karpeleslab/klb-react-services';
import Btn from '../../../../core/inputs/Btn/Btn';
import {FiLoader} from 'react-icons/fi';
import Select from '../../../../core/inputs/Select/Select';

const OnFile = ({order, settings, setPaying, refreshOrder, refreshProcessResult}) => {
	const {t} = useTranslation();
	const [selected, setSelected] = useState();

	const [processOrder, processingOrder] = useOrderProcess(order.Order__);

	useEffect(() => {
		setPaying(processingOrder);
	}, [processingOrder]);

	useEffect(() => {
		const billings = settings.fields.user_billing.values;
		const billingId = billings.length > 0 ? billings[0].User_Billing__ : null;
		setSelected(billingId);
	}, [settings, setSelected]);

	const handleProcess = () => {
		processOrder(settings.session, settings.method, {user_billing: selected}, {snackMessageToken: 'order_paid'})
			.then(d => {
				refreshOrder(d.order);
				refreshProcessResult(d);
			});
	};
	const options = settings.fields.user_billing.values.map(billing => {
		return {
			value: billing.User_Billing__,
			name: t('user_billing_selector_value', {
				name: billing.Methods[0].Name,
				exp: billing.Methods[0].Expiration
			}),
		};
	});
	return (
		<div className="flex flex-wrap overflow-hidden sm:-mx-3 my-4">

			<div className="w-full overflow-hidden sm:my-3 sm:px-3">
				<Select
					label={t('user_billing_selector')}
					onChange={(e) => setSelected(e.target.value)}
					value={selected}
					options={options}
				/>
			</div>
			<div className="w-full overflow-hidden mt-4 sm:my-3 sm:px-3 flex items-center justify-center">
				<Btn
					className={'md:ml-auto block'}
					onClick={handleProcess}
					disabled={processingOrder || !selected}
					accessibility={t('onfile_checkout_btn')}
				>
					{processingOrder ? <FiLoader className="animate-spin"/> : t('onfile_checkout_btn')}
				</Btn>
			</div>
		</div>
	);
};

export default OnFile;

OnFile.propTypes = {
	refreshOrder: PropTypes.func.isRequired,
	refreshProcessResult: PropTypes.func.isRequired,
	setPaying: PropTypes.func.isRequired,
	settings: PropTypes.object.isRequired,
	order: PropTypes.object.isRequired,
};
