import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';

import { dateFormat } from '../../../utils/date';

import './EventTicketAvailability.css';

const EventTicketAvailability = ({ event }) => {
	const { t }    = useTranslation();
	const generate = () => {
		const now     = new Date();
		const content  = {
			classes: '',
			legend : t(''),
			status : t(''),
			annotation: null
		};
		if (
			new Date(parseInt(event.Reservation_End.unixms)) <= now ||
			event.Status === 'reserve_closed'
		) { // closed
			content.classes = 'border border-white stripped-bg italic';
			content.legend = t('reservation_is');
			content.status = t('closed');
		} else if (
			new Date(parseInt(event.Reservation_Start.unixms)) > now ||
			event.Status === 'pending'
		) { // soon
			content.classes = 'bg-pxl-accent1';
			content.legend = t('reservation_soon');
			content.status = t('soon');
			content.annotation = t('on_date', { date: dateFormat(event.Reservation_Start, 'MMM Do') });
		} else if (event.Total_Remaining <= 0) { // full
			content.classes = 'border border-white stripped-bg italic';
			content.legend = t('event_is');
			content.status = t('full');
		} else if (event.Status === 'reserve_open') { // open
			content.classes = 'bg-pxl-accent2';
			content.legend = t('tickets_are');
			content.status = t('available');
			content.annotation = t('until_date', { date: dateFormat(event.Reservation_End, 'MMM Do') });
		}
		// if weird edge case, it'll default to closed 
		return (
			<div className={`my-2 mx-4 py-2 px-1 ${content.classes} text-center`}>
				<p className="text-sm">{content.legend}</p>
				<p className="text-2xl uppercase pxl-font-display font-bold">{content.status}</p>
				{content.annotation && (
					<p className="text-sm italic">({content.annotation})</p>
				)}
			</div>
		);
	};

	return generate();
};

EventTicketAvailability.propTypes = {
	event: PropTypes.shape({
		Event_Date: PropTypes.shape({
			unixms: PropTypes.string
		}),
		Max_Seat_Per_User: PropTypes.string,
		Reservation_End  : PropTypes.shape({
			unixms: PropTypes.string
		}),
		Reservation_Start: PropTypes.shape({
			unixms: PropTypes.string
		}),
		Seats          : PropTypes.array,
		Status         : PropTypes.string,
		Total_Capacity : PropTypes.number,
		Total_Remaining: PropTypes.number
	})
};

export default EventTicketAvailability;
