export const phoneValidator = (phone) => {
	if (!phone) return false;
	const data = phone.replace(/\s|-/g, '');
	if (!data) return false;
	if (data.length > 20) return false;
	if (data.indexOf('--') !== -1) return false;
	if (data.match(/^\\+[0-9]+\\.[0-9]{3,}$/g)) return true;
	if (data.match(/^[0-9]{3,}$/g)) return true;
	if (data.match(/^\+(?:[0-9] ?){6,14}[0-9]$/g)) return true;
	return false;
};
