import React from 'react';

import AccountConfirm        from '../../mails/User/AccountConfirm/Mail';
import BillingFailed         from '../../mails/Order/Billing/Failed/Mail';
import BillingInvalidate     from '../../mails/Order/Billing/Invalidate/Mail';
import BillingMethodChanged  from '../../mails/Order/Billing/MethodChanged/Mail';
import BillPdf               from '../../mails/Order/Billing/Pdf/Pdf';
import BookingConfirm        from '../../mails/Booking/Confirm/Mail';
import BookingPaid           from '../../mails/Booking/Paid/Mail';
import SeatAllocated         from '../../mails/Booking/SeatAllocated/Mail';
import LoginNotification     from '../../mails/User/LoginNotification/Mail';
import MembershipWelcome     from '../../mails/Membership/Welcome/Mail';
import NewOrder              from '../../mails/Order/Created/Mail';
import OrderShipping         from '../../mails/Order/Shipping/Mail'; 
import PasswordForgotten     from '../../mails/User/PasswordForgotten/Mail';
import PasswordRecovered     from '../../mails/User/PasswordRecovered/Mail';
import RegistrationConfirm   from '../../mails/User/RegistrationConfirm/Mail';

export default [
	{
		path     : '/mail/account_confirm.mail',
		component: <AccountConfirm />
	},
	{
		path     : '/mail/billing_method_changed.mail',
		component: <BillingMethodChanged />
	},
	{
		path     : '/mail/login_notification.mail',
		component: <LoginNotification />
	},
	{
		path     : '/mail/mail_forgotten_pwd.mail',
		component: <PasswordForgotten />
	},
	{
		path     : '/mail/mail_pwd_recovered.mail',
		component: <PasswordRecovered />
	},
	{
		path     : '/mail/membership/welcome.mail',
		component: <MembershipWelcome /> 
	},
	{
		path     : '/mail/order/billing_failed.mail',
		component: <BillingFailed />
	},
	{
		path     : '/mail/order/billing_method_invalidate.mail',
		component: <BillingInvalidate />
	},
	{
		path     : '/mail/order/order.mail',
		component: <NewOrder />
	},
	{
		path     : '/mail/order/bill.pdf',
		component: <BillPdf />
	},
	{
		path     : '/mail/order/shipping.mail',
		component: <OrderShipping />
	},
	{
		path     : '/mail/registration_validation_code.mail',
		component: <RegistrationConfirm />
	},
	{
		path     : '/mail/reserve_pxl_confirm.mail',
		component: <BookingConfirm />
	},
	{
		path     : '/mail/reserve_pxl_paid.mail',
		component: <BookingPaid />
	},
	{
		path     : '/mail/reserve_pxl_seat_allocated.mail',
		component: <SeatAllocated />
	}
];
