import React              from 'react';
import { useTranslation } from 'react-i18next';
import { getHostname }    from '@karpeleslab/klbfw';

const Text = () => {
	const { t } = useTranslation();

	return (
		<textarea
			id="mail-text"
			defaultValue={
				t('billing_method_changed_description') +
				'\n\r' +
				t('billing_method_contact_us') +
				'\n\r' +
				`https://${getHostname()}/contact` +
				'\n\r' +
				t('user_password_email_footer', { website: getHostname() }) +
				'\n\r'
			}
		>
		</textarea>
	);
};

export default Text;
