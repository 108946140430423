import React     from 'react';
import PropTypes from 'prop-types';

const EmailText = ({
	variant = 'default',
	size = 'small',
	children,
	...etc
}) => {
	const sizes = {
		small : 10,
		medium: 14,
		large : 16
	};

	const colors = {
		default  : '#002560',
		highlight: '#72BA3E',
		inverted : '#fff'
	};

	if (!sizes[size]) size = 'medium';
	if (!colors[variant]) variant = 'default';

	return (
		<p
			color={colors[variant]}
			size={`${sizes[size]}px`}
			style={{
				color     : colors[variant],
				fontSize  : `${sizes[size]}px`,
				fontWeight: variant === 'highlight' ? 'bold' : 'normal'
			}}
			{...etc}
		>
			{children}
		</p>
	);
};

EmailText.propTypes = {
	children: PropTypes.any,
	size    : PropTypes.oneOf(['small', 'medium', 'large']),
	variant : PropTypes.oneOf(['default', 'highlight', 'inverted'])
};

export default EmailText;
