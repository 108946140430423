import React              from 'react';
import PropTypes          from 'prop-types';
import { useParams }      from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRest }        from '@karpeleslab/react-klbfw-hooks';

import ImgLoader      from '../../layout/ImgLoader/ImgLoader';
import MissingPicture from '../MissingPicture/MissingPicture';
import ShopPrice      from '../ShopPrice/ShopPrice';
import WavyLine       from '../../layout/WavyLine/WavyLine';

import { getImage } from '../ShopItems/ShopItem';
import { imgKey }   from '../ShopItems/ShopItems';

import './ShopSimilarItems.css';
export default function ShopSimilarItems({authorCode}) {
	const {t}  = useTranslation();
	const {id} = useParams();

	let params = {
		sort            : 'Basic.Priority:desc',
		results_per_page: 5,
		image_variation : [imgKey],
		query           : {
			'Description.AuthorCode': authorCode,
			Catalog_Product__ : { '$not' : id }
		}
	};
	const [productsRes] = useRest('Catalog/Product:search', params, false, 5000); // keep cache only 5 seconds

	if (!productsRes || !productsRes.data || productsRes.data.data.length < 1)
		return <div className="mt-8 shop-similar-item"><WavyLine/></div>;

	const products = productsRes.data.data;

	return (
		<div className="mt-8 shop-similar-item">
			<WavyLine/>
			<div className="mx-2 mt-4 font-bold pxl-display-font text-xl">
				{t('shop_similar_products')}
			</div>
			<div className="flex flex-wrap">
				{products.map((product, i) => {
					const img   = getImage(product);
					const title = product['Basic.Name'];
					return (
						<div
							key={`${product.Catalog_Product__}-${i}`}
							className="flex flex-col items-center m-2 shop-item-wrap"
						>
							<a
								href={`/shop/${product.Catalog_Product__}`}
								className="flex flex-col justify-center items-center px-6 pt-6 pb-2 item-link"
								aria-label={title}
							>
								{img ? (
									<ImgLoader
										src={img}
										alt={title}
										className="shop-item-img"
									/>
								) : (
									<MissingPicture className="border border-white"/>
								)}
								<div className="font-bold mt-2 text-xl item-text">
									{title}
								</div>
							</a>
							<ShopPrice item={product} />
						</div>
					);
				})}
			</div>
		</div>
	);
}

ShopSimilarItems.propTypes = {
	authorCode: PropTypes.string.isRequired,
};

