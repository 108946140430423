import PropTypes                      from 'prop-types';
import React, { useEffect, useState } from 'react';
import Free                           from './Methods/Free';
import OnFile                         from './Methods/OnFile';
import Wallet                         from './Methods/Wallet';
import Stripe                         from './Methods/Stripe';
import CashOnDelivery 				  from './Methods/CashOnDelivery';

const PaymentMethodContent = ({ order, selectedPaymentMethod, method, methodData, setPaying, refreshOrder, refreshProcessResult }) => {
	const [component, setComponent] = useState(null);

	useEffect(() => {
		switch (method) {
			case 'Free':
				setComponent(
					<Free
						order={order}
						setPaying={setPaying}
						refreshOrder={refreshOrder}
						settings={methodData}
						refreshProcessResult={refreshProcessResult}
					/>
				);
				break;
			case 'OnFile':
				setComponent(
					<OnFile
						order={order}
						setPaying={setPaying}
						refreshOrder={refreshOrder}
						settings={methodData}
						refreshProcessResult={refreshProcessResult}
					/>
				);
				break;
			case 'Wallet':
				setComponent(
					<Wallet
						order={order}
						setPaying={setPaying}
						refreshOrder={refreshOrder}
						settings={methodData}
						refreshProcessResult={refreshProcessResult}
					/>);
				break;
			case 'Stripe':
				setComponent(
					<Stripe
						order={order}
						setPaying={setPaying}
						refreshOrder={refreshOrder}
						settings={methodData}
						refreshProcessResult={refreshProcessResult}
					/>
				);
				break;
			case 'CashOnDelivery':
				setComponent(
					<CashOnDelivery
						order={order}
						setPaying={setPaying}
						refreshOrder={refreshOrder}
						settings={methodData}
						refreshProcessResult={refreshProcessResult}
					/>
				);
				break;
			default:
				return <></>;
		}

	}, [setComponent, methodData]);

	if (!component) return <></>;
	if (method !== selectedPaymentMethod) return <></>;

	return (
		<div className='w-full'>
			{component}
		</div>
	);
};

PaymentMethodContent.propTypes = {
	method: PropTypes.string.isRequired,
	methodData: PropTypes.object.isRequired,
	order: PropTypes.object.isRequired,
	refreshOrder: PropTypes.func.isRequired,
	refreshProcessResult: PropTypes.func.isRequired,
	selectedPaymentMethod: PropTypes.string.isRequired,
	setPaying: PropTypes.func.isRequired
};

export default PaymentMethodContent;
