import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';

import StripeForm            from '../../core/inputs/StripeForm/StripeForm';
import {UserContext} from '../../../context/UserContext';
import {useTranslation} from 'react-i18next';

const Stripe = ({ onTokenError, onIntentGenerated, setSaveStatus, location }, ref) => {
	const {t} 								  = useTranslation();
	const [stripe, setStripe]                 = useState(null);
	const [stripeElements, setStripeElements] = useState(null);
	const [saveEnabled, setSaveEnabled]       = useState(false);
	const [user] = useContext(UserContext);
	const [intent, setIntent] = useState(null);

	const onStripeChange = e => {
		setSaveEnabled(e.complete === true);
	};

	useEffect(() => {
		setSaveStatus(saveEnabled);
	}, [saveEnabled]);

	useImperativeHandle(ref, () => ({
		handleProcess: async () => {
			if (!stripe || !stripeElements)
				return; // stripe not loaded yet


			const result = await stripe.confirmSetup({
				elements: stripeElements,
				redirect: 'if_required',
				confirmParams: {
					payment_method_data: {
						billing_details: {
							name: `${location.First_Name} ${location.Last_Name}`,
							email: user.user.data.Email,
							address: {
								country: location.Country__,
								postal_code: location.Zip,
								state: location.Province ?? '',
								city: location.City ?? '',
								line1: location.Address ?? '',
								line2: location.Address2 ?? '',
							}
						},
					}, // Make sure to change this to your payment completion page
					//return_url: `https://${window.location.host}${getPXLOrderRoute(order.Order__)}`,
				}
			});

			if (result.error) {
				if (result.error.type === 'card_error' || result.error.type === 'validation_error') {
					onTokenError(result.error.message);
				} else {
					onTokenError(t('An unexpected error occurred.'));
				}

				return;
			}

			onIntentGenerated(intent);
		}
	}));

	return (
		<div>
			<StripeForm
				setIntent={setIntent}
				setStripeElements={setStripeElements}
				setStripe={setStripe}
				onChange={onStripeChange}
			/>
		</div>
	);
};

export default forwardRef(Stripe);

Stripe.propTypes = {
	onTokenError    : PropTypes.func,
	onIntentGenerated: PropTypes.func,
	setSaveStatus   : PropTypes.func,
	location   : PropTypes.object.isRequired,
};
