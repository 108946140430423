import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import { rest }                       from '@karpeleslab/klbfw';
import { useRest, useRestRefresh }    from '@karpeleslab/react-klbfw-hooks';

import Btn          from '../../core/inputs/Btn/Btn';
import { FiLoader } from 'react-icons/fi';
import { Link }     from 'react-router-dom';
import Plan         from '../../pxl/FanClubPlans/Plan';
import PopIn        from '../../layout/PopIn/PopIn';

import { useSetSnackbar } from '../../../hooks/useSnackbar';

import { dateFormat } from '../../../utils/date';

const CurrentSubscription = () => {
	const { t }               = useTranslation();
	const setSnackbar         = useSetSnackbar();
	const fetchSub            = useRestRefresh('Membership/@fanclub/User/@');

	const [confirmUnsub, setConfirmUnsub] = useState(false);
	const [unsubbing, setUnsubbing]       = useState(false);

	const [plans]                                 = useRest('Membership/@fanclub');
	const [userSubscription, setUserSubscription] = useState(null);

	const refreshSub = () => {
		fetchSub()
			.then(setUserSubscription)
			.catch(() => {}); // happens if user has no subscription
	};

	const unsubscribe = () => {
		setUnsubbing(true);
		rest('Membership/@fanclub/User/@:unsubscribe', 'POST')
			.then(() => {
				setConfirmUnsub(false);
				refreshSub();
			})
			.catch(err => setSnackbar(err.error, 'error'))
			.finally(() => setUnsubbing(false));
	};

	useEffect(() => refreshSub(), []);

	return (
		<>
			<div className="w-full flex flex-col items-center mt-4">
				{userSubscription?.data ? (
					<div
						className="w-full sm:w-1/2 flex items-stretch my-4"
						style={{ justifyContent: 'stretch' }}
					>
						<div className="pxl-fanclub-plan relative h-full flex flex-col justify-between items-center m-6 p-4 border border-white">
							<p className="text-3xl font-bold mt-4 mb-6">
								{userSubscription.data.Membership_Plan.Catalog_Product['Basic.Name']}
							</p>
							<p>{userSubscription.data.Membership_Plan.Catalog_Product['Description.Short']}</p>
							<div className="my-4">
								<p className="text-2xl text-center pb-6">
									{userSubscription.data.Price.tax.display_short} {t(`period_${userSubscription.data.Membership_Plan.Period}`)}
								</p>
								{userSubscription.data.Status === 'inactive' ? (
									<p className="text-md text-center text-red-600 pb-6">
										{t('subscription_end', { date: dateFormat(userSubscription.data.Expires, 'L') })}
									</p>
								) : (
									<p className="text-md text-center pb-6">
										{t('renew_date', { date: dateFormat(userSubscription.data.Expires, 'L') })}
									</p>
								)}
							</div>
							<div className="w-full flex justify-between items-center">
								{userSubscription.data.Status !== 'inactive' ? (
									<span
										className="text-red-600 hover:underline cursor-pointer"
										onClick={() => setConfirmUnsub(true)}
									>
										{t('unsubscribe')}
									</span>
								) : <span></span>}
								{userSubscription.data.Status === 'inactive' ? (
									<Btn variant="primary" to="/fanclub" accessibility={t('renew_upgrade')}>
										{t('renew_upgrade')}
									</Btn>
								) : (
									<Link
										className="hover:underline cursor-pointer"
										component="a"
										to="/fanclub"
										aria-label={t('change')}
									>
										{t('change')}
									</Link>
								)}
								
							</div>
						</div>
					</div>
				) : (plans?.data?.Membership_Plan_Free?.length > 0 ? (
					<>
						<Plan
							loggedIn={true}
							plan={Object.values(plans.data.Membership_Plan_Free)[0]}
							userSubscription={userSubscription ? userSubscription.data : null}
						/>
						<Link
							className="hover:underline cursor-pointer"
							component="a"
							to="/fanclub"
							aria-label={t('change')}
						>
							{t('change')}
						</Link>
					</>
				) : (
					<div className="w-full flex flex-col items-center">
						<p className="py-6 text-xl">{t('join_fanclub_long')}</p>
						<Btn variant="primary" to="/fanclub" accessibility={t('join_fanclub')}>
							{t('join_fanclub')}
						</Btn>
					</div>
				))}
			</div>
			<PopIn open={confirmUnsub} setOpen={setConfirmUnsub}>
				<h2 className="text-2xl text-center pb-4">
					{t('confirm_unsubscribe')}
				</h2>
				<p>{t('confirm_unsubscribe_description')}</p>
				<div className="w-full flex justify-between items-center mt-8">
					<Btn onClick={() => setConfirmUnsub(false)} accessibility={t('go_back')}>
						{t('go_back')}
					</Btn>
					<Btn
						variant="primary"
						disabled={unsubbing}
						onClick={unsubscribe}
						accessibility={t('confirm')}
					>
						{unsubbing ? <FiLoader className="animate-spin" /> : t('confirm')}
					</Btn>
				</div>
			</PopIn>
		</>
	);
};

export default CurrentSubscription;
