import React from 'react';
import LayoutHelmet from '../../components/layout/LayoutHelmet/LayoutHelmet';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useOrder} from '@karpeleslab/klb-react-services';
import Loader from '../../components/layout/Loader/Loader';
import NotFound from '../../components/core/utils/NotFound/NotFound';
import OrderWithPayment from '../../components/pxl/Order/OrderWithPayment';
import Hero from '../../components/layout/Hero/Hero';

const Order = () => {
	const {t} = useTranslation();
	const {orderId} = useParams();
	const [order, refresh] = useOrder(orderId);

	if (!order) {
		return (
			<main className="w-full max-w-3xl mb-auto mx-auto pt-4">
				<LayoutHelmet title={t('order')}/>
				<Loader/>
			</main>
		);
	}

	if (!order.data) {
		return (
			<main className="p-6">
				<LayoutHelmet title={t('error_content_not_found_title')}/>
				<NotFound/>
			</main>
		);
	}

	return (
		<>
			<LayoutHelmet title={t('order')}/>
			<Hero title={t('order_details')}/>
			<main className="w-full max-w-3xl mb-auto mx-auto">
				{order.data.Invoice_Number && (
					<div className="w-full max-w-7xl my-0 mx-auto flex flex-col justify-center">
						<h1 className="text-center text-2xl my-4 pxl-display-font">
							{t('order_no', {number: order.data.Invoice_Number})}
						</h1>
					</div>)
				}
				<OrderWithPayment refreshOrder={refresh} order={order.data}/>
			</main>
		</>
	);
};

export default Order;
