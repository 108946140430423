import React, { useContext, useEffect, useState } from 'react';
import PropTypes          from 'prop-types';
import { useHistory }     from 'react-router';
import { useTranslation } from 'react-i18next';

import Alert              from '../../layout/Alert/Alert';
import { BsFillStarFill } from 'react-icons/bs';
import Btn                from '../../core/inputs/Btn/Btn';
import Collapsible        from 'react-collapsible';
import EventTicketDetails from '../EventTicketDetails/EventTicketDetails';
import LoginModal         from '../../user/Login/LoginModal';

import { UserContext } from '../../../context/UserContext';

const EventTicketList = ({ event, tickets, hasReservation = false, hasReachedMaxReservation = false }) => {
	const { t }   = useTranslation();
	const history = useHistory();

	const standardSortedSeats = sortSeats(tickets, false);
	const subSortedSeats      = sortSeats(tickets, true);

	const [showLogin, setShowLogin]               = useState(false);
	const [loginCallbackUrl, setLoginCallbackUrl] = useState(null);
	const [{ subLoaded, subscription }, setUser, fetchSubscription]  = useContext(UserContext);

	const displayLogin = (requiresMembership, url) => {
		setLoginCallbackUrl({ requiresMembership, url });
		setShowLogin(true);
	};

	const onLoggedIn = data => {
		setUser(u => ({ ...u, loggedIn: true, user: { data: data.user } }));
		fetchSubscription();
	};

	useEffect(() => {
		if (subLoaded && loginCallbackUrl) {
			setShowLogin(false);
			setTimeout(() => {
				if (loginCallbackUrl && loginCallbackUrl.requiresMembership && !subscription)
					history.push('/fanclub');
				else if (loginCallbackUrl)
					history.push(loginCallbackUrl.url);
			}, 10);
		}
	}, [subLoaded, subscription, loginCallbackUrl]);

	return (
		<>
			{!hasReachedMaxReservation && <>
				<Collapsible
					triggerDisabled={!hasReservation}
					open={hasReservation ? undefined : true}
					trigger={hasReservation ? <Btn accessibility={t('event_buy_more')}>{t('event_buy_more')}</Btn> : undefined}
					triggerTagName='div'
					//classParentString={`pt-5 mt-5 text-center aa`}
				>
					{subSortedSeats.length > 0 && (
						<div className="px-4 pt-4 pb-2 mt-2">
							<div className="pxl-display-font font-bold mb-4">
								{t('event_reserved_to_fan')}
								<BsFillStarFill
									className="inline-block ml-2 text-amber-400"
									style={{ transform: 'translateY(-2px)' }}
								/>
							</div>
							{subSortedSeats.map(ticket => (
								<EventTicketDetails
									key={ticket.Planner_Event_Seat__}
									ticket={ticket}
									event={event}
									enableOrder
									displayLogin={displayLogin}
								/>
							))}
						</div>
					)}

					{standardSortedSeats.length > 0 &&
					<div className="px-4 pt-2 mt-2">
						<div className="pxl-display-font font-bold mb-4">
							{t('event_to_everybody')}
						</div>
						{standardSortedSeats.map(ticket => (
							<EventTicketDetails
								key={ticket.Planner_Event_Seat__}
								ticket={ticket}
								event={event}
								enableOrder
								displayLogin={displayLogin}
							/>
						))}
					</div>
					}
				</Collapsible>
			</>
			}

			{hasReachedMaxReservation &&
			<div className={'py-6 md:py-12'}>
				<Alert type={'warning'}>{t('event_reserve_max_reached_warn')}</Alert>
			</div>
			}
			<LoginModal
				open={showLogin}
				loginCallback={onLoggedIn}
				setOpen={setShowLogin}
			/>
		</>
	);
};

const sortSeats = (seats, isSub) => {
	const groupedSeats = seats.reduce((old, seat) => {
		if (isSub && seat.Membership_Required !== 'Y') {
			return old;
		} else if (!isSub && seat.Membership_Required === 'Y') {
			return old;
		}
		if (old[seat.Type]) {
			old[seat.Type] = [...old[seat.Type], seat];
			return old;
		}
		old[seat.Type] = [seat];
		return old;
	}, {});
	return [
		...(groupedSeats.seat ? groupedSeats.seat : []),
		...(groupedSeats.standing ? groupedSeats.standing : []),
		...(groupedSeats.slot ? groupedSeats.slot : []),
		...(groupedSeats.stream ? groupedSeats.stream : []),
	];
};

export default EventTicketList;

EventTicketList.propTypes = {
	hasReachedMaxReservation: PropTypes.bool,
	hasReservation: PropTypes.bool,
	event: PropTypes.object.isRequired,
	tickets: PropTypes.array.isRequired
};
