import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';

import ImgLoader from '../../layout/ImgLoader/ImgLoader';

const AlbumThirdPartyServicesLinks = ({ album }) => {
	const { t } = useTranslation();
	return (
		<div className="flex flex-wrap justify-center items-center">
			{album.Links.map(l => {
				const image = require(`../../../assets/img/${l.Store.toLowerCase()}.png`);
				return l.Visible && (
					<div 
						key={l.Music_Label_Album_Link__}
						className="flex-none m-2"
					>
						<a 
							className="block w-16 h-16 mx-auto p-4 rounded-full hover:bg-trueGray-100 transition-all"
							href={l.Link}
							target="_blank"
							rel="noopener noreferrer"
							title={t(`store_${l.Store}`)}
							aria-label={t(`store_${l.Store}`)}
						>
							{image && (
								<ImgLoader
									src={image.default}
									alt={l.Store}
									width="32px"
									height="32px"
								/>
							)}
						</a>
					</div>
				);
			})}
		</div>
	);
};

AlbumThirdPartyServicesLinks.propTypes = {
	album: PropTypes.shape({ 
		Links: PropTypes.arrayOf(
			PropTypes.shape({
				Store: PropTypes.string
			}).isRequired
		).isRequired
	}).isRequired,
};

export default AlbumThirdPartyServicesLinks;
