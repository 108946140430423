import React, { useContext } from 'react';
import { useTranslation }    from 'react-i18next';
import { Get }               from '@karpeleslab/klbfw';

import Helmet from 'react-helmet';
import Html   from './Html';
import Text   from './Text';

import { TemplateContext }      from '../../../context/TemplateContext';
import { getFrom, getFromName } from '../../utils';

const Mail = () => {
	const { t }      = useTranslation();
	const { artist } = useContext(TemplateContext);

	return (
		<div>
			<Helmet>
				<title>{t('email_welcome_fanclub_title')}</title>
				<meta name="mail-from-name" content={getFromName()} />
				<meta name="mail-from" content={getFrom()} />
			</Helmet>
			<Html artist={artist?.Name} plan={Get('_product')?.['Basic.Name']}/>
			<Text artist={artist?.Name} plan={Get('_product')?.['Basic.Name']} />
		</div>
	);
};

export default Mail;
