import React, { useEffect } from 'react';
import { useTranslation }   from 'react-i18next';
import PropTypes            from 'prop-types';
import { useRest }          from '@karpeleslab/react-klbfw-hooks';

import Select from '../Select/Select';

const CountrySelect = ({ onChange, value, isGeoLookup= false, ...etcetera }) => {
	const { t }       = useTranslation();
	const [geoLookup] = useRest('ThirdParty/Geoip:lookup');
	const [countries] = useRest('Country');

	useEffect(() => {
		if(!isGeoLookup) return;
		if (geoLookup && geoLookup.data && geoLookup.data.country && geoLookup.data.country.iso_code) {
			onChange({ target: { value: geoLookup.data.country.iso_code }});
		}
	}, [geoLookup, isGeoLookup]);

	const options = (countries && countries.data)
		? countries.data.map(c => ({ name: c.Name, value : c.Country__ }))
		: [{ name: t('loading'), value: 'invalid'}];

	return (
		<div className="w-full my-4 pt-6">
			<Select
				label={t('country')}
				options={options}
				value={value}
				onChange={onChange}
				{...etcetera}
			/>
		</div>
	);
};

CountrySelect.propTypes = {
	onChange: PropTypes.func,
	isGeoLookup: PropTypes.bool,
	value   : PropTypes.string
};

export default CountrySelect;
