import React, { useContext, useState } from 'react';
import { useTranslation }              from 'react-i18next';
import LayoutHelmet                    from '../../components/layout/LayoutHelmet/LayoutHelmet';
import CartElement                     from '../../components/pxl/CartElement/CartElement';
import { useSetSnackbar }              from '../../hooks/useSnackbar';
import { rest }                        from '@karpeleslab/klbfw';
import Btn                             from '../../components/core/inputs/Btn/Btn';
import { UserContext }                 from '../../context/UserContext';
import Billing                         from '../../components/user/Billing/Billing';
import Hero                            from '../../components/layout/Hero/Hero';
import LoginModal                      from '../../components/user/Login/LoginModal';
import { FiLoader }                    from 'react-icons/fi';
import { useHistory }                  from 'react-router-dom';

export default function CheckoutShop() {
	const { t } = useTranslation();
	const [{ loggedIn }, setUser] = useContext(UserContext);
	const [location, setLocation] = useState(null);
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const setSnackbar = useSetSnackbar();

	const onValidated = data => setUser({ loggedIn: true, user: { data: data.user } });

	const next = () => {
		setLoading(true);
		return rest('Catalog/Cart/@:createOrder', 'POST')
			.then((res) => {
				history.push(`/order/${res.data.Order__}`);
			})
			.catch((error) => {
				setLoading(false);
				setSnackbar(error.message, 'error');
			});
	};

	return <>
		<LayoutHelmet title={t('checkout_title')}/>
		<Hero title={t('checkout_title')}/>
		<main className='w-full max-w-7xl mb-auto mx-auto pt-4'>
			<CartElement isCheckout={true}/>
			{loggedIn ? (
				<>
					<div className={'mt-8'}>
						<Billing fit={true} onLocationSelected={setLocation}/>
					</div>
					<div className={'flex justify-between items-center w-full mt-8 px-3 md:px-0'}>
						<Btn
							disabled={loading}
							onClick={() => history.goBack()}
							accessibility={t('back_btn')}
						>
							{t('back_btn')}
						</Btn>
						<Btn
							disabled={!location || loading}
							onClick={next}
							accessibility={t('cart_process_payment')}
						>
							{loading ? <FiLoader className='animate-spin'/> : t('cart_process_payment')}
						</Btn>
					</div>
				</>
			) : <LoginModal loginCallback={onValidated} open/>}
		</main>
	</>;
}
