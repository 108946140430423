import React from 'react';
import PropTypes from 'prop-types';

export default function Alert({children, type, className = ''}) {
	let typeClass = 'bg-green-600';
	let textClass = 'text-white';
	if (type === 'success') {
		typeClass = 'bg-green-600';
	} else if (type === 'error') {
		typeClass = 'bg-red-600';
	} else if (type === 'warning') {
		typeClass = 'bg-orange-600';
	} else if (type === 'info') {
		typeClass = 'bg-gray-600';
	}
	return <div className={`${typeClass} p-4 ${textClass} w-full ${className}`} role={'alert'}>
		{children}
	</div>;
}

Alert.propTypes = {
	children: PropTypes.node,
	type: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
	className: PropTypes.string,
};
