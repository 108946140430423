import React, { useContext, useEffect, useState } from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';

import BtnWithArrow from '../BtnWithArrow/BtnWithArrow';

import { TemplateContext } from '../../../context/TemplateContext';

import './HeroAlt.css';

const HeroAlt = ({ children = null, title = '' }) => {
	const { t }       = useTranslation();
	const { cover }   = useContext(TemplateContext);
	const [bg, setBg] = useState(null);

	useEffect(() => {
		if (cover && cover.Url && typeof window !== 'undefined') {
			const img = new Image();
			img.onload = () => setBg(cover.Url);
			img.src = cover.Url;
		}
	}, [cover]);

	return (
		<header
			className={'pxl-hero-alt relative w-full box-border h-full pt-24 bg-gray-900 text-white'}
			style={{ minHeight: '80vh' }}
		>
			<div
				className="absolute top-0 left-0 z-0 w-full h-full bg-cover bg-fixed bg-center transition-opacity duration-500"
				style={{
					backgroundImage: bg ? `url(${bg})` : 'none',
					opacity: bg ? '1' : '0'
				}}
			>
			</div>
			<div className="absolute bottom-0 left-0 z-10 w-full flex flex-col justify-center items-center pb-24">
				<div className="w-full max-w-7xl m-auto flex flex-wrap justify-start items-center px-2 pb-6 js-hero">
					<div className="flex w-full lg:w-1/2 p-2 md:pl-6 flex-col justify-center items-center">
						{title.length > 0 && (
							<h1
								className="c-hero__title w-full sm:w-auto text-center sm:text-left text-5xl sm:text-7xl italic pb-4"
								style={{ mixBlendMode: 'exclusion' }}
							>
								{title}
							</h1>
						)}
						<div
							className="c-hero__catchphrase w-full pb-6 text-justify text-3xl"
							style={{ mixBlendMode: 'exclusion' }}
						>
							{children}
						</div>
					</div>
				</div>
				<BtnWithArrow
					size="large"
					className="flex-none"
					to="#about"
					accessibility={t('learn_more')}
				>
					{t('learn_more')}
				</BtnWithArrow>
			</div>
		</header>
	);
};

HeroAlt.propTypes = {
	children: PropTypes.node,
	title   : PropTypes.string
};

export default HeroAlt;
