import React, { useContext, useRef, useState } from 'react';
import { useParams }      from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRest }        from '@karpeleslab/react-klbfw-hooks';
import { rest }           from '@karpeleslab/klbfw';

import Billing                     from '../../components/user/Billing/Billing';
import Btn                         from '../../components/core/inputs/Btn/Btn';
import EditBilling                 from '../../components/user/EditBilling/EditBilling';
import LayoutHelmet                from '../../components/layout/LayoutHelmet/LayoutHelmet';
import Redirect                    from '../../components/core/utils/Redirect/Redirect';
import Stripe                      from '../../components/user/Billing/Stripe';
import SubscriptionCheckoutSummary from '../../components/pxl/SubscriptionCheckoutSummary/SubscriptionCheckoutSummary';

import { useSetSnackbar } from '../../hooks/useSnackbar';
import { UserContext }    from '../../context/UserContext';
import LoginModal         from '../../components/user/Login/LoginModal';
import { FiLoader }       from 'react-icons/fi';

const CheckoutSubscription = () => {
	const { subscriptionId }        = useParams();
	const { t }                     = useTranslation();
	const [data]                    = useRest(`Membership/Plan/${subscriptionId}`);
	const [{ loggedIn }, setUser]   = useContext(UserContext);
	const stripeRef                 = useRef(null);
	const [location, setLocation]   = useState(null);
	const [stripeOK, setStripeOK]   = useState(false);
	const [loading, setLoading]     = useState(false);
	const [completed, setCompleted] = useState(false);
	const setSnackbar               = useSetSnackbar();
	
	const [existingBilling, setExistingBilling] = useState([]);
	const checkBilling = (data) => {
		if (data && data.length > 0 && data[0].User_Billing__)
			setExistingBilling(data[0].User_Billing__);
		else
			setExistingBilling(null);
	};

	const getStripeToken = () => {
		setLoading(true);
		stripeRef.current.handleProcess();
	};

	const onStripeError = msg => {
		setSnackbar(msg, 'error');
		setLoading(false);
	};

	const onValidated = data => setUser({ loggedIn: true, user: { data: data.user } });

	const processOrder = async intent => {
		try {
			const args = {};
			if (existingBilling)
				args.User_Billing__ = existingBilling;
			else {
				args.User_Billing = {
					stripe_intent  : intent.stripe_intent,
					Label          : 'Fanclub subscription',
					method         : 'Stripe',
					User_Location__: location.User_Location__,
				};
			}
			const { data } = await rest(`Membership/Plan/${subscriptionId}:subscribe`, 'POST', args);
			if (data) {
				setSnackbar(t('thank_you_purchase'), 'success');
				setCompleted(true);
			} else
				setSnackbar(t('error_generic'), 'error');
		} catch (err) {
			setSnackbar(err.message, 'error');
		}
		setLoading(false);
	};

	return completed ? <Redirect target="/user" /> : (
		<main className="max-w-7xl my-0 mx-auto">
			<LayoutHelmet title={t('subscribe_now')} />
			<h1 className="pb-8 text-6xl text-center pt-28">{t('subscribe_now')}</h1>
			<div className="flex flex-col-reverse md:flex-row flex-wrap justify-around items-center md:items-start">
				<div className="md:flex-none p-4">
					<div className="pb-4">
						<h1 className="pb-8 text-2xl text-left">{t('billing_address')}</h1>
						{loggedIn ? (
							<Billing onLocationSelected={setLocation} />
						) : (
							<p>{t('login_to_continue')}</p>
						)}
					</div>
					<div className="pb-4">
						<h1 className="pt-8 text-2xl text-left">{t('credit_card_information')}</h1>
						{loggedIn ? (
							<div className="md:mr-4">
								{existingBilling ? (
									<EditBilling onLoad={checkBilling} />
								) : (
									<Stripe
										location={location}
										ref={stripeRef}
										onTokenError={onStripeError}
										onIntentGenerated={processOrder}
										setSaveStatus={setStripeOK}
									/>
								)}
							</div>
						) : (
							<p>{t('login_to_continue')}</p>
						)}
					</div>
				</div>
				<div className="md:sticky top-0 md:flex-none md:max-w-md p-6 overflow-hidden">
					<h1 className="pb-8 text-2xl text-left">{t('order_summary')}</h1>
					{(data && data.data && data.data.Catalog_Product) && (
						<SubscriptionCheckoutSummary plan={data.data.Catalog_Product} />
					)}
				</div>
			</div>
			<div className="w-full pt-4">
				<Btn
					variant="primary"
					className="w-full"
					disabled={!location || (!stripeOK && !existingBilling) || loading}
					onClick={() => !existingBilling ? getStripeToken() : processOrder()}
					accessibility={t('proceed')}
				>
					{loading ? <FiLoader className="animate-spin" /> : t('proceed')}
				</Btn>
			</div>
			{!loggedIn && <LoginModal loginCallback={onValidated} open={true} />}
		</main>
	);
};

export default CheckoutSubscription;
