import React, { useState } from 'react';
import { useTranslation }  from 'react-i18next';
import { useLocation }     from 'react-router-dom';
import { useRest }         from '@karpeleslab/react-klbfw-hooks';

import ArgsBuilderFromUrl, { 
	buildArgsFromUrlSearchParams 
} from '../../core/utils/ArgsBuilderFromUrl/ArgsBuilderFromUrl';

import Btn                 from '../../core/inputs/Btn/Btn';
import Loader              from '../../layout/Loader/Loader';
import ModuleNotLoaded     from '../../core/utils/ModuleNotLoaded/ModuleNotLoaded';
import NewsCard            from '../NewsCard/NewsCard';
import NewsSelectedFilters from '../NewsSelectedFilters/NewsSelectedFilters';
import Paging              from '../../layout/Paging/Paging';

export default function ExclusiveArticleList() {
	const { t }                 = useTranslation();
	const location              = useLocation();
	const imageVariation        = 'strip&scale_crop=305x171';
	const [args, setArgs]       = useState({
		image_variation: imageVariation,
		sort           : 'weight:desc,published:desc',
		audience       : 'web',
		...buildArgsFromUrlSearchParams(location),
	});

	const [filters, setFilters] = useState(args?.query?.tag.map(f => ({ Classify_Tag__: f })) || []);
	
	const [data] = useRest('Membership/@fanclub:cms_search', args, false, 10000); // keep cache for 10 seconds

	const addFilters = filtersArr => {
		filtersArr.forEach(filter => {
			const idx = filters.findIndex(f => f.Classify_Tag__ === filter.Classify_Tag__);
			if (idx < 0)
				setFilters(filters.concat([filter])); // Setting the filters will trigger a refresh as the useRest parameters changed
		});
	};

	const goToPage = page_no => setArgs(a => ({ ...a, page_no }));

	const removeFilters = filtersArr => {
		const tmp = JSON.parse(JSON.stringify(filters));
		filtersArr.forEach(filter => {
			const idx = tmp.findIndex(f => f.Classify_Tag__ === filter.Classify_Tag__);
			if (idx >= 0)
				tmp.splice(idx, 1);
		});
		setFilters(tmp);
	};

	if (!data) // loading
		return <Loader/>;
	else if (data && data.result !== 'success') // error 
		return <ModuleNotLoaded/>;
	else // OK
		return (
			<div className="w-full">
				<ArgsBuilderFromUrl args={args} filters={filters} setArgs={setArgs} />
				<div className="flex flex-col-reverse md:flex-row justify-start items-between">
					<div className="flex flex-wrap w-full md:w-3/4">
						{data?.data?.data?.length > 0
							? data.data.data.map(item => (
								<NewsCard
									key={item.Content_Cms_Entry__}
									addFilters={addFilters}
									filters={filters}
									imageVariation={imageVariation}
									item={item}
									type="articles"
								/>))
							: <p className="w-full text-xl text-center">{t('no_articles_yet')}</p>
						}
					</div>
					<div className="w-full md:w-1/4 flex flex-col-reverse md:flex-col mt-4">
						<div className="py-6 px-4 flex flex-col items-center mt-4 mb-8 md:mt-0 border border-white">
							<h3 className="text-2xl pb-4">{t('got_more_exclusives')}</h3>
							<p className="pb-4">{t('got_more_exclusives_description')}</p>
							<Btn
								to="/fanclub"
								variant="inverted"
								accessibility={t('change')}
							>
								{t('change')}
							</Btn>
						</div>
						<NewsSelectedFilters
							classify={data.data.content_cms.Classify.Classify__}
							selectedFilters={filters}
							onRemoveFilter={filtersArr => removeFilters(filtersArr)}
						/>
					</div>
				</div>
				{data.paging && (
					<Paging
						page_max={data.paging.page_max}
						page_no={data.paging.page_no}
						onClick={goToPage}
					/>
				)}
			</div>
		);
}
