import { useMediaQuery } from 'react-responsive';

export const useDesktopMediaQuery = () =>
	useMediaQuery({ query: '(min-width: 1280px)' });

export const useTabletAndBelowMediaQuery = () =>
	useMediaQuery({ query: '(max-width: 1279px)' });

export const useMobileMediaQuery = () => 
	useMediaQuery({ query: '(max-width: 767px)' });

export const useCustomMediaQuery = maxPx => 
	useMediaQuery({ query: `(max-width: ${maxPx}px)`});

export const Desktop = ({ children }) => 
	useDesktopMediaQuery() ? children : null;

export const TabletAndBelow = ({ children }) =>
	useTabletAndBelowMediaQuery() ? children : null;

export const Mobile = ({ children }) =>
	useMobileMediaQuery() ? children : null;
