import React, { useContext }               from 'react';
import PropTypes                           from 'prop-types';
import { Redirect, Route }                 from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { UserContext } from '../../context/UserContext';

export default function RouteTransition({ path, children, requiresLogin = false, ...etc }) {
	const [{ loading, loggedIn }] = useContext(UserContext);

	if (loading) {
		return <></>;
	} else {
		return (
			<SwitchTransition mode='out-in'>
				<CSSTransition
					key={path || 'default-key'}
					classNames='u-transition--lateral'
					addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
				>
					<Route path={path} {...etc}>
						{(requiresLogin && !loggedIn) ? <Redirect to={`/login?return_to=${path}`}/> : children}
					</Route>
				</CSSTransition>
			</SwitchTransition>
		);
	}
}

RouteTransition.propTypes = {
	path: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired,
	requiresLogin: PropTypes.bool
};
