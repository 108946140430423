import React      from 'react';
import PropTypes  from 'prop-types';

import { AddToCard }  from '../AddToCard/AddToCard';
import ImgLoader      from '../../layout/ImgLoader/ImgLoader';
import { Link }       from 'react-router-dom';
import MissingPicture from '../MissingPicture/MissingPicture';
import { OutOfStock } from '../OutOfStock/OutOfStock';
import ShopPrice      from '../ShopPrice/ShopPrice';

import { imgKey } from './ShopItems';

import './ShopItem.css';

export const getImage = (product) => {
	if (product.Image.main)
		return product.Image.main[0][imgKey];
	else if (product.Image.list)
		return product.Image.list[0][imgKey];

	return null;
};

export default function ShopItem({product}) {
	const title = product['Basic.Name'];
	const img   = getImage(product);
	const stock = typeof product.Deliverable.Stock === 'string'
		? parseInt(product.Deliverable.Stock)
		: product.Deliverable.Stock;

	let maxAmount  = 6;
	const hasStock = !product.Deliverable.Use_Stock || (product.Deliverable.Use_Stock && stock > 0);
	if (hasStock && stock > 0)
		maxAmount = stock;

	return (
		<div className="shop-item relative flex-col items-center w-72 m-2 border border-white">
			<Link
				to={`/shop/${product.Catalog_Product__}`}
				className="flex flex-col justify-center items-center item-link w-full"
				aria-label={title}
			>
				<div
					className="relative w-full"
					style={{ paddingTop: '100%' }}
				>
					<div className="absolute top-0 left-0 w-full h-full">
						{img ? (
							<ImgLoader
								src={img}
								alt={title}
								width='100%'
								height='100%'
								showSpinner={false}
							/>
						) : (
							<MissingPicture className="border border-white"/>
						)}
					</div>
				</div>
				<div className="mt-2 px-2 font-bold text-xl item-text">{title}</div>
			</Link>
			<ShopPrice item={product}/>
			<div className="w-full mt-auto p-2">
				{hasStock ? (
					<AddToCard id={product.Catalog_Product__} maxAmount={maxAmount}/>
				) : (
					<div className="flex-1 flex justify-center items-center">
						<OutOfStock/>
					</div>
				)}
			</div>
		</div>
	);
}

ShopItem.propTypes = {
	product: PropTypes.object
};
