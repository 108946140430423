import React, { useContext }                  from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation }                     from 'react-i18next';
import { useRest }                            from '@karpeleslab/react-klbfw-hooks';

import Btn                    from '../../components/core/inputs/Btn/Btn';
import { EventDetails }       from '../../components/pxl/EventDetails/EventDetails';
import EventHeader            from '../../components/pxl/EventDetails/EventHeader';
import EventStream            from '../../components/pxl/EventDetails/EventStream';
import EventStreamAlert       from '../../components/pxl/EventDetails/EventStreamAlert';
import EventTicketListWrapper from '../../components/pxl/EventTicketList/EventTicketListWrapper';
import LayoutHelmet           from '../../components/layout/LayoutHelmet/LayoutHelmet';
import Loader                 from '../../components/layout/Loader/Loader';
import ModuleNotLoaded        from '../../components/core/utils/ModuleNotLoaded/ModuleNotLoaded';
import NotFound               from '../../components/core/utils/NotFound/NotFound';

import { useMobileMediaQuery } from '../../hooks/useMediaQueries';
import { dateFormat }          from '../../utils/date';
import { UserContext }         from '../../context/UserContext';
import Comments                from '../../components/pxl/Comments/Comments';
import {TemplateContext}       from '../../context/TemplateContext';

const useQuery = () => new URLSearchParams(useLocation().search);
export const EVENT_TOP_VARIATION = 'strip&format=jpeg&scale=200x280';

export default function Event() {
	const { t }             = useTranslation();
	const history           = useHistory();
	const { slug }          = useParams();
	const query             = useQuery();
	const args              = { slug: decodeURIComponent(slug) };
	const isMobile          = useMobileMediaQuery();
	const [{ loggedIn }]    = useContext(UserContext);
	let enableComments      = true;

	const settings           = useContext(TemplateContext);

	if(!settings.social || !settings.social.includes('comment')) {
		enableComments = false;
	}

	if (query.get('_preview'))
		args._preview = query.get('_preview');

	args.image_variation = EVENT_TOP_VARIATION;

	const [data] = useRest('Planner/@planner/Schedule:loadBySlug', args, true);

	if (data === null) {
		return <Loader/>;
	} else if (data === false) {
		return (
			<>
				<LayoutHelmet title={t('error_content_not_found_title')} />
				<main className='p-6'>
					<NotFound/>
				</main>
			</>
		);
	} else if (data.result !== 'success') {
		return <ModuleNotLoaded/>;
	} else if (data.data && data.data.content_cms_entry_data) {
		return (
			<>
				<LayoutHelmet
					description={data.data.content_cms_entry_data.Short_Contents}
					image={data.data.content_cms_entry_data?.Top_Drive_Item?.Media_Image?.Url}
					title={data.data.content_cms_entry_data.Title}
				/>
				{(data.data.planner_schedule?.Planner_Event && loggedIn) && (
					<EventStream
						event={data.data.planner_schedule.Planner_Event}
						thumbnail={data.data.content_cms_entry_data?.Drive_Item?.View_Url}
					/>
				)}
				<main className='w-full max-w-7xl my-0 mx-auto pt-28 mb-auto'>
					<h1 className="mb-4 text-4xl font-bold text-center pxl-display-font">{data.data.content_cms_entry_data.Title}</h1>
					{(data.data?.planner_schedule?.Planner_Event?.Event_Date) && (
						<h2 className="mb-8 text-2xl font-bold text-center pxl-display-font">
							{dateFormat(data.data.planner_schedule.Planner_Event.Event_Date, 'MMMM Do YYYY, HH:mm')}
						</h2>
					)}
					{(data.data?.planner_schedule?.Planner_Event) && (
						<div className="mx-4 mb-2">
							<EventStreamAlert event={data.data.planner_schedule.Planner_Event} />
						</div>
					)}
					<div className="flex flex-col md:flex-row">
						<div className="flex-1">
							<EventHeader cms={data.data.content_cms_entry_data} event={data.data.planner_schedule}/>
							{!isMobile && (
								<>
									<EventDetails
										alternatives={data.alternatives}
										cms={data.data.content_cms_entry_data}
										event={data.data.planner_schedule}
										slug={slug}
									/>
									<div className='p-6'>
										<Btn onClick={() => history.goBack()} accessibility={t('go_back')}>
											{t('go_back')}
										</Btn>
									</div>
								</>
							)}
						</div>
						{data.data.planner_schedule.Planner_Event && (
							<div className="w-full md:max-w-md mt-4 sm:mt-0">
								<EventTicketListWrapper event={data.data.planner_schedule.Planner_Event }/>
							</div>
						)}
						{isMobile && (
							<>
								<EventDetails
									alternatives={data.alternatives}
									cms={data.data.content_cms_entry_data}
									event={data.data.planner_schedule}
									slug={slug}
								/>
								<div className='p-6'>
									<Btn onClick={() => history.goBack()} accessibility={t('go_back')}>
										{t('go_back')}
									</Btn>
								</div>
							</>
						)}
					</div>
					{enableComments && <div className='py-4 w-full'>
						<Comments
							id={data.data.content_cms_entry_data.Comments.Key}
							path={`Content/Cms/Entry/${data.data.content_cms_entry_data.Content_Cms_Entry__}:comment`}
						/>
					</div>}
				</main>
			</>
		);
	}
}
