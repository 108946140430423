import React from 'react';
import EventTicketListWithReserve from '../EventTicketList/EventTicketListWithReserve';
import {useRest} from '@karpeleslab/react-klbfw-hooks';
import {getPlannerEventSeatReserveEndpoint} from '../../../hooks/usePlannerEventSeatReserve';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Loader from '../../layout/Loader/Loader';


export const EventUserTickets = ({event}) => {
	const {t} = useTranslation();

	const params = {Status: {'$in': ['reserve_paid', 'reserve_conf']}, results_per_page: 100};
	const [reserves] = useRest(getPlannerEventSeatReserveEndpoint(event.Planner_Event__), params, false, 3000);

	if (!reserves) {
		return <div style={{height: '200px'}}>
			<Loader />
		</div>;
	}

	if (reserves.data.length === 0) {
		return <></>;
	}

	return <>
		<div className="mx-4 text-2xl font-bold pxl-display-font">
			{t('your_reservation')}
		</div>
		<EventTicketListWithReserve event={event}/>
		<div className="mx-4 mt-4 mb-2 text-2xl font-bold pxl-display-font">
			{t('if_you_need_more')}
		</div>
	</>;
};

EventUserTickets.propTypes = {
	event: PropTypes.object.isRequired,
};
