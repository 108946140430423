import React     from 'react';
import PropTypes from 'prop-types';

import {
	FaFacebookF,
	FaInstagram,
	FaTwitter,
	FaYoutube,
	FaTwitch,
	FaReddit,
	FaTiktok
} from 'react-icons/fa';
import { GrSoundcloud } from 'react-icons/gr';

const SocialNetworkBtn = ({ link, name }) => {
	const getHoverStyle = () => {
		switch (name) {
			case 'facebook':
				return 'hover:bg-sky-700';
			case 'instagram':
				return 'hover:bg-gradient-to-bl hover:from-fuchsia-600 hover:to-orange-400';
			case 'soundcloud':
				return 'hover:bg-orange-400';
			case 'twitter':
				return 'hover:bg-sky-400';
			case 'youtube':
				return 'hover:bg-red-500';
			case 'twitch':
				return 'hover:bg-purple-500';
			case 'reddit':
				return 'hover:bg-orange-400';
			case 'tiktok':
				return 'hover:bg-sky-400';
		}
	};

	const getIcon = () => {
		switch (name) {
			case 'facebook':
				return <FaFacebookF />;
			case 'instagram':
				return <FaInstagram />;
			case 'soundcloud':
				return <GrSoundcloud />;
			case 'twitter':
				return <FaTwitter />;
			case 'youtube':
				return <FaYoutube />;
			case 'reddit':
				return <FaReddit />;
			case 'twitch':
				return <FaTwitch />;
			case 'tiktok':
				return <FaTiktok />;
		}
	};

	return (
		<a
			href={link}
			target="_blank"
			rel="noopener noreferrer"
			className={`mr-4 p-1 text-xs rounded-full bg-gray-400 text-pxl-darkerGray no-underline ${getHoverStyle()}`}
			aria-label={name}
		>
			{getIcon()}
		</a>
	);
};

SocialNetworkBtn.propTypes = {
	link: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired
};

export default SocialNetworkBtn;