// from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/NoSsr/NoSsr.js 
import * as React from 'react';
import PropTypes  from 'prop-types';

const specialProperty = 'exact-prop: \u200b';
function exactProp(propTypes) {
	if (process.env.NODE_ENV === 'production') 
		return propTypes;
  
	return {
		...propTypes,
		[specialProperty]: (props) => {
			const unsupportedProps = Object.keys(props).filter((prop) => !propTypes.hasOwnProperty(prop)); // eslint-disable-line
			if (unsupportedProps.length > 0) {
				return new Error(
					`The following props are not supported: ${unsupportedProps
					.map((prop) => `\`${prop}\``)
					.join(', ')}. Please remove them.`,
				);
			}
			return null;
		},
	};
}
  

const useEnhancedEffect =
	typeof window !== 'undefined' && process.env.NODE_ENV !== 'test'
		? React.useLayoutEffect
		: React.useEffect;

/**
 * NoSsr purposely removes components from the subject of Server Side Rendering (SSR).
 *
 * This component can be useful in a variety of situations:
 * - Escape hatch for broken dependencies not supporting SSR.
 * - Improve the time-to-first paint on the client by only rendering above the fold.
 * - Reduce the rendering time on the server.
 * - Under too heavy server load, you can turn on service degradation.
 */
function NoSsr(props) {
	const { children, defer = false, fallback = null } = props;
	const [mountedState, setMountedState] = React.useState(false);

	useEnhancedEffect(() => {
		if (!defer) {
			setMountedState(true);
		}
	}, [defer]);

	React.useEffect(() => {
		if (defer) {
			setMountedState(true);
		}
	}, [defer]);

	// We need the Fragment here to force react-docgen to recognise NoSsr as a component.
	return <React.Fragment>{mountedState ? children : fallback}</React.Fragment>;
}

NoSsr.propTypes = {
	// ----------------------------- Warning --------------------------------
	// | These PropTypes are generated from the TypeScript type definitions |
	// |	 To update them edit the d.ts file and run "yarn proptypes"	 |
	// ----------------------------------------------------------------------
	/**
	 * You can wrap a node.
	 */
	children: PropTypes.node,
	/**
	 * If `true`, the component will not only prevent server-side rendering.
	 * It will also defer the rendering of the children into a different screen frame.
	 */
	defer: PropTypes.bool,
	/**
	 * The fallback content to display.
	 */
	fallback: PropTypes.node,
};

if (process.env.NODE_ENV !== 'production') {
	// eslint-disable-next-line
	NoSsr['propTypes' + ''] = exactProp(NoSsr.propTypes);
}

export default NoSsr;
