import React              from 'react';
import { useTranslation } from 'react-i18next';

const ModuleNotLoaded = () => {
	const { t } = useTranslation();
	return (
		<div>{ t('module_not_loaded') }</div>
	);
};

export default ModuleNotLoaded;
