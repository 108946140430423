import React, {useEffect, useState} from 'react';
import PropTypes                    from 'prop-types';
import moment                       from 'moment';
import { useTranslation }           from 'react-i18next';
import { getLocale }                from '@karpeleslab/klbfw';

import { AddToCard }      from '../AddToCard/AddToCard';
import ImgLoader          from '../../layout/ImgLoader/ImgLoader';
import MissingPicture     from '../MissingPicture/MissingPicture';
import NoSsr              from '../../core/utils/NoSsr/NoSsr';
import { OutOfStock }     from '../OutOfStock/OutOfStock';
import ShareButtons       from '../../core/inputs/ShareButtons/ShareButtons';
import ShopImageSelector  from '../ShopImageSelector/ShopImageSelector';
import ShopPrice          from '../ShopPrice/ShopPrice';
import Tags               from '../Tags/Tags';

export default function ShopEntryElement({entry}) {
	const {t}                     = useTranslation();
	const [images, setImages]     = useState([]);
	const [selected, setSelected] = useState('');
	const [tags]                  = useState((entry['Basic.Tags'] ?? []).filter(t => !('hidden' in (t.Flags ?? {}))));
	const locale                  = getLocale();

	const formatDate = dateSec => 
		moment(dateSec * 1000).locale(locale).format('L');

	useEffect(() => {
		if (entry.Image) {
			const arrayImages = [
				...(entry.Image.main ? entry.Image.main : []),
				...(entry.Image.list ? entry.Image.list : []),
			];
			if (arrayImages.length === 0)
				return;

			setImages(arrayImages);
			setSelected(arrayImages[0].Url);
		}
	}, [entry]);

	const element = images.length > 0 ? (
		<>
			<ShopImageSelector images={images} selected={selected} onClick={(selected) => setSelected(selected)}/>
			<div className={'flex-1'}>
				<ImgLoader
					src={selected}
					alt={entry['Basic.Name']}
					width="100%"
					height="100%"
					className="object-contain"
				/>
			</div>
		</>
	) : <MissingPicture/>;

	const stock    = typeof entry.Deliverable.Stock === 'string' ? parseInt(entry.Deliverable.Stock) : entry.Deliverable.Stock;
	let maxAmount  = 6;
	const hasStock = !entry.Deliverable.Use_Stock || (entry.Deliverable.Use_Stock && stock > 0);
	if (hasStock && stock > 0)
		maxAmount = stock;

	return <div className={'flex flex-col md:flex-row'}>
		<div className={'flex md:flex-row flex-col-reverse w-full md:w-3/4 mr-20'}>
			{element}
		</div>
		<div className={'w-full md:w-1/4'}>
			<div className={'font-bold text-xl pxl-display-font mb-4 mx-3 md:mx-2'}>{entry['Basic.Name']}</div>
			{tags && <div className={'mb-4 mx-3 md:mx-2'}>
				<Tags tags={tags}/>
			</div>}
			{entry['Description.Author'] &&
			<div className={'mb-4 mx-3 md:mx-2'}>
				<span className={'text-sm'}>{t('shop_from_author')}</span>
				<span className={'mx-3 md:mx-2 underline pxl-display-font'}>{entry['Description.Author']}</span>
			</div>}
			<div className={'mb-4 mx-3 md:mx-2'}>{<ShopPrice item={entry} textAlign={'text-left'}/>}</div>
			<div className={'mb-4 mx-3 md:mx-2'}>
				{hasStock ?
					<AddToCard id={entry.Catalog_Product__} maxAmount={maxAmount}/> :
					<OutOfStock/>
				}
			</div>
			{entry['Description.CatchPhrase'] &&
			<div className={'mb-4 mx-3 md:mx-2 p-4 bg-pxl-darkGray'}>{entry['Description.CatchPhrase']}</div>}
			{entry['Basic.Date_Release'] && <div className={'mb-4 mx-3 md:mx-2'}>
				<span className={'text-sm'}>{t('shop_release_date')}</span>
				<span className={'mx-3 md:mx-2 pxl-display-font'}>{formatDate(entry['Basic.Date_Release'].unix)}</span></div>}
			{entry['Description.Short'] &&
			<div className={'mb-4 mx-3 md:mx-2 p-4 bg-pxl-darkGray'}>{entry['Description.Short']}</div>}
			<div className="p-4 text-justify">
				<NoSsr>
					<ShareButtons/>
				</NoSsr>
			</div>
		</div>
	</div>;
}
ShopEntryElement.propTypes = {
	entry: PropTypes.object
};
