import React     from 'react';
import PropTypes from 'prop-types';

import './Hero.css';
export default function Hero ({ title = '', className = '' }) {
	return (
		<header className={`pxl-hero-wrapper relative flex justify-center items-center ${className}`}>
			<div className="pxl-hero absolute top-0 left-0 w-full h-full max-w-7xl bg-contain"></div>
			<h1 className="relative z-20 w-full max-w-7xl ml-3 md:ml-0 pr-8 pxl-display-font text-4xl text-center">{title}</h1>
		</header>
	);
}

Hero.propTypes = {
	title: PropTypes.string,
	className: PropTypes.string,
};
