import PropTypes          from 'prop-types';
import React              from 'react';
import { useTranslation } from 'react-i18next';

const CartHeader = ({ readonly = false }) => {
	const { t } = useTranslation();

	return (
		<thead>
			<tr>
				<th align='center'><strong>{t('cart_table_product')}</strong></th>
				<th align='center'><strong>{t('cart_table_quantity')}</strong></th>
				<th align='center'><strong>{t('cart_item_price_raw')}</strong></th>
				<th align='center'><strong>{t('cart_item_vat_rate')}</strong></th>
				<th align='center'><strong>{t('cart_item_total')}</strong></th>
				{!readonly && <th align='center'><strong>{t('cart_table_remove')}</strong></th>}
			</tr>
		</thead>
	);
};

export default CartHeader;

CartHeader.propTypes = {
	readonly: PropTypes.bool
};
