import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {useRest} from '@karpeleslab/react-klbfw-hooks';
import {Helmet} from 'react-helmet';
import Btn from '../../components/core/inputs/Btn/Btn';
import Loader from '../../components/layout/Loader/Loader';
import ModuleNotLoaded from '../../components/core/utils/ModuleNotLoaded/ModuleNotLoaded';
import ShopEntryElement from '../../components/pxl/ShopEntryElement/ShopEntryElement';
import ShopSimilarItems from '../../components/pxl/ShopSimilarItems/ShopSimilarItems';

export default function ShopEntry() {
	const {t} = useTranslation();
	const history = useHistory();
	const {id} = useParams();
	const [entry] = useRest(`Catalog/Product/${id}`, {}, false, 5000); // keep cache only 5seconds

	if (!entry) {
		return <Loader/>;
	} else if (entry.result !== 'success') {
		return <ModuleNotLoaded/>;
	}

	return <main className="w-full max-w-7xl my-0 mx-auto pt-24 mb-auto">
		<Helmet>
			{ /* https://devhints.io/html-meta */}
			<title>{entry.data['Basic.Name']}</title>
			<meta property="og:title" content={entry.data['Basic.Name']}/>
			<meta name="twitter:title" content={entry.data['Basic.Name']}/>
		</Helmet>
		<ShopEntryElement entry={entry.data} />
		{entry.data['Description.AuthorCode'] && (
			<ShopSimilarItems authorCode={entry.data['Description.AuthorCode']} />
		)}
		<div className="p-6">
			<Btn onClick={() => history.goBack()} accessibility={t('go_back')}>
				{t('go_back')}
			</Btn>
		</div>
	</main>;
}