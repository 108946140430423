import React, { useState } from 'react';
import { useTranslation }  from 'react-i18next';
import { useParams }       from 'react-router';
import { Get, rest }       from '@karpeleslab/klbfw';

import { CSSTransition, SwitchTransition } from 'react-transition-group';

import Btn          from '../../components/core/inputs/Btn/Btn';
import Hero         from '../../components/layout/Hero/Hero';
import LayoutHelmet from '../../components/layout/LayoutHelmet/LayoutHelmet';
import Loader       from '../../components/layout/Loader/Loader';
import TextInput    from '../../components/core/inputs/TextInput/TextInput';
import BtnWithArrow from '../../components/layout/BtnWithArrow/BtnWithArrow';

import { useSetSnackbar } from '../../hooks/useSnackbar';

const ReinitPassword = () => {
	const { t }                       = useTranslation();
	const { userId }                  = useParams();
	const key                         = Get('k');
	const initial                     = Get('initial');
	const [pwd, setPwd]               = useState('');
	const [pwdConfirm, setPwdConfirm] = useState('');
	const [loading, setLoading]       = useState(false);
	const [sent, setSent]             = useState(false);
	const setSnackbar                 = useSetSnackbar();
	
	const onSubmit = e => {
		e.preventDefault();
		setLoading(true);
		rest('User:recover_password', 'POST', {
			login: userId,
			key,
			p1: pwd,
			p2: pwdConfirm
		})
			.then(() => setSent(true))
			.catch(err => setSnackbar(err.error, 'error'))
			.finally(() => setLoading(false));
	};

	return (
		<>
			<LayoutHelmet title={t(initial === void 0 ? 'reset_password' : 'reset_password_for_futokorogatana')}/>
			<Hero title={t(initial === void 0 ? 'reset_password' : 'reset_password_for_futokorogatana')}/>
			<main className="w-full max-w-3xl flex flex-col items-stretch mb-4 mx-auto p-2 md:p-6">
				<SwitchTransition mode="out-in">
					<CSSTransition
						key={!loading ? 'loaded' : 'loading'}
						classNames="u-transition--vertical"
						addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
					>
						{!loading ? (
							sent ? (
								<div key="request-sent" className="w-full">
									<p>{t('confirm_pwd_reset')}</p>
									{initial !== void 0 &&
									<div className="w-full flex flex-col justify-center items-center mt-10">
										<BtnWithArrow
											size="large"
											className="flex-none"
											to="/"
											accessibility={t('go_back_top')}
										>
											{t('go_back_top')}
										</BtnWithArrow>
									</div>
									}
								</div>
							) : (
								<form
									key="reset-pwd-form"
									action=""
									className="w-full flex flex-col items-center"
									onSubmit={onSubmit}
								>
									<TextInput
										label={t('new_password')}
										type="password"
										className="mt-4"
										value={pwd}
										required={true}
										onChange={e => setPwd(e.target.value)}
									/>
									<TextInput
										label={t('confirm_new_password')}
										type="password"
										className="mt-4"
										value={pwdConfirm}
										required={true}
										onChange={e => setPwdConfirm(e.target.value)}
									/>
									<Btn variant="primary" type="submit" className="mt-4" accessibility={t('send')}>
										{t('send')}
									</Btn>
								</form>
							)
						) : (
							<div key="request-loading" className="w-full">
								<Loader />
							</div>
						)}
					</CSSTransition>
				</SwitchTransition>
			</main>
		</>
	);
};

export default ReinitPassword;
