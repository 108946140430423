import PropTypes            from 'prop-types';
import { useOrderProcess }  from '@karpeleslab/klb-react-services';
import { useTranslation }   from 'react-i18next';
import React, { useEffect } from 'react';
import Btn                  from '../../../../core/inputs/Btn/Btn';
import { FiLoader }         from 'react-icons/fi';

const Wallet = ({ order, settings, setPaying, refreshOrder, refreshProcessResult }) => {
	const { t } = useTranslation();

	const [processOrder, processingOrder] = useOrderProcess(order.Order__);

	useEffect(() => {
		setPaying(processingOrder);
	}, [processingOrder]);

	const handleProcess = () => {
		processOrder(settings.session, settings.method, {}, { snackMessageToken: 'order_paid' })
			.then(d => {
				refreshOrder(d.order);
				refreshProcessResult(d);
			});
	};

	return (
		<div className='flex flex-wrap overflow-hidden sm:-mx-3'>

			<div className='w-full overflow-hidden sm:my-3 sm:px-3'>
				<div className='bg-blue-600 p-4 text-white' role='alert'>
					<p>
						{t('wallet_balance', { balance: settings.fields.Wallet.Balance.display })}
					</p>
				</div>
			</div>

			<div className='w-full overflow-hidden sm:my-3 sm:px-3 flex items-center justify-center'>
				<Btn
					className={'ml-auto block'}
					onClick={handleProcess}
					disabled={processingOrder}
					accessibility={t('wallet_checkout_btn')}
				>
					{processingOrder ? <FiLoader className="animate-spin" /> : t('wallet_checkout_btn')}
				</Btn>
			</div>

		</div>
	);
};

export default Wallet;

Wallet.propTypes = {
	refreshOrder: PropTypes.func.isRequired,
	refreshProcessResult: PropTypes.func.isRequired,
	setPaying: PropTypes.func.isRequired,
	settings: PropTypes.object.isRequired,
	order: PropTypes.object.isRequired,
};
