import React     from 'react';
import PropTypes from 'prop-types';

import MenuContextContainer         from '../../../context/MenuContext';
import MusicContextContainer        from '../../../context/MusicContext';
import { RestContextContainer }     from '@karpeleslab/klb-react-services';
import { SnackbarContextContainer } from '../../../context/SnackbarContext';
import TemplateContextContainer     from '../../../context/TemplateContext';
import { UserContextContainer }     from '../../../context/UserContext';

const LayoutContexts = ({ children }) => {
	return (
		<RestContextContainer>
			<SnackbarContextContainer>
				<UserContextContainer>	
					<TemplateContextContainer>
						<MenuContextContainer>
							<MusicContextContainer>
								{ children }
							</MusicContextContainer>
						</MenuContextContainer>
					</TemplateContextContainer>
				</UserContextContainer>
			</SnackbarContextContainer>
		</RestContextContainer>
	);
};

LayoutContexts.propTypes = {
	children: PropTypes.node
};

export default LayoutContexts;