import React, {useEffect} from 'react';
import PropTypes          from 'prop-types';
import Btn                from '../../../../core/inputs/Btn/Btn';
import {FiLoader}         from 'react-icons/fi';
import {useTranslation}   from 'react-i18next';
import {useOrderProcess}  from '@karpeleslab/klb-react-services';

const CashOnDelivery = ({order, settings, setPaying, refreshOrder, refreshProcessResult}) => {
	const {t} = useTranslation();

	const [processOrder, processingOrder] = useOrderProcess(order.Order__);

	useEffect(() => {
		setPaying(processingOrder);
	}, [processingOrder]);

	const handleProcess = () => {
		processOrder(settings.session, settings.method, {}, {snackMessageToken: 'cash_on_delivery_confirm'})
			.then(d => {
				refreshOrder(d.order);
				refreshProcessResult(d);
			});
	};

	return (
		<div className="flex flex-wrap overflow-hidden sm:-mx-3">

			<div className="w-full overflow-hidden sm:my-3 sm:px-3">
				<h1 className="text-center text-2xl my-4">
					{t(order.Status === 'pending' ? 'cash_on_delivery_text' : 'cash_on_delivery_text_waiting')}
				</h1>
			</div>

			{order.Status === 'pending' && <div className="w-full overflow-hidden sm:my-3 sm:px-3 flex items-center justify-center">
				<Btn
					onClick={handleProcess}
					disabled={processingOrder}
					accessibility={t('cash_on_delivery_order_btn')}
				>
					{processingOrder ? <FiLoader className="animate-spin"/> : t('cash_on_delivery_order_btn')}
				</Btn>
			</div>}
		</div>
	);
};

export default CashOnDelivery;

CashOnDelivery.propTypes = {
	order: PropTypes.object.isRequired,
	refreshOrder: PropTypes.func.isRequired,
	refreshProcessResult: PropTypes.func.isRequired,
	setPaying: PropTypes.func.isRequired,
	settings: PropTypes.object.isRequired
};
