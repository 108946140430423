import React     from 'react';
import PropTypes from 'prop-types';
import WavyLine  from '../../layout/WavyLine/WavyLine';

// import './ArticleHead.scss';

export default function ArticleHead({image, summary, tags = []}) {
	return (
		<div
			className="relative h-auto flex flex-col"
			style={{minHeight: '48px'}}
		>
			<div className="flex flex-wrap">
				{tags.map(t => (
					('hidden' in (t.Flags ?? {})) ? <></> :
						<button
							key={t.Classify_Tag__}
							className="bg-gradient-to-r from-pxl-accent1 to-pxl-accent2 border-0 p-1 text-xs text-white pointer-events-none mr-2"
						>
							{t.Full_Name}
						</button>
				))}
			</div>
			<div className="relative z-10 w-100 max-w-5xl py-6 text-2xl">
				<p>{summary}</p>
			</div>
			<WavyLine className="max-w-5xl mx-auto my-6 py-6"/>
			{image && (
				<div><img
					className="mx-auto"
					src={image}
					alt=""
				/></div>
			)}
		</div>
	);
}

ArticleHead.propTypes = {
	image: PropTypes.string,
	summary: PropTypes.string,
	tags: PropTypes.arrayOf(PropTypes.shape({
		Classify_Tag__: PropTypes.string,
		Full_Name: PropTypes.string
	}))
};
