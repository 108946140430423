import React, { useContext }      from 'react';
import PropTypes                  from 'prop-types';
import { useTranslation }         from 'react-i18next';

import EventTicketAvailability    from '../EventTicketAvailability/EventTicketAvailability';
import EventTicketList            from './EventTicketList';

import { UserContext }            from '../../../context/UserContext';

import './EventTicketList.css';
import Alert from '../../layout/Alert/Alert';
import {dateFormat} from '../../../utils/date';
import {EventUserTickets} from '../EventUserTickets/EventUserTickets';

export default function EventTicketListWrapper({ event }) {
	const { t }          = useTranslation();
	const [{ loggedIn }] = useContext(UserContext);

	if (!event) {
		return <></>;
	}

	if (event.Seats.length === 0) {
		return <div className={'p-5 mt-5'}>
			{t('event_no_tickets')}
		</div>;
	}

	const maxTicketNumber = parseInt(event.Max_Seat_Per_User);

	const isReservationOver = parseInt(event.Reservation_End.unixms) <= Date.now();

	return (
		<>
			{loggedIn && <EventUserTickets event={event} />}

			<EventTicketAvailability event={event} />

			{!isNaN(maxTicketNumber) && (
				<div className={'text-sm italic mx-4'}>
					{t('event_max_ticket_user', { value: maxTicketNumber })}
				</div>
			)}

			{isReservationOver ?
				<Alert type={'info'}>{t('event_reservation_is_over', {date: dateFormat(event.Reservation_End, 'L LT')})}</Alert> :
				<EventTicketList
					tickets={event.Seats}
					event={event}
					hasReachedMaxReservation={false}
					hasReservation={false}
				/>
			}
		</>
	);
}


EventTicketListWrapper.propTypes = {
	event: PropTypes.shape({
		Event_Date       : PropTypes.object,
		Max_Seat_Per_User: PropTypes.string,
		Reservation_End  : PropTypes.object,
		Reservation_Start: PropTypes.object,
		Seats            : PropTypes.array,
		Total_Remaining  : PropTypes.number
	}).isRequired,
};
