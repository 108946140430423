import PropTypes     from 'prop-types';
import React         from 'react';
//import CartSubTotals from './CartSubTotals';
import CartTotals    from './CartTotals';

const CartPrices = ({ cart }) => {
	return (
		<div className={'flex flex-col md:flex-row items-center md:justify-end'}>
			{/*<CartSubTotals subtotals={cart.subtotals}/>*/}
			<CartTotals cart={cart}/>
		</div>
	);
};

export default CartPrices;

CartPrices.propTypes = {
	cart: PropTypes.object.isRequired
};
