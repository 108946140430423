import React, { useEffect, useRef } from 'react';
import PropTypes                    from 'prop-types';

import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import './ImgLoader.css';

const units = ['auto', 'px', '%', 'vw', 'vh', 'rem', 'em', 'pt', 'pc'];

const ImgLoader = ({
	alt = '',
	className = '',
	height = 'auto',
	rootClasses = '',
	showSpinner = false,
	src,
	width = 'auto',
	...etcetera
}) => {
	if (units.filter(u => 
		width.endsWith(u)).length < 1 ||
		units.filter(u => height.endsWith(u)).length < 1
	)
		throw new Error(`Invalid unit provided for image with source ${src}`);

	const imgRef         = useRef(null);
	const placeholderRef = useRef(null);

	useEffect(() => {
		if (!imgRef?.current || !placeholderRef?.current)
			return;

		const img = new Image();
		img.onload = () => {
			if (!imgRef?.current || !placeholderRef?.current)
				return;

			imgRef.current.src = src;
			placeholderRef.current.classList.add('loaded');
		};
		img.src= src;
	}, [imgRef, placeholderRef]);

	return typeof window === 'undefined'
		? ( // SSR, no need for the placeholder and all
			<div
				ref={placeholderRef}
				className='relative inline-block loaded'
				style={{ width, height }}
			>
				<img
					src={src}
					alt={alt}
					style={{ width, height }}
					className={`absolute top-0 left-0 w-full h-full ${className}`}
					{...etcetera}
				/>
			</div>
		)
		: ( // client-side, putting a placeholder and fading the image in once loaded
			<div
				ref={placeholderRef}
				className={`img-placeholder relative inline-block ${rootClasses}`}
				style={{ width, height }}
			>
				<img
					ref={imgRef}
					src={src}
					alt={alt}
					className={`absolute top-0 left-0 w-full h-full ${className}`}
					{...etcetera}
				/>
				<div className="img-placeholder-mask">
					{showSpinner && (
						<AiOutlineLoading3Quarters className="img-placeholder-spinner" />
					)}
				</div>
			</div>
		);
};

ImgLoader.propTypes = {
	alt        : PropTypes.string,
	className  : PropTypes.string,
	rootClasses: PropTypes.string,
	showSpinner: PropTypes.bool,
	src        : PropTypes.string.isRequired,
	width      : PropTypes.string,
	height     : PropTypes.string
};

export default ImgLoader;
