import React, {useContext, useState} from 'react';
import PropTypes                     from 'prop-types';
import {UserContext}                 from '../../../context/UserContext';
import {TemplateContext}             from '../../../context/TemplateContext';
import {rest}                        from '@karpeleslab/klbfw';
import {AiFillHeart, AiOutlineHeart} from 'react-icons/ai';

const Like = ({entryId, initialLikeCount = 0, initialLiked = false}) => {
	const [{loggedIn}] = useContext(UserContext);
	const settings = useContext(TemplateContext);

	const [count, setCount] = useState(initialLikeCount);
	const [liked, setLiked] = useState(initialLiked);
	const [loadingLike, setLoadingLike] = useState(false);

	if (!loggedIn || !settings.social || !settings.social.includes('like'))
		return <></>;

	const toggleLike = () => {
		if (loadingLike || !loggedIn) return;

		setLoadingLike(true);
		const method = liked ? 'unlike' : 'like';
		rest(`Content/Cms/Entry/${entryId}:${method}`, 'POST')
			.then((c) => {
				setCount(c.data.Like_Count);
				setLiked(c.data.Liked);
			})
			.finally(() => {
				setLoadingLike(false);
			});
	};

	return (
		<div className="overflow-hidden flex items-center" style={{height:'32px'}}>
			<span className={'mr-2 cursor-pointer'} onClick={() => toggleLike()}>
				{!liked && <AiOutlineHeart size={32}/>}
				{liked && <AiFillHeart size={32}/>}
			</span>
			<span className={'pr-1 pb-1'}>{count}</span>
		</div>
	);
};

export default Like;

Like.propTypes = {
	entryId: PropTypes.string.isRequired,
	initialLikeCount: PropTypes.number.isRequired,
	initialLiked: PropTypes.bool.isRequired,
};