import React     from 'react';
import PropTypes from 'prop-types';

import Btn                               from '../../core/inputs/Btn/Btn';
// import { Link }                          from 'react-router-dom';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import './MonthsFrieze.css';
import moment                            from 'moment';
import {useTranslation} from 'react-i18next';

const MonthsFrieze = ({ selectedMonth = { index: 1, year: 1 }, onChange = () => {} }) => {
	const {t} = useTranslation();
	const getMonthLocaleLongName = idx => {
		return moment.months(idx - 1);
	};

	const changeMonth = prevOrNextOrObj => {
		if (typeof prevOrNextOrObj === 'string') {
			if (prevOrNextOrObj === 'prev') {
				if (selectedMonth.month === 1) {
					onChange({
						index: selectedMonth.index - 1,
						month: 12,
						year : selectedMonth.year - 1
					});
				} else {
					onChange({
						index: selectedMonth.index - 1,
						month: selectedMonth.month - 1,
						year : selectedMonth.year
					});
				}
			} else {
				if (selectedMonth.month === 12) {
					onChange({
						index: selectedMonth.index + 1,
						month: 1,
						year : selectedMonth.year + 1
					});
				} else {
					onChange({
						index: selectedMonth.index + 1,
						month: selectedMonth.month + 1,
						year : selectedMonth.year
					});
				}
			}
		} else onChange(prevOrNextOrObj); 
	};

	const monthsArray = [];
	for (let i = 1; i <= 36; i++) {
		const date = new Date();
		date.setMonth(date.getMonth() - 12); // starting one year prior to the current year
		date.setMonth(date.getMonth() + Math.floor((i - 1) / 12) * 12); // going to one year after current year
		const fullYear = date.getFullYear();
		monthsArray.push({ index: i, month: i % 12, year: fullYear, name: getMonthLocaleLongName(i % 12) });
	}

	return (
		<div className="relative w-full flex justify-center items-center">
			<button 
				className="w-10 h-10 mx-1 flex justify-center items-center rounded-full text-center cursor-pointer border border-transparent bg-transparent text-white hover:text-black hover:bg-white active:shadow-sm ouline-none"
				onClick={() => changeMonth('prev')}
				disabled={(selectedMonth.index <= 1)}
				aria-label={t('acc_previous_page')}
			>
				<FiChevronLeft style={{ transform: 'scale(1.25)' }} />
			</button>
			<div className="c-months-frieze relative w-full max-w-2xl h-10 overflow-hidden">
				<div 
					className="absolute top-1 transition-transform flex justify-center items-center"
					style={{ 
						left     : 'calc(50% - 50px)',
						transform: `translate3d(-${ Math.round(((selectedMonth.index - 1) / 36) * 100) }%, 0, 0)` 
					}}
				>
					{monthsArray.map(month => (
						<Btn
							key={month.index}
							variant={month.index === selectedMonth.index ? 'primary' : 'discrete'}
							size="small"
							className="mx-2 whitespace-nowrap"
							to={`/events/${month.year}/${month.month < 1 ? 12 : month.month}`}
							tabIndex={-1}
							accessibility={`${month.name}-${month.year}`}
						>
							{month.name}
						</Btn>
					))}
				</div>
			</div>
			<button 
				className="w-10 h-10 mx-1 flex justify-center items-center rounded-full text-center cursor-pointer border border-transparent bg-transparent text-white hover:text-black hover:bg-white active:shadow-sm ouline-none"
				onClick={() => changeMonth('next')}
				disabled={(selectedMonth.index >= 36)}
				aria-label={t('acc_next_page')}
			>
				<FiChevronRight style={{ transform: 'scale(1.25)' }} />
			</button>
		</div>
	);
};

MonthsFrieze.propTypes = {
	selectedMonth: PropTypes.shape({
		month: PropTypes.number,
		year : PropTypes.number
	}),
	onChange: PropTypes.func
};

export default MonthsFrieze;
