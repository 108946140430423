import React              from 'react';
import { useTranslation } from 'react-i18next';

import Hero         from '../../components/layout/Hero/Hero';
import LayoutHelmet from '../../components/layout/LayoutHelmet/LayoutHelmet';
import NewsList     from './../../components/pxl/NewsList/NewsList';

export default function News () {
	const { t }  = useTranslation();
	return (
		<>
			<LayoutHelmet title={t('news')} />
			<Hero title={t('news')} />
			<main className="w-full max-w-7xl my-0 mx-auto">
				<section className="mt-6 pt-6">
					<NewsList showPaging={true} />
				</section>
			</main>
		</>
	);
}
