import React        from 'react';
import PropTypes    from 'prop-types';

import './Select.css';

const Select = ({ className, disabled = false, label, options, size = 'normal', ...etcetera }) => {

	return (
		<label
			className={`pxl-select ${size} ${className}${disabled ? ' disabled' : ''}`}
		>
			<select disabled={disabled} {...etcetera}>
				<option
					disabled
					value=""
					style={{ display: 'none' }}
				/>
				{options.map((item, index) => (
					<option
						key={`option-${item.name}-${index}`}
						value={item.value}
					>
						{item.name}
					</option>
				))}
			</select>
			<span>{label}</span>
		</label>
	);
};

Select.propTypes = {
	className: PropTypes.string,
	children : PropTypes.node,
	disabled : PropTypes.bool,
	label    : PropTypes.string,
	options  : PropTypes.arrayOf(
		PropTypes.shape({
			name : PropTypes.string,
			value: PropTypes.string
		})
	),
	size    : PropTypes.oneOf(['xs', 'small', 'normal', 'large'])
};

export default Select;