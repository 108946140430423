import React, { useRef, useState } from 'react';
import PropTypes                   from 'prop-types';
import { useTranslation }          from 'react-i18next';

import Btn          from '../../core/inputs/Btn/Btn';
import { FiLoader } from 'react-icons/fi';
import PopIn        from '../../layout/PopIn/PopIn';
import Stripe       from '../../user/Billing/Stripe';

import { useSetSnackbar }             from '../../../hooks/useSnackbar';
import {useUserBillingMethodUpdate, useUserLocation} from '@karpeleslab/klb-react-services';

const CreditCardInfo = ({ method, onCardUpdated, locationId }) => {
	const { t }                       = useTranslation();
	const [loading, setLoading]       = useState(false);
	const [openChange, setOpenChange] = useState(false);
	const [stripeOK, setStripeOK]     = useState(false);
	const stripeRef                   = useRef(null);
	const setSnackbar                 = useSetSnackbar();
	const [update]                    = useUserBillingMethodUpdate(method.User_Billing_Method__);
	const [location]				  = useUserLocation(locationId);

	const getStripeToken = () => {
		setLoading(true);
		stripeRef.current.handleProcess();
	};

	const onStripeError = msg => {
		setSnackbar(msg, 'error');
		setLoading(false);
	};

	const onStripeIntent = intent => {
		setLoading(true);
		update({ stripe_intent: intent.stripe_intent, method: 'Stripe' })
			.then(() => onCardUpdated())
			.catch(err => setSnackbar(err.error, 'error'))
			.finally(() => {
				setLoading(false);
				setOpenChange(false);
			});
	};

	return (
		<>
			<div className="p-4 border my-4 flex justify-between items-center">
				<div>
					<p>{method.Name}</p>
					<p className="mt-2">{t('stripe_ccexp_label')} {method.Expiration}</p>
					{(!method.Valid || method.Expired) && (
						<p className="mt-2 text-red-600">
							{t(method.Expired ? 'payment_method_expired' : 'payment_method_invalid')}
						</p>
					)}
				</div>
				<Btn onClick={() => setOpenChange(true)} accessibility={t('change')}>
					{t('change')}
				</Btn>
			</div>
			<PopIn open={openChange} setOpen={setOpenChange}>
				<div className="w-full">
					<h2 className="text-2xl text-center">
						{t('edit_credit_card_info')}
					</h2>
					<Stripe
						location={location?.data}
						ref={stripeRef}
						onTokenError={onStripeError}
						onIntentGenerated={onStripeIntent}
						setSaveStatus={setStripeOK}
					/>
					<Btn
						variant="primary"
						onClick={getStripeToken}
						disabled={!stripeOK || loading}
						accessibility={t('change')}
					>
						{loading ? <FiLoader className="animate-spin" /> : t('change')}
					</Btn>
				</div>
			</PopIn>
		</>
	);
};

CreditCardInfo.propTypes = {
	method: PropTypes.shape({
		Expiration           : PropTypes.string.isRequired,
		Expired              : PropTypes.bool.isRequired,
		Name                 : PropTypes.string.isRequired,
		User_Billing_Method__: PropTypes.string.isRequired,
		User_Billing__       : PropTypes.string.isRequired,
		Valid                : PropTypes.bool.isRequired
	}).isRequired,
	onCardUpdated: PropTypes.func,
	locationId: PropTypes.string.isRequired
};

export default CreditCardInfo;