import React, { useContext } from 'react';
import PropTypes             from 'prop-types';
import { LoginContext }      from '@karpeleslab/klb-react-services';


import ButtonsBuilder                      from './ButtonsBuilder';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import FormBuilder                         from './FormBuilder';
import Loader                              from '../../layout/Loader/Loader';
import OauthBtnsBuilder                    from './OauthBtnsBuilder';

import { useMobileMediaQuery } from '../../../hooks/useMediaQueries';

const Login = ({ profilePicture = true }) => {
	const isMobile = useMobileMediaQuery();
	const { loading, handleSubmit, formTitle } = useContext(LoginContext);

	return (
		<div className="w-full h-full flex flex-col justify-center items-center bg-pxl-darkerGray">
			<SwitchTransition>
				<CSSTransition
					key={!loading ? 'loaded' : 'loading'}
					addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
					classNames={isMobile ? 'u-transition--zoom' : 'u-transition--lateral'}
				>
					{
						!loading ? (
							<div className="grid grid-cols-1 gap-6 text-justify">
								<div className="mb-4">
									<h2 className="text-3xl text-left">{formTitle}</h2>
								</div>
								<form onSubmit={handleSubmit}>
									<FormBuilder profilePicture={profilePicture}/>
									<OauthBtnsBuilder />
									<ButtonsBuilder />
								</form>
							</div>
						) : (
							<Loader/>
						)
					}
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
};

export default Login;

Login.propTypes = {
	profilePicture: PropTypes.bool
};
