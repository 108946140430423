import React              from 'react';
import { Get }            from '@karpeleslab/klbfw';
import { useTranslation } from 'react-i18next';

const Text = () => {
	const { t } = useTranslation();

	const text = [t('mail_shipped_title', { number: Get('_order').Invoice_Number })];
	if (Get('_tracking')) 
		text.push(t('mail_shipped_tracking_number'), `<b>${Get('_tracking')}</b>`);

	return (
		<textarea
			id="mail-text"
			defaultValue={text.join('\n\r')}
		/>
	);
};

export default Text;
