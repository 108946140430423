import React     from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag';

const Tags = ({ tags }) => {
	return (
		<>
			{tags && tags.length > 0 && (
				<div className={'mb-2'}>
					{tags.map((tag, i) => <Tag key={i} tag={tag.Name} />)}
				</div>
			)}
		</>
	);
};

export default Tags;

Tags.propTypes = {
	tags: PropTypes.array.isRequired,
};
