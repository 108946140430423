import React, { useContext } from 'react';

import BiographySection from './Sections/BiographySection';
import HeroAlt          from '../../components/layout/HeroAlt/HeroAlt';
import LayoutHelmet     from '../../components/layout/LayoutHelmet/LayoutHelmet';
import Loader           from '../../components/layout/Loader/Loader';
import MembersSection   from './Sections/MembersSection';
import NoSsr            from '../../components/core/utils/NoSsr/NoSsr';
import NewsSection      from './Sections/NewsSection';

import { TemplateContext } from '../../context/TemplateContext';

export default function Home() {
	const { artist, news_visible = 'N' } = useContext(TemplateContext);

	if (!artist)
		return <Loader/>;

	return (
		<main>
			<LayoutHelmet/>
			<HeroAlt title={artist.Name}>
				{artist.Catchphrase}
			</HeroAlt>
			<a id="about" tabIndex={-1} aria-hidden />
			<div className='pxl-sections w-full max-w-3xl my-0 mx-auto'>
				<BiographySection />
				<MembersSection />
				{news_visible === 'Y' && <NoSsr>
					{/* Ideally, this section should be SSR, but it's causing a timeout */}
					<NewsSection/>
				</NoSsr>
				}

			</div>
		</main>
	);
}
