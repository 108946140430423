import React              from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes          from 'prop-types';

const Text = ({ username, website }) => {
	const { t } = useTranslation();

	return (
		<textarea
			id="mail-text"
			defaultValue={t('account_confirmed_description', { username, website })}
		>
		</textarea>
	);
};

Text.propTypes = {
	username: PropTypes.string,
	website : PropTypes.string
};

export default Text;
