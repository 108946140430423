import React from 'react';
import PropTypes from 'prop-types';

export default function MissingPicture({className}) {
	return <svg
		xmlns="http://www.w3.org/2000/svg"
		className={`w-full h-full ${className}`}
		fill="none"
		viewBox="0 0 24 24"
		stroke="currentColor"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="0.5"
			d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"/>
	</svg>;
}

MissingPicture.propTypes = {
	className: PropTypes.string,
};