import React, { useEffect, useState } from 'react';
import { useTranslation }             from 'react-i18next';
import PropTypes                      from 'prop-types';
import { rest }                       from '@karpeleslab/klbfw';
import { useRest }                    from '@karpeleslab/react-klbfw-hooks';

import { useUserLocationCreate, useUserSetDefaultLocation } from '@karpeleslab/klb-react-services';

import AddressForm  from '../../core/utils/AddressForm/AddressForm';
import Btn          from '../../core/inputs/Btn/Btn';
import { FiLoader } from 'react-icons/fi';
import Loader       from '../../layout/Loader/Loader';

const fieldsWhitelist   = ['First_Name', 'Last_Name', 'Middle_Name', 'Address', 'Address2', 'Zip', 'Province', 'City'];
const additionnalFields = { Contact_Phone: '' };

const AddBillingAddress = ({ onCancel, onComplete, setAsDefaultBillingAddress = true }) => {
	const { t }                         = useTranslation();
	const [address, setAddress]         = useState(null);
	const [countryCode, setCountryCode] = useState(null);
	const [zip, setZip] = useState(null);
	const [provinces, setProvinces] = useState([]);
	const [geoLookup]                   = useRest('ThirdParty/Geoip:lookup');

	const [isValid, setIsValid]                        = useState(false);
	const [createLocation, creatingLocation]           = useUserLocationCreate('@');
	const [setDefaultLocation, settingDefaultLocation] = useUserSetDefaultLocation('@');

	const proceed = e => {
		e.preventDefault();

		let newLocation = null;
		createLocation(address, { snackMessageToken: null })
			.then(location => {
				newLocation = location;
			})
			.then(() => {
				if (setAsDefaultBillingAddress) {
					return setDefaultLocation(
						newLocation.User_Location__,
						'Billing',
						{ snackMessageToken: null }
					);
				} else return true;
			})
			.then(() => onComplete(newLocation));
	};

	const setFieldsAndOrder = countryCode => {
		rest(`Country/${countryCode}`)
			.then(({ data }) => {
				const fields         = [];
				const newAddressFieldsList = {};
				data.Display_Format.forEach(ds => {
					ds.forEach(field => {
						if (fieldsWhitelist.includes(field) && !field.startsWith('!')) {
							if (field !== 'Country__' && field !== 'Zip') fields.push(field);
							let val = '';
							if (field === 'Country__') val = countryCode;
							else if (address && address[field]) val = address[field];
							newAddressFieldsList[field] = val;
						}
					});
				});
				fields.push(...Object.keys(additionnalFields));
				const _fielsdOrder = ['Country__', 'Zip', ...fields];
				setAddress({ ...newAddressFieldsList, _fielsdOrder, ...additionnalFields });
			});
	};

	const zipBlurred = () => {
		if (zip === address['Zip'] || address['Zip'] === '') {
			return;
		}

		rest(`Country/${countryCode}:zip`, 'GET' ,{zip: address['Zip']})
			.then(({data}) => {
				const newAddressValues = {...address};
				Object.keys(data).forEach((field) => {
					let addrField = field;
					if(field === 'Province' && provinces.length < 1) return ;
					if(field === 'Province_Name' && provinces.length > 0) addrField = 'Province';

					if (!newAddressValues[addrField] || newAddressValues[addrField] === '') {
						newAddressValues[addrField] = data[field];
					}
				});
				setAddress(newAddressValues);
			})
			.catch((error) => {
				if (error.token === 'error_zip_not_found') {
					return;
				}
				throw(error);
			})
			.finally(()=> {
				setZip(address['Zip']);
			});
	};

	const fetchProvinces = (country) => {
		rest(`Country/${country}:getProvinces`)
			.then(({data}) => setProvinces(data));
	};

	useEffect(() => {
		if (!geoLookup || !geoLookup.data || !geoLookup.data.country || !geoLookup.data.country.iso_code)
			return;

		setCountryCode(geoLookup.data.country.iso_code);
	}, [geoLookup]);

	useEffect(() => {
		if (address && address.Country__ && address.Country__ !== countryCode)
			setCountryCode(address.Country__);
	}, [address]);

	useEffect(() => {
		if (countryCode) {
			setFieldsAndOrder(countryCode);
			fetchProvinces(countryCode);
		}
	}, [countryCode]);

	return (
		<form onSubmit={proceed}>
			<h2 className="text-2xl text-center">
				{t('add_an_address')}
			</h2>
			{address && address._fielsdOrder ? (
				<AddressForm
					isGeoLookup={true}
					address={address}
					setAddress={setAddress}
					setIsValid={setIsValid}
					zipBlurred={zipBlurred}
					provinces={provinces}
				/>
			) : <Loader />}
			<div className="w-full flex justify-between items-center pt-4 px-2">
				<div className="flex-none">
					<Btn
						disabled={creatingLocation || settingDefaultLocation}
						onClick={() => onCancel()}
						tabIndex={4}
						accessibility={t('cancel')}
					>
						{t('cancel')}
					</Btn>
				</div>
				<div className="flex-none">
					<Btn
						type="submit"
						variant="primary"
						disabled={!isValid || creatingLocation || settingDefaultLocation}
						tabIndex={4}
						accessibility={t('add')}
					>
						{(creatingLocation || settingDefaultLocation) ? (
							<FiLoader className="animate-spin" />
						) : t('add')}
					</Btn>
				</div>
			</div>
		</form>
	);
};

AddBillingAddress.propTypes = {
	onCancel  : PropTypes.func,
	onComplete: PropTypes.func,
	setAsDefaultBillingAddress: PropTypes.bool
};

export default AddBillingAddress;
