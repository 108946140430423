import React, { useEffect, useState } from 'react';

import OrderTable from '../order/OrderTable';

import { useOrders } from '@karpeleslab/klb-react-services';

const OrderHistory = () => {
	const [settings, setSettings]  = useState({
		page_no         : 1,
		results_per_page: 20
	});
	const [orders, fetch, loading] = useOrders(settings);

	const changePage = page_no => {
		setSettings(s => ({
			...s,
			page_no
		}));
	};

	useEffect(() => { fetch(); }, []);

	return (
		<div className="w-full">
			{orders && orders.data && !loading && (
				<OrderTable
					changePage={changePage}
					paging={orders.paging}
					orders={orders.data}
				/>
			)}
		</div>
	);
};

export default OrderHistory;