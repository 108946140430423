import React, { useContext } from 'react';
import { useTranslation }    from 'react-i18next';

import ExclusiveArticleList from '../../components/pxl/ExclusiveArticleList/ExclusiveArticleList';
import Hero                 from '../../components/layout/Hero/Hero';
import LayoutHelmet         from '../../components/layout/LayoutHelmet/LayoutHelmet';

import { UserContext } from '../../context/UserContext';

const FanclubArticles = () => {
	const { t }	         = useTranslation();
	const [{ loggedIn }] = useContext(UserContext);
	return (
		<>
			<LayoutHelmet title={t('member_exclusives')} />
			<Hero title={t('member_exclusives')} />
			<main className="w-full max-w-7xl my-0 mx-auto">
				{loggedIn && <ExclusiveArticleList />}
			</main>
		</>
	);
};

export default FanclubArticles;
