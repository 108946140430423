import React, {useState} from 'react';
import {rest}            from '@karpeleslab/klbfw';
import TextInput         from '../../core/inputs/TextInput/TextInput';
import BtnWithArrow      from '../../layout/BtnWithArrow/BtnWithArrow';
import {useTranslation}  from 'react-i18next';
import PropTypes         from 'prop-types';

const PostComment = ({path, refresh}) => {
	const {t} = useTranslation();

	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const post = () => {
		setLoading(true);
		rest(path, 'POST', {message: message,})
			.finally(() => {
				refresh();
				setLoading(false);
				setMessage('');
			});
	};

	return (
		<div>
			<div className="w-full py-6">
				<TextInput
					type="textarea"
					label={`${t('your_message')}`}
					value={message}
					disabled={loading}
					rows="10"
					onChange={e => setMessage(e.target.value)}
					required
				/>
			</div>
			<div className="flex justify-end pt-4">
				<BtnWithArrow
					variant="inverted"
					size="small"
					disabled={!message.trim() || loading}
					loading={loading}
					accessibility={t('send')}
					onClick={() => post()}
				>
					{t('send')}
				</BtnWithArrow>
			</div>
		</div>
	);
};

export default PostComment;

PostComment.propTypes = {
	refresh: PropTypes.func.isRequired,
	path: PropTypes.string.isRequired,
};