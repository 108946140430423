import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getHostname }    from '@karpeleslab/klbfw';

const Text = ({ artist, plan }) => {
	const { t } = useTranslation();

	return (
		<textarea
			id="mail-text"
			defaultValue={
				t('email_welcome_fanclub_description', { artist, plan }) +
				'\n\r' +
				t('user_password_mail_regards') +
				'\n\r' +
				t('user_password_email_footer', { website: getHostname() }) +
				'\n\r'
			}
		>
		</textarea>
	);
};

Text.propTypes = {
	artist: PropTypes.string,
	plan  : PropTypes.string
};

export default Text;
