import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';
import { dateFormat }     from '../../utils/date';

import Btn                               from '../core/inputs/Btn/Btn';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { VscFilePdf, VscSearch } from 'react-icons/vsc';

const OrderTable = ({ changePage = () => {}, paging, orders }) => {
	const { t }  = useTranslation();

	const goTo = offset => {
		changePage(paging.page_no + offset);
	};

	return (
		<div className="mt-4">
			<table className="w-full text-center">
				<thead>
					<tr>
						<th>{t('order_status')}</th>
						<th>{t('order_created')}</th>
						<th>{t('order_paid')}</th>
						<th>{t('order_total')}</th>
						<th>{t('order_actions')}</th>
					</tr>
				</thead>
				<tbody>
					{orders.map(order => (
						<tr key={order.Order__} className="py-2">
							<td>
								{order.Status}
								{(order.Flags && order.Flags.autorenew) && (
									<>
										<br />
										<i className="text-sm">(autorenew)</i>
									</>
								)}
							</td>
							<td>{dateFormat(order.Created, 'L')}</td>
							<td>{order.Paid && dateFormat(order.Paid, 'L')}</td>
							<td>{order.Total_Vat.display_short}</td>
							<td className="flex justify-center">
								<Btn
									size="xs"
									to={`/order/${order.Order__}`}
									className="m-2"
									accessibility={t('acc_see_order')}
								>
									<VscSearch />
								</Btn>
								<Btn
									size="xs"
									href={order.Invoice_Url}
									className="m-2"
									accessibility="PDF"
									title={t('view_invoice')}
								>
									<VscFilePdf />
								</Btn>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<div className="w-full mt-4 flex justify-between items-center">
				<div>{t('nbresults', { count: paging.count })}</div>
				<div className="flex justify-end items-center">
					<div className="mr-4">
						{t('page_nb_of_total', { page_no: paging.page_no, page_max: paging.page_max })}
					</div>
					<div className="mr-4">
						<Btn
							size="xs"
							disabled={paging.page_no < 2}
							onClick={() => goTo(-1)}
							accessibility={t('acc_previous_page')}
						>
							<FiChevronLeft />
						</Btn>
					</div>
					<div>
						<Btn
							size="xs"
							disabled={paging.page_no >= paging.page_max}
							onClick={() => goTo(+1)}
							accessibility={t('acc_next_page')}
						>
							<FiChevronRight />
						</Btn>
					</div>
				</div>
			</div>
		</div>
	);
};

OrderTable.propTypes = {
	changePage: PropTypes.func,
	paging    : PropTypes.shape({
		count           : PropTypes.string,
		page_max        : PropTypes.number,
		page_no         : PropTypes.number,
		results_per_page: PropTypes.number
	}),
	orders: PropTypes.arrayOf(
		PropTypes.shape({
			Created: PropTypes.shape({
				iso: PropTypes.string
			}),
			Order__: PropTypes.string,
			Paid   : PropTypes.shape({
				iso: PropTypes.string
			}),
			Payment_Method: PropTypes.string,
			Status        : PropTypes.string,
			Total_Vat     : PropTypes.shape({
				display      : PropTypes.string,
				display_short: PropTypes.string
			})
		})
	)
};

export default OrderTable;
