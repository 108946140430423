import React, { useEffect, useState } from 'react';
import PropTypes                      from 'prop-types';
import { useTranslation }             from 'react-i18next';
import { getToken, rest }                   from '@karpeleslab/klbfw';

import ImgLoader     from '../../layout/ImgLoader/ImgLoader';
import { PxlPlayer } from '@pxljp/pxl-player-react';

export const Player = ({ eventId, thumbnail }) => {
	const { t, i18n }           = useTranslation();
	const [options, setOptions] = useState({
		eventId,
		clientId: 'none',
		code    : 'none',
		domain  : location.origin,
		endpoint: 'none',
		getHeaderAuthorization: () => `Session ${getToken()}`,
		i18n: 'en-US',
	});

	const [responseStream, setResponseStream] = useState(null);

	useEffect(() => {
		if (['en-US', 'ja-JP'].includes(i18n?.language))
			setOptions(opts => ({ ...opts, i18n: i18n.language }));
	}, []);

	// streaming auto start
	const updateStreaming = (state) => {
		rest(`Planner/Event/${eventId}:stream`)
			.then((res) => {
				if (!state.enabled) return;
				setResponseStream(res);
				if (res.data.Status === 'none') {
					let t = res.data.Hls_Recheck_Delay;
					if (t < 5) t = 5;
					state.timeout = setTimeout(() => updateStreaming(state), t * 1000);
				}
			})
			.catch((res) => {
				if (res.data.Status === 'none') {
					if (!state.enabled) return;
					state.timeout = setTimeout(() => updateStreaming(state), 60000);
				}
			});
	};

	useEffect(() => {
		let state = { enabled: true };
		updateStreaming(state);
		return () => {
			state.enabled = false;
			setResponseStream(null);
			clearTimeout(state.timeout);
		};
	}, [eventId]);

	if (responseStream?.data) {
		if (responseStream?.data?.Type === 'none') {
			return (
				<div className={'w-full h-full flex justify-center items-center content-center'}>
					{thumbnail && (
						<div className="absolute z-0 top-0 left-0 w-full h-full opacity-30">
							<ImgLoader
								src={thumbnail}
								width="100%"
								height="100%"
								className="object-cover"
							/>
						</div>
					)}
					<div className={'relative z-10 p-5 py-10 border-white border text-center my-10 inline-block'}>
						{t('player_stream_not_started_yet')}
					</div>
				</div>
			);
		} else if (!responseStream?.data?.Has_Seat && responseStream.data.Type === 'private') {
			return (
				<div className={'w-full h-full flex justify-center items-center content-center'}>
					<div className={'p-5 py-10 border-white border text-center my-10 inline-block'}>
						{t('player-ticket-required')}
					</div>
				</div>
			);
		} else return <PxlPlayer options={options}/>;
	} else return <></>;
};

Player.propTypes = {
	eventId  : PropTypes.string.isRequired,
	thumbnail: PropTypes.string
};
