import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {CSSTransition, SwitchTransition} from 'react-transition-group';
import {allowPageScroll, noPageScroll} from '../../../utils/misc';

const PopIn = ({ children, maxWidth = null, open, setOpen }) => {
	let widthClasses = maxWidth || 'md:w-2/3 md:max-w-lg';

	useEffect(() => {
		if (open) {
			noPageScroll();
		} else {
			allowPageScroll();
		}
	}, [open]);

	return (
		<SwitchTransition mode="out-in">
			<CSSTransition
				key={open ? 'open' : 'closed'}
				classNames="u-transition--zoom"
				addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
			>
				{open ? (
					<div
						key="popin-open"
						className="fixed top-0 left-0 z-50 w-screen h-screen flex justify-center bg-black bg-opacity-40 overflow-y-auto pb-10 md:pb-0"
						onClick={() => setOpen(false)}
					>
						<div
							className={`w-full ${widthClasses} m-auto px-6 py-8 bg-pxl-darkerGray border border-white mb-auto`}
							onClick={e => e.stopPropagation()}
						>
							{children}
						</div>
					</div>
				) : <div key="popin-closed"/>}
			</CSSTransition>
		</SwitchTransition>
	);
};

PopIn.propTypes = {
	children: PropTypes.node,
	maxWidth: PropTypes.string,
	open    : PropTypes.bool,
	setOpen : PropTypes.func,
};

export default PopIn;
