import React, { useState } from 'react';
import { useLocation }     from 'react-router-dom';
import { useTranslation }  from 'react-i18next';
import { useRest }         from '@karpeleslab/react-klbfw-hooks';

import ArgsBuilderFromUrl, { 
	buildArgsFromUrlSearchParams 
} from '../../core/utils/ArgsBuilderFromUrl/ArgsBuilderFromUrl';
import Btn                 from '../../core/inputs/Btn/Btn';
import { HiOutlineFilter } from 'react-icons/hi';
import Loader              from '../../layout/Loader/Loader';
import ModuleNotLoaded     from '../../core/utils/ModuleNotLoaded/ModuleNotLoaded';
import Paging              from '../../layout/Paging/Paging';
import SelectedFilters     from '../ShopMenu/SelectedFilters';
import ShopItem            from './ShopItem';
import ShopMenu            from '../ShopMenu/ShopMenu';

import './ShopItems.css';

export const imgKey = 'format=jpeg&scale_crop=280x280&strip';

export default function ShopItems() {
	const { t }           = useTranslation();
	const location        = useLocation();
	const [args, setArgs] = useState({
		image_variation : imgKey,
		results_per_page: 20,
		sort            : 'Basic.Priority:desc',
		...buildArgsFromUrlSearchParams(location, 'Basic.Tags'),
	});

	const [filters, setFilters]         = useState(args?.query?.['Basic.Tags']?.map(f => ({ Classify_Tag__: f })) || []);
	const [products]                    = useRest('Catalog/Product:search', args, false, 5000);
	const [tags]                        = useRest('Classify/@/Tag', { sort: 'Refcount:desc' });
	const [showFilters, setShowFilters] = useState(false);

	const goToPage = page_no => setArgs(a => ({ ...a, page_no }));

	return (
		<>
			<ArgsBuilderFromUrl args={args} setArgs={setArgs} filters={filters} />
			<div className="relative w-full flex items-start mt-6">
				{tags?.data?.length > 0 && (
					<div
						className={`shop-filters ${showFilters ? 'open' : ''} flex-none w-56`}
						onClick={() => setShowFilters(false)}
					>
						<ShopMenu baseTags={tags} filters={filters} setFilters={setFilters} />
					</div>
				)}
				<div className="flex-1 md:pl-10">
					<div className="flex items-center pt-2 pb-4 md:pb-0">
						<Btn
							size="xs"
							className="md:hidden mr-2"
							onClick={() => setShowFilters(v => !v)}
						>
							<HiOutlineFilter />
						</Btn>
						<span className="text-2xl font-bold">{t('browse_products')}</span>
					</div>
					<div className="md:hidden my-4">
						<SelectedFilters
							filters={filters}
							setFilters={setFilters}
						/>
					</div>
					<div className="flex flex-wrap flex-row justify-evenly items-stretch pb-4">
						{!products && <Loader />}
						{products && products.result !== 'success' ? <ModuleNotLoaded /> : ''}
						{products?.data?.data?.length > 0 ? (
							products.data.data.map((product, i) => {
								return <ShopItem product={product} key={i}/>;
							})
						) : (
							<div className="mt-4">{t('shop_error_empty')}</div>
						)}
					</div>
					{products?.paging && (
						<Paging
							page_max={products.paging.page_max}
							page_no={products.paging.page_no}
							onClick={goToPage}
						/>
					)}
				</div>
			</div>
		</>
	);
}
