import PropTypes              from 'prop-types';
import React, { useCallback } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useSetSnackbar }        from '../../../hooks/useSnackbar';
import { useAutoRefresher }      from '../../../hooks/useAutoRefresher';
import Alert from '../../layout/Alert/Alert';

const OrderProcessingAlert = ({ refresh }) => {
	const setSnackbar = useSetSnackbar();
	const { t } = useTranslation();

	const refresher = useCallback(() => {
		refresh().then(o => {
			if (o.data.Status !== 'completed') return;
			setSnackbar(t('order_completed_success'), 'success');
		});
	}, []);

	useAutoRefresher(refresher, 60 * 1000);

	return (
		<Alert type={'warning'}>
			<p className='font-bold pxl-display-font'>{t('order_processing_title')}</p>
			<p>
				<Trans i18nKey='order_processing_warn'>
					sample<br/>
					<strong>sample</strong>
				</Trans>
			</p>
		</Alert>
	);
};

export default OrderProcessingAlert;

OrderProcessingAlert.propTypes = {
	refresh: PropTypes.func.isRequired
};
