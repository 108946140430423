import 'url-search-params-polyfill';

if (!String.prototype.replaceAll) {
	String.prototype.replaceAll = function(str, newStr){

		// If a regex pattern
		if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
			return this.replace(str, newStr);
		}

		// If a string
		return this.replace(new RegExp(str, 'g'), newStr);

	};
}

Promise.prototype.finally = Promise.prototype.finally || {
	finally (fn) {
		const onFinally = callback => Promise.resolve(fn()).then(callback);
		return this.then(
			result => onFinally(() => result),
			reason => onFinally(() => Promise.reject(reason))
		);
	}
}.finally;
