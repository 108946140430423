import PropTypes                              from 'prop-types';
import React                                  from 'react';
import { useRest }                            from '@karpeleslab/react-klbfw-hooks';
import { getPlannerEventSeatReserveEndpoint } from '../../../hooks/usePlannerEventSeatReserve';
import EventReserveDetails                    from '../EventReserveDetails/EventReserveDetails';
import Loader                                 from '../../layout/Loader/Loader';

const EventTicketListWithReserve = ({ event }) => {
	const params = { Status: { '$in': ['reserve_paid', 'reserve_conf'] }, results_per_page: 100 };
	const [reserves] = useRest(getPlannerEventSeatReserveEndpoint(event.Planner_Event__), params, true, 1000);

	return (
		<>
			{(reserves?.data?.length > 0) && (
				<div className={'p-5'}>
					{reserves.data.map(reserve => (
						<EventReserveDetails
							key={reserve.Planner_Event_Seat_Reserve__}
							reserve={reserve}
						/>
					))}
				</div>
			)}

			{reserves === null && (
				<div className={'p-5 mt-5'}>
					<Loader/>
				</div>
			)}
		</>
	);
};

export default EventTicketListWithReserve;

EventTicketListWithReserve.propTypes = {
	event: PropTypes.object.isRequired
};
