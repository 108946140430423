import React, {useEffect,} from 'react';
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';


const StripeIntent = ({setStripe, setStripeElements, onChange}) => {
	const stripe = useStripe();
	const stripeElements = useElements();

	useEffect(() => {
		setStripe(stripe);
		setStripeElements(stripeElements);
	}, [setStripe, setStripeElements, stripe, stripeElements]);

	const paymentElementOptions = {
		layout: 'tabs',
	};

	return (
		<PaymentElement options={paymentElementOptions} onChange={onChange}/>
	);
};

export default StripeIntent;

StripeIntent.propTypes = {
	onChange: PropTypes.func.isRequired,
	setStripe: PropTypes.func.isRequired,
	setStripeElements: PropTypes.func.isRequired
};
