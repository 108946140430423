import React, {
	createContext,
	useCallback,
	useState
} from 'react';
import PropTypes from 'prop-types';

const defaultMusicContext = {
	currentIdx: -1,
	playing: false,
	queue: []
};

export const MusicContext = createContext(defaultMusicContext);

const MusicContextContainer = ({children}) => {
	const [defaultCtx, setDefaultCtx] = useState(defaultMusicContext);

	const addToQueue = useCallback((item, playNow = false) => {
		const res = {...defaultCtx};
		let idxInQueue = defaultCtx.queue.findIndex(t => t.track.Music_Label_Record__ === item.track.Music_Label_Record__);
		if (idxInQueue >= 0) {
			res.currentIdx = idxInQueue;
			res.playing = true;
		} else {
			res.currentIdx = playNow ? defaultCtx.queue.length : defaultCtx.currentIdx;
			res.playing = playNow ? true : defaultCtx.playing;
			res.queue = [...defaultCtx.queue, item];
		}

		setDefaultCtx(res);
	}, [defaultCtx, setDefaultCtx]);

	const playIdxInQueue = useCallback(idx => {
		if (idx >= defaultCtx.queue.length) return;

		setDefaultCtx(ctx => ({
			...ctx,
			currentIdx: idx,
			playing: true,
		}));
	}, [defaultCtx, setDefaultCtx]);

	const removeFromQueue = useCallback(trackId => {
		const indexOfTrackInQueue = defaultCtx.queue.indexOf(item => item.track.Music_Label_Record__ === trackId);
		const filteredQueue = defaultCtx.queue.filter(item => item.track.Music_Label_Record__ !== trackId);

		setDefaultCtx(ctx => ({
			currentIdx: filteredQueue.length < 1 || indexOfTrackInQueue >= 0 ? -1 : ctx.currentIdx,
			playing: filteredQueue.length < 1 || indexOfTrackInQueue >= 0 ? false : ctx.playing,
			queue: filteredQueue
		}));
	}, [defaultCtx, setDefaultCtx]);

	const togglePlay = useCallback(() => {
		setDefaultCtx(ctx => ({...ctx, playing: !ctx.playing}));
	}, [setDefaultCtx]);

	const clearQueue = useCallback(() => setDefaultCtx(defaultMusicContext), [setDefaultCtx]);

	const setPlaylist = useCallback((items, startIndex = 0) => {
		if (!items || items.length === 0) {
			return;
		}

		const res = {...defaultMusicContext};
		res.currentIdx = startIndex;
		res.playing = true;
		res.queue = items;

		setDefaultCtx(res);
	}, [defaultCtx, setDefaultCtx]);
	
	const nextSong = useCallback(() => {
		if (defaultCtx.queue.length <= defaultCtx.currentIdx + 1) {
			setDefaultCtx(ctx => ({...ctx, currentIdx: 0}));
			return;
		}

		setDefaultCtx(ctx => ({...ctx, currentIdx: ctx.currentIdx + 1}));
	}, [defaultCtx, setDefaultCtx]);

	const previousSong = useCallback(() => {
		if (defaultCtx.currentIdx - 1 < 0) {
			setDefaultCtx(ctx => ({...ctx, currentIdx: ctx.queue.length - 1}));
			return;
		}

		setDefaultCtx(ctx => ({...ctx, currentIdx: ctx.currentIdx - 1}));
	}, [defaultCtx, setDefaultCtx]);

	return (
		<MusicContext.Provider
			value={{
				...defaultCtx,
				addToQueue,
				playIdxInQueue,
				removeFromQueue,
				clearQueue,
				setPlaylist,
				togglePlay,
				nextSong,
				previousSong,
			}}
		>
			{children}
		</MusicContext.Provider>
	);
};

MusicContextContainer.propTypes = {
	children: PropTypes.node
};

export default MusicContextContainer;
