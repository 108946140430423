import React     from 'react';
import PropTypes from 'prop-types';

import ImgLoader from '../../layout/ImgLoader/ImgLoader';

import './ShopImageSelector.css';

export default function ShopImageSelector({images, onClick, selected}) {
	if (images.length > 5) {
		images.length = 5;
	}

	return <div className={'flex md:flex-col flex-row flex-wrap w-auto justify-evenly md:justify-start'}>
		{images.map((image, i) => (
			<ImgLoader
				key={`img-${i}`}
				src={image.Url}
				rootClasses={
					`shop-image-selector-item m-4${
						selected === image.Url ? ' selected' : ''
					}`
				}
				alt={`img-${i}`}
				width="100px"
				height="100px"
				onClick={() => onClick(image.Url)}
			/>
		))}
	</div>;
}

ShopImageSelector.propTypes = {
	images: PropTypes.array,
	onClick: PropTypes.func,
	selected: PropTypes.string,
};
