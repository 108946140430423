import React              from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes          from 'prop-types';
import Tag from '../Tags/Tag';

import { dateFormat } from '../../../utils/date';

export default function AlbumInList ({ album }) {
	const { t }        = useTranslation();

	return (
		<article 
			className="flex flex-col sm:flex-row justify-start items-center sm:items-start m-4 py-6 mb-6"
		>
			<div className="flex-initial flex flex-col justify-center items-center px-6">
				<div className="text-4xl pxl-display-font font-bold text-center">
					{album.Music_Label_Album.Name}
				</div>
				<p className="text-sm text-center italic my-2">
					{ t('released_on', {
						date: dateFormat(album.Music_Label_Album.Published, 'L')
					})}
				</p>
				<div className="text-center">
					<Tag tag={album.Music_Label_Album.Music_Genre.Genre} />
				</div>
				{(album.Tags && album.Tags.length > 0) &&
				<div className="text-center mt-2">
					{album.Tags.map((t) => ('hidden' in t.Flags ?? {}) ? <></> : <Tag key={t.Classify_Tag__} tag={t.Name} />)}
				</div>}
				<div className="text-center mt-6">
					<p>{album.Music_Label_Album.Description}</p>
				</div>
			</div>
		</article> 
	);
}

AlbumInList.propTypes = {
	album: PropTypes.shape({ 
		Music_Label_Album: PropTypes.shape({ 
			Description: PropTypes.string,
			Duration   : PropTypes.string,
			GTIN       : PropTypes.string,
			Music_Genre: PropTypes.shape({
				Genre: PropTypes.string 
			}),
			Music_Label_Album: PropTypes.string,
			Name             : PropTypes.string,
			Published        : PropTypes.shape({ 
				full: PropTypes.string,
				iso : PropTypes.string
			}),
		}),
		Music_Label_Album__: PropTypes.string,
		Tags: PropTypes.arrayOf(PropTypes.shape({
			Name: PropTypes.string.isRequired,
			Classify_Tag__: PropTypes.string.isRequired,
		}))
	})
};
