import React                from 'react';
import { useTranslation }   from 'react-i18next';
import { Get, getHostname } from '@karpeleslab/klbfw';


const Text = () => {
	const { t }  = useTranslation();
	const ticket = Get('_ticket'); 
	const slug = Get('_slug'); 

	return (
		<textarea id="mail-text" defaultValue={
			ticket.Name + ', ' + 
			'\n\r' +
			t('booking_confirmed_description', { website: getHostname() }) +
			'\n\r' +
			'⚠️ ' + t('reservation_pay_on_location', { price: ticket.Planner_Event_Seat.Price.display }) +
			'\n\r' +
			'\n\r' +
			'• ' + t('booked_by') + `: ${ticket.Name} (${ticket.Tel})` +
			'\n\r' +
			'• ' + t('event_name') + `: ${ticket.Planner_Event.Name}` +
			'\n\r' +
			'• ' + t('event_location') + `: ${ticket.Planner_Event.Location}` +
			'\n\r' +
			'• ' + t('event_seat') + `: ${ticket.Planner_Event_Seat.Seat_Name_Display ?? ticket.Planner_Event_Seat.Seat_Name}` +
			'\n\r' +
			'• ' + t('event_serial') + `: ${ticket.Serial}` +
			'\n\r' +
			'\n\r' +
			t('reserve_info_text') + `: ${`https://${getHostname()}/events/${slug}`}` +
			'\n\r' +
			t('user_password_email_footer', { website: getHostname() })
		}>
		</textarea>
	);
};

export default Text;
