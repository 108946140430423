import React, { useContext }     from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Btn              from '../../core/inputs/Btn/Btn';
import LangSwitch       from '../../core/inputs/LangSwitch/LangSwitch';
import SocialNetworkBtn from './SocialNetworkBtn';

import PxlImage from '../../../assets/img/logo-pxl.svg';

import { TemplateContext } from '../../../context/TemplateContext';

export default function Footer () {
	const { t }                = useTranslation();
	const { artist, location } = useContext(TemplateContext);

	return (
		<footer className="w-full text-xs mt-12 border-t border-white border-opacity-30">
			<div className="box-border w-full max-w-7xl mx-auto px-4 text-xs flex flex-col-reverse lg:flex-row justify-strech items-center py-16">
				<div className="flex-none mt-6 lg:mt-0 mr-0 lg:mr-12">
					<img alt={'pxl-logo'} src={PxlImage} />
				</div>
				<div className="flex-1 flex flex-col items-stretch">
					<div className="w-full flex flex-col lg:flex-row items-start">
						{location && (
							<div className="mr-4 mb-4 lg:mb-0">
								<Btn
									to="/legal-information"
									variant="discrete"
									size="xs"
									accessibility={t('legal_information')}
								>
									{t('legal_information')}
								</Btn>
							</div>
						)}
						<div className="mr-4 mb-4 lg:mb-0">
							<Btn
								to="/pages/tos"
								variant="discrete"
								size="xs"
								accessibility={t('terms_of_service')}
							>
								{t('terms_of_service')}
							</Btn>
						</div>
						<div className="mr-4 mb-4 lg:mb-0">
							<Btn
								to="/pages/privacy"
								variant="discrete"
								size="xs"
								accessibility={t('privacy_policy')}
							>
								{t('privacy_policy')}
							</Btn>
						</div>
						<div className={'ml-3 md:ml-0'}>
							<LangSwitch />
						</div>
					</div>
					<hr className="w-full opacity-30 my-6" />
					<div className="w-full flex flex-col lg:flex-row justify-between items-center px-3">
						<div className="flex items-center pb-6 lg:pb-0">
							{(artist && artist.Links.length > 0) && <p className="mr-6">{t('follow_us_on')}</p>}
							{artist && (
								artist.Links.map(({ Link, Type }) => (
									<SocialNetworkBtn key={Type} link={Link} name={Type} />
								))
							)}
						</div>
						<div>
							<p>
								<Trans
									values={{date:(new Date()).getFullYear()}}
									i18nKey="copyright_sentence"
								>
									sample
									<a
										href="https:///www.pxl.jp/"
										className="text-pxl-accent1 hover:underline"
										target="_blank"
										rel="noopener noreferrer"
										aria-label={t('copyright_sentence')}
									>sample
									</a>
								</Trans>
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
