import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';

const PaymentMethodTab = ({method, selected, onChange, disabled = false}) => {
	const {t} = useTranslation();
	const selectedClass = selected === method ? 'primary' : '';
	const disabledClass = disabled ? 'cursor-pointer' : '';
	switch (method) {
		case 'Free':
		case 'OnFile':
		case 'Wallet':
		case 'Stripe':
		case 'CashOnDelivery':
			return (
				<li
					key={method}
					className={`pxl-btn ${selectedClass} ${disabledClass}`}
					onClick={() => onChange(method)}
				>
					<div className={'p-5 px-8'}>{t(`payment_method_${method.toLowerCase()}`)}</div>
				</li>
			);
		default:
			return null;
	}
};

export default PaymentMethodTab;

PaymentMethodTab.propTypes = {
	disabled: PropTypes.bool,
	method: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	selected: PropTypes.string
};
