import React, { createContext, useState } from 'react';
import PropTypes                          from 'prop-types';
import { getSettings }                    from '@karpeleslab/klbfw';

const defaultContext = getSettings(); // We directly have it

export const TemplateContext = createContext(defaultContext);

const TemplateContextContainer = ({ children }) => {
	const [context] = useState(getSettings());

	return (
		<TemplateContext.Provider value={context}>
			{children}
		</TemplateContext.Provider>
	);
};

TemplateContextContainer.propTypes = {
	children: PropTypes.node
};

export default TemplateContextContainer;
