import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';

const EventLocation = ({ event }) => {
	const { t } = useTranslation();

	return (
		<table className={'w-full h-full'}>
			<tbody>
				{(event.Location_Url && event.Location_Url !== '') && <tr>
					<td className='pr-2' />
					<td>
						<a
							className={'underline'}
							href={event.Location_Url}
							target='_blank'
							rel='noopener noreferrer'
							aria-label={t('event_website')}
						>
							{t('event_website')}
						</a>
					</td>
				</tr>
				}

				{(event.Location && event.Location !== '') &&
				<tr>
					<td className='pr-2'>{t('event_location')}</td>
					<td>{event.Location}</td>
				</tr>
				}

				{(event.Location_Postal && event.Location_Postal !== '') &&
				<tr>
					<td className='pr-2'>{t('event_address')}</td>
					<td>{event.Location_Postal}</td>
				</tr>
				}
			</tbody>
		</table>
	);
};

export default EventLocation;

EventLocation.propTypes = {
	event: PropTypes.object.isRequired,
};
