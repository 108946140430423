import React, { useContext, useState } from 'react';
import { useTranslation }              from 'react-i18next';
import { upload }                      from '@karpeleslab/klbfw';

import AvatarEditor   from '../Login/AvatarEditor';
import Btn            from '../../core/inputs/Btn/Btn';
import ChangeEmail    from './ChangeEmail/ChangeEmail';
import ChangePassword from './ChangePassword/ChangePassword';
import { FiLoader }   from 'react-icons/fi';

import { UserContext }  from '../../../context/UserContext';

import './UserInformation.css';

const avatarField = {
	cat   : 'special',
	name  : 'profile_pic',
	param : { purpose: 'main' },
	target: 'User/@/Profile:addImage',
	type  : 'image'
};

const UserInformation = () => {
	const { t }                                 = useTranslation();
	const [uploadingAvatar, setUploadingAvatar] = useState(false);
	const [fileBlob, setFileBlob]               = useState(null);
	const [user]                                = useContext(UserContext);

	const setAvatarImage = (name, blob) => {
		setFileBlob(blob);
	};

	const uploadAvatar = () => {
		upload.onprogress = d => {
			let runningCount = 0;
			let blockTotal = 0;
			let progressTotal = 0;
			d.running.forEach((running) => {
				if (running.status !== 'pending' && running.status !== 'complete') {
					runningCount++;
					progressTotal += running.done;
					blockTotal += running.blocks;
				}
			});

			const ratio = blockTotal > 0 ? progressTotal / blockTotal : 0;

			setUploadingAvatar({ count: runningCount, ratio });
		};

		upload.append('User/@/Profile:addImage', fileBlob, avatarField.param)
			.then(() => {
				setFileBlob(null);
				setUploadingAvatar(false);
			});
		upload.run();
	};

	return (
		<div className="flex flex-wrap items-center user-info-section">
			<div className="flex flex-col items-center flex-none w-full md:w-1/2">
				<AvatarEditor
					key={'profile_pic'}
					id={'profile_pic'}
					fieldId={'profile_pic'}
					field={avatarField}
					currentImg={
						user &&
						user.user &&
						user.user.data &&
						user.user.data.Profile &&
						user.user.data.Profile.Media_Image
							? user.user.data.Profile.Media_Image.Url
							: null
					}
					setAvatarImage={setAvatarImage}
				/>
				{!!fileBlob && (
					<Btn
						variant="primary"
						onClick={uploadAvatar}
						disabled={!!uploadingAvatar || !fileBlob}
						accessibility={t('change_avatar')}
					>
						{uploadingAvatar ? <FiLoader className="animate-spin" /> : t('change_avatar')}
					</Btn>
				)}
			</div>
			<div className="flex-none w-full md:w-1/2">
				{user?.user?.data?.Has_Password && (
					<div className="mb-8">
						<ChangeEmail />
					</div>
				)}
				<ChangePassword />
			</div>
		</div>
	);
};

export default UserInformation;