import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';

import ReactAvatarEditor from 'react-avatar-editor';

const AvatarImage = ({ defaultAvatar, editor, handleSetImage, handleScale, state }) => {
	const { t } = useTranslation();

	if (state.imgPath !== null) {
		return (
			<div className="text-center">
				<div
					className="w-full mx-auto"
				>
					<ReactAvatarEditor
						ref={editor}
						image={state.imgPath}
						width={state.width}
						height={state.height}
						borderRadius={100}
						border={0}
						scale={state.scale}
						rotate={0}
						onLoadSuccess={handleSetImage}
						onPositionChange={handleSetImage}
						className="w-44 h-44 mx-auto bg-trueGray-700"
					/>
				</div>
				<input
					name="scale"
					type="range"
					onChange={handleScale}
					min="1"
					max="2"
					step="0.01"
					defaultValue="1"
					className="w-44 mx-auto"
				/>
			</div>
		);
	} else {
		return (
			<div className="w-44 mx-auto rounded-full overflow-hidden">
				<img
					src={defaultAvatar}
					alt={t('default_avatar')}
					className="w-44 h-44 mx-auto bg-trueGray-700"
				/>
			</div>
		);
	}
};

export default AvatarImage;

AvatarImage.propTypes = {
	defaultAvatar : PropTypes.string,
	editor        : PropTypes.object,
	handleSetImage: PropTypes.func,
	handleScale   : PropTypes.func,
	state         : PropTypes.object,
};
