import React              from 'react';
import { Get }            from '@karpeleslab/klbfw';
import { useTranslation } from 'react-i18next';
import { getHostname }    from '@karpeleslab/klbfw';

const Text = () => {
	const { t } = useTranslation();
	return (
		<textarea
			id="mail-text"
			defaultValue={
				Get('_user').Profile.Display_Name +
				'\n\r' +
				t('mail_payment_invalidate_desc_txt', { website: getHostname() }) +
				'\n\r' +
				getHostname() +
				'\n\r' + 
				`https://${getHostname()}/\n\r`
			}
		/>
	);
};

export default Text;
