import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRest }        from '@karpeleslab/react-klbfw-hooks';

import { getPlannerEventSeatReserveEndpoint } from '../../../hooks/usePlannerEventSeatReserve';

const EventStreamAlertLogged = ({ event }) => {
	const { t } = useTranslation();

	const params = { Status: { '$in': ['reserve_paid', 'reserve_conf'] }, results_per_page: 100 };
	const [reserves] = useRest(getPlannerEventSeatReserveEndpoint(event.Planner_Event__), params, false, 3000);

	return (reserves?.data?.length < 1) && (
		<div className={'bg-pxl-darkGray p-5 mb-5 text-center'}>
			{t('event_live_will_be_here')}
		</div>
	);
};

export default EventStreamAlertLogged;

EventStreamAlertLogged.propTypes = {
	event: PropTypes.object.isRequired
};
