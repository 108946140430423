import React from 'react';
import PropTypes from 'prop-types';
import {useCatalogCartItemRemove} from '@karpeleslab/klb-react-services';
import {useTranslation} from 'react-i18next';
import {FiLoader} from 'react-icons/fi';
import {CgRemove} from 'react-icons/all';
import './CartMobile.css';

export const CartMobile = ({items, refreshCart, readonly}) => {
	return <>
		{items.map((item, i) => <CartItemMobile
			key={i}
			item={item}
			refreshCart={refreshCart}
			readonly={readonly}
		/>)}
	</>;
};

CartMobile.propTypes = {
	items: PropTypes.array.isRequired,
	readonly: PropTypes.bool,
	refreshCart: PropTypes.func.isRequired
};

export const CartItemMobile = ({item, refreshCart, readonly = false }) => {
	const { t } = useTranslation();
	const [remove, removing] = useCatalogCartItemRemove(item.key);

	const handleDelete = () => {
		remove().then((newCart) => {
			refreshCart(newCart);
		});
	};

	return <div className={'px-3 flex justify-between mb-5 rounded-2xl cart-mobile'}>
		<div className={'flex flex-col w-full'}>
			<div className={'my-2 flex justify-between'}>
				<div className={'p-4 bg-pxl-darkGray'}>{item.data['Basic.Name']}</div>
				<div className={'ml-4 p-4 bg-pxl-darkGray'}>{`x ${item.quantity}`}</div>
			</div>
			<div className={'flex my-2 flex justify-between'}>
				<div className={'p-4 bg-pxl-darkGray'}>{t('cart_item_price_raw')}</div>
				<div className={'ml-4 p-4 bg-pxl-darkGray'}>{item.price.raw.display}</div>
			</div>
			<div className={'flex my-2 flex justify-between'}>
				<div className={'p-4 bg-pxl-darkGray'}>{t('cart_item_vat_rate')}</div>
				<div className={'ml-4 p-4 bg-pxl-darkGray'}>{`${item.price.tax_rate}%`}</div>
			</div>
			<div className={'flex my-2 flex justify-between'}>
				<div className={'p-4 bg-pxl-darkGray'}>{t('cart_item_total')}</div>
				<div className={'ml-4 p-4 bg-pxl-darkGray'}>{item.price.tax.display}</div>
			</div>
		</div>
		{!readonly && (<div className={'ml-4 mt-4'}>
			<div className={'bg-pxl-darkGray p-1 rounded-full'}>
				<button className={'btn'} onClick={() => handleDelete()} disabled={removing}>
					{removing ? <FiLoader className='animate-spin'/> : <CgRemove/>}
				</button>
			</div>
		</div>)
		}
	</div>;
};

CartItemMobile.propTypes = {
	item: PropTypes.object.isRequired,
	readonly: PropTypes.bool,
	refreshCart: PropTypes.func.isRequired
};