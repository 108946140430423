import React, { useEffect, useRef, useState } from 'react';
import {useTranslation}                       from 'react-i18next';
import {useRest}                    from '@karpeleslab/react-klbfw-hooks';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import ArgsBuilderFromUrl, { 
	buildArgsFromUrlSearchParams 
} from '../../components/core/utils/ArgsBuilderFromUrl/ArgsBuilderFromUrl';

import Hero              from '../../components/layout/Hero/Hero';
import LayoutHelmet      from '../../components/layout/LayoutHelmet/LayoutHelmet';
import Paging            from '../../components/layout/Paging/Paging';
import ScheduleComponent from '../../components/pxl/Schedule/Schedule';

const imageVariation = 'strip&scale_crop=375x211';
const noFilters      = [];

export default function Schedule() {
	const {t}           = useTranslation();
	const {year, month} = useParams();
	const history       = useHistory();
	const location      = useLocation();

	const thisYear = (new Date()).getFullYear();
	let addToIndex = 12;

	if (year && year < thisYear) addToIndex = 0;
	else if (year && year > thisYear) addToIndex = 24;

	const [selectedMonth, setSelectedMonth] = useState({
		index: (month ? parseInt(month) : (new Date()).getMonth() + 1) + addToIndex,
		month: month ? parseInt(month) : ((new Date()).getMonth() + 1),
		year: year ? parseInt(year) : (new Date()).getFullYear()
	});

	const [args, setArgs] = useState({
		image_variation : imageVariation,
		month           : (selectedMonth.month < 10 ? '0' : '') + selectedMonth.month.toString(),
		year            : selectedMonth.year.toString(),
		results_per_page: 5,
		...buildArgsFromUrlSearchParams(location)
	});

	const [data] = useRest('Planner/@planner/Schedule', args);

	const goToPage = page_no => setArgs(a => ({ ...a, page_no }));

	const pushHistory = monthObj => history.push(`/events/${monthObj.year}/${monthObj.month}`);


	const didMount1 = useRef(false);
	const didMount2 = useRef(false);

	useEffect(() => {
		// We should ignore that for the first loading as it already setup in the initial state
		if(!didMount1.current) {didMount1.current = true; return; }
		const thisYear = (new Date()).getFullYear();
		let addToIndex = 12;

		if (year && year < thisYear) addToIndex = 0;
		else if (year && year > thisYear) addToIndex = 24;

		let currentMonth = month ? parseInt(month) : ((new Date()).getMonth() + 1);
		if (currentMonth < 1) currentMonth = 12;

		setSelectedMonth({
			index: currentMonth + addToIndex,
			month: currentMonth,
			year: year ? parseInt(year) : (new Date()).getFullYear()
		});
	}, [year, month]); // eslint-disable-line

	useEffect(() => {
		// We should ignore that for the first loading as it already setup in the initial state
		if(!didMount2.current) {didMount2.current = true; return; }

		setArgs(a => ({
			...a,
			month  : (selectedMonth.month < 10 ? '0' : '') + selectedMonth.month.toString(),
			year   : selectedMonth.year.toString(),
			page_no: 1
		}));
	}, [selectedMonth]);

	return (
		<>
			<LayoutHelmet title={t('schedule')}/>
			<Hero title={t('schedule')}/>
			<ArgsBuilderFromUrl args={args} setArgs={setArgs} filters={noFilters} />
			<main className="w-full max-w-7xl mb-auto mx-auto">
				<div className="w-full max-w-7xl my-0 mx-auto flex flex-col justify-center">
					<h2 className="text-center text-3xl my-4 pxl-display-font">
						{selectedMonth.year}
					</h2>
					<ScheduleComponent
						imageVariation={imageVariation}
						loading={!data}
						pushHistory={pushHistory}
						selectedMonth={selectedMonth}
						schedule={data ? data.data : null}
					/>
				</div>
				{data?.paging && (
					<Paging
						page_max={data.paging.page_max}
						page_no={data.paging.page_no}
						onClick={goToPage}
					/>
				)}
			</main>
		</>
	);
}
