import React              from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'react-i18next';

import TicketType from '../EventTicketDetails/TicketType';

const EventReserveDetails = ({ reserve }) => {
	const { t } = useTranslation();

	return (
		<div className={'mb-2 p-4 flex flex-col items-center bg-pxl-darkGray'}>
			<div className="mb-2 text-2xl font-bold">
				{t('event_ticket_number', { value: reserve.Serial })}
			</div>
			{reserve.Allocated_Seat && <div className="mb-2 text-xl font-bold">
				{t('event_seat_number', { value: reserve.Allocated_Seat })}
			</div>}
			<div className="mb-2 text-4xl">
				<TicketType type={reserve.Planner_Event_Seat.Type} />
			</div>
			<div className="font-bold">
				{reserve.Planner_Event_Seat.Seat_Name_Display ?? reserve.Planner_Event_Seat.Seat_Name}
			</div>
			<div>
				{reserve.Planner_Event_Seat.Description}
			</div>
		</div>
	);
};

export default EventReserveDetails;

EventReserveDetails.propTypes = {
	reserve: PropTypes.object.isRequired,
};
