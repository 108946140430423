import React, {useContext} from 'react';
import {UserContext}       from '../../../context/UserContext';
import {useRest}           from '@karpeleslab/react-klbfw-hooks';
import Alert               from '../../layout/Alert/Alert';
import {useTranslation}    from 'react-i18next';
import Loader              from '../../layout/Loader/Loader';
import WavyLine            from '../../layout/WavyLine/WavyLine';
import PostComment         from './PostComment';
import Comment             from './Comment';
import PropTypes           from 'prop-types';

const Comments = ({id, path, isReply = false}) => {
	const {t} = useTranslation();
	const [{loggedIn}] = useContext(UserContext);
	const [comments, refresh] = useRest('Social/Comment', {key: id});

	return (
		<div className="w-full">
			<WavyLine className="max-w-5xl mx-auto my-6 py-6"/>
			{!isReply && <div className="relative z-10 w-100 max-w-5xl py-6 text-2xl">
				<p>{t('comments_title')}</p>
			</div>}
			{(!loggedIn && !isReply) && <div className="my-4 w-full">
				<Alert type={'info'} className="text-center">
					{t('comment_login_warning')}
				</Alert>
			</div>
			}
			{comments === null && <div className="my-4 w-full">
				<Loader/>
			</div>}
			{comments && comments.data.length < 1 &&
			<div className="my-4 w-full text-center">
				<p>{isReply ? t('reply_empty') : t('comment_empty')}</p>
			</div>
			}
			{comments && comments.data.length > 0 &&
			<div className="my-4 w-full">
				{comments.data.map((c) => <Comment comment={c} key={c.Social_Comment__}/>)}
			</div>
			}
			{loggedIn && <PostComment
				path={path}
				refresh={refresh}
			/>
			}
		</div>
	);
};

export default Comments;

Comments.propTypes = {
	id: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	isReply: PropTypes.bool
};