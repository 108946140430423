import React                from 'react';
import { useTranslation }   from 'react-i18next';
import { Get, getHostname } from '@karpeleslab/klbfw';

import EmailLayout from '../../../components/email/EmailLayout';

const Html = () => {
	const { t }  = useTranslation();
	const ticket = Get('_ticket'); 
	const slug = Get('_slug'); 

	return (
		<EmailLayout
			intro={t('booking_confirmed')}
			paragraphs={[
				`${ticket.Name},`,
				'&nbsp;',
				t('booking_confirmed_description', { website: getHostname() }),
				'&nbsp;',
				`⚠️ <b>${t('reservation_pay_on_location', { price: ticket.Planner_Event_Seat.Price.display })}</b>`
			]}
			showContactBtn={true}
		>
			<table
				border="0"
				cellSpacing="0"
				cellPadding="0"
				width="100%"
				style={{ width: '100%' }}
			>
				<tbody>
					<tr>
						<td>
							<table
								cellSpacing="0"
								cellPadding="0"
								border="0"
								align="center"
								width="100%"
								style={{ lineHeight: '1.4rem' }}
							>
								<tr>
									<td width="20%" nowrap>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{t('booked_by')}
										</p>
									</td>
									<td>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											: {`${ticket.Name} (${ticket.Tel})`}
										</p>
									</td>
								</tr>
								<tr>
									<td width="20%" nowrap>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{t('event_name')}
										</p>
									</td>
									<td>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											: {ticket.Planner_Event.Name}
										</p>
									</td>
								</tr>
								<tr>
									<td width="20%" nowrap>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{t('event_location')}
										</p>
									</td>
									<td>
										<a
											href={ticket.Planner_Event.Location_Url}
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff',
												textDecoration: 'underline'
											}}
										>
											: {ticket.Planner_Event.Location}
										</a>
									</td>
								</tr>
								<tr>
									<td width="20%" nowrap>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{t('event_seat')}
										</p>
									</td>
									<td>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											: {ticket.Planner_Event_Seat.Seat_Name_Display ?? ticket.Planner_Event_Seat.Seat_Name}
										</p>
									</td>
								</tr>
								<tr>
									<td width="20%" nowrap>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											{t('event_serial')}
										</p>
									</td>
									<td>
										<p
											style={{
												fontFamily: 'sans-serif',
												fontSize: '14px',
												fontWeight: 'normal',
												margin: '0',
												marginBottom: '15px',
												color: '#ffffff'
											}}
										>
											: {ticket.Serial}
										</p>
									</td>
								</tr>
								{ticket.Planner_Event_Seat.Type !== 'stream' && 
								<tr>
									<td></td>
									<td>
										<img
											alt="qrCode" style={{width: '200px'}}
											src={Get('_qrcode')}
											width="200"/>
									</td>
								</tr>}
							</table>
						</td>
					</tr>
					<tr>
						<td>
							<hr size="2" color="#ffffff" />
						</td>
					</tr>
					<tr>
						<td>
							<p
								style={{
									fontFamily: 'sans-serif',
									fontSize: '14px',
									fontWeight: 'normal',
									margin: '0',
									marginBottom: '15px',
									color: '#ffffff'
								}}
							>
								{t('reserve_info_text')}
							</p>
							<p
								style={{
									fontFamily: 'sans-serif',
									fontSize: '14px',
									fontWeight: 'normal',
									margin: '0',
									marginBottom: '15px',
									color: '#ffffff'
								}}
							>&nbsp;</p>
							<a
								href={`https://${getHostname()}/events/${slug}`}
								target="_blank"
								rel="noopener noreferrer"
								style={{
									display: 'inline-block',
									color: '#ffffff',
									backgroundColor: '#fc0046',
									border: 'solid 1px #fc0046',
									boxSizing: 'border-box',
									cursor: 'pointer',
									textDecoration: 'none',
									fontSize: '14px',
									fontWeight: 'bold',
									margin: '0',
									padding: '12px 25px',
									textTransform: 'capitalize',
									borderColor: '#fc0046'
								}}
								aria-label={t('contact_us')}
							>
								{ticket.Planner_Event.Name}
							</a> 
							<p
								style={{
									fontFamily: 'sans-serif',
									fontSize: '14px',
									fontWeight: 'normal',
									margin: '0',
									marginBottom: '15px',
									color: '#ffffff'
								}}
							>&nbsp;</p>
						</td>
					</tr>
					<tr>
						<td>
							<hr size="2" color="#ffffff" />
						</td>
					</tr>
					<tr>
						<td>
							<p
								style={{
									fontFamily: 'sans-serif',
									fontSize: '14px',
									fontWeight: 'normal',
									margin: '0',
									marginBottom: '15px',
									color: '#ffffff'
								}}
							>
								{t('contact_us_desc')}
							</p>
						</td>
					</tr>
				</tbody>
			</table>
		</EmailLayout>
	);
};

export default Html;
