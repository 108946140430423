import React from 'react';
import PropTypes from 'prop-types';

export default function Tag({tag}) {
	return <div className={'inline-block text-sm p-1 mx-1 sm:ml-0 sm:mr-2 border border-white pxl-display-font'}>
		{tag}
	</div>;
}

Tag.propTypes = {
	tag: PropTypes.string.isRequired,
};