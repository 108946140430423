import React        from 'react';
import PropTypes    from 'prop-types';
import {dateFormat} from '../../../utils/date';

import ImgLoader            from '../../layout/ImgLoader/ImgLoader';
import {Link}               from 'react-router-dom';
import {IoImageOutline}     from 'react-icons/io5';

import './NewsCard.css';
import {BsFillPinAngleFill} from 'react-icons/all';

const NewsCard = ({ addFilters, filters, imageVariation, item, type = 'news' }) => {
	return (
		<div className="box-border w-full md:w-1/2 py-4 px-3 md:px-4">
			<Link
				to={`/${type}/${item.Slug}`}
				className="pxl-newscard relative block w-full border border-transparent hover:border-white cursor-pointer"
				tabIndex={1}
				aria-label={item.Title}
			>
				<div className="relative z-10">
					<div
						className="relative w-full bg-pxl-darkGray"
						style={{ paddingTop: '56.25%' }}
					>
						{item.Drive_Item ? (
							<div className="absolute top-0 left-0 w-full h-full">
								<ImgLoader
									src={
										item.Drive_Item.Media_Image[imageVariation] ||
										item.Drive_Item.View_Url 
									}
									width="100%"
									height="100%"
									className="object-cover"
								/>
							</div>
						) : (
							<div className="absolute top-0 left-0 w-full h-full flex justify-center items-center text-white text-4xl">
								<IoImageOutline />
							</div>
						)}
						{item.Weight > 0 && <BsFillPinAngleFill className={'pinned-news-icon'} size={'24px'} />}
					</div>
				</div>
				<div className="pxl-newscard__content relative z-10 w-full flex flex-col justify-between items-stretch py-4 bg-pxl-darkerGray">
					{item.Tags !== null && <div>
						<div className="my-2 flex flex-wrap">
							{item.Tags.map(t => (
								('hidden' in (t.Flags ?? {})) ? '' :
									<button
										key={t.Classify_Tag__}
										className={
											`bg-gradient-to-r from-pxl-accent1 to-pxl-accent2 border-0 p-1 text-xs ${
												filters.findIndex(f => 
													f.Classify_Tag__ === t.Classify_Tag__
												) >= 0 
													? 'text-white pointer-events-none' 
													: ''
											} mr-2`
										}
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											addFilters([t]);
										}}
										tabIndex={1}
									>
										{t.Full_Name}
									</button>
							))}
							<p>{dateFormat(item.Published, 'L').replaceAll('/', '.')}</p>
						</div>
						<div className="text-2xl pxl-display-font font-semibold">{item.Title}</div>
					</div>}
					<div>
						<p>{item.Short_Contents}</p>
					</div>
				</div>
			</Link>
		</div>
	);
};

NewsCard.propTypes = {
	addFilters: PropTypes.func,
	filters   : PropTypes.arrayOf(
		PropTypes.shape({
			Classify_Tag__: PropTypes.string,
			Full_Name     : PropTypes.Full_Name
		})
	),
	imageVariation: PropTypes.string,
	item: PropTypes.shape({
		Weight: PropTypes.number,
		Content_Cms_Author: PropTypes.shape({
			Name: PropTypes.string
		}),
		Content_Cms_Entry__: PropTypes.string,
		Drive_Item: PropTypes.shape({
			Media_Image: PropTypes.object,
			View_Url   : PropTypes.string
		}),
		Published: PropTypes.shape({
			unixms: PropTypes.string
		}),
		Short_Contents: PropTypes.string,
		Slug          : PropTypes.string,
		Tags          : PropTypes.arrayOf(
			PropTypes.shape({
				Classify_Tag__: PropTypes.string,
				Full_Name     : PropTypes.Full_Name
			})
		),
		Title: PropTypes.string
	}),
	type: PropTypes.string
};

export default NewsCard;
