import React, { useContext } from 'react';
import { useTranslation }    from 'react-i18next';
import { Get, getHostname }  from '@karpeleslab/klbfw';

import Helmet from 'react-helmet';
import Html   from './Html';
import Text   from './Text';

import { TemplateContext } from '../../../context/TemplateContext';
import { dateFormat }                          from '../../../utils/date';
import { getFrom, getFromName, getSubjectTag } from '../../utils';

const Mail = () => {
	const { t } = useTranslation();
	const { artist } = useContext(TemplateContext);
	const now = dateFormat('now', 'L LT');

	const recoverUrl = `https://${getHostname()}/recover-password/${
		Get('_recover_user').User_Id
	}?k=${encodeURIComponent(Get('_user_key'))}`;

	return (
		<div>
			<Helmet>
				<title>{t('mail_forgotten_pwd_subject', { artist: getSubjectTag() })}</title>
				<meta name='mail-from-name' content={getFromName()}/>
				<meta name='mail-from' content={getFrom()}/>
			</Helmet>
			<Html artist={artist?.Name} recoverUrl={recoverUrl} date={now}/>
			<Text recoverUrl={recoverUrl} date={now}/>
		</div>
	);
};

export default Mail;
