import React, {useContext}        from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation }         from 'react-i18next';
import { useRest }                from '@karpeleslab/react-klbfw-hooks';

import ArticleHead        from '../../components/pxl/ArticleHead/ArticleHead';
import Btn                from '../../components/core/inputs/Btn/Btn';
import Hero               from '../../components/layout/Hero/Hero';
import LayoutHelmet       from '../../components/layout/LayoutHelmet/LayoutHelmet';
import Loader             from '../../components/layout/Loader/Loader';
import ModuleNotLoaded    from '../../components/core/utils/ModuleNotLoaded/ModuleNotLoaded';
import NoSsr              from '../../components/core/utils/NoSsr/NoSsr';
import NotFound           from '../../components/core/utils/NotFound/NotFound';
import ShareButtons       from '../../components/core/inputs/ShareButtons/ShareButtons';
import PropTypes          from 'prop-types';
import { getLocale }      from '@karpeleslab/klbfw';
import ArticleLangWarning from '../../components/pxl/ArticleHead/ArticleLangWarning';
import Comments           from '../../components/pxl/Comments/Comments';
import {TemplateContext}  from '../../context/TemplateContext';
import Like               from '../../components/pxl/Like/Like';
import Author             from '../../components/pxl/Author/Author';

const useQuery = () => new URLSearchParams(useLocation().search);

export default function Article({ cmsId = '@news', enableShare = true, enableComments = false, fanclub = false, author = true, backLink = '/news' }) {
	const { t } = useTranslation();
	const { slug } = useParams();
	const query = useQuery();
	const settings           = useContext(TemplateContext);

	if(!settings.social || !settings.social.includes('comment')) {
		enableComments = false;
	}

	const params = { slug: decodeURIComponent(slug) };
	if (query.get('_preview'))
		params._preview = query.get('_preview');
	params.image_variation = 'strip&scale=0x300';

	const [data] = useRest(fanclub ? 'Membership/@fanclub:loadArticleSlug' : `Content/Cms/${cmsId}:loadSlug`, params, true);

	// Used when we need to perform a slug redirect, we need to identify the route we need to redirect to
	let target = 'news'; // /news/slug
	if (fanclub) target = 'articles'; // /articles/slug
	else if (cmsId !== '@news') target = 'pages'; // /pages/slug

	return (
		<>
			<Hero title={data?.data?.content_cms_entry_data?.Title || t('news')}/>
			{data === null
				? <Loader/>
				: (data !== false && data.result !== 'success')
					? <ModuleNotLoaded/>
					: data?.data?.content_cms_entry_data
						? (
							<>
								<LayoutHelmet
									description={data.data.content_cms_entry_data.Short_Contents}
									image={data.data.content_cms_entry_data?.Top_Drive_Item?.View_Url}
									title={data.data.content_cms_entry_data.Title}
								/>
								{(getLocale() !== data?.data?.content_cms_entry_data?.Language__) &&
								<ArticleLangWarning
									entry={data?.data?.content_cms_entry_data}
									alternatives={data.alternatives ?? []}
									target={target}
								/>
								}
								<div className='w-full max-w-7xl my-0 px-3 md:px-0 mx-auto'>
									<ArticleHead
										lang={data?.data?.content_cms_entry_data?.Language__}
										image={data?.data?.content_cms_entry_data?.Top_Drive_Item?.Media_Image?.['strip&scale=0x300']}
										summary={data?.data.content_cms_entry_data.Short_Contents}
										tags={data?.data?.content_cms_entry_data?.Content_Cms_Entry?.Tags ?? []}
										title={data?.data.content_cms_entry_data?.Title}
									/>
									{enableShare &&
									<main className='flex justify-center py-4'>
										<NoSsr>
											<ShareButtons/>
										</NoSsr>
										<Like
											entryId={data.data.content_cms_entry_data.Content_Cms_Entry__}
											initialLikeCount={data?.data?.content_cms_entry_data?.Likes.Like_Count ?? 0}
											initialLiked={data?.data?.content_cms_entry_data?.Likes.Liked ?? false}
										/>
									</main>
									}
									<div
										className='py-4'
										dangerouslySetInnerHTML={{ __html: data.data.content_cms_entry_data.Contents }}
									/>
									{author && <Author
										author={author ? data?.data.content_cms_entry_data?.Content_Cms_Author?.Name : undefined}
										date={data?.data?.content_cms_entry_data?.Published}
									/> }
									{enableComments && <div
										className='py-4'
									>
										<Comments
											id={data.data.content_cms_entry_data.Comments.Key}
											path={`Content/Cms/Entry/${data.data.content_cms_entry_data.Content_Cms_Entry__}:comment`}
										/>
									</div>
									}
								</div>
							</>
						) : (
							<main className='p-6'>
								<LayoutHelmet title={t('error_content_not_found_title')}/>
								<NotFound/>
							</main>
						)
			}
			<div className='flex w-full justify-center py-10'>
				<Btn variant='inverted' size='large' to={backLink} accessibility={t('go_back')}>
					{t('go_back')}
				</Btn>
			</div>
		</>
	);
}

Article.propTypes = {
	cmsId: PropTypes.string,
	backLink: PropTypes.string,
	enableShare: PropTypes.bool,
	enableComments: PropTypes.bool,
	author: PropTypes.bool,
	fanclub: PropTypes.bool,
};
