import React     from 'react';
import PropTypes from 'prop-types';

import Btn                           from '../../core/inputs/Btn/Btn';
import { FiLoader }                  from 'react-icons/fi';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';

import './BtnWithArrow.css';

const BtnWithArrow = ({ className, children, loading = false, accessibility = '', ...etcetera }) => {
	return (
		<Btn className={`pxl-btn-with-arrow ${className}`} accessibility={accessibility} {...etcetera}>
			<div className="flex justify-center items-center">
				{loading ? (
					<FiLoader className="animate-spin" />
				) : (
					<>
						<div className="arrow">
							<HiOutlineArrowNarrowRight />
						</div>
						{children}
					</>
				)}
			</div>
		</Btn>
	);
};

BtnWithArrow.propTypes = {
	children : PropTypes.node,
	className: PropTypes.string,
	accessibility: PropTypes.string,
	loading  : PropTypes.bool
};

export default BtnWithArrow;
